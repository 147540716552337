import { useState, useCallback } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';

import {
  arrayOfObjectsToArray,
  handleApiResult,
  OperationManager,
  OPERATIONS,
  useAppMutation,
  useAppQuery,
} from '../../utils';
import { userShoppingItemQueries } from '../queries/userShoppingItemQueries';

const useUserShoppingItem = () => {
  const [userShoppingItem, setUserShoppingItem] = useState(null);
  const [userShoppingItemMany, setUserShoppingItemMany] = useState([]);
  const [userShoppingItems, setUserShoppingItems] = useState([]);
  const [userShoppingItemsOps, setUserShoppingItemsOps] = useState({});
  const [userShoppingItemsOpsManager] = useState(new OperationManager());
  const [userShoppingItemsMap, setUserShoppingItemsMap] = useState({});

  userShoppingItemsOpsManager.setStateControls(
    userShoppingItemsOps,
    setUserShoppingItemsOps
  );

  const handleApiResultOpts = {
    hookName: 'useUserShoppingItem',
    opsManager: userShoppingItemsOpsManager,
    setItem: setUserShoppingItem,
    setItemsMany: setUserShoppingItemMany,
    setItems: setUserShoppingItems,
    afterSettingToUse: (items) => {
      const itemsMap = items.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
      setUserShoppingItemsMap(itemsMap);
    },
  };

  /**
   * GRAPHQL CALLERS
   */

  const [mutationUserShoppingItemCreate] = useAppMutation({
    queries: userShoppingItemQueries,
    queryName: 'userShoppingItemCreate',
    onDone: handleApiResult(OPERATIONS.CREATE, handleApiResultOpts),
  });

  const [mutationUserShoppingItemUpdate] = useAppMutation({
    queries: userShoppingItemQueries,
    queryName: 'userShoppingItemUpdate',
    onDone: handleApiResult(OPERATIONS.UPDATE, handleApiResultOpts),
  });

  const [mutationUserShoppingItemRemoveMany] = useAppMutation({
    queries: userShoppingItemQueries,
    queryName: 'userShoppingItemRemoveMany',
    onDone: handleApiResult(OPERATIONS.REMOVE_MANY, handleApiResultOpts),
  });

  const [queryUserShoppingItemList] = useAppQuery({
    queries: userShoppingItemQueries,
    queryName: 'userShoppingItemList',
    onDone: handleApiResult(OPERATIONS.LIST, handleApiResultOpts),
  });

  const [queryUserShoppingItemGetMany] = useAppQuery({
    queries: userShoppingItemQueries,
    queryName: 'userShoppingItemGetMany',
    onDone: handleApiResult(OPERATIONS.GET_MANY, handleApiResultOpts),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const userShoppingItemCreate = useCallback(
    (variables) => {
      userShoppingItemsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.CREATE,
        OP_RESULT_CODES.SAVING
      );

      mutationUserShoppingItemCreate({ variables });
    },
    [mutationUserShoppingItemCreate, userShoppingItemsOpsManager]
  );

  const userShoppingItemUpdate = useCallback(
    (variables) => {
      userShoppingItemsOpsManager.setStartOperation(
        variables?.where?.id,
        OPERATIONS.UPDATE,
        OP_RESULT_CODES.SAVING
      );

      mutationUserShoppingItemUpdate({ variables });
    },
    [mutationUserShoppingItemUpdate, userShoppingItemsOpsManager]
  );

  const userShoppingItemRemoveMany = useCallback(
    (variables) => {
      userShoppingItemsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.REMOVE_MANY,
        OP_RESULT_CODES.DELETING
      );

      mutationUserShoppingItemRemoveMany({ variables });
    },
    [mutationUserShoppingItemRemoveMany, userShoppingItemsOpsManager]
  );

  const userShoppingItemList = useCallback(
    (variables) => {
      userShoppingItemsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING
      );

      queryUserShoppingItemList({ variables });
    },
    [queryUserShoppingItemList, userShoppingItemsOpsManager]
  );

  const userShoppingItemGetMany = useCallback(
    (variables) => {
      userShoppingItemsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.GET_MANY,
        OP_RESULT_CODES.LOADING
      );

      queryUserShoppingItemGetMany({ variables });
    },
    [queryUserShoppingItemGetMany, userShoppingItemsOpsManager]
  );

  return {
    userShoppingItem,
    userShoppingItems,
    userShoppingItemMany,

    userShoppingItemCreate,
    userShoppingItemGetMany,
    userShoppingItemUpdate,
    userShoppingItemRemoveMany,
    userShoppingItemList,

    userShoppingItemsOpsManager,
    userShoppingItemsMap,
  };
};

export { useUserShoppingItem };
