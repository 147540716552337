import { memo, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@sdflc/ui';
import { DictionaryContext, LocalizationContext } from 'contexts';
import { converStringToValue } from '@sdflc/utils';

const SelectDictionaryItem = memo((props) => {
  const { dictionaryUid, ...rest } = props;
  const { getText } = useContext(LocalizationContext);
  const { dictionary } = useContext(DictionaryContext);

  const options = useMemo(() => {
    const availableDics = dictionary.getDataFirst({});

    if (!Array.isArray(availableDics[dictionaryUid]?.items)) {
      return;
    }

    if (dictionary.isLoading()) {
      return;
    }

    return availableDics[dictionaryUid]?.items.map((item) => {
      return {
        value: converStringToValue(
          item.itemValue,
          availableDics[dictionaryUid]?.valueType
        ),
        text: item.l10n?.itemName ?? item.itemValue,
      };
    });
  }, [dictionary, dictionaryUid]);

  return (
    <Select
      {...rest}
      options={options}
      loading={dictionary.isLoading()}
      loadingMessage={getText('generic.loading')}
      placeholder={getText(rest.placeholder)}
    />
  );
});

SelectDictionaryItem.displayName = 'SelectDictionaryItem';

SelectDictionaryItem.propTypes = {
  ...Select.propTypes,
  dictionaryUid: PropTypes.string,
};

SelectDictionaryItem.defaultProps = {};

export { SelectDictionaryItem };
