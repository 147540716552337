import { useState, useCallback } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';
import {
  handleApiResult,
  OperationManager,
  OPERATIONS,
  processApiUserLocation,
  useAppMutation,
  useAppQuery,
} from '../../utils';
import { arrayOfObjectsToArray } from '../../utils/transformers';
import { userAllowListItemQueries } from '../queries/userAllowListItemQueries';

const useApiUserAllowListItem = () => {
  const [userAllowListItem, setUserAllowListItem] = useState(null);
  const [userAllowListItems, setUserAllowListItems] = useState([]);
  const [userAllowListItemsOps, setUserAllowListItemsOps] = useState({});
  const [userAllowListItemsOpsManager] = useState(new OperationManager());

  userAllowListItemsOpsManager.setStateControls(
    userAllowListItemsOps,
    setUserAllowListItemsOps
  );

  const handleApiResultOpts = {
    hookName: 'useUserAllowListItem',
    opsManager: userAllowListItemsOpsManager,
    setItem: setUserAllowListItem,
    setItems: setUserAllowListItems,
    transformItem: (item) => {
      return {
        ...item,
        userLocation: processApiUserLocation(item?.userLocation),
      };
    },
  };

  /**
   * GRAPHQL CALLERS
   */

  const [mutationUserAllowListItemCreate] = useAppMutation({
    queries: userAllowListItemQueries,
    queryName: 'userAllowListItemCreate',
    onDone: handleApiResult(OPERATIONS.CREATE, handleApiResultOpts),
  });

  const [mutationUserAllowListItemUpdate] = useAppMutation({
    queries: userAllowListItemQueries,
    queryName: 'userAllowListItemUpdate',
    onDone: handleApiResult(OPERATIONS.UPDATE, handleApiResultOpts),
  });

  const [mutationUserAllowListItemRemoveMany] = useAppMutation({
    queries: userAllowListItemQueries,
    queryName: 'userAllowListItemRemoveMany',
    onDone: handleApiResult(OPERATIONS.REMOVE_MANY, handleApiResultOpts),
  });

  const [queryUserAllowListItemList] = useAppQuery({
    queries: userAllowListItemQueries,
    queryName: 'userAllowListItemList',
    onDone: handleApiResult(OPERATIONS.LIST, handleApiResultOpts),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const userAllowListItemCreate = useCallback(
    (variables) => {
      userAllowListItemsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.CREATE,
        OP_RESULT_CODES.SAVING
      );

      mutationUserAllowListItemCreate({ variables });
    },
    [mutationUserAllowListItemCreate, userAllowListItemsOpsManager]
  );

  const userAllowListItemUpdate = useCallback(
    (variables) => {
      userAllowListItemsOpsManager.setStartOperation(
        variables?.where?.id,
        OPERATIONS.UPDATE,
        OP_RESULT_CODES.SAVING
      );

      mutationUserAllowListItemUpdate({ variables });
    },
    [mutationUserAllowListItemUpdate, userAllowListItemsOpsManager]
  );

  const userAllowListItemRemoveMany = useCallback(
    (variables) => {
      userAllowListItemsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.REMOVE_MANY,
        OP_RESULT_CODES.DELETING
      );

      mutationUserAllowListItemRemoveMany({ variables });
    },
    [mutationUserAllowListItemRemoveMany, userAllowListItemsOpsManager]
  );

  const userAllowListItemList = useCallback(
    (variables) => {
      userAllowListItemsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING
      );

      queryUserAllowListItemList({ variables });
    },
    [queryUserAllowListItemList, userAllowListItemsOpsManager]
  );

  return {
    userAllowListItem,
    userAllowListItems,

    userAllowListItemCreate,
    userAllowListItemUpdate,
    userAllowListItemRemoveMany,
    userAllowListItemList,

    userAllowListItemsOpsManager,
  };
};

export { useApiUserAllowListItem };
