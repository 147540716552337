import { ORDER_ITEM_STATUSES } from 'config';
import { BUTTONS } from '../config/actions';

export const getOrderItemActionButtons = (args) => {
  const { shopMode, readOnly, orderItem, order } = args ?? {};
  const { isMine, amIShopper } = order ?? {};
  const isTaken = orderItem.status === ORDER_ITEM_STATUSES.TAKEN;
  const valueId = orderItem.id;

  const buttons = [];
  const canComment = isMine || amIShopper;

  if (shopMode) {
    if (!readOnly) {
      buttons.push(BUTTONS.EDIT);
    }
    // In shopping mode user can edit item and view item comments
    if (canComment) {
      buttons.push(BUTTONS.VIEW_COMMENTS);
    }
    // and either return item button or take button
    // buttons.push(isTaken ? BUTTONS.RETURN_ORDER_ITEM : BUTTONS.TAKE_ORDER_ITEM);
  } else if (readOnly) {
    // In read-only mode user can only see comments
    if (canComment) {
      buttons.push(BUTTONS.VIEW_COMMENTS);
    }
    if (isTaken) {
      // this is to ...
      buttons.push(BUTTONS.TAKEN);
    }
  } else if (valueId) {
    // In the edit mode user can unselect item, ie remove from the order or edit it ...
    buttons.push(
      ...[
        BUTTONS.VIEW_COMMENTS,
        BUTTONS.EDIT_SELECTED,
        BUTTONS.ORDER_ITEM_UNSELECT,
      ]
    );
  } else {
    // Or select item, ie add to the order
    buttons.push(BUTTONS.ORDER_ITEM_SELECT);
  }

  return buttons;
};
