import { useState, useCallback, useContext } from 'react';
import { OpResult } from '@sdflc/api-helpers';
import { logger, OPERATIONS, useAppMutation, useAppQuery } from '../../utils';
import { commentsQueries } from '../queries/commentsQueries';
import { commentReactionQueries } from '../queries/commentReactionQueries';
import { AuthContext } from '../../contexts';
import { cloneDeep } from 'lodash';

const useComments = () => {
  const { appUser } = useContext(AuthContext);
  const [comment, setComment] = useState(new OpResult());
  const [comments, setComments] = useState(new OpResult());
  const [commentReaction, setCommentReaction] = useState(new OpResult());
  const [channelKey, setChannelKey] = useState('');

  const buildReactionStat = (comment) => {
    if (!comment) {
      return;
    }

    comment.mineReactions = {};
    comment.reactionState = comment.reactions.reduce((acc, item) => {
      if (!acc[item.commentReaction]) {
        acc[item.commentReaction] = {
          qty: 0,
          users: [],
        };
      }

      acc[item.commentReaction].qty++;
      acc[item.commentReaction].users.push(item.user ?? item.userCreated);

      if (item.createdBy === appUser.id) {
        comment.mineReactions[item.commentReaction] = item.id;
      }

      return acc;
    }, {});
  };

  const handleDone = (operation) => (result) => {
    switch (operation) {
      default:
        logger.warn(
          'The "useComments" hook got unexpected value of operation: ',
          operation
        );
        break;

      case OPERATIONS.LIST:
        if (result.didSucceed()) {
          result.getData().forEach(buildReactionStat);
        }
        setComments(result);
        break;

      case OPERATIONS.GET_MANY:
        if (result.didSucceed()) {
          result.getData().forEach(buildReactionStat);
        }
        setComments(result);
        break;

      case OPERATIONS.CREATE:
        if (result.didSucceed()) {
          result.getData().forEach(buildReactionStat);
        }
        setComment(result);
        setComments(
          new OpResult().setData([result.getDataFirst(), ...comments.getData()])
        );
        // TODO: Update list of user contacts
        break;

      case OPERATIONS.UPDATE:
        if (result.didSucceed()) {
          result.getData().forEach(buildReactionStat);
        }
        setComment(result);
        // TODO: Update list of user contacts
        break;

      case OPERATIONS.REMOVE_MANY:
        setComment(result);
        const ids = result.getData().map((item) => item.id);
        setComments(
          new OpResult().setData([
            ...comments.getData().filter((item) => ids.indexOf(item.id) === -1),
          ])
        );
        // TODO: Update list of user contacts
        break;
    }
  };

  const handleDoneCommentReaction = (operation) => (result) => {
    switch (operation) {
      default:
        logger.warn(
          'The "useComments" hook got unexpected value of comment reaction operation: ',
          operation
        );
        break;

      case OPERATIONS.SET:
        if (result.didSucceed()) {
          const dataItem = result.getDataFirst();

          const comment = comments
            .getData()
            .find((comment) => comment.id === dataItem.channelCommentId);

          if (comment && Array.isArray(comment.reactions)) {
            comment.reactions.push(dataItem);
            buildReactionStat(comment);
          }

          setComments(new OpResult().setData(cloneDeep(comments.getData())));
        }

        setCommentReaction(result);

        break;

      case OPERATIONS.REMOVE_MANY:
        if (result.didSucceed()) {
          const dataItem = result.getDataFirst();

          const comment = comments
            .getData()
            .find((comment) => comment.id === dataItem.channelCommentId);

          if (comment && Array.isArray(comment.reactions)) {
            comment.reactions = comment.reactions.filter(
              (reaction) => reaction.id !== dataItem.id
            );
            buildReactionStat(comment);
          }
          setComments(new OpResult().setData(cloneDeep(comments.getData())));
        }

        setCommentReaction(result);
        break;
    }
  };

  /**
   * GRAPHQL CALLERS
   */

  const [
    mutationCommentCreate,
    {
      called: commentCreateCalled,
      loading: commentCreateLoading,
      reset: commentCreateReset,
    },
  ] = useAppMutation({
    queries: commentsQueries,
    queryName: 'channelCommentCreate',
    onDone: handleDone(OPERATIONS.CREATE),
  });

  const [
    mutationCommentUpdate,
    {
      called: commentUpdateCalled,
      loading: commentUpdateLoading,
      reset: commentUpdateReset,
    },
  ] = useAppMutation({
    queries: commentsQueries,
    queryName: 'channelCommentUpdate',
    onDone: handleDone(OPERATIONS.UPDATE),
  });

  const [
    mutationCommentRemoveMany,
    {
      called: commentRemoveManyCalled,
      loading: commentRemoveManyLoading,
      reset: commentRemoveManyReset,
    },
  ] = useAppMutation({
    queries: commentsQueries,
    queryName: 'channelCommentRemoveMany',
    onDone: handleDone(OPERATIONS.REMOVE_MANY),
  });

  const [
    queryCommentList,
    { called: commentListCalled, loading: commentListLoading },
  ] = useAppQuery({
    queries: commentsQueries,
    queryName: 'channelCommentList',
    onDone: handleDone(OPERATIONS.LIST),
  });

  const [
    queryCommentGetMany,
    { called: commentGetManyCalled, loading: commentGetManyLoading },
  ] = useAppQuery({
    queries: commentsQueries,
    queryName: 'channelCommentGetMany',
    onDone: handleDone(OPERATIONS.GET_MANY),
  });

  const [
    mutationCommentReactionSet,
    {
      called: commentReactionSetCalled,
      loading: commentReactionSetLoading,
      reset: commentReactionSetReset,
    },
  ] = useAppMutation({
    queries: commentReactionQueries,
    queryName: 'commentReactionSet',
    onDone: handleDoneCommentReaction(OPERATIONS.SET),
  });

  const [
    mutationCommentReactionRemoveMany,
    {
      called: commentReactionRemoveManyCalled,
      loading: commentReactionRemoveManyLoading,
      reset: commentReactionRemoveManyReset,
    },
  ] = useAppMutation({
    queries: commentReactionQueries,
    queryName: 'commentReactionRemoveMany',
    onDone: handleDoneCommentReaction(OPERATIONS.REMOVE_MANY),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const commentCreate = useCallback(
    (variables) => {
      setComment(new OpResult().setData(variables).startLoading());
      mutationCommentCreate({ variables });
    },
    [mutationCommentCreate]
  );

  const commentUpdate = useCallback(
    (variables) => {
      setComment(new OpResult().setData(variables).startLoading());
      mutationCommentUpdate({ variables });
    },
    [mutationCommentUpdate]
  );

  const commentRemoveMany = useCallback(
    (variables) => {
      setComment(new OpResult().startLoading());
      mutationCommentRemoveMany({ variables });
    },
    [mutationCommentRemoveMany]
  );

  const commentList = useCallback(
    (comments, variables) => {
      setComments(comments.clearErrors().startLoading().clone());
      queryCommentList({ variables });
    },
    [queryCommentList]
  );

  const commentGetMany = useCallback(
    (variables) => {
      setComments(comment.clearErrors().startLoading().clone());
      queryCommentGetMany({ variables });
    },
    [queryCommentGetMany, comment]
  );

  const commentReactionSet = useCallback(
    (variables) => {
      setCommentReaction(new OpResult().setData(variables).startLoading());
      mutationCommentReactionSet({ variables });
    },
    [mutationCommentReactionSet]
  );

  const commentReactionRemoveMany = useCallback(
    (variables) => {
      setCommentReaction(new OpResult().setData(variables).startLoading());
      mutationCommentReactionRemoveMany({ variables });
    },
    [mutationCommentReactionRemoveMany]
  );

  return {
    comment,
    comments,
    commentCreate,
    commentCreateCalled,
    commentCreateLoading,
    commentCreateReset,
    commentUpdate,
    commentUpdateCalled,
    commentUpdateLoading,
    commentUpdateReset,
    commentRemoveMany,
    commentRemoveManyCalled,
    commentRemoveManyLoading,
    commentRemoveManyReset,
    commentList,
    commentListCalled,
    commentListLoading,
    commentGetMany,
    commentGetManyCalled,
    commentGetManyLoading,
    commentReaction,
    commentReactionSet,
    commentReactionSetCalled,
    commentReactionSetLoading,
    commentReactionSetReset,
    commentReactionRemoveMany,
    commentReactionRemoveManyCalled,
    commentReactionRemoveManyLoading,
    commentReactionRemoveManyReset,

    channelKey,
    setChannelKey,
  };
};

export { useComments };
