import React, { memo, useContext } from 'react';
//import PropTypes from 'prop-types';
//import { Tabs } from '@sdflc/ui';
import { OrderItems } from '../OrderItems';
// import { OrdersView } from './OrdersView';
import { /*LocalizationContext,*/ OrdersContext } from 'contexts';
// import { OPERATIONS } from 'utils';

const OrderShop = memo((props) => {
  //const { getText } = useContext(LocalizationContext);
  const { orderHooks /*, orderId, orderGetOp*/ } = useContext(OrdersContext);
  const {
    order,
    //ordersOpsManager,
    //handleClickAction: handleClickOrderAction,
  } = orderHooks;

  //const updateOp = ordersOpsManager.get(orderId, OPERATIONS.UPDATE);

  return (
    <OrderItems shopMode order={order} />
    // <Tabs>
    //   <Tabs.Tab
    //     id='tab-order-items'
    //     name={getText('pages.orders.tabs.orderItems')}
    //     icon='ListAlt'
    //   >
    //     <OrderItems shopMode order={order} />
    //   </Tabs.Tab>
    //   <Tabs.Tab
    //     id='tab-order-edit'
    //     name={getText('pages.orders.tabs.deliveryInfo')}
    //     icon='LocalShipping'
    //   >
    //     <OrdersView
    //       shopMode
    //       getOp={orderGetOp}
    //       updateOp={updateOp}
    //       order={order}
    //       onClickAction={handleClickOrderAction}
    //       ordersOpsManager={ordersOpsManager}
    //     />
    //   </Tabs.Tab>
    // </Tabs>
  );
});

OrderShop.displayName = 'OrderShop';
OrderShop.propTypes = {};
OrderShop.defaultProp = {};

export { OrderShop };
