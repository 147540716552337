import React, { memo, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DictionaryContext, LocalizationContext } from 'contexts';

const ValueDictionaryItem = memo((props) => {
  const { dictionaryUid, hidden, value } = props;
  const { dictionary } = useContext(DictionaryContext);
  const { getText } = useContext(LocalizationContext);

  const valueToRender = useMemo(() => {
    let tmpValue = value;

    if (dictionary.isLoading()) {
      return getText('generic.loading');
    }

    const availableDics = dictionary.getDataFirst({});

    if (!Array.isArray(availableDics[dictionaryUid]?.items)) {
      return `${value}*`;
    }

    for (let item of availableDics[dictionaryUid]?.items) {
      // eslint-disable-next-line
      if (item.itemValue == value) {
        tmpValue = item.l10n?.itemName ?? item.itemValue;
        break;
      }
    }

    return tmpValue;
  }, [value, dictionaryUid, dictionary, getText]);

  if (hidden === true) {
    return null;
  }

  return <span>{valueToRender}</span>;
});

ValueDictionaryItem.displayName = 'ValueDictionaryItem';

ValueDictionaryItem.propTypes = {
  dictionaryUid: PropTypes.string,
  value: PropTypes.any,
  hidden: PropTypes.bool,
};

ValueDictionaryItem.defaultProps = {
  value: null,
  hidden: false,
};

export { ValueDictionaryItem };
