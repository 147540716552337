import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { ToolBarItem } from '@sdflc/ui';

import { logger, OperationManager, OPERATIONS } from 'utils';
import { ACTIONS_BUTTONS, ACTIONS_IDS } from 'config';

import { useBankAccount } from 'graphql/hooks';
import { onlyPropsOfObject } from '../../../utils/utils';
import { LocalizationContext } from 'contexts';

import { bankAccountForm } from './bankAccountForm';

const tplNewUserContact = {
  countryId: 'CA',
  institutionNumber: '',
  transitNumber: '',
  accountNumber: '',
  locationId: null,
  status: 100,
};

const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const useBankAccounts = (props) => {
  const { getText } = useContext(LocalizationContext);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);

  const {
    bankAccount,
    bankAccounts,
    bankAccountList,
    bankAccountCreate,
    bankAccountUpdate,
    bankAccountRemoveMany,
    bankAccountsOpsManager,
  } = useBankAccount();

  const contactId = formData?.id || '';
  const createOp = bankAccountsOpsManager.getCreateOp();
  const updateOp = bankAccountsOpsManager.get(contactId, OPERATIONS.UPDATE);
  const saveOp = contactId ? updateOp : createOp;
  const saveOpResult = saveOp.result;
  const removingOps = bankAccountsOpsManager.getOpValues(
    OPERATIONS.REMOVE_MANY
  );

  useEffect(() => {
    setOpen(!!formData);
  }, [formData]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of new banking account

    // Banking account item is not being saved yet
    if (!createOp.called) {
      return;
    }

    // banking account create operation result is not in progress and succeeded
    if (!createOp.result.isInProgress() && createOp.result.didSucceed()) {
      bankAccountsOpsManager.reset(
        OperationManager.defaultContext,
        OPERATIONS.CREATE
      );
      setFormData(null);
    }
  }, [createOp, bankAccountsOpsManager]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of exising banking account

    // banking account is not being saved yet
    if (!updateOp.called) {
      return;
    }

    // banking account operation result is not in progress and succeeded
    if (!updateOp.result.isInProgress() && updateOp.result.didSucceed()) {
      bankAccountsOpsManager.reset(contactId, OPERATIONS.UPDATE);
      setFormData(null);
    }
  }, [updateOp, bankAccountsOpsManager, contactId]);

  const handleClickAction = useCallback(
    (action, item) => {
      switch (action.id) {
        default:
          logger.warn('The "useBankAccounts" got an unexpected action:', {
            action,
            item,
          });
          break;

        case ACTIONS_IDS.ADD:
        case ACTIONS_IDS.CREATE:
          bankAccountsOpsManager.reset(
            OperationManager.defaultContext,
            OPERATIONS.CREATE
          );
          setFormData(cloneDeep(tplNewUserContact));
          break;

        case ACTIONS_IDS.SAVE:
          if (!item.id) {
            bankAccountCreate({ params: item });
          } else {
            bankAccountUpdate({
              where: { id: item.id },
              params: onlyPropsOfObject(item, tplNewUserContact),
            });
          }
          break;

        case ACTIONS_IDS.CANCEL:
          setFormData(null);
          break;

        case ACTIONS_IDS.EDIT:
          bankAccountsOpsManager.reset(item?.id, OPERATIONS.UPDATE);
          setFormData(cloneDeep(item));
          break;

        case ACTIONS_IDS.REMOVE:
          bankAccountRemoveMany({ where: [{ id: item.id }] });
          break;

        case ACTIONS_IDS.MAKE_DEFAULT:
          bankAccountUpdate({
            where: { id: item.id },
            params: {
              isDefault: true,
            },
          });
          break;
      }
    },
    [
      bankAccountCreate,
      bankAccountUpdate,
      bankAccountRemoveMany,
      bankAccountsOpsManager,
    ]
  );

  const handleClickClose = useCallback(() => {
    handleClickAction({ id: ACTIONS_IDS.CANCEL }, null);
  }, [handleClickAction]);

  const formCfg = useMemo(() => {
    return {
      sections: bankAccountForm,
      labels: getText('pages.bankAccounts.form.labels'),
      hints: getText('pages.bankAccounts.form.hints'),
    };
  }, [getText]);

  const formActions = useMemo(() => [BUTTONS.SAVE, BUTTONS.CANCEL], []);
  const actionInProgress = useMemo(
    () => (saveOpResult.isInProgress() ? [ACTIONS_IDS.SAVE] : []),
    [saveOpResult]
  );

  const toolBarItems = useMemo(() => {
    return [
      // {
      //   id: 'keyword',
      //   type: ToolBarItem.types.COMPONENT,
      //   name: 'keyword',
      //   value: filters.keyword,
      //   component: Input,
      //   placeholder: getText('generic.search'),
      //   leftIcon: 'Search',
      // },
      { ...BUTTONS.ADD, type: ToolBarItem.types.BUTTON },
    ];
  }, []);

  return {
    open,
    bankAccount,
    bankAccounts,
    bankAccountList,
    formData,
    formCfg,
    formActions,
    setFormData,
    handleClickAction,
    handleClickClose,
    bankAccountsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOp,
    saveOpResult,
    removingOps,
  };
};

export { useBankAccounts };
