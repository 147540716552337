import axios from 'axios';
import { OpResult, OP_RESULT_CODES } from '@sdflc/api-helpers';

import { appSettings } from '../config';
import { logger } from './logger';

export const uploadFile = async (args) => {
  const { formData } = args || {};

  if (!formData) {
    return OpResult.fail(
      OP_RESULT_CODES.VALIDATION_FAILED,
      {},
      'Cannot upload file as no fromData provided'
    );
  }

  try {
    const uploadResult = await axios.post(
      `${appSettings.storageApiUrl}/uploaded-file`,
      formData,
      {
        withCredentials: true,
        responseType: 'json',
        headers: {
          'x-space-id': appSettings.authSpace,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return new OpResult(uploadResult.data);
  } catch (ex) {
    logger.log('Failed to upload file:', ex);
    return OpResult.fail(OP_RESULT_CODES.FAILED, {}, 'Failed to upload file');
  }
};
