import { useState, useCallback } from 'react';
import { OpResult } from '@sdflc/api-helpers';
import { useAppQuery } from '../../utils';
import { dictionaryQueries } from '../queries/dictionary';

const useDictionary = () => {
  const [dictionary, setDictionary] = useState(new OpResult());

  const [queryDictionaryItemList, { called: dictionaryItemListCalled }] =
    useAppQuery({
      queries: dictionaryQueries,
      queryName: 'dictionaryItemList',
      onDone: (result) => {
        let data = null;

        if (result.didSucceed()) {
          const rawData = result.getData();

          data = rawData.reduce((acc, item) => {
            if (acc[item.dictionary?.uid] === undefined) {
              acc[item.dictionary?.uid] = {
                valueType: Number(item.dictionary?.valueType),
                items: [],
              };
            }

            acc[item.dictionary?.uid].items.push(item);

            return acc;
          }, {});

          result.setData({
            ...dictionary.getDataFirst({}),
            ...data,
          });
        }

        setDictionary(result);
      },
    });

  const dictionaryItemList = useCallback(
    (params, dictionary) => {
      if (dictionary instanceof OpResult) {
        setDictionary(dictionary.clearErrors().startLoading().clone());
      }
      queryDictionaryItemList({ variables: params });
    },
    [queryDictionaryItemList]
  );

  return {
    dictionaryItemList,
    dictionary,
    dictionaryItemListCalled,
  };
};

export { useDictionary };
