import { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@sdflc/ui';
import { contactTypeToIcon } from 'utils/iconHelpers';

const UserContactType = memo((props) => {
  const { contactTypeId } = props;

  return <Icon name={contactTypeToIcon(contactTypeId)} />;
});

UserContactType.displayName = 'UserContactType';

UserContactType.propTypes = {
  contactTypeId: PropTypes.string,
};

UserContactType.defaultProps = {
  contact: 'phone',
};

export { UserContactType };
