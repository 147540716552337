import { useState, useCallback, useEffect } from 'react';
import { OpResult } from '@sdflc/api-helpers';
import { logger, OPERATIONS, useAppQuery } from '../../utils';
import { trulesQueries } from '../queries/trulesQueries';
import { cloneDeep } from 'lodash';

const queue = [];
const enqueued = new Set();

const buildKey = (params) => {
  return `${params.transformGroupId}-${params.transformString}`.toLowerCase();
};

const useTRules = () => {
  const [transformResult, setTransformResult] = useState(new OpResult());
  const [transformManyResult, setTransformManyResult] = useState(
    new OpResult().setCode(1000)
  );
  const [transforms, setTransforms] = useState({});

  const handleDone = (operation) => (result) => {
    switch (operation) {
      default:
        logger.warn(
          'The "useTRules" hook got unexpected value of operation: ',
          operation
        );
        break;

      case OPERATIONS.TRANSFORM:
        setTransformResult(result);
        break;

      case OPERATIONS.TRANSFORM_MANY:
        if (result.didSucceed()) {
          /*
            {
              "transformGroupId": "a69ade1f-6905-4aa5-bfff-f8809fe7bad5",
              "transformString": "Watermelon",
              "transformResult": "https://storage.googleapis.com/shopadropa-assets-bucket/images/shopping-items/watermelon.jpg",
              "appliedTransformRuleIds": [
                "29657da1-f32a-4b01-9789-a342dfb533ed"
              ]
            },
          */

          /*
            create an object 2 levels for easy access:
            {
              [transformGroupId]: {
                [requestString]: transformResult
              }
            }
          */

          const data = result.data.reduce((acc, item) => {
            if (!acc[item.transformGroupId]) {
              acc[item.transformGroupId] = {};
            }

            acc[item.transformGroupId][item.transformString] =
              item.transformResult;

            return acc;
          }, transforms);

          setTransforms(cloneDeep(data));

          //logger.log('Setting new transforms', data);
        }

        //setTransformManyResult(result);

        break;
    }
  };

  // useEffect(() => {
  //   logger.log('New transforms', transforms);
  // }, [transforms]);

  const [
    queryTransform,
    { called: transformCalled, loading: transformLoading },
  ] = useAppQuery({
    queries: trulesQueries,
    queryName: 'transform',
    onDone: handleDone(OPERATIONS.TRANSFORM),
  });

  const [queryTransformMany] = useAppQuery({
    queries: trulesQueries,
    queryName: 'transformMany',
    onDone: handleDone(OPERATIONS.TRANSFORM_MANY),
  });

  const transform = useCallback(
    (params) => {
      setTransformResult(new OpResult().startLoading());
      queryTransform({ variables: params });
    },
    [queryTransform]
  );

  const transformMany = useCallback(
    (variables) => {
      setTransformManyResult(new OpResult().startLoading());
      queryTransformMany({ variables });
    },
    [queryTransformMany]
  );

  const transformEnque = (params) => {
    const key = buildKey(params);

    if (enqueued.has(key)) {
      return;
    }

    //logger.log('Enqueue request to transform', params);

    setTransforms((state) => {
      if (!state[params.transformGroupId]) {
        state[params.transformGroupId] = {};
      }

      state[params.transformGroupId][params.transformString] = true;

      return state;
    });

    queue.push(params);
    enqueued.add(key);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (queue.length) {
        //logger.debug('Send queue to transform', JSON.stringify(queue, null, 2));
        queryTransformMany({ variables: { params: queue } });
        queue.length = 0;
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, [queryTransformMany]);

  return {
    transform,
    transformResult,
    transformCalled,
    transformLoading,
    transformMany,
    transformManyResult,
    transforms,
    transformEnque,
  };
};

export { useTRules };
