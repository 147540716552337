import React, { memo, useContext, useMemo } from 'react';
import {
  ImageViewerContext,
  LocalizationContext,
  OrdersContext,
} from 'contexts';
import {
  Caption,
  Icon,
  Text,
  Button,
  Actions,
  Grid,
  GridItem,
  FormInput,
} from '@sdflc/ui';
import { BUTTONS_WITH_NAMES, ORDER_ITEM_STATUSES, colors } from 'config';
import { isOrderPaymentMethodCash } from 'utils';
import { ACTIONS_IDS } from 'config';
import { InputCurrency, InputUser } from 'components/Controls';
import { getOrderStepPagePreRequisites } from './ordersHelpers';

const OrderConfirmed = memo(() => {
  const { getText } = useContext(LocalizationContext);
  const { setViewImageIdx } = useContext(ImageViewerContext);
  const {
    orderHooks,
    orderItemHooks,
    handleClickAction,
    isLoading,
    orderReceiptHooks,
  } = useContext(OrdersContext);
  const { order } = orderHooks;
  const { orderItems } = orderItemHooks;
  const { orderReceipts } = orderReceiptHooks;

  const { orderDetails, labelData, hintData } = useMemo(() => {
    return getOrderStepPagePreRequisites({
      order,
      orderItems,
      getText,
      stepName: 'orderConfirmed',
    });
  }, [order, orderItems, getText]);

  if (isLoading) {
    return null;
  }

  return (
    <Grid>
      <GridItem width={['100%', '100%', '100%', '80%', '80%']}>
        <Caption level={3}>
          <Icon name='CheckBox' iconSize={64} color={colors.darkBlue} pr={2} />
          {getText('blocks.orderConfirmed.header', order)}
        </Caption>
        <Caption level={4}>
          <Text>{getText('blocks.orderConfirmed.title', order)}</Text>
        </Caption>
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '20%', '20%']}>
        <Actions
          actions={[BUTTONS_WITH_NAMES.GO_TO_ORDER_VIEW]}
          onClick={handleClickAction}
          dimension={Button.DIMENSION_NAMES.MEDIUM}
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '100%', '100%']}>
        <Text mt={3}>
          {getText(
            `blocks.orderConfirmed.subtitle.${orderDetails.paymentMethod.toLowerCase()}`,
            order
          )}
        </Text>
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '100%', '100%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          inputComponent={InputUser}
          fieldName='shopper'
          readOnly
          buttonName={'Rate'}
          onClick={() => {
            handleClickAction({ id: ACTIONS_IDS.RATE_SHOPPER }, order);
          }}
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='orderItemsQty'
          readOnly
          leftIcon='FormatListNumbered'
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='orderItemsTakenQty'
          readOnly
          leftIcon='FormatListNumbered'
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='orderAmount'
          inputComponent={InputCurrency}
          readOnly
          currencyCode={orderDetails.currency}
          buttonName={
            orderReceipts.length ? getText('buttons.viewReceipts') : undefined
          }
          onClick={
            orderReceipts.length
              ? () => {
                  setViewImageIdx(0);
                }
              : undefined
          }
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='deliveryCost'
          inputComponent={InputCurrency}
          readOnly
          currencyCode={orderDetails.currency}
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='serviceFeeTotalAmount'
          inputComponent={InputCurrency}
          readOnly
          currencyCode={orderDetails.currency}
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='totalAmountForBuyer'
          inputComponent={InputCurrency}
          readOnly
          currencyCode={orderDetails.currency}
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='paymentMethod'
          readOnly
        />
      </GridItem>
    </Grid>
  );
});

OrderConfirmed.displayName = 'OrderConfirmed';

export { OrderConfirmed };
