import React, { Fragment, memo, useContext } from 'react';
import PropTypes from 'prop-types';

import { LocalizationContext } from '../../../contexts';

import { Block } from 'components/Block/Block';
import { PaymentMethodsList } from './PaymentMethodsList';
import { usePaymentMethods } from './usePaymentMethods';
import { AppToolbar } from 'components/AppToolbar';

const PaymentMethods = memo((props) => {
  const { hasTitle } = props;
  const { getText } = useContext(LocalizationContext);

  const {
    handleClickAction,
    counter,
    toolBarItems,
    //setCounter,
    //embedUrl,
  } = usePaymentMethods();

  return (
    <Fragment>
      <Block title={hasTitle ? getText('pages.paymentMethod.title') : ''}>
        <AppToolbar items={toolBarItems} onClick={handleClickAction} />
        <PaymentMethodsList onClickAction={handleClickAction} key={counter} />
      </Block>
    </Fragment>
  );
});

PaymentMethods.displayName = 'PaymentMethods';

PaymentMethods.propTypes = {
  children: PropTypes.node,
  hasTitle: PropTypes.bool,
};

PaymentMethods.defaultProps = {
  hasTitle: false,
};

export { PaymentMethods };
