export const APP_PAGE_KEYS = {
  HOME: 'home',
  SIGN_IN: 'signin',
  SIGN_UP: 'signup',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  USER_SHOPPING_ITEMS: 'user-shopping-items',
  ORDERS_TO_PICK: 'orders-to-pick',
  MY_PAST_ORDERS: 'my-past-orders',
  ORDERS_I_DELIVERED: 'order-i-delivered',
  ORDER_EDIT: 'order-edit',
  ORDER_DETAILS: 'order-details',
  ORDER_SHOP_ITEMS: 'order-shop-items',
  ORDER_DELIVERED: 'order-delivered',
  ORDER_CONFIRMED: 'order-confirmed',
  ORDER_PAID: 'order-paid',
  ORDER_COMPLETE: 'order-complete',
  TRANSACTIONS: 'transactions',
  SETTINGS_USER_SUBSCRIBES: 'settings-user-subscribes',
  SETTINGS_USER_SUBSCRIBE_DISTANCE: 'settings-user-subscribe-distance',
  SETTINGS_ORDER_SIBSCRIPTION_BY_DISTANCE:
    'settings-order-subscription-by-distance',
  SETTINGS_ALLOWED_USERS: 'settings-allowed-user',
  SETTINGS_BLOCKED_USERS: 'settings-blocked-user',
};
