import { gql } from '@apollo/client';

const UPLOADED_FILE_LIST = gql`
  query UploadedFileList(
    $filter: UploadedFileFilter
    $params: PaginationAndSortig
  ) {
    uploadedFileList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        accountId
        userId
        filePath
        fileUrl
        name
        notes
        originalFilename
        sizeBytes
        status
      }
    }
  }
`;

const UPLOADED_FILE_GET_MANY = gql`
  query UploadedFileGetMany($ids: [ID]) {
    uploadedFileGetMany(ids: $ids) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        accountId
        userId
        filePath
        fileUrl
        name
        notes
        originalFilename
        sizeBytes
        status
      }
    }
  }
`;

const UPLOADED_FILE_UPDATE = gql`
  mutation UploadedFileUpdate(
    $params: UploadedFileInput
    $where: UploadedFileWhereInput
  ) {
    uploadedFileUpdate(params: $params, where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        accountId
        userId
        filePath
        fileUrl
        name
        notes
        originalFilename
        sizeBytes
        status
      }
    }
  }
`;

const UPLOADED_FILE_REMOVE_MANY = gql`
  mutation UploadedFileRemoveMany($where: [UploadedFileWhereInput]) {
    uploadedFileRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const uploadedFileQueries = {
  uploadedFileList: UPLOADED_FILE_LIST,
  uploadedFileGetMany: UPLOADED_FILE_GET_MANY,
  uploadedFileUpdate: UPLOADED_FILE_UPDATE,
  uploadedFileRemoveMany: UPLOADED_FILE_REMOVE_MANY,
};

export { uploadedFileQueries };
