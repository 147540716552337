import { gql } from '@apollo/client';

const BANK_ACCOUNT_LIST = gql`
  query BankAccountList(
    $filter: BankAccountFilter
    $params: PaginationAndSorting
  ) {
    bankAccountList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
      }
      data {
        id
        locationId
        countryId
        accountNumber
        transitNumber
        institutionNumber
        abaRoutingNumber
        sortCode
        iban
        isDefault
        user {
          username
        }
        userLocation {
          locationTypeId
          notes
          location {
            id
            countryId
            postalCode
            address2
            latitude
            longitude
            l10ns {
              id
              locationId
              lang
              address1
              address2Name
              city
              state
            }
          }
        }
      }
    }
  }
`;

const BANK_ACCOUNT_GET_MANY = gql`
  query BankAccountGetMany($ids: [ID]) {
    bankAccountGetMany(ids: $ids) {
      code
      errors {
        name
        errors
      }
      data {
        id
        locationId
        countryId
        accountNumber
        transitNumber
        institutionNumber
        abaRoutingNumber
        sortCode
        iban
        isDefault
        user {
          username
        }
        userLocation {
          locationTypeId
          notes
          location {
            id
            countryId
            postalCode
            address2
            latitude
            longitude
            l10ns {
              id
              locationId
              lang
              address1
              address2Name
              city
              state
            }
          }
        }
      }
    }
  }
`;

const BANK_ACCOUNT_CREATE = gql`
  mutation BankAccountCreate($params: BankAccountInput) {
    bankAccountCreate(params: $params) {
      code
      errors {
        name
        errors
      }
      data {
        id
        locationId
        countryId
        accountNumber
        transitNumber
        institutionNumber
        abaRoutingNumber
        sortCode
        iban
        isDefault
        user {
          username
        }
        userLocation {
          locationTypeId
          notes
          location {
            id
            countryId
            postalCode
            address2
            latitude
            longitude
            l10ns {
              id
              locationId
              lang
              address1
              address2Name
              city
              state
            }
          }
        }
      }
    }
  }
`;

const BANK_ACCOUNT_UPDATE = gql`
  mutation BankAccountUpdate(
    $where: BankAccountWhereInput
    $params: BankAccountInput
  ) {
    bankAccountUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
      }
      data {
        id
        locationId
        countryId
        accountNumber
        transitNumber
        institutionNumber
        abaRoutingNumber
        sortCode
        iban
        isDefault
        user {
          username
        }
        userLocation {
          locationTypeId
          notes
          location {
            id
            countryId
            postalCode
            address2
            latitude
            longitude
            l10ns {
              id
              locationId
              lang
              address1
              address2Name
              city
              state
            }
          }
        }
      }
    }
  }
`;

const BANK_ACCOUNT_REMOVE_MANY = gql`
  mutation BankAccountRemoveMany($where: [BankAccountWhereInput]) {
    bankAccountRemoveMany(where: $where) {
      code
      errors {
        name
        errors
      }
      data {
        id
      }
    }
  }
`;

const bankAccountQueries = {
  bankAccountList: BANK_ACCOUNT_LIST,
  bankAccountGetMany: BANK_ACCOUNT_GET_MANY,
  bankAccountCreate: BANK_ACCOUNT_CREATE,
  bankAccountUpdate: BANK_ACCOUNT_UPDATE,
  bankAccountRemoveMany: BANK_ACCOUNT_REMOVE_MANY,
};

export { bankAccountQueries };
