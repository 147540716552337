import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  Panel,
  Text,
  ValueDate,
  Flex,
  ItemsGroup,
  ConfirmationButton,
  Image,
  Button,
} from '@sdflc/ui';

import { ItemTile } from '../ItemTile';
import { AuthContext } from '../../contexts';
import { EmojiStatIcon } from '../EmojiStatIcon';

const CommentItem = memo((props) => {
  const { comment, onRemove, onAddReaction, onRemoveReaction } = props;
  const { appUser } = useContext(AuthContext);

  if (!comment) {
    return null;
  }

  const reactions = Object.keys(comment?.reactionState || {});

  return (
    <ItemTile>
      <Panel>{comment.commentText}</Panel>
      <Panel pt={3}>
        <Text fontSize={1} m={0}>
          <ItemsGroup>
            <span>{comment.userCreated?.username}</span>
            <ValueDate value={dayjs(comment.createdAt).format()} />
          </ItemsGroup>
        </Text>
      </Panel>
      <Panel pt={3}>
        {Array.isArray(comment.attachments) && (
          <ItemsGroup>
            {comment.attachments.map((attachment) => {
              if (attachment.attachmentMimeType.startsWith('image/')) {
                return (
                  <Image
                    key={attachment.attachmentUrl}
                    src={attachment.attachmentUrl}
                    alt='Image'
                    height={'64px'}
                    width='auto'
                  />
                );
              }
              return <span>{attachment.attachmentUrl}</span>;
            })}
          </ItemsGroup>
        )}
      </Panel>
      <ItemsGroup>
        {typeof onRemove === 'function' && (
          <Flex>
            {comment.createdBy === appUser.id && (
              <ConfirmationButton
                icon='Delete'
                onConfirm={() => onRemove(comment)}
                variant='danger'
                dimension='small'
              />
            )}
          </Flex>
        )}
        {reactions.map((reaction) => {
          return (
            <EmojiStatIcon
              key={reaction}
              name={reaction}
              reactionStat={comment?.reactionState?.[reaction]}
              myReactionId={comment?.mineReactions?.[reaction]}
              onRemove={onRemoveReaction}
            />
          );
        })}
        {typeof onAddReaction === 'function' && (
          <Flex>
            <Button
              icon='AddReaction'
              onClick={() => onAddReaction(comment, ':thumbsup')}
              variant='tertiary'
              dimension='small'
            />
          </Flex>
        )}
      </ItemsGroup>
    </ItemTile>
  );
});

CommentItem.displayName = 'CommentItem';

CommentItem.propTypes = {
  comment: PropTypes.any,
  onRemove: PropTypes.func,
  onAddReaction: PropTypes.func,
  onRemoveReaction: PropTypes.func,
};

CommentItem.defaultProps = {
  comment: null,
};

export { CommentItem };
