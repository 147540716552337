import { Fragment, memo, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';

import { OpResult } from '@sdflc/api-helpers';
import {
  ItemsGroup,
  Input,
  Grid,
  GridItem,
  HR,
  Button,
  ConfirmationButton,
  Badge,
  Tag,
  Skeleton,
  Text,
} from '@sdflc/ui';

import { SettingsRow } from '../../SettingsRow';
import { ACTIONS_BUTTONS } from '../../../config';

import { contactTypeToIcon } from 'utils/iconHelpers';
import { LocalizationContext } from 'contexts';

const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const width = ['100%', '100%', '100%', '50%'];

const UserContactItem = memo((props) => {
  const { value, onClickAction, loading, removingResult } = props;
  const { getText } = useContext(LocalizationContext);
  const {
    contactTypeId,
    contactValue,
    contactDescription,
    isDefault /*, status */,
  } = value || {};

  const handleClickAction = useCallback(
    (action) => {
      if (check.function(onClickAction)) {
        onClickAction(action, value);
      } else {
        console.warn(
          'The "UserContactItem" component expected the "onClickAction" to be a function but got:',
          onClickAction
        );
      }
    },
    [onClickAction, value]
  );

  const DefaultTag = useMemo(
    () =>
      isDefault ? (
        <Tag
          variant={Badge.VARIANT_NAMES.WARNING}
          dimension={Badge.DIMENSION_NAMES.SMALL}
        >
          {getText('generic.default')}
        </Tag>
      ) : null,
    [getText, isDefault]
  );

  if (!value) {
    return null;
  }

  const removingResult2use =
    removingResult instanceof OpResult ? removingResult : null;
  const error = removingResult2use?.getErrorSummary() ?? '';

  return (
    <Fragment>
      <SettingsRow name={contactTypeId}>
        <Grid>
          <GridItem width={width}>
            <Skeleton loading={loading}>
              <Input
                leftIcon={contactTypeToIcon(contactTypeId)}
                value={contactValue}
                readOnly
                RightSide={DefaultTag}
              />
            </Skeleton>
          </GridItem>
          <GridItem width={width}>
            <Skeleton loading={loading}>
              <Input value={contactDescription} readOnly />
            </Skeleton>
          </GridItem>
        </Grid>
        {!!onClickAction && (
          <ItemsGroup>
            <Skeleton loading={loading}>
              <Button
                {...BUTTONS.EDIT_ALT}
                dimension={Button.DIMENSION_NAMES.SMALL}
                onClick={() => handleClickAction(BUTTONS.EDIT_ALT)}
              />
              {!isDefault && (
                <Button
                  {...BUTTONS.MAKE_DEFAULT}
                  dimension={Button.DIMENSION_NAMES.SMALL}
                  onClick={() => handleClickAction(BUTTONS.MAKE_DEFAULT)}
                />
              )}
              <ConfirmationButton
                {...BUTTONS.REMOVE}
                dimension={Button.DIMENSION_NAMES.SMALL}
                onConfirm={() => handleClickAction(BUTTONS.REMOVE)}
                progress={removingResult2use?.isInProgress()}
              />
              {!!error && (
                <Text variant={Text.VARIANT_NAMES.DANGER}>{error}</Text>
              )}
            </Skeleton>
          </ItemsGroup>
        )}
      </SettingsRow>
      <HR />
    </Fragment>
  );
});

UserContactItem.displayName = 'UserContactItem';

UserContactItem.propTypes = {
  value: PropTypes.shape({}),
  onClickAction: PropTypes.func,
  loading: PropTypes.bool,
  removingResult: PropTypes.any,
};

UserContactItem.defaultProps = {
  value: null,
};

export { UserContactItem };
