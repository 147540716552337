import { memo } from 'react';
import PropTypes from 'prop-types';

import { Alert, HorizontalScroll, ItemsGroup, Panel } from '@sdflc/ui';

import { OrderCard } from 'components/OrderCard';
import { ORDER_LIST_CATEGORIES } from 'config/orderCard';
import { NoOrders } from './NoOrders';

const OrdersList = memo((props) => {
  const {
    items,
    isScrollable,
    showDistance,
    onClickAction,
    orderListCategory,
    //list,
    listOp,
    removingOps,
    setStepOps,
    hasLocations,
  } = props;

  const isLoading = listOp.result.isInProgress();

  const ordersToRender = (
    <ItemsGroup
      wrapMode={isScrollable ? 'nowrap' : 'wrap'}
      alignItems='flex-start'
    >
      {items.map((item, idx) => {
        return (
          item && (
            <OrderCard
              key={item.id ? item.id : idx + 1}
              orderListCategory={orderListCategory}
              order={item}
              onClickAction={onClickAction}
              isLoading={isLoading}
              showDistance={showDistance}
              removingOps={removingOps}
              setStepOps={setStepOps}
            />
          )
        );
      })}
    </ItemsGroup>
  );

  if (listOp.called && !isLoading) {
    if (listOp.result.didFail()) {
      return <Alert variant='danger'>{listOp.result.getErrorSummary()}</Alert>;
    } else if (
      items.length === 0 &&
      hasLocations &&
      orderListCategory !== ORDER_LIST_CATEGORIES.IN_PROGRESS
    ) {
      return <NoOrders orderListCategory={orderListCategory} />;
    }
  }

  return (
    <Panel mt={2} overflow='initial'>
      {isScrollable ? (
        <HorizontalScroll>{ordersToRender}</HorizontalScroll>
      ) : (
        ordersToRender
      )}
    </Panel>
  );
});

OrdersList.displayName = 'OrdersList';

OrdersList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  isScrollable: PropTypes.bool,
  showDistance: PropTypes.bool,
  onClickAction: PropTypes.func,
  orderListCategory: PropTypes.oneOf(Object.values(ORDER_LIST_CATEGORIES)),
  list: PropTypes.func,
  listOp: PropTypes.any,
  removingOps: PropTypes.any,
  hasLocations: PropTypes.bool,
};

OrdersList.defaultProps = {
  onClickAction: null,
  showDistance: false,
  isScrollable: false,
  orderListCategory: ORDER_LIST_CATEGORIES.NONE,
};

export { OrdersList };
