import { gql } from '@apollo/client';

const DICTIONARY_ITEM_LIST = gql`
  query (
    $filter: DictionaryItemFilter
    $params: PaginationAndSorting
    $lang: String
  ) {
    dictionaryItemList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        dictionaryId
        dictionary {
          uid
          valueType
        }
        itemValue
        itemValueAlt
        itemValueBgColor
        itemValueColor
        itemValueIcon
        itemValueImage
        itemValueUrl
        l10n(lang: $lang) {
          itemName
          itemDescription
          itemHint
          itemTooltip
        }
        l10ns {
          itemName
          itemDescription
          itemHint
          itemTooltip
        }
      }
    }
  }
`;

const dictionaryQueries = {
  dictionaryItemList: DICTIONARY_ITEM_LIST,
};

export { dictionaryQueries };
