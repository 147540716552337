import { colors as standardColor } from '@sdflc/ui';

const colors = {
  ...standardColor,
  grey: '#efefef',
  darkGrey: '#444',
  headerBg: '#3B4F8C',
  sideBarBg: '#314274',
  linkActiveBg: '#95CAF6',
};

export { colors };
