import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { STATUSES } from '@sdflc/utils';
import { ToolBarItem } from '@sdflc/ui';

import {
  logger,
  onlyPropsOfObject,
  OperationManager,
  OPERATIONS,
} from '../../../utils';
import {
  CommentsContext,
  LocalizationContext,
  OrdersContext,
  UserShoppingItemContext,
} from '../../../contexts';

import {
  ORDER_ITEM_STATUSES,
  ACTIONS_IDS,
  BUTTONS_WITH_NAMES,
} from '../../../config';

import { orderItemFormSectionsForShopper } from './orderItemFormSectionsForShopper';
import { orderItemFormSections } from './orderItemFormSections';
import { orderItemFormSectionsEditShopper } from './orderItemFormSectionsEditShopper';

const tplNewOrderItem = {
  orderId: null,
  shoppingItemId: null,
  quantity: 1,
  unitId: 'package',
  minPricePerUnit: null,
  maxPricePerUnit: null,
  onlyIfOnSaleIndicator: false,
  notImportantIndicator: false,
  buyerComment: '',
  shopperComment: '',
  storeBrandId: null,
  actualQuantity: null,
  price: null,
  totalAmount: null,
  status: STATUSES.ACTIVE,
};

const useOrderItems = (props) => {
  const { shopMode } = props;
  const { getText } = useContext(LocalizationContext);
  const { setChannelKey } = useContext(CommentsContext);
  const { userShoppingItemsMap } = useContext(UserShoppingItemContext);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const { orderItemHooks, orderHooks } = useContext(OrdersContext);
  const {
    orderItem,
    orderItems,
    orderItemList,
    orderItemCreate,
    orderItemUpdate,
    orderItemRemoveMany,
    orderItemsOpsManager,
  } = orderItemHooks;
  const { order } = orderHooks;

  const orderItemId = formData?.id || '';
  const createOp = orderItemsOpsManager.getCreateOp();
  const updateOp = orderItemsOpsManager.get(orderItemId, OPERATIONS.UPDATE);
  const saveOp = orderItemId ? updateOp : createOp;
  const saveOpResult = saveOp.result;
  const removingOps = orderItemsOpsManager.getOpValues(OPERATIONS.REMOVE_MANY);

  useEffect(() => {
    setOpen(!!formData);
  }, [formData]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of new user shoping item

    // User contact item is not being saved yet
    if (!createOp.called) {
      return;
    }

    // User contact create operation result is not in progress and succeeded
    if (!createOp.result.isInProgress() && createOp.result.didSucceed()) {
      orderItemsOpsManager.reset(
        OperationManager.defaultContext,
        OPERATIONS.CREATE
      );
      setFormData(null);
    }
  }, [createOp, orderItemsOpsManager]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of exising contact

    // Contact is not being saved yet
    if (!updateOp.called) {
      return;
    }

    // contact operation result is not in progress and succeeded
    if (!updateOp.result.isInProgress() && updateOp.result.didSucceed()) {
      orderItemsOpsManager.reset(orderItemId, OPERATIONS.UPDATE);
      setFormData(null);
    }
  }, [updateOp, orderItemsOpsManager, orderItemId]);

  const handleClickAction = useCallback(
    (action, item) => {
      logger.log(`The "useOrderItems" got an action:`, { action, item });
      switch (action.id) {
        default:
          logger.warn('The "useOrderItems" got an unexpected action:', {
            action,
            item,
          });

          break;

        case ACTIONS_IDS.ADD:
        case ACTIONS_IDS.CREATE:
          orderItemsOpsManager.reset(
            OperationManager.defaultContext,
            OPERATIONS.CREATE
          );
          setFormData(cloneDeep(tplNewOrderItem));
          break;

        case ACTIONS_IDS.SAVE:
          if (!item.id) {
            orderItemCreate({
              params: onlyPropsOfObject(
                {
                  ...item,
                  orderId: order.id,
                },
                tplNewOrderItem
              ),
            });
          } else {
            orderItemUpdate({
              where: { id: item.id },
              params: onlyPropsOfObject(
                {
                  ...item,
                  orderId: order.id,
                },
                tplNewOrderItem
              ),
            });
          }
          break;

        case ACTIONS_IDS.CANCEL:
          setFormData(null);
          break;

        case ACTIONS_IDS.EDIT:
          orderItemsOpsManager.reset(item?.id, OPERATIONS.UPDATE);
          setFormData(cloneDeep(item));
          break;

        case ACTIONS_IDS.REMOVE:
          orderItemRemoveMany({ where: [{ id: item.id }] });
          break;

        case ACTIONS_IDS.TAKE_ORDER_ITEM:
          orderItemUpdate({
            where: { id: item.id },
            params: {
              actualQuantity: item.quantity || 1,
              status: ORDER_ITEM_STATUSES.TAKEN,
            },
          });
          break;

        case ACTIONS_IDS.MISSING_ORDER_ITEM:
          orderItemUpdate({
            where: { id: item.id },
            params: {
              status: ORDER_ITEM_STATUSES.MISSING,
            },
          });
          break;

        case ACTIONS_IDS.RETURN_ORDER_ITEM:
          orderItemUpdate({
            where: { id: item.id },
            params: {
              status: ORDER_ITEM_STATUSES.RETURNED,
            },
          });
          break;

        case ACTIONS_IDS.VIEW_COMMENTS:
          setChannelKey(`order-item-${item.id}`);
          break;

        case ACTIONS_IDS.ORDER_ITEM_SELECT:
          orderItemCreate({
            params: onlyPropsOfObject(
              {
                ...item,
                orderId: order.id,
              },
              tplNewOrderItem
            ),
          });
          break;

        case ACTIONS_IDS.ORDER_ITEM_UNSELECT:
          orderItemRemoveMany({ where: [{ id: item.id }] });
          break;
      }
    },
    [
      orderItemCreate,
      orderItemUpdate,
      orderItemRemoveMany,
      orderItemsOpsManager,
      setChannelKey,
      order,
    ]
  );

  const handleClickClose = useCallback(() => {
    handleClickAction({ id: ACTIONS_IDS.CANCEL }, null);
  }, [handleClickAction]);

  const formCfg = useMemo(() => {
    return {
      sections: shopMode
        ? order?.isMine
          ? orderItemFormSectionsEditShopper
          : orderItemFormSectionsForShopper
        : orderItemFormSections,
      labels: getText('pages.orderItems.form.labels'),
      hints: getText('pages.orderItems.form.hints'),
    };
  }, [getText, shopMode, order]);

  const formTitle = useMemo(() => {
    return getText(
      formData?.id
        ? 'pages.orderItems.form.title.edit'
        : 'pages.orderItems.form.title.add',
      {
        shoppingItemName:
          userShoppingItemsMap[formData?.shoppingItemId]?.shoppingItemName ??
          'N/A',
      }
    );
  }, [getText, formData, userShoppingItemsMap]);

  const formActions = useMemo(
    () => [BUTTONS_WITH_NAMES.SAVE, BUTTONS_WITH_NAMES.CANCEL],
    []
  );
  const actionInProgress = useMemo(
    () => (saveOpResult.isInProgress() ? [ACTIONS_IDS.SAVE] : []),
    [saveOpResult]
  );

  const toolBarItems = useMemo(() => {
    return order.isMine
      ? [
          // {
          //   id: 'keyword',
          //   type: ToolBarItem.types.COMPONENT,
          //   name: 'keyword',
          //   value: filters.keyword,
          //   component: Input,
          //   placeholder: getText('generic.search'),
          //   leftIcon: 'Search',
          // },
          { ...BUTTONS_WITH_NAMES.ADD, type: ToolBarItem.types.BUTTON },
        ]
      : [];
  }, [order]);

  return {
    open,
    orderItem,
    orderItems,
    orderItemList,
    formData,
    formCfg,
    formTitle,
    formActions,
    setFormData,
    handleClickAction,
    handleClickClose,
    orderItemsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOp,
    saveOpResult,
    removingOps,
  };
};

export { useOrderItems };
