import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useToaster } from '@sdflc/ui';

import {
  AppPagesContext,
  CommentsContext,
  ImageViewerContext,
  LocalizationContext,
  OrdersContext,
  UploadedFileContext,
} from '../contexts';

import { useApiOrderReceipt, useApiOrder, useOrderItem } from 'graphql/hooks';

import {
  actionIdToOrderStepId,
  getActionsForOrder,
  logger,
  onlyPropsOfObject,
  OperationManager,
  OPERATIONS,
  stepIdToActionId,
} from 'utils';
import {
  APP_PAGE_KEYS,
  ACTIONS_BUTTONS,
  ACTIONS_IDS,
  ORDER_STEPS,
  ORDER_ITEM_STATUSES,
} from '../config';

import { createDirectionsLink } from 'utils/googleMaps';
import { cloneDeep } from 'lodash';
import { useHandleOrderSetStepResult } from 'hooks/useOrderSetStepResult';

const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const tplNewOrder = {
  storeBrandId: null,
  paymentMethodId: null,
  locationId: null,
  deliveryCost: null,
  dueDate: null,
  buyerComment: null,
  shopperComment: null,
};

export const templateReceipt = {
  orderId: null,
  storeBrandId: null,
  amount: null,
  receiptFileId: null,
};

export const tplOrderReview = {
  orderId: null,
  rating: null,
  review: '',
};

/**
 * The component loads an order by its ID taken from the URL and also its items
 * @param {object} props The component props: children
 * @returns
 */
const OrdersProvider = (props) => {
  const { children } = props;
  const toast = useToaster();
  const { getText, setPageTitle } = useContext(LocalizationContext);
  const { goToPage } = useContext(AppPagesContext);
  const { setChannelKey } = useContext(CommentsContext);
  const { setViewImages } = useContext(ImageViewerContext);
  const { uploadedFileList, uploadedFilesOpsManager } =
    useContext(UploadedFileContext);
  const orderHooks = useApiOrder();
  const orderItemHooks = useOrderItem();
  const orderReceiptHooks = useApiOrderReceipt();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState('');
  const [receiptToEdit, setReceiptToEdit] = useState(null);
  const [totalFormOpen, setTotalFormOpen] = useState(false);
  const [reviewToEdit, setReviewToEdit] = useState(null);
  const [orderReceiptIdToRemove, setOrderReceiptIdToRemove] = useState(null);
  const [orderPageTitleId, setOrderPageTitleId] = useState('pages.order.title');
  const params = useParams();

  const {
    order,
    orderGet,
    orderUpdate,
    orderRemoveMany,
    orderSetStep,
    ordersOpsManager,
  } = orderHooks;

  const { orderItems, orderItemList, orderItemsOpsManager } = orderItemHooks;
  const {
    orderReceipts,
    orderReceiptList,
    orderReceiptsOpsManager,
    orderReceiptUpdate,
    orderReceiptCreate,
    orderReceiptRemoveMany,
  } = orderReceiptHooks;

  const orderId = params.orderId;
  const orderGetOp = ordersOpsManager.get(orderId, OPERATIONS.GET);
  const orderItemsListOp = orderItemsOpsManager.get(
    OperationManager.defaultContext,
    OPERATIONS.LIST
  );
  const orderReceiptsListOp = orderReceiptsOpsManager.get(
    OperationManager.defaultContext,
    OPERATIONS.LIST
  );

  const updateOp = ordersOpsManager.get(orderId, OPERATIONS.UPDATE);
  const setStepOp = ordersOpsManager.get(orderId, OPERATIONS.ORDER_SET_STEP);
  const saveOp = updateOp;
  const saveOpResult = saveOp.result;
  const setStepOpResult = setStepOp.result;
  const receiptToEditId = receiptToEdit?.id;
  const orderReceiptCreateOp = orderReceiptsOpsManager.get(
    OperationManager.defaultContext,
    OPERATIONS.CREATE
  );
  const orderReceiptUpdateOp = orderReceiptsOpsManager.get(
    receiptToEditId,
    OPERATIONS.UPDATE
  );
  const orderReceiptSaveOp = receiptToEditId
    ? orderReceiptUpdateOp
    : orderReceiptCreateOp;
  const orderReceiptSaveOpResult = orderReceiptSaveOp.result;
  const orderReceiptRemoveOp = orderReceiptsOpsManager.get(
    orderReceiptIdToRemove,
    OPERATIONS.REMOVE_MANY
  );
  const orderReceiptRemoveOpResult = orderReceiptRemoveOp.result;

  const uploadedFileListOp = uploadedFilesOpsManager.get(
    OperationManager.defaultContext,
    OPERATIONS.LIST
  );

  useEffect(() => {
    if (!orderGetOp.called) {
      // Order was not yet requested so request it
      logger.debug(`Load an order with ID '${orderId}`);
      setPageTitle(getText('generic.loading'));
      orderGet({ id: orderId });
    } else if (orderGetOp.result.isInProgress()) {
      // If is in progress do nothing
      setPageTitle(getText('generic.loading'));
    } else if (orderGetOp.result.didFail()) {
      // If failed to load, clear the page title or setup some generic error message
      setPageTitle(getText('errors.failedToLoadOrderPageTitle'));
    } else {
      // Order info loaded, set the current order and set page title
      // const order = orderGetOp.result.getDataFirst();
      // setPageTitle(
      //   getText(orderPageTitleId, {
      //     orderNum: order.orderNum,
      //   })
      // );
      //setPageTitle('');
    }

    return () => {
      setPageTitle(null);
    };
  }, [orderGet, orderGetOp, orderId, setPageTitle, getText, orderPageTitleId]);

  useEffect(() => {
    if (!orderItemsListOp.called) {
      logger.debug(`Load order items for the order with ID '${orderId}`);
      orderItemList({ filter: { orderId: [orderId] } });
    }
  }, [orderItemsListOp, orderItemList, orderId]);

  useEffect(() => {
    if (!orderReceiptsListOp.called) {
      logger.debug(`Load order receipts for the order with ID '${orderId}`);
      orderReceiptList({ filter: { orderId: [orderId] } });
    } else if (
      !orderReceiptsListOp.result.isInProgress() &&
      orderReceiptsListOp.result.didSucceed()
    ) {
      uploadedFileList({
        filter: {
          id: orderReceiptsListOp.result.data
            .map((receipt) => receipt.receiptFileId)
            .filter((receiptFileId) => !!receiptFileId),
        },
      });
    }
  }, [orderReceiptsListOp, orderReceiptList, orderId, uploadedFileList]);

  useEffect(() => {
    if (uploadedFileListOp.called) {
      if (
        !uploadedFileListOp.result.isInProgress() &&
        uploadedFileListOp.result.didSucceed()
      ) {
        //logger.log('Receipts were loaded:', uploadedFileListOp.result.data);
        setViewImages(
          uploadedFileListOp.result.data.map((uploadedFile) => {
            return {
              id: uploadedFile.id,
              src: uploadedFile.fileUrl,
              thumbnailUrl: uploadedFile.thumbnailUrl || uploadedFile.fileUrl,
              caption: uploadedFile.name,
            };
          })
        );
      }
    }
  }, [uploadedFileListOp, setViewImages]);

  useEffect(() => {
    if (
      orderItemsListOp.called &&
      orderGetOp.called &&
      orderReceiptsListOp.called
    ) {
      logger.debug('Order, Order Items and Order Receipts were requested');

      if (
        !orderItemsListOp.result.isInProgress() &&
        !orderGetOp.result.isInProgress() &&
        !orderReceiptsListOp.result.isInProgress()
      ) {
        setIsLoading(false);
      }

      if (orderGetOp.result.didFail()) {
        setLoadingError(orderGetOp.result.getErrorSummary());
      } else if (orderItemsListOp.result.didFail()) {
        setLoadingError(orderItemsListOp.result.getErrorSummary());
      } else if (orderReceiptsListOp.result.didFail()) {
        setLoadingError(orderReceiptsListOp.result.getErrorSummary());
      }
    }
  }, [orderItemsListOp, orderGetOp, orderReceiptsListOp]);

  useEffect(() => {
    // This effect is for case when when an order has been updated

    // Contact is not being saved yet
    if (!updateOp.called) {
      return;
    }

    // contact operation result is not in progress and succeeded
    if (!updateOp.result.isInProgress() && updateOp.result.didSucceed()) {
      ordersOpsManager.reset(orderId, OPERATIONS.UPDATE);
    }
  }, [updateOp, ordersOpsManager, orderId]);

  useEffect(() => {
    if (orderReceiptSaveOp.called) {
      if (
        !orderReceiptSaveOp.result.isInProgress() &&
        orderReceiptSaveOp.result.didSucceed()
      ) {
        orderReceiptsOpsManager.reset(receiptToEditId, OPERATIONS.UPDATE);
        setReceiptToEdit(null);

        if (Array.isArray(orderReceipts)) {
          const orderAmount = orderReceipts.reduce((acc, receipt) => {
            acc +=
              receipt.amount != null && !isNaN(receipt.amount)
                ? Number(receipt.amount)
                : 0;
            return acc;
          }, 0);

          logger.debug(
            `Receipt added, update order total amount from the ${orderReceipts.length} receipts to ${orderAmount}`
          );

          orderUpdate({
            where: { id: orderId },
            params: {
              orderAmount,
            },
          });
        }
      }
    }
  }, [
    setReceiptToEdit,
    orderUpdate,
    orderReceiptsOpsManager,
    orderReceiptSaveOp,
    orderReceipts,
    receiptToEditId,
    orderId,
  ]);

  useEffect(() => {
    if (orderReceiptIdToRemove) {
      orderReceiptRemoveMany({ where: [{ id: orderReceiptIdToRemove }] });
    }
  }, [orderReceiptRemoveMany, orderReceiptIdToRemove]);

  useHandleOrderSetStepResult({ setStepOp, ordersOpsManager, orderId });

  useEffect(() => {
    if (orderReceiptRemoveOpResult.called) {
      if (!orderReceiptRemoveOpResult.isInProgress()) {
        setOrderReceiptIdToRemove(null);
        orderReceiptsOpsManager.reset(
          orderReceiptIdToRemove,
          OPERATIONS.REMOVE_MANY
        );

        if (orderReceiptRemoveOpResult.didFail()) {
          toast({
            message: getText('blocks.receipts.failedToRemove'),
            variant: 'danger',
            timeout: 30,
          });
        }
      }
    }
  }, [
    orderReceiptRemoveOpResult,
    orderReceiptsOpsManager,
    orderReceiptIdToRemove,
    toast,
    getText,
  ]);

  const handleClickAction = useCallback(
    (action, item, formData) => {
      logger.log('The "OrdersProvider" got an action:', {
        action,
        item,
      });

      if (!item) {
        item = order;
        logger.warn(
          'The "OrdersProvider" got an empty item so user current order instead:',
          order
        );
      }

      switch (action.id) {
        default:
          logger.warn('The "OrdersProvider" got an unexpected action:', {
            action,
            item,
          });
          break;

        case ACTIONS_IDS.SAVE:
          orderUpdate({
            where: { id: item.id },
            params: onlyPropsOfObject(item, tplNewOrder),
          });
          break;

        case ACTIONS_IDS.REMOVE:
          ordersOpsManager.reset(item?.id, OPERATIONS.REMOVE_MANY);
          orderRemoveMany({ where: [{ id: item.id }] });
          break;

        case ACTIONS_IDS.GO_TO_ORDER_SHOP:
        case ACTIONS_IDS.SHOP_ORDER_ITEMS:
          goToPage(APP_PAGE_KEYS.ORDER_SHOP_ITEMS, { orderId: item.id });
          break;

        case ACTIONS_IDS.GO_TO_ORDER_VIEW:
        case ACTIONS_IDS.VIEW:
          goToPage(APP_PAGE_KEYS.ORDER_DETAILS, { orderId: order.id });
          break;

        case ACTIONS_IDS.GO_TO_ORDER_EDIT:
        case ACTIONS_IDS.EDIT:
          goToPage(APP_PAGE_KEYS.ORDER_EDIT, { orderId: order.id });
          break;

        case ACTIONS_IDS.PUBLISH:
        case ACTIONS_IDS.PICK:
        case ACTIONS_IDS.ON_THE_WAY:
        case ACTIONS_IDS.DELIVERED:
        case ACTIONS_IDS.CONFIRM:
        case ACTIONS_IDS.DISPUTE:
        case ACTIONS_IDS.CANCEL_ACTION:
        case ACTIONS_IDS.CONFIRM_PAYMENT:
          ordersOpsManager.reset(item?.id, OPERATIONS.ORDER_SET_STEP);
          orderSetStep({
            params: {
              id: item.id,
              stepId: actionIdToOrderStepId(action.id),
              reason: item.reason,
            },
          });
          break;

        case ACTIONS_IDS.ENTER_TOTAL:
          setTotalFormOpen(true);
          break;

        case ACTIONS_IDS.SAVE_ORDER_TOTAL:
          ordersOpsManager.reset(item?.id, OPERATIONS.UPDATE);
          orderUpdate({
            where: { id: item.id },
            params: {
              orderAmount: formData.orderAmount,
              shopperComment: formData.shopperComment,
            },
          });
          break;

        case ACTIONS_IDS.VIEW_ORDER_COMMENTS:
          setChannelKey(`order-${item.id}`);
          break;

        case ACTIONS_IDS.ADD_RECEIPT:
          templateReceipt.storeBrandId = order.storeBrandId;
          setReceiptToEdit(templateReceipt);
          break;

        case ACTIONS_IDS.GET_DIRECTIONS:
          window.open(
            createDirectionsLink({
              origin: '',
              destination: order.userLocation?.formattedAddress,
            }),
            'shopaDropaDirections'
          );
          break;

        case ACTIONS_IDS.RATE_BUYER:
        case ACTIONS_IDS.RATE_SHOPPER:
          setReviewToEdit(cloneDeep({ ...tplOrderReview, orderId: order.id }));
          break;

        case ACTIONS_IDS.SUBMIT_REVIEW:
          // TODO: Save review
          logger.log('Save the order review', item);
          break;

        case ACTIONS_IDS.CANCEL:
          setReviewToEdit(null);
          break;
      }
    },
    [
      orderUpdate,
      orderSetStep,
      orderRemoveMany,
      setTotalFormOpen,
      setChannelKey,
      ordersOpsManager,
      goToPage,
      order,
    ]
  );

  const handleClickReceiptAction = useCallback(
    (action, item, formData) => {
      logger.log('handleClickReceiptAction', { actionId: action?.id, item });
      switch (action.id) {
        default:
          logger.warn(
            'The "handleClickReceiptAction" got an unexpected action:',
            {
              action,
              item,
            }
          );
          break;

        case ACTIONS_IDS.SAVE:
          item.orderId = orderId;
          if (receiptToEditId) {
            orderReceiptUpdate({
              where: { id: receiptToEditId },
              params: onlyPropsOfObject(item, templateReceipt),
            });
          } else {
            orderReceiptCreate({
              params: onlyPropsOfObject(item, templateReceipt),
            });
          }

          break;

        case ACTIONS_IDS.CANCEL:
          setReceiptToEdit(null);
          break;

        case ACTIONS_IDS.EDIT:
          setReceiptToEdit(item);
          break;

        case ACTIONS_IDS.REMOVE:
          // Trigger order receipt removal
          setOrderReceiptIdToRemove(item.id);
          break;
      }
    },
    [orderReceiptUpdate, orderReceiptCreate, receiptToEditId, orderId]
  );

  const orderActions = useMemo(() => {
    const actionsIds = getActionsForOrder({ order, orderReceipts });
    return actionsIds.map((actionsId) => BUTTONS[actionsId]);
  }, [order, orderReceipts]);

  const actionInProgress = useMemo(() => {
    const stepId = setStepOp.result.isInProgress()
      ? setStepOp.variables.params.stepId
      : '';
    const actionId = stepIdToActionId(stepId);
    return actionId;
  }, [setStepOp]);

  const needsReceipts = useMemo(() => {
    return (
      order &&
      (order.isMine || order.amIShopper) &&
      orderReceipts.length === 0 &&
      [ORDER_STEPS.PICKED, ORDER_STEPS.IN_STORE, ORDER_STEPS.IN_STORE].includes(
        order.currentStepId
      )
    );
  }, [order, orderReceipts]);

  const qtyPickedItems = useMemo(() => {
    return orderItems.reduce((qty, orderItem) => {
      if (orderItem.status === ORDER_ITEM_STATUSES.TAKEN) {
        qty++;
      }
      return qty;
    }, 0);
  }, [orderItems]);

  const waitingForPaymentConfirmation = useMemo(() => {
    return (
      order?.isMine && [ORDER_STEPS.CONFIRMED].includes(order.currentStepId)
    );
  }, [order]);

  const hooks = useMemo(
    () => ({
      orderId,
      isLoading,
      loadingError,
      orderGetOp,
      orderItemsListOp,
      orderHooks,
      orderItemHooks,
      orderReceiptHooks,
      receiptToEdit,
      totalFormOpen,
      orderActions,
      actionInProgress,
      saveOpResult,
      setStepOpResult,
      orderReceiptSaveOpResult,
      setReceiptToEdit,
      handleClickAction,
      handleClickReceiptAction,
      setOrderPageTitleId,
      reviewToEdit,
      needsReceipts,
      qtyPickedItems,
      waitingForPaymentConfirmation,
    }),
    [
      orderId,
      isLoading,
      loadingError,
      orderGetOp,
      orderItemsListOp,
      orderHooks,
      orderItemHooks,
      orderReceiptHooks,
      receiptToEdit,
      totalFormOpen,
      orderActions,
      actionInProgress,
      saveOpResult,
      setStepOpResult,
      orderReceiptSaveOpResult,
      setReceiptToEdit,
      handleClickAction,
      handleClickReceiptAction,
      setOrderPageTitleId,
      reviewToEdit,
      needsReceipts,
      qtyPickedItems,
      waitingForPaymentConfirmation,
    ]
  );

  return (
    <OrdersContext.Provider value={hooks}>{children}</OrdersContext.Provider>
  );
};

OrdersProvider.displayName = 'OrdersProvider';

OrdersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { OrdersProvider };
