import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Caption, Grid, GridItem } from '@sdflc/ui';

const SettingsRow = memo((props) => {
  const { name, children } = props;

  return (
    <Grid dimension='fluid'>
      <GridItem width={['100%', '100%', '100%', '30%']}>
        <Caption level={4} pt={4}>
          {name}
        </Caption>
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '70%']}>{children}</GridItem>
    </Grid>
  );
});

SettingsRow.displayName = 'SettingsRow';

SettingsRow.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
};
SettingsRow.defaultProp = {};

export { SettingsRow };
