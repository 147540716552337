const OPERATIONS = {
  SIGN_UP: 'SIGN_UP',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  APP_ME: 'APP_ME',
  LIST: 'LIST',
  GET: 'GET',
  GET_MANY: 'GET_MANY',
  CREATE: 'CREATE',
  CREATE_MANY: 'CREATE_MANY',
  UPDATE: 'UPDATE',
  UPDATE_MANY: 'UPDATE_MANY',
  SET: 'SET',
  REMOVE: 'REMOVE',
  REMOVE_MANY: 'REMOVE_MANY',
  TRANSFORM: 'TRANSFORM',
  TRANSFORM_MANY: 'TRANSFORM_MANY',
  PUBLISH: 'PUBLISH',
  PICK: 'PICK',
  CANCEL: 'CANCEL',
  ORDER_SET_STEP: 'ORDER_SET_STEP',
  UPDATE_USER: 'UPDATE_USER',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD: 'RESET_PASSWORD',
};

export { OPERATIONS };
