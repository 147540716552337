import { ORDER_STATUSES, ORDER_STEPS } from 'config';
import { ORDER_LIST_CATEGORIES } from 'config/orderCard';

export const en = {
  generic: {
    loading: 'Loading ...',
    help: 'Help',
    comments: 'Comments',
    send: 'Send',
    username: 'Username',
    search: 'Search',
    contactUs: 'Contact Us',
    default: 'Default',
    amount: 'Amount',
    onSale: 'On Sale',
    notTaken: 'Not Taken',
  },
  buttons: {
    ok: 'OK',
    save: 'Save',
    add: 'Add',
    edit: 'Edit',
    cancel: 'Cancel',
    remove: 'Remove',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    signOut: 'Sign Out',
    download: 'Download',
    upload: 'Upload',
    import: 'Import',
    send: 'Send',
    invite: 'Invite',
    getDirections: 'Get Directions',
    pick: 'Pick',
    showAllDetails: 'Show all details',
    chooseFile: 'Choose file',
    showAll: 'Show All',
    showLess: 'Show Less',
    summary: 'Summary',
    comments: 'Comments',
    uploadReceipt: 'Upload Receipt',
    orderItemTake: 'Mark as taken',
    orderItemReturn: 'Return the item',
    rateBuyer: 'Rate the buyer',
    rateShopper: 'Rate the shopper',
    goHome: 'Go Home',
    viewReceipts: 'Veiw receipts',
  },
  orderStatuses: {
    [ORDER_STATUSES.DRAFT]: 'Draft',
    [ORDER_STATUSES.PUBLISHED]: 'Published',
    [ORDER_STATUSES.PROGRESS]: 'In progress',
    [ORDER_STATUSES.COMPLETED]: 'Completed',
    [ORDER_STATUSES.CANCELLED]: 'Canceled',
    [ORDER_STATUSES.REMOVED]: 'Removed',
  },
  orderSteps: {
    [ORDER_STEPS.DRAFT]: 'Draft',
    [ORDER_STEPS.AVAILABLE]: 'Waiting to be picked',
    [ORDER_STEPS.PICKED]: 'Picked',
    [ORDER_STEPS.IN_STORE]: 'In store',
    [ORDER_STEPS.ON_THE_WAY]: 'Done',
    [ORDER_STEPS.DELIVERED]: 'Delivered',
    [ORDER_STEPS.CONFIRMED]: 'Confirmed',
    [ORDER_STEPS.NOT_CONFIRMED]: 'Dispute',
    [ORDER_STEPS.PAID]: 'Paid',
    [ORDER_STEPS.COMPLETE]: 'Complete',
    [ORDER_STEPS.CANCELLED_BY_USER]: 'Canceled by the user',
    [ORDER_STEPS.CANCELLED_BY_SYSTEM]: 'Canceled by the system',
    waitingForConfirmation: 'Waiting for confirmation',
    waitingForPaymentConfirmation: 'Waiting for payment confirmation',
  },
  orderCard: {
    details: 'Details',
    positions: 'positions',
    youAre: 'You are',
    shopperAndBuyer: 'Buyer & Shopper',
    buyer: 'Buyer',
    shopper: 'Shopper',
  },
  order: {
    currentStepId: 'Current Status',
    locationId: 'Deliver To',
    dueDate: 'Deliver By',
  },
  noOrders: {
    [ORDER_LIST_CATEGORIES.MY_ORDERS]:
      'You have no drafts yet. Start creating an order by tapping on the "Add" button',
    [ORDER_LIST_CATEGORIES.IN_PROGRESS]: 'You have no orders in process yet',
    [ORDER_LIST_CATEGORIES.PUBLISHED]:
      'It seems there are no orders around to pick',
    [ORDER_LIST_CATEGORIES.SHOPPER_COMPLETED]:
      'You have no orders you have picked up and delivered',
  },
  suggestions: {
    addLocation: 'Add at least 1 address',
    addName: 'Enter your name',
  },
  errors: {
    failedToLoadOrderPageTitle: 'Order Load Failed',
  },
  forms: {
    address: {
      labels: {
        address1: 'Address 1',
        address2: '',
        address2Name: '',
        city: 'City',
        state: 'State',
        postalCode: 'Postal Code',
        country: 'Country',
        countryId: 'Country',
        notes: 'Notes',
      },
      hints: {},
      placeholders: {
        address2: '103',
        address2Name: 'Apt/Unit/etc',
      },
    },
    passwordChange: {
      labels: {
        password: '',
        repeatPassword: '',
      },
      hints: {
        password: '',
        repeatPassword: '',
      },
      placeholders: {
        password: 'New password',
        repeatPassword: 'Repeat new password',
      },
    },
    orderReview: {
      labels: {
        rating: 'Rating',
        review: 'Your review',
      },
      hints: {},
      placeholders: {},
    },
  },
  selectors: {
    selectUserShoppingItem: {
      placeholder: 'Select an item',
    },
    selectUserLocation: {
      placeholder: 'Select a location',
    },
    selectStoreBrand: {
      placeholder: 'Select a store brand',
    },
    selectUnit: {
      placeholder: 'Select a unit',
    },
    selectCategory: {
      placeholder: 'Select a category',
    },
    selectDistance: {
      placeholder: 'Select distance',
    },
    selectPaymentMethod: {
      cash: 'Pay by cash',
      placeholder: 'Select a payment method',
    },
    selectUser: {
      placeholder: 'Select a user',
    },
  },
  blocks: {
    receipts: {
      noItems: 'There are no receipts yet',
      failedToRemove: 'Failed to remove the receipt',
      form: {
        title: {
          add: 'Add a receipt',
          edit: 'Edit a receipt',
        },
        labels: {
          storeBrandId: 'Store',
          amount: 'Amount',
        },
        hints: {},
      },
    },
    orders: {
      errors: {
        failedToCreate: 'Failed to create an order: :error',
        failedToSetOrderStep: 'Failed to change order state: :error',
      },
    },
    orderDelivered: {
      header: 'Congratulations!',
      title: 'You have delievered the order #:orderNum',
      subtitle:
        'The system has sent a delivery notification to the buyer. As soon as the buyer confirms the order we will proceed to the next step',
      labels: {
        buyer: 'Delivered For',
        shopper: 'Delivered By',
        deliveredTo: 'Delivered to',
        deliveredAt: 'Delivery time',
        orderItemsQty: 'Order items quantity',
        orderItemsTakenQty: 'Taken items quantity',
        orderAmount: 'Order total amount',
        deliveryCost: 'You have earned',
        paymentMethod: 'Payment Method',
        whereToTransfer: 'Where to transfer the funds',
      },
      hints: {
        whereToTransfer:
          'Please select an account where to transfer funds when the buyer confirms the order',
      },
    },
    orderConfirmed: {
      header: 'Congratulations!',
      title: 'The order #:orderNum has been confirmed',
      subtitle: {
        card: 'Your credit card has been charged',
        cash: 'As the payment method is Cash, please transfer money to the shopper. The order will be considered closed as soon as the shopper confirms receiving the payment.',
      },
      labels: {
        shopper: 'Shopper',
        orderItemsQty: 'Order items quantity',
        orderItemsTakenQty: 'Taken items quantity',
        orderAmount: 'Order subtotal',
        deliveryCost: 'Delivery cost',
        serviceFeeTotalAmount: 'Service Fee Cost',
        totalAmountForBuyer: 'Total amount',
        paymentMethod: 'Payment method',
      },
    },
    orderPaid: {
      header: 'Congratulations!',
      title: 'The order #:orderNum has been paid',
      subtitle: 'The order has been completed successfully',
      labels: {
        shopper: 'Delivered By',
        buyer: 'Delivered For',
        deliveredTo: 'Delivered To',
        deliveredAt: 'Delivery time',
        orderItemsQty: 'Order items quantity',
        orderItemsTakenQty: 'Taken items quantity',
        orderSubtotal: 'Order total subtotal',
        serviceFeeCost: 'Service Fee Cost',
        deliveryCost: 'Delivery cost',
        orderTotal: 'Total Cost',
        orderAmount: 'Order subtotal',
        serviceFeeTotalAmount: 'Service Fee Cost',
        totalAmountForBuyer: 'Total amount',
        totalAmountForShopper: 'Total amount',
        paymentMethod: 'Payment method',
      },
    },
    orderComplete: {
      header: 'Congratulations!',
      title: 'The order #:orderNum has been completed',
      subtitle: 'You have completed your own order',
      labels: {
        orderItemsQty: 'Order items quantity',
        orderItemsTakenQty: 'Taken items quantity',
        orderSubtotal: 'Order total subtotal',
        orderAmount: 'Order amount',
      },
    },
    userSubscribeListItem: {
      title: 'User Subscribes',
      noItems: 'You are not subscribed for any user',
      form: {
        title: 'Subscribe for user',
        description: 'Please fill up the form',
        labels: {
          subscribedForUserId: 'User',
          status: 'Is subscription active',
        },
        hints: {},
        placeholders: {
          subscribedForUserId: 'User',
          status: '',
        },
      },
    },
    orderSubscriptionByDistance: {
      title: 'Distance Subscriptions',
      noItems: 'You are not subscribed for any orders around your addresses',
      form: {
        title: 'Subscribe for orders',
        description: 'Please fill up the form',
        labels: {
          subscribedForUserId: 'User',
          status: 'Is subscription active',
        },
        hints: {},
        placeholders: {
          subscribedForUserId: 'User',
          status: '',
        },
      },
    },
    userAllowListItem: {
      title: 'Allowed Users',
      noItems:
        'You have no allowed users added. It means anyone can see your published orders. If you want to allow access only to select users please add them on this page',
      form: {
        title: 'Add allowed user',
        description: 'Please fill up the form',
        labels: {
          allowedUserId: 'Allowed user',
          status: 'Is active',
        },
        hints: {},
        placeholders: {
          allowedUserId: '',
          status: '',
        },
      },
    },
    userBlockListItem: {
      title: 'Blocked Users',
      noItems:
        'You have no blocked users added. Blocked users cannot see your published orders or pick your orders.',
      form: {
        title: 'Add blocked user',
        description: 'Please fill up the form',
        labels: {
          allowedUserId: 'Blocked user',
          status: 'Is active',
        },
        hints: {},
        placeholders: {
          allowedUserId: '',
          status: '',
        },
      },
    },
  },
  pages: {
    notFound: {
      title: 'Page Not Found',
      description:
        'It seems that the page you are looking for does not exist or was renamed. Please started from the home page',
    },
    signIn: {
      form: {
        title: 'User Sign In',
        description: 'Please fill up the form',
        forgotPassword: 'Forgot password',
        labels: {
          username: 'Username',
          password: 'Password',
          rememberMe: 'Remember me',
        },
        hints: {},
        placeholders: {
          username: 'Username',
          password: 'Password',
        },
      },
    },
    signUp: {
      form: {
        title: 'User Sign Up',
        description:
          'To start using the service you need to sign up. Please fillup the form',
        labels: {
          username: 'Username',
          email: 'Email',
          password: 'Password',
          repeatPassword: 'Repeat Password',
          agreeWithTerms: 'I agree with ',
        },
        hints: {},
        placeholders: {
          username: 'Username',
          email: 'Email',
          password: 'Password',
          repeatPassword: 'Repeat Password',
        },
      },
    },
    forgotPassword: {
      form: {
        title: 'Forgot password',
        description:
          'Please enter your user name and we will send a link to reset password to the email specified to the username',
        labels: {
          username: 'Username',
        },
        hints: {},
        placeholders: {
          username: 'Username',
        },
      },
      requested: {
        title: 'Reset Password Request',
        description:
          'If entered username existin in our system we will send you an email with a link to a page to reset your password. Please keep in mind that the link has expiration time provided in the email.',
      },
    },
    resetPassword: {
      errors: {
        noRequestId: {
          title: 'Invalid Request ID',
          description:
            'It seems you have invalid link to the page to reset your password. Please resubmit for another link.',
        },
      },
      succeeded: {
        title: 'Password Has Been Reset',
        description:
          "You have successfully reset your password. Please use the button below to go to the 'Sign In' page",
      },
      buttons: {
        resubmit: 'Resubmit',
      },
      form: {
        title: 'Reset password',
        description: 'Please enter new password',
        labels: {
          password: 'Password',
          repeatPassword: 'Repeat Password',
        },
        hints: {},
        placeholders: {
          password: 'password',
          repeatPassword: 'Repeat Password',
        },
      },
    },
    verifyEmail: {
      statuses: {
        PROGRESS: 'Verifying Email',
        SUCCESS: 'Email has been verified',
        FAILURE: 'Failed to verify email',
      },
    },
    userContacts: {
      title: 'My Contacts',
      add: 'Add contact',
      noContacts: 'You have no contacts yet.',
      form: {
        title: {
          add: 'Add contact',
          edit: 'Edit contact',
        },
        labels: {
          contactTypeId: 'Type',
          contactValue: 'Contact',
          contactDescription: 'Description',
          status: 'Status',
        },
        hints: {
          contactTypeId: '',
          contactValue: '',
          contactDescription: 'Description',
          status: '',
        },
      },
    },
    bankAccounts: {
      title: 'My Bank Accounts',
      add: 'Add Bank',
      itemName: 'Bank Account',
      noItems: 'You have no bank accounts yet',
      form: {
        title: {
          add: 'Add',
          edit: 'Edit',
        },
        labels: {
          countryId: 'Country',
          institutionNumber: 'Institution Number',
          transitNumber: 'Transit (Branch) Number',
          accountNumber: 'Account Number',
          locationId: 'Location',
        },
        hints: {
          countryId: '',
          institutionNumber: '',
          transitNumber: '',
          accountNumber: '',
          locationId: '',
        },
      },
      errors: {
        list: 'Failed to list bank accounts: :error',
        getMany: 'Failed to get bank accounts: :error',
        create: 'Failed to create a bank account: :error',
        update: 'Failed to update a bank account: :error',
        removeMany: 'Failed to remove bank account(s): :error',
      },
    },
    paymentMethods: {
      title: 'My Payment Methods',
      add: 'Add',
      noItems: 'You have no payment methods yet',
      form: {
        title: {
          add: 'Add',
          edit: 'Edit',
        },
        // labels: {
        //   countryId: 'Country',
        //   institutionNumber: 'Institution Number',
        //   transitNumber: 'Transit (Branch) Number',
        //   accountNumber: 'Account Number',
        //   locationId: 'Location',
        // },
        // hints: {
        //   countryId: '',
        //   institutionNumber: '',
        //   transitNumber: '',
        //   accountNumber: '',
        //   locationId: '',
        // },
      },
      errors: {
        list: 'Failed to list banks details: :error',
        getMany: 'Failed to bank details: :error',
        create: 'Failed to create a bank detail: :error',
        update: 'Failed to update a bank detail: :error',
        removeMany: 'Failed to bank detail(s): :error',
      },
    },
    paymentMethodsCreated: {
      title: 'Payment Methods Created',
      errors: {
        create: 'Failed to register created payment method: :error',
      },
    },
    userNameEditor: {
      form: {
        labels: {},
        placeholders: {
          displayName: 'Public name',
        },
        hints: {},
      },
    },
    userName: {
      title: 'Display Name',
    },
    userLocations: {
      title: 'My Addresses',
      add: 'Add address',
      noLocations: 'You have no addresses yet.',
      form: {
        title: {
          add: 'Add an address',
          edit: 'Edit an address',
        },
        labels: {},
        placeholders: {},
        hints: {},
      },
    },
    profile: {
      title: 'Profile',
    },
    userShoppingItems: {
      title: 'Shopping Items',
      noItems: 'You have no shopping items yet.',
      add: 'Add',
      form: {
        title: {
          add: 'Add a shopping item',
          edit: 'Edit a shopping item',
        },
        labels: {
          shoppingItemName: 'Name',
          manufacturer: 'Manufacturer',
          shoppingItemDescription: 'Description',
          categoryId: 'Category',
          unitId: 'Unit',
          quantity: 'Quantity',
          minPricePerUnit: 'Min. Price',
          maxPricePerUnit: 'Max. Price',
          pictureUrl: 'Image',
        },
        placeholders: {},
        hints: {},
      },
    },
    home: {
      titles: {
        myOrders: 'My orders',
        myInProgress: 'In progress',
        published: 'Available nearby',
      },
    },
    orders: {
      title: 'Your Active Orders',
      noItems: 'You have no orders',
      noUserLocations: 'To create an order you need to add an address first',
      orderNotFound: 'No order found',
      add: 'Create order',
      tabs: {
        orderItems: 'Products',
        orderInvite: 'Invite',
        deliveryInfo: 'Delivery Info',
        details: 'Details',
        paymentInfo: 'Payment Info',
      },
      summaryBox: {
        title: 'Summary',
        receipts: 'Receipts',
        shoppingText: 'Taken :qtyPickedItems out of :qtyOrderItems products',
        readOnlyText: 'There are :qtyOrderItems items in the order',
        nextSteps: 'The next steps are',
        needReceipts: 'To proceed please add receipts information',
        waitingForConfirmation: 'Waiting for the buyer to confirm the order',
        waitingForPaymentConfirmation:
          'Waiting for the shopper to confirm the payment',
      },
      form: {
        title: {
          add: 'Create an order',
          edit: 'Edit the order #:orderNum',
        },
        labels: {
          storeBrandId: 'Shop in the store',
          paymentMethodId: 'Payment method',
          locationId: 'Deliver to the address',
          dueDate: 'Deliver by',
          deliveryCost: 'Delivery cost',
          buyerComment: 'Your note to shopper',
        },
        hints: {
          paymentMethodId:
            'You can change the payment method only when the order is not in progress',
          storeBrandId:
            'Select a store where you want the order to be fulfilled. The store cannot be changed after the order was picked up',
          locationId:
            'You can change the location only when the order is not in progress',
          deliveryCost:
            'This is the amount you are paying for the order delivery. The amount cannot be changed after the order was picked up',
        },
        placeholders: {},
      },
      errors: {
        list: 'Failed to list orders: :error',
        getMany: 'Failed to retrieve orders: :error',
        create: 'Failed to create an order: :error',
        update: 'Failed to update an order: :error',
        removeMany: 'Failed to remove order(s): :error',
        publish: 'Failed to publish an order: :error',
        pick: 'Failed to pick an order: :error',
        setStep: 'Failed to change order status: :error',
      },
    },
    order: {
      title: 'Order #:orderNum',
      shopping: {
        title: 'Shopping #:orderNum',
      },
      view: {
        title: 'Order #:orderNum',
      },
      delivered: {
        title: 'Order #:orderNum delivered',
      },
      confirmed: {
        title: 'Order #:orderNum confirmed',
      },
      paid: {
        title: 'Order #:orderNum paid',
      },
      complete: {
        title: 'Order #:orderNum complete',
      },
      edit: {
        title: 'Edit order #:orderNum ',
      },
      totalAndReceipt: {
        title: 'Total and receipts',
        subtitleReceipts: 'Receipts',
        labels: {
          totalAmount: 'Order Total',
          shopperComment: 'Your notes to buyer',
        },
        hints: {
          totalAmount: 'Amount you paid for the order',
        },
        placeholders: {},
      },
      details: {
        orderNo: 'Order No',
        address: 'Address',
        due: 'Due',
        buyerComment: 'Buyer comment',
        noBuyerComment: 'No comment provided',
        shopperComment: 'Shopper comment',
        deliveryCost: 'Delivery Cost',
        paymentMethod: 'Payment Method',
        totalAmount: 'Total Amount',
        step: 'Step',
        status: 'Status',
        shopperName: 'Shopper',
        buyerName: 'Buyer',
        storeBrand: 'Store',
      },
    },
    orderInvite: {
      title: 'Invite shopper',
    },
    myPastOrders: {
      title: 'My Past Orders',
      noItems: 'There are no completed orders yet',
    },
    ordersRecurring: {
      title: 'My Recurring Orders',
      noItems: 'You have no recurring orders yet',
    },
    publishedOrders: {
      title: 'Orders To Pick',
      noItems: 'At the moment there are no orders available to pick',
    },
    orderItems: {
      title: 'Order Items',
      noItems: 'The order has no items to shop for',
      startSelection:
        'Start checking items below or if no item found click on the "Create" button to create a new one',
      add: 'Add order item',
      form: {
        title: {
          add: 'Add an order item',
          edit: "Edit ':shoppingItemName'",
        },
        labels: {
          shoppingItemId: 'Shopping item',
          unitId: 'Unit',
          quantity: 'Quantity',
          minPricePerUnit: 'Min. price per unit',
          maxPricePerUnit: 'Max. price per unit',
          onlyIfOnSaleIndicator: 'Only if on sale',
          notImportantIndicator: 'Not important',
          buyerComment: 'Your comment',
          shopperComment: 'Shopper comment',
          status: 'Status',
          storeBrandId: 'Store Brand',
          actualQuantity: 'Actual quantity',
          price: 'Actual price',
          totalAmount: 'Total amount',
        },
        hints: {},
        placeholders: {},
      },
    },
    ordersIDelivered: {
      menuTitle: 'Delivered Orders',
      title: 'Orders I Delivered',
      noItems: 'There are no orders you have delievered yet',
    },
    shopperOrdersInProgress: {
      menuTitle: 'In Progress',
      title: 'Orders In Progress',
      noItems: 'You have no orders in progress',
      ordersYouCreated: 'Orders you created',
      ordersYouPicked: 'Orders you picked',
    },
    orderItemComments: {
      noItems: 'No comments yet',
      labels: {
        comment: 'Enter comment',
      },
    },
    settings: {
      title: 'Settings',
      tabs: {
        general: {
          title: 'General',
          sections: {
            name: 'Name',
            avatar: 'Avatar',
            password: 'Password',
          },
          description:
            'Manage your display name, upload avatar and change your password',
        },
        contacts: {
          title: 'Contacts',
          description:
            'Manage your contact information so other users who work on your order could reach out to you. Also, these contacts will be used by ShopaDropa for providing support',
        },
        addresses: {
          title: 'Addresses',
          description:
            'Manage your addresses where you want your orders to be delivered. Please note that as soon as someone picked your order they will know your address',
        },
        paymentMethods: {
          title: 'Payment Methods',
          description:
            'Manage your payment methods (credit cards) used to pay for orders you create and publish. If you prefer to pay by cash no credit card information needed',
        },
        bankAccounts: {
          title: 'Bank Accounts',
          description:
            'Manage your bank accounts where ShopaDropa will send the money your spent and earned by executing orders',
        },
        userSubscribe: {
          title: 'User Subscribe',
          description:
            'Subscribe for users from which you want to receive notifications when they publish orders',
        },
        orderSubscriptionByDistance: {
          title: 'Distance subscriptions',
          description:
            'Subscribe for order placed within specific distance from your locations',
        },
        userAllowListItem: {
          title: 'Allowed Users',
          description: 'Set a list of users who can see all your orders',
        },
        userBlockListItem: {
          title: 'Blocked Users',
          description: 'Set a list of users who cannot see your orders',
        },
      },
    },
    transactions: {
      title: 'Transactions',
    },
    userSubscribes: {
      title: 'User subscribes',
    },
    orderSubscriptionByDistance: {
      title: 'Distance subscriptions',
    },
    userAllowListItem: {
      title: 'Allowed users',
    },
    userBlockListItem: {
      title: 'Blocked users',
    },
  },
  footer: {
    contactUs: {
      title: 'Contact Us',
      description:
        'Use the form to reach out to us with questions, concerns or ideas',
      labels: {
        subject: 'Subject',
        message: 'Message',
      },
      hints: {},
      placeholders: {},
    },
  },
  entities: {
    order: {
      buyerComment: 'Buyer comment',
      locationId: 'Deliever To',
      dueDate: 'Deliver By',
      currentStepId: 'Current status',
      orderAmount: 'Order amount',
      serviceFeeTotalAmount: 'Service Fee',
      totalAmountForBuyer: 'Total amount',
      totalAmountForShopper: 'Total amount',
      deliveryCost: 'Delivery cost',
      orderItemsQty: 'Items quantity',
    },
  },
};
