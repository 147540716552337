import { memo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';
import { Alert, Panel } from '@sdflc/ui';

import { LocalizationContext } from '../../../contexts';
import { UserContactItem } from './UserContactItem';

const tplUserContacts = Array.from({ length: 2 }).map((_, idx) => ({
  id: idx,
  contactTypeId: 'home',
  contactValue: idx,
  contactDescription: '',
  isDefault: false,
  status: 100,
}));

const UserContactsList = memo((props) => {
  const { onClickAction, list, items, listOp, removingOps } = props;
  const { getText } = useContext(LocalizationContext);

  useEffect(() => {
    // Load user contacts if they were not yet loaded

    if (!listOp.called && check.function(list)) {
      list({
        filter: {},
        params: {},
      });
    }
  }, [list, listOp]);

  if (
    listOp.called &&
    !listOp.result.isInProgress() &&
    listOp.result.didFail()
  ) {
    return <Alert variant='danger'>{listOp.result.getErrorSummary()}</Alert>;
  }

  const items2use = listOp.result?.isInProgress() ? tplUserContacts : items;

  return (
    <Panel mt={2} p={1} overflow='initial'>
      {items2use.map((item) => {
        return (
          <UserContactItem
            key={item.id}
            value={item}
            onClickAction={onClickAction}
            loading={listOp.result?.isInProgress()}
            removingResult={removingOps[item.id]?.result}
          />
        );
      })}
      {!listOp.result?.isInProgress() && items.length === 0 && (
        <Alert variant='default'>
          {getText('pages.userContacts.noContacts')}
        </Alert>
      )}
    </Panel>
  );
});

UserContactsList.displayName = 'UserContactsList';

UserContactsList.propTypes = {
  onClickAction: PropTypes.func,
  list: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  listOp: PropTypes.any,
  removingOps: PropTypes.any,
};

UserContactsList.defaultProps = {
  onClickAction: null,
};

export { UserContactsList };
