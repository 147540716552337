export const SHOW_USER_MODES = {
  NONE: 'none', // noone
  BUYER: 'buyer', // the order owner
  SHOPPER: 'shopper', // a user currently assigned as shopper to the order
  INVITEE: 'invitee', // a user that was invited to the order
  VIEWER: 'viewer', // a user that is viewing published order to consider pick the order up
};

export const ORDER_LIST_CATEGORIES = {
  NONE: 'none',
  MY_ORDERS: 'MY_ORDERS',
  IN_PROGRESS: 'IN_PROGRESS',
  PUBLISHED: 'PUBLISHED',
  SHOPPER_COMPLETED: 'SHOPPER_COMPLETED',
  MY_PAST_ORDERS: 'MY_PAST_ORDERS',
};
