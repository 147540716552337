import { FORM_INPUT_TYPES } from '@sdflc/ui';
import { SelectStoreBrand } from 'components/Controls/SelectStoreBrand';
import { OrderReceiptUploader } from './OrderReceiptUploader';

export const orderReceiptFormSections = [
  {
    fields: [
      {
        field: 'storeBrandId',
        width: ['100%'],
        component: SelectStoreBrand,
        componentProps: {
          leftIcon: 'Storefront',
          disabled: true,
        },
      },
      {
        field: 'amount',
        type: FORM_INPUT_TYPES.NUMBER,
        width: ['100%'],
        componentProps: {
          leftIcon: 'AttachMoney',
        },
      },
      {
        field: 'receiptFileId',
        width: ['100%'],
        component: OrderReceiptUploader,
        componentProps: {},
      },
    ],
  },
];
