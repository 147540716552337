import { InputAddress } from '../../Controls/InputAddress';

export const userLocationForm = [
  {
    fields: [
      {
        field: 'location',
        component: InputAddress,
        width: ['100%'],
      },
    ],
  },
];
