import { useState, useCallback } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';
import {
  handleApiResult,
  OperationManager,
  OPERATIONS,
  useAppMutation,
  useAppQuery,
} from '../../utils';
import { arrayOfObjectsToArray } from '../../utils/transformers';
import { orderReceiptQueries } from '../queries/orderReceiptQueries';

const useApiOrderReceipt = () => {
  const [orderReceipt, setOrderReceipt] = useState(null);
  const [orderReceiptMany, setOrderReceiptMany] = useState([]);
  const [orderReceipts, setOrderReceipts] = useState([]);
  const [orderReceiptsOps, setOrderReceiptsOps] = useState({});
  const [orderReceiptsOpsManager] = useState(new OperationManager());

  orderReceiptsOpsManager.setStateControls(
    orderReceiptsOps,
    setOrderReceiptsOps
  );

  const handleApiResultOpts = {
    hookName: 'useApiOrderReceipt',
    opsManager: orderReceiptsOpsManager,
    setItem: setOrderReceipt,
    setItemsMany: setOrderReceiptMany,
    setItems: setOrderReceipts,
  };

  /**
   * GRAPHQL CALLERS
   */

  const [mutationOrderReceiptCreate] = useAppMutation({
    queries: orderReceiptQueries,
    queryName: 'orderReceiptCreate',
    onDone: handleApiResult(OPERATIONS.CREATE, handleApiResultOpts),
  });

  const [mutationOrderReceiptUpdate] = useAppMutation({
    queries: orderReceiptQueries,
    queryName: 'orderReceiptUpdate',
    onDone: handleApiResult(OPERATIONS.UPDATE, handleApiResultOpts),
  });

  const [mutationOrderReceiptRemoveMany] = useAppMutation({
    queries: orderReceiptQueries,
    queryName: 'orderReceiptRemoveMany',
    onDone: handleApiResult(OPERATIONS.REMOVE_MANY, handleApiResultOpts),
  });

  const [queryOrderReceiptList] = useAppQuery({
    queries: orderReceiptQueries,
    queryName: 'orderReceiptList',
    onDone: handleApiResult(OPERATIONS.LIST, handleApiResultOpts),
  });

  const [queryOrderReceiptGetMany] = useAppQuery({
    queries: orderReceiptQueries,
    queryName: 'orderReceiptGetMany',
    onDone: handleApiResult(OPERATIONS.GET_MANY, handleApiResultOpts),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const orderReceiptCreate = useCallback(
    (variables) => {
      orderReceiptsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.CREATE,
        OP_RESULT_CODES.SAVING
      );

      mutationOrderReceiptCreate({ variables });
    },
    [mutationOrderReceiptCreate, orderReceiptsOpsManager]
  );

  const orderReceiptUpdate = useCallback(
    (variables) => {
      orderReceiptsOpsManager.setStartOperation(
        variables?.where?.id,
        OPERATIONS.UPDATE,
        OP_RESULT_CODES.SAVING
      );

      mutationOrderReceiptUpdate({ variables });
    },
    [mutationOrderReceiptUpdate, orderReceiptsOpsManager]
  );

  const orderReceiptRemoveMany = useCallback(
    (variables) => {
      orderReceiptsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.REMOVE_MANY,
        OP_RESULT_CODES.DELETING
      );

      mutationOrderReceiptRemoveMany({ variables });
    },
    [mutationOrderReceiptRemoveMany, orderReceiptsOpsManager]
  );

  const orderReceiptList = useCallback(
    (variables) => {
      orderReceiptsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING
      );

      queryOrderReceiptList({ variables });
    },
    [queryOrderReceiptList, orderReceiptsOpsManager]
  );

  const orderReceiptGetMany = useCallback(
    (variables) => {
      orderReceiptsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.GET_MANY,
        OP_RESULT_CODES.LOADING
      );

      queryOrderReceiptGetMany({ variables });
    },
    [queryOrderReceiptGetMany, orderReceiptsOpsManager]
  );

  return {
    orderReceipt,
    orderReceipts,
    orderReceiptMany,

    orderReceiptCreate,
    orderReceiptGetMany,
    orderReceiptUpdate,
    orderReceiptRemoveMany,
    orderReceiptList,

    orderReceiptsOpsManager,
  };
};

export { useApiOrderReceipt };
