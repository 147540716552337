import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';

import { Switch } from '@sdflc/ui';

const EnableSwitch = memo((props) => {
  const { value, onChange, ...rest } = props;

  const handleChange = useCallback(
    (name, value) => {
      if (check.function(onChange)) {
        onChange(name, value === false ? 0 : 100);
      }
    },
    [onChange]
  );

  return <Switch value={value === 100} {...rest} onChange={handleChange} />;
});

EnableSwitch.displayName = 'EnableSwitch';
EnableSwitch.propTypes = {
  ...Switch.propTypes,
  value: PropTypes.number,
};
EnableSwitch.defaultProp = {};

export { EnableSwitch };
