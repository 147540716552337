import React from 'react';
import PropTypes from 'prop-types';
import { TRulesContext } from '../contexts';
import { useTRules } from '../graphql/hooks';

const TRulesProvider = (props) => {
  const { children } = props;
  const hooks = useTRules();

  return (
    <TRulesContext.Provider value={hooks}>{children}</TRulesContext.Provider>
  );
};

TRulesProvider.displayName = 'TRulesProvider';

TRulesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { TRulesProvider };
