import { FORM_INPUT_TYPES } from '@sdflc/ui';

export const passwordChangeForm = [
  {
    fields: [
      {
        field: 'password',
        width: ['100%', '100%', '50%', '50%'],
        type: FORM_INPUT_TYPES.PASSWORD,
        inputProps: {
          autocomplete: 'turnedoff',
        },
      },
      {
        field: 'repeatPassword',
        width: ['100%', '100%', '50%', '50%'],
        type: FORM_INPUT_TYPES.PASSWORD,
        inputProps: {
          autocomplete: 'turnedoff',
        },
      },
    ],
  },
];
