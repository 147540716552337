import React from 'react';
import PropTypes from 'prop-types';
import { OrderItemsContext } from '../contexts';
import { useOrderItem } from '../graphql/hooks';

const OrderItemsProvider = (props) => {
  const { children } = props;
  const hooks = useOrderItem();

  return (
    <OrderItemsContext.Provider value={hooks}>
      {children}
    </OrderItemsContext.Provider>
  );
};

OrderItemsProvider.displayName = 'OrderItemsProvider';

OrderItemsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { OrderItemsProvider };
