export const ORDER_STEPS = {
  DRAFT: 50,
  AVAILABLE: 100,
  PICKED: 200,
  IN_STORE: 210,
  ON_THE_WAY: 220,
  DELIVERED: 230,
  CONFIRMED: 300,
  NOT_CONFIRMED: 310,
  PAID: 400,
  COMPLETE: 450,
  CANCELLED_BY_USER: 500,
  CANCELLED_BY_SYSTEM: 510,
};

export const ORDER_EDITABLE_BY_ME = [
  ORDER_STEPS.DRAFT,
  ORDER_STEPS.AVAILABLE,
  ORDER_STEPS.PICKED,
  ORDER_STEPS.IN_STORE,
  ORDER_STEPS.ON_THE_WAY,
  ORDER_STEPS.DELIVERED,
];

export const ORDER_SHOPABLE_BY_ME = [
  ORDER_STEPS.PICKED,
  ORDER_STEPS.IN_STORE,
  ORDER_STEPS.ON_THE_WAY,
];

export const ORDER_SHOULD_SHAW_PROGRESS = [
  ORDER_STEPS.PICKED,
  ORDER_STEPS.IN_STORE,
  ORDER_STEPS.ON_THE_WAY,
  ORDER_STEPS.DELIVERED,
  ORDER_STEPS.CONFIRMED,
  ORDER_STEPS.NOT_CONFIRMED,
  ORDER_STEPS.PAID,
  ORDER_STEPS.COMPLETE,
];

export const ORDER_CONFIRMED_OR_COMPLETE = [
  ORDER_STEPS.CONFIRMED,
  ORDER_STEPS.PAID,
  ORDER_STEPS.COMPLETE,
];
export const ORDER_COMPLETE = [ORDER_STEPS.PAID, ORDER_STEPS.COMPLETE];
