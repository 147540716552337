import React from 'react';
import PropTypes from 'prop-types';
import { StoreBrandContext } from '../contexts';
import { useStoreBrand } from '../graphql/hooks';

const StoreBrandProvider = (props) => {
  const { children } = props;
  const hooks = useStoreBrand();

  return (
    <StoreBrandContext.Provider value={hooks}>
      {children}
    </StoreBrandContext.Provider>
  );
};

StoreBrandProvider.displayName = 'StoreBrandProvider';

StoreBrandProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { StoreBrandProvider };
