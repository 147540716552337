import { memo } from 'react';
import PropTypes from 'prop-types';

import { Container, LogoWrapper } from './styled';
import { AppPagesProvider } from 'providers';
import { EmptyLayout, Panel } from '@sdflc/ui';
import { Logo } from 'components';

const CenteredLayout = memo((props) => {
  const { page, children, bgImage } = props;
  const { options } = page || {};

  return (
    <AppPagesProvider>
      <EmptyLayout asFlex notScrollable height='100%'>
        <Container
          bgImage={bgImage || options.bgImage}
          bgColor={options.bgColor}
        >
          <LogoWrapper>
            <Panel p={3}>
              <Logo size='small' />
            </Panel>
          </LogoWrapper>
          {children}
        </Container>
      </EmptyLayout>
    </AppPagesProvider>
  );
});

CenteredLayout.displayName = 'CenteredLayout';

CenteredLayout.propTypes = {
  children: PropTypes.node,
  bgImage: PropTypes.string,
};

CenteredLayout.defaultProps = {};

export { CenteredLayout };
