import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Centered, Overlay } from '@sdflc/ui';

import { colors } from '../../config';
import { Loading } from '../Loading';
import { LocalizationContext } from 'contexts';

const PageLoading = memo((props) => {
  let { message } = props;
  const { getText } = useContext(LocalizationContext);

  if (!message) {
    message = getText('generic.loading');
  }

  return (
    <Overlay backgroundColor={colors.grey}>
      <Centered>
        <Loading />
        <span>{message}</span>
      </Centered>
    </Overlay>
  );
});

PageLoading.displayName = 'PageLoading';

PageLoading.propTypes = {
  message: PropTypes.string,
};

PageLoading.defaultProps = {};

export { PageLoading };
