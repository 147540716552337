import { gql } from '@apollo/client';

const APP_USER_GET_MANY = gql`
  query AppUserGetMany($ids: [ID]) {
    appUserGetMany(ids: $ids) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userRating
        l10n {
          lang
          displayName
          preferredName
        }
        user {
          avatarUrl
          birthday
          username
          email
          emailConfirmation
          firstName
          middleName
          lastName
          timeZone
          uiLanguage
          status
        }
      }
    }
  }
`;

const APP_USER_LIST = gql`
  query AppUserList($filter: AppUserFilter, $params: PaginationAndSorting) {
    appUserList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
      }
      data {
        id
        userRating
        l10n {
          lang
          displayName
          preferredName
        }
        user {
          username
          avatarUrl
        }
      }
    }
  }
`;

const appUserQueries = {
  appUserGetMany: APP_USER_GET_MANY,
  appUserList: APP_USER_LIST,
};

export { appUserQueries };
