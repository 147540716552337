import { useState, useCallback } from 'react';
import { OpResult } from '@sdflc/api-helpers';
import { useAppQuery } from '../../utils';
import { contentItemQueries } from '../queries/contentItemQueries';

const useContentItem = () => {
  const [content, setContent] = useState(new OpResult());

  const [queryContentItemList, { called: contentItemListCalled }] = useAppQuery(
    {
      queries: contentItemQueries,
      queryName: 'contentItemList',
      onDone: (result) => {
        if (result.didSucceed()) {
          const data = result.getData().reduce((acc, item) => {
            acc[item.itemId] = {
              value: item.l10n?.localization ?? '',
              contentType: item.contentType,
              variant: item.variant,
            };
            return acc;
          }, {});

          result.setData({
            ...content.getDataFirst({}),
            ...data,
          });
        }

        setContent(result);
      },
    }
  );

  const contentItemList = useCallback(
    (params, content) => {
      if (content instanceof OpResult) {
        setContent(content.clearErrors().startLoading().clone());
      }
      queryContentItemList({ variables: params });
    },
    [queryContentItemList]
  );

  return {
    contentItemList,
    content,
    contentItemListCalled,
  };
};

export { useContentItem };
