import React, { memo, useCallback, useContext, useMemo } from 'react';
import { FormDrawer } from '@sdflc/ui';

import { LocalizationContext, OrdersContext } from 'contexts';
import { orderReceiptFormSections } from './orderReceiptSections';
import { logger } from 'utils';
import { ACTIONS_IDS, FORM_ACTIONS } from 'config';

const OrderReceiptAddEdit = memo(() => {
  const { getText } = useContext(LocalizationContext);
  const { receiptToEdit, handleClickReceiptAction, orderReceiptSaveOpResult } =
    useContext(OrdersContext);

  const formCfg = useMemo(() => {
    return {
      sections: orderReceiptFormSections,
      labels: getText('blocks.receipts.form.labels'),
      hints: getText('blocks.receipts.form.hints'),
    };
  }, [getText]);

  const handleClickClose = useCallback(() => {
    handleClickReceiptAction({ id: ACTIONS_IDS.CANCEL });
  }, [handleClickReceiptAction]);

  const actionInProgress = useMemo(() => {
    return orderReceiptSaveOpResult.isInProgress() ? ACTIONS_IDS.SAVE : '';
  }, [orderReceiptSaveOpResult]);

  return (
    <FormDrawer
      isOpen={!!receiptToEdit}
      title={getText(
        receiptToEdit?.id
          ? 'blocks.receipts.form.title.edit'
          : 'blocks.receipts.form.title.add'
      )}
      description=''
      formData={receiptToEdit ?? {}}
      formCfg={formCfg}
      onClose={handleClickClose}
      actions={FORM_ACTIONS}
      actionInProgress={actionInProgress}
      onClickAction={handleClickReceiptAction}
      progress={orderReceiptSaveOpResult.isInProgress()}
      opResult={orderReceiptSaveOpResult}
      onChange={(formData) => {
        logger.log('Receipt form has changed', formData);
      }}
    />
  );
});

OrderReceiptAddEdit.displayName = 'OrderReceiptAddEdit';
OrderReceiptAddEdit.propTypes = {};
OrderReceiptAddEdit.defaultProp = {};

export { OrderReceiptAddEdit };
