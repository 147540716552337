import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  background-image: ${({ bgImage }) =>
    bgImage ? `url('${bgImage}')` : 'none'};
  background-color: ${({ bgColor }) => bgColor || 'initial'};
`;

export const LogoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;
