import { FORM_INPUT_TYPES } from '@sdflc/ui';
import { SelectPaymentMethod } from 'components/Controls';

export const orderPaymentFormSections = [
  {
    fields: [
      {
        field: 'deliveryCost',
        type: FORM_INPUT_TYPES.NUMBER,
        width: ['100%'],
        componentProps: {
          leftIcon: 'AttachMoney',
          timeStep: 30,
          time24h: true,
        },
      },
      {
        field: 'paymentMethodId',
        width: ['100%'],
        component: SelectPaymentMethod,
        componentProps: {},
      },
    ],
  },
];
