import { memo, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';

import {
  ItemsGroup,
  Button,
  ConfirmationButton,
  Skeleton,
  Tile,
  Flex,
  Tag,
  Badge,
  Text,
} from '@sdflc/ui';

import { ACTIONS_IDS, colors } from '../../../config';

import { EnableSwitch } from 'components/Controls';
import { UserLocationType } from 'components/UserLocationType';
import { OpResult } from '@sdflc/api-helpers/build';
import { LocalizationContext } from 'contexts';

const OrderSubscriptionByDistanceListItem = memo((props) => {
  const { value, onClickAction, loading, removingResult, updatingResult } =
    props;
  const { getText } = useContext(LocalizationContext);
  const { isDefault, location, locationTypeId, formattedAddress } =
    value?.userLocation ?? {};
  const { distanceKm } = value ?? {};

  const handleClickAction = useCallback(
    (action) => {
      if (check.function(onClickAction)) {
        onClickAction(action, value);
      } else {
        console.warn(
          'The "OrderSubscriptionByDistanceListItem" component expected the "onClickAction" to be a function but got:',
          onClickAction
        );
      }
    },
    [onClickAction, value]
  );

  const DefaultTag = useMemo(
    () =>
      isDefault ? (
        <Tag
          variant={Badge.VARIANT_NAMES.WARNING}
          dimension={Badge.DIMENSION_NAMES.SMALL}
        >
          {getText('generic.default')}
        </Tag>
      ) : null,
    [isDefault, getText]
  );

  if (!value) {
    return null;
  }

  const updatingResult2use =
    updatingResult instanceof OpResult ? updatingResult : new OpResult();
  const removingResult2use =
    removingResult instanceof OpResult ? removingResult : new OpResult();
  const error = removingResult2use?.getErrorSummary() ?? '';

  return (
    <Tile mb={2} backgroundColor={colors.white}>
      <ItemsGroup direction='row'>
        <Flex>
          <Skeleton loading={loading}>
            <EnableSwitch
              value={value.status}
              hasInputContainer={false}
              title='Toggle subscription status'
              onChange={() =>
                handleClickAction({ id: ACTIONS_IDS.TOGGLE_STATUS })
              }
              inProgress={updatingResult2use.isInProgress()}
            />
          </Skeleton>
        </Flex>
        <Flex>
          <Skeleton loading={loading}>
            <UserLocationType locationTypeId={locationTypeId} />
          </Skeleton>
        </Flex>
        <Flex flex='1 1'>
          <Skeleton loading={loading}>
            <Text>{formattedAddress}</Text>
          </Skeleton>
        </Flex>
        <Flex>
          <Skeleton loading={loading}>
            <Text>{distanceKm} km</Text>
          </Skeleton>
        </Flex>
        {!!error && (
          <Flex flex='1 1'>
            <Text
              variant={Text.VARIANT_NAMES.DANGER}
              dimension={Text.DIMENSION_NAMES.SMALL}
            >
              {error}
            </Text>
          </Flex>
        )}
        <Flex>
          <Skeleton loading={loading}>
            <ItemsGroup>
              <Button
                icon='Edit'
                dimension={Button.DIMENSION_NAMES.SMALL}
                variant={Button.VARIANT_NAMES.SECONDARY}
                onClick={() =>
                  handleClickAction({ id: ACTIONS_IDS.EDIT }, value)
                }
              />
              <ConfirmationButton
                icon='Delete'
                dimension={Button.DIMENSION_NAMES.SMALL}
                variant={Button.VARIANT_NAMES.DANGER}
                onConfirm={() => handleClickAction({ id: ACTIONS_IDS.REMOVE })}
              />
            </ItemsGroup>
          </Skeleton>
        </Flex>
      </ItemsGroup>
    </Tile>
  );
});

OrderSubscriptionByDistanceListItem.displayName =
  'OrderSubscriptionByDistanceListItem';

OrderSubscriptionByDistanceListItem.propTypes = {
  value: PropTypes.shape({}),
  onClickAction: PropTypes.func,
  loading: PropTypes.bool,
  removingResult: PropTypes.any,
  updatingResult: PropTypes.any,
};

OrderSubscriptionByDistanceListItem.defaultProps = {
  value: null,
};

export { OrderSubscriptionByDistanceListItem };
