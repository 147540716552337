import { castArray } from 'lodash';

export const formatLocalizedAddress = (location, lang) => {
  const { l10ns, /*latitude, longitude,*/ countryId, postalCode, address2 } =
    location || {};

  let l10n = castArray(l10ns).find((l10n) => l10n?.lang === lang);

  if (!l10n) {
    l10n = l10ns[0] || {};
  }

  const { address1, address2Name, city, state } = l10n;

  const formattedAddressParts = [
    address2 ? `${address2Name} ${address2}` : null,
    address1,
    city,
    state,
    postalCode,
    countryId,
  ];

  return formattedAddressParts.filter((part) => !!part).join(', ');
};
