import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { InputContainer, Button } from '@sdflc/ui';
import { UserBadge } from 'components/UserBadge';
import check from 'check-types';

const InputUser = memo((props) => {
  const { value, buttonName, onClick } = props;

  const extraButton = useMemo(() => {
    if (!buttonName || !check.function(onClick)) {
      return null;
    }

    return (
      <Button
        name={buttonName}
        onClick={onClick}
        dimension={Button.DIMENSION_NAMES.SMALL}
        variant={Button.VARIANT_NAMES.TERTIARY}
      />
    );
  }, [buttonName, onClick]);

  return (
    <InputContainer RightSide={extraButton}>
      <UserBadge appUser={value} />
    </InputContainer>
  );
});

InputUser.displayName = 'InputUser';
InputUser.propTypes = {
  value: PropTypes.any,
  buttonName: PropTypes.string,
  onClick: PropTypes.func,
};
InputUser.defaultProp = {};

export { InputUser };
