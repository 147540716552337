import { memo, useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Header,
  HeaderItem,
  Sticky,
  VisibleBelow,
  VisibleAbove,
  Button,
  DropDownButton,
} from '@sdflc/ui/dist';
import { Logo } from '../Logo';

import { AuthContext, LocalizationContext } from '../../contexts';
import { logger } from '../../utils';

const MENU_OPTIONS = [
  {
    id: 'logout',
    href: '#logout',
    icon: 'Logout',
    name: 'Logout',
  },
];

const AppHeader = memo((props) => {
  const { page, onMenuToggle } = props;
  const { appUser, signOut, signOutCalled, signOutLoading } =
    useContext(AuthContext);
  const { getText } = useContext(LocalizationContext);
  const { user } = appUser ?? {};
  const { email, firstName, lastName } = user ?? {};
  const displayName = [firstName, lastName, email]
    .filter((item) => item)
    .join(' ')
    .trim();

  useEffect(() => {
    if (!signOutLoading && signOutCalled) {
      window.location.reload();
    }
  }, [signOutCalled, signOutLoading]);

  const handleClick = useCallback(
    (option) => {
      switch (option.id) {
        default:
          logger.debug(
            'The "AppHeader" component got a click with unsupported option',
            option
          );
          break;

        case 'logout':
          signOut();
          break;
      }
    },
    [signOut]
  );

  return (
    <Sticky>
      <Header>
        <HeaderItem>
          <VisibleBelow breakpoint='md'>
            <Button icon='Menu' variant='tertiary' onClick={onMenuToggle} />
          </VisibleBelow>
          <VisibleAbove breakpoint='md'>
            <Logo />
          </VisibleAbove>
        </HeaderItem>
        <HeaderItem>{getText(page.name)}</HeaderItem>
        <HeaderItem marginLeft='auto'>
          <VisibleBelow breakpoint='md'>
            <DropDownButton
              variant='tertiary'
              name=' '
              icon='AccountCircle'
              options={MENU_OPTIONS}
              onClick={handleClick}
            />
          </VisibleBelow>
          <VisibleAbove breakpoint='md'>
            <DropDownButton
              variant='tertiary'
              name={displayName}
              icon='AccountCircle'
              options={MENU_OPTIONS}
              onClick={handleClick}
            />
          </VisibleAbove>
        </HeaderItem>
        {/* <HeaderItem marginLeft='auto'>
          <BasicNavigation items={navLinks} direction='row' withIcons={true} />
        </HeaderItem> */}
      </Header>
    </Sticky>
  );
});

AppHeader.displayName = 'AppHeader';

AppHeader.propTypes = {
  onMenuToggle: PropTypes.func,
};

AppHeader.defaultProps = {};

export { AppHeader };
