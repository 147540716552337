import { useLazyQuery, useMutation } from '@apollo/client';
import { OpResult, OP_RESULT_CODES } from '@sdflc/api-helpers';

import { apolloClient } from './apolloClient';
import { processDttmFields } from './processDttmFields';
import { logger } from './logger';

export const useAppMutation = (props) => {
  const { queries, queryName, onDone, onError, onCompleted } = props || {};

  if (typeof queries !== 'object') {
    console.warn(
      'The "buildUseMutation" expects "queries" prop to be an object with queries but got:',
      queries
    );
  }

  if (typeof queryName !== 'string' || !queries[queryName]) {
    console.warn(
      'The "buildUseMutation" expects "queryName" prop to be a name of queries in the "queries" but got:',
      queryName
    );
  }

  return useMutation(queries[queryName], {
    client: apolloClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onError: (response, clientOptions) => {
      const result = new OpResult(response[queryName]);

      result.setCode(OP_RESULT_CODES.NETWORK_ERROR);

      if (typeof onError === 'function') {
        onError(result, clientOptions);
      }

      if (typeof onDone === 'function') {
        onDone(result, clientOptions);
      }
    },
    onCompleted: (response, clientOptions) => {
      const result = new OpResult(response[queryName]);

      processDttmFields(result);

      if (typeof onCompleted === 'function') {
        onCompleted(result, clientOptions);
      }

      if (typeof onDone === 'function') {
        onDone(result, clientOptions);
      }
    },
  });
};

export const useAppQuery = (args) => {
  const {
    queries,
    queryName,
    onDone,
    onError,
    onCompleted,
    calledInPlace,
    debug,
  } = args || {};

  if (typeof queries !== 'object') {
    console.warn(
      'The "buildUseQuery" expects "queries" prop to be an object with queries but got:',
      queries
    );
  }

  if (typeof queryName !== 'string' || !queries[queryName]) {
    console.warn(
      'The "buildUseQuery" expects "queryName" prop to be a name of queries in the "queries" but got:',
      queryName
    );
  }

  return useLazyQuery(queries[queryName], {
    client: apolloClient,
    notifyOnNetworkStatusChange: calledInPlace ? false : true,
    fetchPolicy: 'network-only',
    onError: calledInPlace
      ? undefined
      : (response, clientOptions) => {
          const result = new OpResult(response[queryName]);

          if (debug) {
            logger.debug(`The query '${queryName}' failed:`, response);
          }

          result.setCode(OP_RESULT_CODES.NETWORK_ERROR);

          if (typeof onError === 'function') {
            onError(result, clientOptions);
          }

          if (typeof onDone === 'function') {
            onDone(result, clientOptions);
          }
        },
    onCompleted: calledInPlace
      ? undefined
      : (response, clientOptions) => {
          const result = new OpResult(response[queryName]);

          if (debug) {
            logger.debug(`The query '${queryName}' complete:`, response);
          }

          processDttmFields(result);

          if (typeof onCompleted === 'function') {
            onCompleted(result, clientOptions);
          }

          if (typeof onDone === 'function') {
            onDone(result, clientOptions);
          }
        },
  });
};
