import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PageLoading } from 'components/PageLoading';
import { useHistory } from 'react-router-dom';

const Redirect = memo((props) => {
  const history = useHistory();

  useEffect(() => {
    if (props.page?.options?.to) {
      history.push(props.page.options.to);
    } else {
      console.warn(
        'The "Redirect" component expected to have prop "props.page.options.to" but got:',
        props.page
      );
    }
  }, [history, props.page]);

  return <PageLoading />;
});

Redirect.displayName = 'Redirect';

Redirect.propTypes = {
  to: PropTypes.string,
};

Redirect.defaultProps = {};

export { Redirect };
