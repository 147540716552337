import { Fragment, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormDrawer } from '@sdflc/ui';

import { LocalizationContext } from '../../../contexts';

import { Block } from '../../Block';
import { AppToolbar } from '../../AppToolbar';

import { OrderSubscriptionsByDistanceList } from './OrderSubscriptionsByDistanceList';
import { useOrderSubscriptionsByDistance } from './useOrderSubscriptionsByDistance';

const OrderSubscriptionsByDistance = memo((props) => {
  const { hasTitle } = props;
  const { getText } = useContext(LocalizationContext);
  const {
    open,
    orderSubscriptionByDistances,
    orderSubscriptionByDistanceList,
    formData,
    formCfg,
    formActions,
    handleClickAction,
    handleClickClose,
    orderSubscriptionByDistancesOpsManager,
    actionInProgress,
    toolBarItems,
    saveOpResult,
    updatingOps,
    removingOps,
  } = useOrderSubscriptionsByDistance();

  return (
    <Fragment>
      <Block
        title={
          hasTitle ? getText('blocks.orderSubscriptionByDistance.title') : ''
        }
      >
        <AppToolbar items={toolBarItems} onClick={handleClickAction} />
        <OrderSubscriptionsByDistanceList
          onClickAction={handleClickAction}
          list={orderSubscriptionByDistanceList}
          items={orderSubscriptionByDistances}
          listOp={orderSubscriptionByDistancesOpsManager.getListOp()}
          updatingOps={updatingOps}
          removingOps={removingOps}
        />
      </Block>
      <FormDrawer
        isOpen={open}
        title={getText('blocks.orderSubscriptionByDistance.form.title')}
        description=''
        formData={formData}
        formCfg={formCfg}
        onClose={handleClickClose}
        actions={formActions}
        actionInProgress={actionInProgress}
        onClickAction={handleClickAction}
        progress={saveOpResult.isInProgress()}
        opResult={saveOpResult}
      />
    </Fragment>
  );
});

OrderSubscriptionsByDistance.displayName = 'OrderSubscriptionsByDistance';

OrderSubscriptionsByDistance.propTypes = {
  children: PropTypes.node,
  hasTitle: PropTypes.bool,
};

OrderSubscriptionsByDistance.defaultProps = {
  hasTitle: false,
};

export { OrderSubscriptionsByDistance };
