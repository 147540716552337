import { gql } from '@apollo/client';

const USER_SUBSCRIBE_LIST_ITEM_LIST = gql`
  query UserSubscribeListItemList(
    $filter: UserSubscribeListItemFilter
    $params: PaginationAndSorting
  ) {
    userSubscribeListItemList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        subscribedForUserId
        status
        subscribedForUser {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
      }
    }
  }
`;

const USER_SUBSCRIBE_LIST_ITEM_CREATE = gql`
  mutation UserSubscribeListItemCreate($params: UserSubscribeListItemInput) {
    userSubscribeListItemCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        subscribedForUserId
        status
        subscribedForUser {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
      }
    }
  }
`;

const USER_SUBSCRIBE_LIST_ITEM_UPDATE = gql`
  mutation UserSubscribeListItemUpdate(
    $where: UserSubscribeListItemWhereInput
    $params: UserSubscribeListItemInput
  ) {
    userSubscribeListItemUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        subscribedForUserId
        status
        subscribedForUser {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
      }
    }
  }
`;

const USER_SUBSCRIBE_LIST_ITEM_REMOVE_MANY = gql`
  mutation UserSubscribeListItemRemoveMany(
    $where: [UserSubscribeListItemWhereInput]
  ) {
    userSubscribeListItemRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const userSubscribeListItemQueries = {
  userSubscribeListItemList: USER_SUBSCRIBE_LIST_ITEM_LIST,
  userSubscribeListItemCreate: USER_SUBSCRIBE_LIST_ITEM_CREATE,
  userSubscribeListItemUpdate: USER_SUBSCRIBE_LIST_ITEM_UPDATE,
  userSubscribeListItemRemoveMany: USER_SUBSCRIBE_LIST_ITEM_REMOVE_MANY,
};

export { userSubscribeListItemQueries };
