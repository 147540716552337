import { Button } from '@sdflc/ui';

export const ACTIONS_IDS = {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  SIGN_IN_ALT: 'SIGN_IN_ALT',
  SIGN_UP_ALT: 'SIGN_UP_ALT',
  SAVE: 'SAVE',
  OK: 'OK',
  CREATE: 'CREATE',
  ADD: 'ADD',
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  EDIT_ALT: 'EDIT_ALT',
  REMOVE: 'REMOVE',
  CANCEL: 'CANCEL',
  PICK: 'PICK',
  PICK_ALT: 'PICK_ALT',
  PUBLISH: 'PUBLISH',
  CANCEL_ACTION: 'CANCEL_ACTION',
  SHOP_ORDER_ITEMS: 'SHOP_ORDER_ITEMS',
  TAKE_ORDER_ITEM: 'TAKE_ORDER_ITEM',
  RETURN_ORDER_ITEM: 'RETURN_ORDER_ITEM',
  MISSING_ORDER_ITEM: 'MISSING_ORDER_ITEM',
  ON_THE_WAY: 'ON_THE_WAY',
  DELIVERED: 'DELIVERED',
  CONFIRM: 'CONFIRM',
  DISPUTE: 'DISPUTE',
  CONFIRM_PAYMENT: 'CONFIRM_PAYMENT',
  VIEW_COMMENTS: 'VIEW_COMMENTS',
  ENTER_TOTAL: 'ENTER_TOTAL',
  SAVE_ORDER_TOTAL: 'SAVE_ORDER_TOTAL',
  CANCEL_ORDER_TOTAL: 'CANCEL_ORDER_TOTAL',
  MAKE_DEFAULT: 'MAKE_DEFAULT',
  ORDER_ITEM_SELECT: 'ORDER_ITEM_SELECT',
  ORDER_ITEM_UNSELECT: 'ORDER_ITEM_UNSELECT',
  EDIT_SELECTED: 'EDIT_SELECTED',
  VIEW_ORDER_COMMENTS: 'VIEW_ORDER_COMMENTS',
  INVITE: 'INVITE',
  ADD_RECEIPT: 'ADD_RECEIPT',
  CANCEL_ADD_RECEIPT: 'CANCEL_ADD_RECEIPT',
  GET_DIRECTIONS: 'GET_DIRECTIONS',
  RATE_BUYER: 'RATE_BUYER',
  RATE_SHOPPER: 'RATE_SHOPPER',
  SUBMIT_REVIEW: 'SUBMITE_REVIEW',
  GO_TO_ORDER_EDIT: 'GO_TO_ORDER_EDIT',
  GO_TO_ORDER_SHOP: 'GO_TO_ORDER_SHOP',
  GO_TO_ORDER_VIEW: 'GO_TO_ORDER_VIEW',
  //
  KEYWORD: 'keyword',
  // STATUS BUTTONS (NOT CLICKABLE)
  TAKEN: 'TAKEN',
  //
  TOGGLE_STATUS: 'TOGGLE_STATUS',
};

export const ACTIONS_BUTTONS = (args) => {
  const { withNames, width } = args ?? {};

  return {
    [ACTIONS_IDS.SIGN_IN]: {
      type: 'button',
      id: ACTIONS_IDS.SIGN_IN,
      icon: 'Done',
      name: withNames ? 'Sign In' : '',
      variant: 'primary',
      dimension: 'medium',
      title: 'Sign In',
      width,
    },
    [ACTIONS_IDS.SIGN_IN_ALT]: {
      type: 'button',
      id: ACTIONS_IDS.SIGN_IN,
      icon: '',
      name: withNames ? 'Sign In' : '',
      variant: 'secondary',
      dimension: 'medium',
      title: 'Sign In',
      width,
    },
    [ACTIONS_IDS.SIGN_UP]: {
      type: 'button',
      id: ACTIONS_IDS.SIGN_UP,
      icon: 'Done',
      name: withNames ? 'Sign Up' : '',
      variant: 'primary',
      dimension: 'medium',
      title: 'Sign Up',
      width,
    },
    [ACTIONS_IDS.SIGN_UP_ALT]: {
      type: 'button',
      id: ACTIONS_IDS.SIGN_UP,
      icon: '',
      name: withNames ? 'Sign Up' : '',
      variant: 'secondary',
      dimension: 'medium',
      title: 'Sign Up',
      width,
    },
    [ACTIONS_IDS.CREATE]: {
      type: 'button',
      id: ACTIONS_IDS.CREATE,
      icon: 'Add',
      name: withNames ? 'Create' : '',
      variant: 'primary',
      dimension: 'medium',
      title: 'Create',
      width,
    },
    [ACTIONS_IDS.ADD]: {
      type: 'button',
      id: ACTIONS_IDS.ADD,
      icon: 'Add',
      name: withNames ? 'Add' : '',
      variant: 'tertiary',
      dimension: 'medium',
      title: 'Add',
      width,
    },
    [ACTIONS_IDS.ADD_RECEIPT]: {
      type: 'button',
      id: ACTIONS_IDS.ADD_RECEIPT,
      icon: 'Add',
      name: withNames ? 'Add' : '',
      variant: 'tertiary',
      dimension: 'medium',
      title: 'Add',
      width,
    },
    [ACTIONS_IDS.CANCEL_ADD_RECEIPT]: {
      type: 'button',
      id: ACTIONS_IDS.CANCEL_ADD_RECEIPT,
      icon: 'Cancel',
      name: withNames ? 'Cancel' : '',
      variant: 'danger',
      dimension: 'medium',
      title: 'Cancel',
      width,
    },
    [ACTIONS_IDS.SAVE]: {
      type: 'submit',
      id: ACTIONS_IDS.SAVE,
      icon: 'Check',
      name: withNames ? 'Save' : '',
      variant: 'primary',
      dimension: 'large',
      title: 'Save',
      width,
    },
    [ACTIONS_IDS.OK]: {
      type: 'button',
      id: ACTIONS_IDS.OK,
      icon: 'Check',
      name: withNames ? 'OK' : '',
      variant: 'primary',
      dimension: 'medium',
      title: '',
      width,
    },
    [ACTIONS_IDS.VIEW]: {
      type: 'button',
      id: ACTIONS_IDS.VIEW,
      icon: 'Visibility',
      name: withNames ? 'View' : '',
      variant: 'tertiary',
      dimension: 'medium',
      title: 'View',
      width,
    },
    [ACTIONS_IDS.EDIT]: {
      type: 'button',
      id: ACTIONS_IDS.EDIT,
      icon: 'Edit',
      name: withNames ? 'Edit' : '',
      variant: 'tertiary',
      dimension: 'medium',
      title: 'Edit',
      width,
    },
    [ACTIONS_IDS.EDIT_ALT]: {
      type: 'button',
      id: ACTIONS_IDS.EDIT,
      icon: 'Edit',
      name: withNames ? 'Edit' : '',
      variant: Button.VARIANT_NAMES.PRIMARY,
      dimension: 'medium',
      title: 'Edit',
      width,
    },
    [ACTIONS_IDS.REMOVE]: {
      type: 'button',
      id: ACTIONS_IDS.REMOVE,
      icon: 'Delete',
      name: withNames ? 'Remove' : '',
      variant: 'danger',
      dimension: 'medium',
      title: 'Remove',
      width,
    },
    [ACTIONS_IDS.CANCEL]: {
      type: 'button',
      id: ACTIONS_IDS.CANCEL,
      icon: 'Clear',
      name: withNames ? 'Cancel' : '',
      variant: 'tertiary',
      dimension: 'medium',
      title: 'Close',
      width,
    },
    [ACTIONS_IDS.PICK]: {
      type: 'button',
      id: ACTIONS_IDS.PICK,
      icon: 'Shop',
      name: withNames ? 'Pick' : '',
      variant: 'primary',
      dimension: 'medium',
      title: 'Pick',
      width,
    },
    [ACTIONS_IDS.PICK_ALT]: {
      type: 'button',
      id: ACTIONS_IDS.PICK,
      icon: 'Shop',
      name: withNames ? 'Pick' : '',
      variant: 'secondary',
      dimension: 'medium',
      title: 'Pick',
      width,
    },
    [ACTIONS_IDS.PUBLISH]: {
      type: 'button',
      id: ACTIONS_IDS.PUBLISH,
      icon: 'Publish',
      name: withNames ? 'Publish' : '',
      variant: 'primary',
      dimension: 'medium',
      title: 'Publish',
      width,
    },
    [ACTIONS_IDS.CANCEL_ACTION]: {
      type: 'button',
      id: ACTIONS_IDS.CANCEL_ACTION,
      icon: 'Cancel',
      name: withNames ? 'Cancel' : '',
      variant: 'danger',
      dimension: 'medium',
      title: 'Cancel',
      width,
    },
    [ACTIONS_IDS.SHOP_ORDER_ITEMS]: {
      type: 'button',
      id: ACTIONS_IDS.SHOP_ORDER_ITEMS,
      icon: 'ShoppingCart',
      name: withNames ? 'Shop' : '',
      variant: 'tertiary',
      dimension: 'medium',
      title: 'Shop order items',
      width,
    },
    [ACTIONS_IDS.TAKE_ORDER_ITEM]: {
      type: 'button',
      id: ACTIONS_IDS.TAKE_ORDER_ITEM,
      icon: 'ShoppingCart',
      name: withNames ? 'Take' : '',
      variant: 'tertiary',
      dimension: 'medium',
      title: 'Take order items',
      width,
    },
    [ACTIONS_IDS.RETURN_ORDER_ITEM]: {
      type: 'button',
      id: ACTIONS_IDS.RETURN_ORDER_ITEM,
      icon: 'ShoppingCart',
      name: withNames ? 'Return' : '',
      variant: 'primary',
      dimension: 'medium',
      title: 'Return order items',
      width,
    },
    [ACTIONS_IDS.ON_THE_WAY]: {
      type: 'button',
      id: ACTIONS_IDS.ON_THE_WAY,
      icon: 'Check',
      name: withNames ? 'Done' : '',
      variant: 'primary',
      dimension: 'medium',
      title: 'Delivery is on the way',
      width,
    },
    [ACTIONS_IDS.DELIVERED]: {
      type: 'button',
      id: ACTIONS_IDS.DELIVERED,
      icon: 'ShoppingCart',
      name: withNames ? 'Delivered' : '',
      variant: 'primary',
      dimension: 'medium',
      title: 'Delivered',
      width,
    },
    [ACTIONS_IDS.DELIVERED]: {
      type: 'button',
      id: ACTIONS_IDS.DELIVERED,
      icon: 'ShoppingCart',
      name: withNames ? 'Delivered' : '',
      variant: 'primary',
      dimension: 'medium',
      title: 'Delivered',
      width,
    },
    [ACTIONS_IDS.CONFIRM]: {
      type: 'button',
      id: ACTIONS_IDS.CONFIRM,
      icon: 'ShoppingCart',
      name: withNames ? 'Confirm' : '',
      variant: 'primary',
      dimension: 'medium',
      title: 'Delivery confirmed',
      width,
    },
    [ACTIONS_IDS.CONFIRM_PAYMENT]: {
      type: 'button',
      id: ACTIONS_IDS.CONFIRM_PAYMENT,
      icon: 'Check',
      name: withNames ? 'Confirm Payment' : '',
      variant: 'success',
      dimension: 'medium',
      title: 'Confirm receiving the payment from the buyer',
      width,
    },
    [ACTIONS_IDS.DISPUTE]: {
      type: 'button',
      id: ACTIONS_IDS.DISPUTE,
      icon: 'ShoppingCart',
      name: withNames ? 'Dispute' : '',
      variant: 'danger',
      dimension: 'medium',
      title: 'Delivery not confirmed',
      width,
    },
    [ACTIONS_IDS.VIEW_COMMENTS]: {
      type: 'button',
      id: ACTIONS_IDS.VIEW_COMMENTS,
      icon: 'Forum',
      name: withNames ? 'Comments' : '',
      variant: 'tertiary',
      dimension: 'medium',
      title: 'View comments',
      width,
    },
    [ACTIONS_IDS.ENTER_TOTAL]: {
      type: 'button',
      id: ACTIONS_IDS.ENTER_TOTAL,
      icon: 'Paid',
      name: withNames ? 'Enter Total' : '',
      variant: 'tertiary',
      dimension: 'medium',
      title: 'Enter order total amount and upload receipts',
      width,
    },
    [ACTIONS_IDS.SAVE_ORDER_TOTAL]: {
      type: 'button',
      id: ACTIONS_IDS.SAVE_ORDER_TOTAL,
      icon: 'Check',
      name: withNames ? 'Save' : '',
      variant: 'primary',
      dimension: 'medium',
      title: 'Save',
      width,
    },
    [ACTIONS_IDS.CANCEL_ORDER_TOTAL]: {
      type: 'button',
      id: ACTIONS_IDS.CANCEL_ORDER_TOTAL,
      icon: 'Clear',
      name: withNames ? 'Cancel' : '',
      variant: 'secondary',
      dimension: 'medium',
      title: 'Cancel',
      width,
    },
    [ACTIONS_IDS.MAKE_DEFAULT]: {
      type: 'button',
      id: ACTIONS_IDS.MAKE_DEFAULT,
      icon: '',
      name: withNames ? 'Make Default' : '',
      variant: Button.VARIANT_NAMES.SECONDARY,
      dimension: 'medium',
      title: 'Make default',
      width,
    },
    [ACTIONS_IDS.ORDER_ITEM_SELECT]: {
      type: 'button',
      id: ACTIONS_IDS.ORDER_ITEM_SELECT,
      icon: 'TouchApp',
      name: withNames ? 'Select' : '',
      variant: Button.VARIANT_NAMES.SECONDARY,
      dimension: 'small',
      title: 'Select the item',
      width,
    },
    [ACTIONS_IDS.ORDER_ITEM_UNSELECT]: {
      type: 'button',
      id: ACTIONS_IDS.ORDER_ITEM_UNSELECT,
      icon: 'Delete',
      name: withNames ? 'Unselect' : '',
      variant: Button.VARIANT_NAMES.TERTIARY,
      dimension: 'small',
      title: 'Unselect the item',
      width,
    },
    [ACTIONS_IDS.EDIT_SELECTED]: {
      type: 'button',
      id: ACTIONS_IDS.EDIT,
      icon: 'Edit',
      name: withNames ? 'Edit' : '',
      variant: Button.VARIANT_NAMES.SECONDARY,
      dimension: 'medium',
      title: 'Edit selected item',
      width,
    },
    [ACTIONS_IDS.VIEW_ORDER_COMMENTS]: {
      type: 'button',
      id: ACTIONS_IDS.VIEW_ORDER_COMMENTS,
      icon: 'Forum',
      name: withNames ? 'Comments' : '',
      variant: 'tertiary',
      dimension: 'medium',
      title: 'View order comments',
      width,
    },
    [ACTIONS_IDS.GET_DIRECTIONS]: {
      type: 'button',
      id: ACTIONS_IDS.GET_DIRECTIONS,
      icon: 'Directions',
      name: withNames ? 'Get directions' : '',
      variant: 'secondary',
      dimension: 'medium',
      title: 'Get directions to the delivery location',
      width,
    },
    [ACTIONS_IDS.RATE_BUYER]: {
      type: 'button',
      id: ACTIONS_IDS.RATE_BUYER,
      icon: 'RateReview',
      name: withNames ? 'Rate Buyer' : '',
      variant: 'secondary',
      dimension: 'medium',
      title: 'Give the order buyer rating and write a review',
      width,
    },
    [ACTIONS_IDS.RATE_SHOPPER]: {
      type: 'button',
      id: ACTIONS_IDS.RATE_SHOPPER,
      icon: 'RateReview',
      name: withNames ? 'Rate Shopper' : '',
      variant: 'secondary',
      dimension: 'medium',
      title: 'Give the order shopper rating and write a review',
      width,
    },
    [ACTIONS_IDS.SUBMIT_REVIEW]: {
      type: 'button',
      id: ACTIONS_IDS.SUBMIT_REVIEW,
      icon: 'RateReview',
      name: withNames ? 'Submit' : '',
      variant: 'primary',
      dimension: 'medium',
      title: 'Submit review',
      width,
    },
    [ACTIONS_IDS.INVITE]: {
      type: 'button',
      id: ACTIONS_IDS.INVITE,
      icon: 'AssignmentInd',
      name: withNames ? 'Invite' : '',
      variant: 'tertiary',
      dimension: 'medium',
      title: 'Invite a shopper for the order',
      width,
    },
    [ACTIONS_IDS.GO_TO_ORDER_EDIT]: {
      type: 'button',
      id: ACTIONS_IDS.GO_TO_ORDER_EDIT,
      icon: 'Edit',
      name: withNames ? 'Edit' : '',
      variant: Button.VARIANT_NAMES.PRIMARY,
      dimension: Button.DIMENSION_NAMES.SMALL,
      title: 'Go to the edit order page',
      width,
      disabled: true,
    },
    [ACTIONS_IDS.GO_TO_ORDER_SHOP]: {
      type: 'button',
      id: ACTIONS_IDS.GO_TO_ORDER_SHOP,
      icon: 'Shop',
      name: withNames ? 'Shop' : '',
      variant: Button.VARIANT_NAMES.PRIMARY,
      dimension: Button.DIMENSION_NAMES.SMALL,
      title: 'Go to the shop items order page',
      width,
      disabled: true,
    },
    [ACTIONS_IDS.GO_TO_ORDER_VIEW]: {
      type: 'button',
      id: ACTIONS_IDS.GO_TO_ORDER_VIEW,
      icon: 'Visibility',
      name: withNames ? 'Go to order' : '',
      variant: Button.VARIANT_NAMES.PRIMARY,
      dimension: Button.DIMENSION_NAMES.SMALL,
      title: 'Go to the shop items order page',
      width,
      disabled: true,
    },
    // STATUS BUTTONS (NOT CLICKABLE)
    [ACTIONS_IDS.TAKEN]: {
      type: 'button',
      id: ACTIONS_IDS.TAKEN,
      icon: 'Check',
      name: withNames ? '' : '',
      variant: Button.VARIANT_NAMES.SUCCESS,
      dimension: 'small',
      title: 'The item was purchased',
      width,
      disabled: true,
    },
  };
};

export const BUTTONS_WITH_NAMES = ACTIONS_BUTTONS({ withNames: true });
export const FULL_WIDTH_BUTTONS_WITH_NAMES = ACTIONS_BUTTONS({
  withNames: true,
  width: '100%',
});
export const BUTTONS = ACTIONS_BUTTONS({ withNames: false });

export const FORM_ACTIONS = [
  BUTTONS_WITH_NAMES.SAVE,
  BUTTONS_WITH_NAMES.CANCEL,
];

export const EDIT_REMOVE_ACTIONS = [BUTTONS.EDIT, BUTTONS.REMOVE];
