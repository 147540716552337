import { gql } from '@apollo/client';

const ORDER_SUBSCRIPTION_BY_DISTANCE_LIST = gql`
  query OrderSubscriptionByDistanceList(
    $filter: OrderSubscriptionByDistanceFilter
    $params: PaginationAndSorting
  ) {
    orderSubscriptionByDistanceList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        userLocationId
        distanceKm
        status
        userLocation {
          locationTypeId
          notes
          isDefault
          location {
            address2
            countryId
            postalCode
            l10n {
              address1
              city
              state
            }
          }
        }
      }
    }
  }
`;

const ORDER_SUBSCRIPTION_BY_DISTANCE_CREATE = gql`
  mutation OrderSubscriptionByDistanceCreate(
    $params: OrderSubscriptionByDistanceInput
  ) {
    orderSubscriptionByDistanceCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        userLocationId
        distanceKm
        status
        userLocation {
          locationTypeId
          notes
          isDefault
          location {
            address2
            countryId
            postalCode
            l10n {
              address1
              city
              state
            }
          }
        }
      }
    }
  }
`;

const ORDER_SUBSCRIPTION_BY_DISTANCE_UPDATE = gql`
  mutation OrderSubscriptionByDistanceUpdate(
    $where: OrderSubscriptionByDistanceWhereInput
    $params: OrderSubscriptionByDistanceInput
  ) {
    orderSubscriptionByDistanceUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        userLocationId
        distanceKm
        status
        userLocation {
          locationTypeId
          notes
          isDefault
          location {
            address2
            countryId
            postalCode
            l10n {
              address1
              city
              state
            }
          }
        }
      }
    }
  }
`;

const ORDER_SUBSCRIPTION_BY_DISTANCE_REMOVE_MANY = gql`
  mutation OrderSubscriptionByDistanceRemoveMany(
    $where: [OrderSubscriptionByDistanceWhereInput]
  ) {
    orderSubscriptionByDistanceRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const orderSubscriptionByDistanceQueries = {
  orderSubscriptionByDistanceList: ORDER_SUBSCRIPTION_BY_DISTANCE_LIST,
  orderSubscriptionByDistanceCreate: ORDER_SUBSCRIPTION_BY_DISTANCE_CREATE,
  orderSubscriptionByDistanceUpdate: ORDER_SUBSCRIPTION_BY_DISTANCE_UPDATE,
  orderSubscriptionByDistanceRemoveMany:
    ORDER_SUBSCRIPTION_BY_DISTANCE_REMOVE_MANY,
};

export { orderSubscriptionByDistanceQueries };
