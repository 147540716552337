import React from 'react';
import PropTypes from 'prop-types';
import { ContentContext } from '../contexts';
import { useContentItem } from '../graphql/hooks';

const ContentProvider = (props) => {
  const { children } = props;
  const hooks = useContentItem();

  return (
    <ContentContext.Provider value={hooks}>{children}</ContentContext.Provider>
  );
};

ContentProvider.displayName = 'ContentProvider';

ContentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ContentProvider };
