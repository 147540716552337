import { useState, useCallback, useMemo } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';

import {
  arrayOfObjectsToArray,
  OperationManager,
  OPERATIONS,
  useAppMutation,
  useAppQuery,
  formatLocalizedAddress,
  processInPlaceResult,
  handleApiResult,
  logger,
} from '../../utils';
import { orderQueries } from '../queries/orderQueries';

const useApiOrder = () => {
  const [order, setOrder] = useState([]);
  const [ordersMany, setOrdersMany] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersOps, setOrdersOps] = useState({});
  const [ordersOpsManager] = useState(new OperationManager());

  ordersOpsManager.setStateControls(ordersOps, setOrdersOps);

  // const processResult = (result) => {
  //   if (Array.isArray(result.data)) {
  //     result.data.forEach((item) => {
  //       if (item?.userLocation?.location) {
  //         item.userLocation.formattedAddress = formatLocalizedAddress(
  //           item?.userLocation?.location,
  //           'en'
  //         );
  //       }
  //     });
  //   }

  //   return result;
  // };

  const handleApiResultOpts = useMemo(() => {
    return {
      hookName: 'useApiOrder',
      opsManager: ordersOpsManager,
      setItem: setOrder,
      setItemsMany: setOrdersMany,
      setItems: setOrders,
      transformItem: (order) => {
        if (order?.userLocation?.location) {
          order.userLocation.formattedAddress = formatLocalizedAddress(
            order?.userLocation?.location,
            'en'
          );
        }

        return order;
      },
      customOperations: {
        [OPERATIONS.ORDER_SET_STEP]: (result, clientOptions) => {
          const order = result.getDataFirst();

          if (order) {
            if (result.didSucceed()) {
              setOrder(order);
              setOrders((state) => {
                return state.map((item) => {
                  return item?.id === order?.id ? order : item;
                });
              });
            }
          }

          const id = clientOptions.variables.params.id || '';
          ordersOpsManager.setResult(id, OPERATIONS.ORDER_SET_STEP, result);
        },
      },
    };
  }, [ordersOpsManager]);

  /**
   * GRAPHQL CALLERS
   */

  const [mutationOrderCreate] = useAppMutation({
    queries: orderQueries,
    queryName: 'orderCreate',
    onDone: handleApiResult(OPERATIONS.CREATE, handleApiResultOpts),
  });

  const [mutationOrderUpdate] = useAppMutation({
    queries: orderQueries,
    queryName: 'orderUpdate',
    onDone: handleApiResult(OPERATIONS.UPDATE, handleApiResultOpts),
  });

  const [mutationOrderRemoveMany] = useAppMutation({
    queries: orderQueries,
    queryName: 'orderRemoveMany',
    onDone: handleApiResult(OPERATIONS.REMOVE_MANY, handleApiResultOpts),
  });

  const [mutationOrderSetStep] = useAppMutation({
    queries: orderQueries,
    queryName: 'orderSetStep',
    onDone: handleApiResult(OPERATIONS.ORDER_SET_STEP, handleApiResultOpts),
  });

  const [queryOrderList] = useAppQuery({
    queries: orderQueries,
    queryName: 'orderList',
    onDone: handleApiResult(OPERATIONS.LIST, handleApiResultOpts),
  });

  const [queryOrderGet] = useAppQuery({
    queries: orderQueries,
    queryName: 'orderGet',
    //onDone: handleApiResult(OPERATIONS.GET, handleApiResultOpts),
    debug: true,
    calledInPlace: true,
  });

  const [queryOrderGetMany] = useAppQuery({
    queries: orderQueries,
    queryName: 'orderGetMany',
    onDone: handleApiResult(OPERATIONS.GET_MANY, handleApiResultOpts),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const orderCreate = useCallback(
    (variables) => {
      ordersOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.CREATE,
        OP_RESULT_CODES.SAVING,
        variables
      );

      mutationOrderCreate({ variables });
    },
    [mutationOrderCreate, ordersOpsManager]
  );

  const orderUpdate = useCallback(
    (variables) => {
      ordersOpsManager.setStartOperation(
        variables?.where?.id,
        OPERATIONS.UPDATE,
        OP_RESULT_CODES.SAVING,
        variables
      );
      mutationOrderUpdate({ variables });
    },
    [mutationOrderUpdate, ordersOpsManager]
  );

  const orderRemoveMany = useCallback(
    (variables) => {
      ordersOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.REMOVE_MANY,
        OP_RESULT_CODES.DELETING,
        variables
      );
      mutationOrderRemoveMany({ variables });
    },
    [mutationOrderRemoveMany, ordersOpsManager]
  );

  const orderSetStep = useCallback(
    (variables) => {
      ordersOpsManager.setStartOperation(
        variables?.params?.id,
        OPERATIONS.ORDER_SET_STEP,
        OP_RESULT_CODES.SAVING,
        variables
      );
      mutationOrderSetStep({ variables });
    },
    [mutationOrderSetStep, ordersOpsManager]
  );

  const orderList = useCallback(
    (variables) => {
      ordersOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING,
        variables
      );
      queryOrderList({ variables });
    },
    [queryOrderList, ordersOpsManager]
  );

  const orderGet = useCallback(
    async (variables) => {
      ordersOpsManager.setStartOperation(
        variables?.id,
        OPERATIONS.GET,
        OP_RESULT_CODES.LOADING,
        variables
      );

      logger.debug('orderGet:', variables);

      const response = await queryOrderGet({ variables });

      processInPlaceResult({
        response,
        queryName: 'orderGet',
        variables,
        onDone: handleApiResult(OPERATIONS.GET, handleApiResultOpts),
        debug: true,
      });
    },
    [queryOrderGet, handleApiResultOpts, ordersOpsManager]
  );

  const orderGetMany = useCallback(
    (variables) => {
      ordersOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.ids,
          fieldName: 'id',
        }),
        OPERATIONS.GET_MANY,
        OP_RESULT_CODES.LOADING,
        variables
      );

      queryOrderGetMany({ variables });
    },
    [queryOrderGetMany, ordersOpsManager]
  );

  return {
    order,
    orders,
    ordersMany,

    orderCreate,
    orderUpdate,
    orderRemoveMany,
    orderList,
    orderGet,
    orderGetMany,
    orderSetStep,

    ordersOpsManager,
  };
};

export { useApiOrder };
