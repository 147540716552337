import { memo, useMemo, useContext, useEffect, useCallback } from 'react';
//import PropTypes from 'prop-types';
import check from 'check-types';

import { Select } from '@sdflc/ui';

import { UserShoppingItemContext, LocalizationContext } from 'contexts';

import { OPERATIONS, OperationManager } from 'utils';

const SelectUserShoppingItem = memo((props) => {
  const { name, id, onChange } = props;
  const {
    userShoppingItem,
    userShoppingItems,
    userShoppingItemList,
    userShoppingItemCreate,
    userShoppingItemsOpsManager,
  } = useContext(UserShoppingItemContext);
  const { getText } = useContext(LocalizationContext);

  const listOp = userShoppingItemsOpsManager.getListOp();
  const createOp = userShoppingItemsOpsManager.getCreateOp();

  const handleChange = useCallback(
    (value) => {
      if (check.function(onChange)) {
        onChange(name || id, value);
      }
    },
    [onChange, name, id]
  );

  useEffect(() => {
    if (listOp.called) {
      return;
    }

    userShoppingItemList({ filter: {} });
  }, [userShoppingItemList, listOp]);

  useEffect(() => {
    if (createOp.called) {
      if (!createOp.result.isInProgress()) {
        handleChange(userShoppingItem?.id);
        userShoppingItemsOpsManager.reset(
          OperationManager.defaultContext,
          OPERATIONS.CREATE
        );
      }
    }
  }, [handleChange, userShoppingItem, createOp, userShoppingItemsOpsManager]);

  const options = useMemo(() => {
    if (listOp.result.isInProgress()) {
      return [];
    }

    return userShoppingItems.map((item) => {
      return {
        value: item.id,
        text: item.shoppingItemName ?? item.id,
      };
    });
  }, [userShoppingItems, listOp]);

  return (
    <Select
      {...props}
      options={options}
      loading={listOp.result.isLoading()}
      loadingMessage={getText('generic.loading')}
      placeholder={getText('selectors.selectUserShoppingItem.placeholder')}
      createFromSearch={'Create ":searchText"'}
      searchWhenMoreThan={0}
      onCreate={(_, value) => {
        userShoppingItemCreate({
          params: {
            shoppingItemName: value,
            manufacturer: '',
            shoppingItemDescription: '',
            categoryId: null,
            unitId: 'piece',
            quantity: 1,
            minPricePerUnit: null,
            maxPricePerUnit: null,
            pictureUrl: '',
          },
        });
      }}
    />
  );
});

SelectUserShoppingItem.displayName = 'SelectUserShoppingItem';

SelectUserShoppingItem.propTypes = {
  ...Select.propTypes,
};

SelectUserShoppingItem.defaultProps = {};

export { SelectUserShoppingItem };
