import React, { memo, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Panel, ProgressBar } from '@sdflc/ui';

import { ORDER_ITEM_STATUSES } from 'config';
import { LocalizationContext } from 'contexts';

const OrderItemsProgress = memo((props) => {
  const { shopMode, readOnly, order, items } = props;
  const { getText } = useContext(LocalizationContext);

  const { takenItemsCnt, text } = useMemo(() => {
    const result = items.reduce(
      (acc, item) => {
        if (item.status === ORDER_ITEM_STATUSES.TAKEN) {
          acc.takenItemsCnt++;
        }

        if (item.orderAmount != null && !isNaN(item.orderAmount)) {
          if (acc.orderAmount == null) {
            acc.orderAmount = 0;
          }
          acc.orderAmount += item.orderAmount;
        }

        return acc;
      },
      { takenItemsCnt: 0, orderAmount: null, items, text: '' }
    );

    if (order.orderAmount) {
      result.orderAmount = order.orderAmount;
    }

    result.text = getText('pages.orders.summaryBox.shoppingText', {
      qtyPickedItems: result.takenItemsCnt,
      qtyOrderItems: items.length,
    });

    return result;
  }, [items, order, getText]);

  if (!(shopMode || readOnly)) {
    return null;
  }

  return (
    <Panel mb={2}>
      <ProgressBar
        suffix=''
        showAsPercentage
        text={text}
        // text={
        //   orderAmount != null ? (
        //     <Flex flexDirection='row' alignItems='center'>
        //       <span>Total&nbsp;$</span>
        //       <ValueCurrency value={orderAmount ?? 0} currencyCode={'CAD'} />
        //     </Flex>
        //   ) : (
        //     ''
        //   )
        // }
        value={takenItemsCnt ?? 0}
        max={items.length ?? 1}
        variant={takenItemsCnt < items.length / 2 ? 'warning' : 'success'}
      />
    </Panel>
  );
});

OrderItemsProgress.displayName = 'OrderItemsProgress';

OrderItemsProgress.propTypes = {
  showMode: PropTypes.any,
  readOnly: PropTypes.bool,
  order: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export { OrderItemsProgress };
