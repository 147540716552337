import React, { memo, Fragment, useContext, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Caption, Grid, GridItem, HR, Tabs, Text } from '@sdflc/ui';

import { AppPagesContext, LocalizationContext } from 'contexts';

import {
  UserNameEditor,
  UserContacts,
  UserLocations,
  PaymentMethods,
  BankAccounts,
  PasswordChange,
  AvatarChange,
  UserSubscribeListItems,
  OrderSubscriptionsByDistance,
  UserAllowListItems,
  UserBlockListItems,
} from '../../components';
import { APP_PAGE_KEYS } from 'config';

const PageSettings = memo(() => {
  const location = useLocation();
  const history = useHistory();
  const { appPages, appUrls } = useContext(AppPagesContext);
  const { getText } = useContext(LocalizationContext);

  const handleClickChangeTab = useCallback(
    (tab) => {
      history.push(appPages[tab.id]?.url);
    },
    [history, appPages]
  );

  const tabIdx = useMemo(() => {
    const TABS_TO_PAGE = [
      'settings-general',
      'settings-contacts',
      'settings-addresses',
      'settings-payment-methods',
      'settings-bank-accounts',
      APP_PAGE_KEYS.SETTINGS_USER_SUBSCRIBES,
      APP_PAGE_KEYS.SETTINGS_ORDER_SIBSCRIPTION_BY_DISTANCE,
      APP_PAGE_KEYS.SETTINGS_ALLOWED_USERS,
      APP_PAGE_KEYS.SETTINGS_BLOCKED_USERS,
    ];

    const tabIdx = TABS_TO_PAGE.indexOf(appUrls[location.pathname]?.pageKey);

    return tabIdx !== -1 ? tabIdx : 0;
  }, [location, appUrls]);

  return (
    <Fragment>
      <Tabs onClick={handleClickChangeTab} defaultTabIdx={tabIdx}>
        <Tabs.Tab
          id='settings-general'
          icon='Person'
          name={getText('pages.settings.tabs.general.title')}
        >
          <Text>{getText('pages.settings.tabs.general.description')}</Text>
          <Grid dimension='fluid'>
            <GridItem width={['100%', '100%', '100%', '30%']}>
              <Caption level={4} pt={4}>
                {getText('pages.settings.tabs.general.sections.name')}
              </Caption>
            </GridItem>
            <GridItem width={['100%', '100%', '100%', '70%']}>
              <UserNameEditor />
            </GridItem>
          </Grid>
          <HR />
          <Grid dimension='fluid'>
            <GridItem width={['100%', '100%', '100%', '30%']}>
              <Caption level={4} pt={4}>
                {getText('pages.settings.tabs.general.sections.avatar')}
              </Caption>
            </GridItem>
            <GridItem width={['100%', '100%', '100%', '70%']}>
              {/* <UserNameEditor /> */}
              <AvatarChange />
            </GridItem>
          </Grid>
          <HR />
          <Grid dimension='fluid'>
            <GridItem width={['100%', '100%', '100%', '30%']}>
              <Caption level={4} pt={4}>
                {getText('pages.settings.tabs.general.sections.password')}
              </Caption>
            </GridItem>
            <GridItem width={['100%', '100%', '100%', '70%']}>
              <PasswordChange />
            </GridItem>
          </Grid>
        </Tabs.Tab>
        <Tabs.Tab
          id='settings-contacts'
          icon='Phone'
          name={getText('pages.settings.tabs.contacts.title')}
        >
          {/* <Text>{getText('pages.settings.tabs.contacts.description')}</Text> */}
          <UserContacts />
        </Tabs.Tab>
        <Tabs.Tab
          id='settings-addresses'
          icon='Home'
          name={getText('pages.settings.tabs.addresses.title')}
        >
          {/* <Text>{getText('pages.settings.tabs.addresses.description')}</Text> */}
          <UserLocations />
        </Tabs.Tab>
        <Tabs.Tab
          id='settings-payment-methods'
          icon='CreditCard'
          name={getText('pages.settings.tabs.paymentMethods.title')}
        >
          {/* <Text>{getText('pages.settings.tabs.creditCards.description')}</Text> */}
          <PaymentMethods />
        </Tabs.Tab>
        <Tabs.Tab
          id='settings-bank-accounts'
          icon='AccountBalance'
          name={getText('pages.settings.tabs.bankAccounts.title')}
        >
          {/* <Text>{getText('pages.settings.tabs.bankAccounts.description')}</Text> */}
          <BankAccounts />
        </Tabs.Tab>
        <Tabs.Tab
          id='settings-user-subscribes'
          icon='Notifications'
          name={getText('pages.settings.tabs.userSubscribe.title')}
        >
          <UserSubscribeListItems />
        </Tabs.Tab>
        <Tabs.Tab
          id={APP_PAGE_KEYS.SETTINGS_ORDER_SIBSCRIPTION_BY_DISTANCE}
          icon='LocationSearching'
          name={getText(
            'pages.settings.tabs.orderSubscriptionByDistance.title'
          )}
        >
          <OrderSubscriptionsByDistance />
        </Tabs.Tab>
        <Tabs.Tab
          id={APP_PAGE_KEYS.SETTINGS_ALLOWED_USERS}
          icon='GroupAdd'
          name={getText('pages.settings.tabs.userAllowListItem.title')}
        >
          <UserAllowListItems />
        </Tabs.Tab>
        <Tabs.Tab
          id={APP_PAGE_KEYS.SETTINGS_BLOCKED_USERS}
          icon='Block'
          name={getText('pages.settings.tabs.userBlockListItem.title')}
        >
          <UserBlockListItems />
        </Tabs.Tab>
      </Tabs>
    </Fragment>
  );
});

PageSettings.displayName = 'PageSettings';

export { PageSettings };
