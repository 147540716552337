import { memo } from 'react';

import { OrderAndItemsEdit, OrderWithItemsAndSummary } from 'components';

/**
 * Page component when users edit their order
 */
const PageEditOrder = memo(() => {
  return (
    <OrderWithItemsAndSummary titleId={'pages.order.edit.title'}>
      <OrderAndItemsEdit />
    </OrderWithItemsAndSummary>
  );
});

PageEditOrder.displayName = 'PageEditOrder';

export { PageEditOrder };
