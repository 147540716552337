import React, { memo, Fragment, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';
import { OpResult } from '@sdflc/api-helpers';
import {
  Button,
  ConfirmationButton,
  Grid,
  GridItem,
  ItemsGroup,
  Input,
  HR,
  Skeleton,
  Badge,
  Tag,
  Text,
} from '@sdflc/ui';

import { ACTIONS_BUTTONS } from '../../../config';

import { SettingsRow } from '../../SettingsRow';

import { formatLocalizedAddress } from 'utils/locationsHelpers';
import { LocalizationContext } from 'contexts';

const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const width1 = ['100%', '100%', '100%', '80%'];
const width2 = ['100%', '100%', '100%', '20%'];
const width3 = ['100%', '100%', '100%', '40%'];

const BankAccountItem = memo((props) => {
  const { value, onClickAction, loading, removingResult } = props;
  const { getText } = useContext(LocalizationContext);
  const {
    countryId,
    //locationId,
    userLocation,
    institutionNumber,
    transitNumber,
    accountNumber /*, status */,
    isDefault,
  } = value || {};

  const handleClickAction = useCallback(
    (action) => {
      if (check.function(onClickAction)) {
        onClickAction(action, value);
      } else {
        console.warn(
          'The "BankAccountItem" component expected the "onClickAction" to be a function but got:',
          onClickAction
        );
      }
    },
    [onClickAction, value]
  );

  const DefaultTag = useMemo(
    () =>
      isDefault ? (
        <Tag
          variant={Badge.VARIANT_NAMES.WARNING}
          dimension={Badge.DIMENSION_NAMES.SMALL}
        >
          {getText('generic.default')}
        </Tag>
      ) : null,
    [getText, isDefault]
  );

  if (!value) {
    return null;
  }

  const removingResult2use =
    removingResult instanceof OpResult ? removingResult : null;
  const error = removingResult2use?.getErrorSummary() ?? '';

  return (
    <Fragment>
      <SettingsRow name={getText('pages.bankAccounts.itemName')}>
        <Grid>
          <GridItem width={width1}>
            <Skeleton loading={loading} dispay='block'>
              <Input
                leftIcon={''}
                value={
                  userLocation?.location
                    ? formatLocalizedAddress(userLocation?.location, 'en')
                    : 'N/A'
                }
                readOnly
                RightSide={DefaultTag}
              />
            </Skeleton>
          </GridItem>
          <GridItem width={width2}>
            <Skeleton loading={loading} dispay='block'>
              <Input value={countryId} readOnly />
            </Skeleton>
          </GridItem>
          <GridItem width={width2}>
            <Skeleton loading={loading} dispay='block'>
              <Input value={institutionNumber} readOnly />
            </Skeleton>
          </GridItem>
          <GridItem width={width3}>
            <Skeleton loading={loading} dispay='block'>
              <Input value={transitNumber} readOnly />
            </Skeleton>
          </GridItem>
          <GridItem width={width3}>
            <Skeleton loading={loading} dispay='block'>
              <Input value={accountNumber} readOnly />
            </Skeleton>
          </GridItem>
        </Grid>
        {!!onClickAction && (
          <Skeleton loading={loading}>
            <ItemsGroup>
              <Button
                {...BUTTONS.EDIT_ALT}
                dimension={Button.DIMENSION_NAMES.SMALL}
                onClick={() => handleClickAction(BUTTONS.EDIT_ALT)}
              />
              {!isDefault && (
                <Button
                  {...BUTTONS.MAKE_DEFAULT}
                  dimension={Button.DIMENSION_NAMES.SMALL}
                  onClick={() => handleClickAction(BUTTONS.MAKE_DEFAULT)}
                />
              )}
              <ConfirmationButton
                {...BUTTONS.REMOVE}
                dimension={Button.DIMENSION_NAMES.SMALL}
                onConfirm={() => handleClickAction(BUTTONS.REMOVE)}
                progress={removingResult2use?.isInProgress()}
              />
              {!!error && (
                <Text variant={Text.VARIANT_NAMES.DANGER}>{error}</Text>
              )}
            </ItemsGroup>
          </Skeleton>
        )}
      </SettingsRow>
      <HR />
    </Fragment>
  );
});

BankAccountItem.displayName = 'BankAccountItem';

BankAccountItem.propTypes = {
  value: PropTypes.shape({}),
  onClickAction: PropTypes.func,
  loading: PropTypes.bool,
  removingResult: PropTypes.any,
};

BankAccountItem.defaultProps = {
  contact: null,
};

export { BankAccountItem };
