import React, { memo } from 'react';
import PropTypes from 'prop-types';
import emoji from 'node-emoji';
import { Button } from '@sdflc/ui';
import check from 'check-types';

const EmojiStatIcon = memo((props) => {
  const { name, reactionStat, myReactionId, onRemove } = props;

  if (!name) {
    return null;
  }

  return (
    <Button
      title={name}
      name={`${emoji.get(name)} ${reactionStat?.qty}`}
      variant='tertiary'
      dimension='small'
      onClick={() => {
        if (check.function(onRemove) && myReactionId) {
          onRemove({ commentReaction: name, commentReactionId: myReactionId });
        }
      }}
    />
  );
});

EmojiStatIcon.displayName = 'EmojiStatIcon';

EmojiStatIcon.propTypes = {
  name: PropTypes.string,
  reactionStat: PropTypes.any,
  myReactionId: PropTypes.string,
  onRemove: PropTypes.func,
};

export { EmojiStatIcon };
