import React, { memo, useContext, useMemo } from 'react';
import {
  ImageViewerContext,
  LocalizationContext,
  OrdersContext,
} from 'contexts';
import {
  Caption,
  Icon,
  Text,
  Button,
  Actions,
  Grid,
  GridItem,
  FormInput,
  InputDateTime,
} from '@sdflc/ui';

import {
  BUTTONS_WITH_NAMES,
  colors,
  ORDER_ITEM_STATUSES,
  ACTIONS_IDS,
} from 'config';
import { isOrderPaymentMethodCash } from 'utils';

import { InputUser } from 'components/Controls/InputUser';
import { InputCurrency } from 'components/Controls/InputCurrency';
import { getOrderStepPagePreRequisites } from './ordersHelpers';

const OrderDelivered = memo(() => {
  const { getText } = useContext(LocalizationContext);
  const { setViewImageIdx } = useContext(ImageViewerContext);
  const { orderHooks, orderItemHooks, handleClickAction, orderReceiptHooks } =
    useContext(OrdersContext);
  const { order } = orderHooks;
  const { orderItems } = orderItemHooks;
  const { orderReceipts } = orderReceiptHooks;

  const { orderDetails, labelData, hintData } = useMemo(() => {
    return getOrderStepPagePreRequisites({
      order,
      orderItems,
      getText,
      stepName: 'orderDelivered',
    });
  }, [order, orderItems, getText]);

  return (
    <Grid>
      <GridItem width={['100%', '100%', '100%', '80%', '80%']}>
        <Caption level={3}>
          <Icon
            name='LocalShipping'
            iconSize={64}
            color={colors.darkBlue}
            pr={2}
          />
          {getText('blocks.orderDelivered.header', order)}
        </Caption>
        <Caption level={4}>
          <Text>{getText('blocks.orderDelivered.title', order)}</Text>
        </Caption>
        <Text mt={3}>{getText('blocks.orderDelivered.subtitle', order)}</Text>
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '20%', '20%']}>
        <Actions
          actions={[BUTTONS_WITH_NAMES.GO_TO_ORDER_VIEW]}
          onClick={handleClickAction}
          dimension={Button.DIMENSION_NAMES.MEDIUM}
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          inputComponent={InputUser}
          fieldName='buyer'
          readOnly
          buttonName={order.isMine ? '' : 'Rate'}
          onClick={() => {
            handleClickAction({ id: ACTIONS_IDS.RATE_BUYER }, order);
          }}
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          inputComponent={InputUser}
          fieldName='shopper'
          readOnly
          buttonName={order.amIShopper ? '' : 'Rate'}
          onClick={() => {
            handleClickAction({ id: ACTIONS_IDS.RATE_BUYER }, order);
          }}
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='deliveredTo'
          readOnly
          leftIcon='Room'
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          inputComponent={InputDateTime}
          fieldName='deliveredAt'
          readOnly
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='orderItemsQty'
          readOnly
          leftIcon='FormatListNumbered'
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='orderItemsTakenQty'
          readOnly
          leftIcon='FormatListNumbered'
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='orderAmount'
          inputComponent={InputCurrency}
          readOnly
          currencyCode={orderDetails.currency}
          buttonName={
            orderReceipts.length ? getText('buttons.viewReceipts') : undefined
          }
          onClick={
            orderReceipts.length
              ? () => {
                  setViewImageIdx(0);
                }
              : undefined
          }
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='deliveryCost'
          inputComponent={InputCurrency}
          readOnly
          currencyCode={orderDetails.currency}
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='paymentMethod'
          readOnly
        />
      </GridItem>
      <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
        <FormInput
          labelData={labelData}
          hintData={hintData}
          data={orderDetails}
          fieldName='whereToTransfer'
          readOnly
        />
      </GridItem>
    </Grid>
  );
});

OrderDelivered.displayName = 'OrderDelivered';

export { OrderDelivered };
