import { useEffect, useMemo, useState } from 'react';

import { logger } from 'utils';
import { ACTIONS_IDS } from 'config';

import { usePaymentMethod } from 'graphql/hooks';
import { BUTTONS_WITH_NAMES } from 'config';
import { ToolBarItem } from '@sdflc/ui';

const usePaymentMethods = (props) => {
  // The counter is used to trigger reloading of contacts list when create, update, removal operation happens
  const [counter, setCounter] = useState(1);
  const {
    paymentMethod,
    paymentMethodCreate,
    paymentMethodCreateLoading,
    paymentMethodCreateCalled,
    paymentMethodCreateReset,
    paymentMethodRemoveMany,
    paymentMethodRemoveManyCalled,
    paymentMethodRemoveManyReset,
    paymentMethodEmbedUrl,
    paymentMethodEmbedUrlGet,
    paymentMethodUpdate,
    paymentMethodUpdateReset,
    paymentMethodUpdateCalled,
  } = usePaymentMethod();
  const [embedUrl, setEmbedUrl] = useState('');

  useEffect(() => {
    // This effect should close the app drawer on successfull removing of a contact

    // Contact is not being saved yet
    if (!paymentMethodRemoveManyCalled) {
      return;
    }

    // contact operation result is not in prgoress and succeed
    if (!paymentMethod.isInProgress() && paymentMethod.didSucceed()) {
      paymentMethodRemoveManyReset();
      setCounter(counter + 1);
    }
  }, [
    paymentMethod,
    paymentMethodRemoveManyCalled,
    paymentMethodRemoveManyReset,
    counter,
  ]);

  useEffect(() => {
    // this effect is called when user adds new credit card
    if (paymentMethodEmbedUrl.didSucceed()) {
      const url = paymentMethodEmbedUrl.getDataFirst();
      if (url && url !== embedUrl) {
        setEmbedUrl(url);
        window.location.href = url;
      }
    }
  }, [paymentMethodEmbedUrl, embedUrl]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of exisint contact

    // Contact is not being saved yet
    if (!paymentMethodUpdateCalled) {
      return;
    }

    // contact operation result is not in prgoress and succeed
    if (!paymentMethod.isInProgress() && paymentMethod.didSucceed()) {
      paymentMethodUpdateReset();
      setCounter(counter + 1);
    }
  }, [
    paymentMethod,
    paymentMethodUpdateCalled,
    paymentMethodUpdateReset,
    counter,
  ]);

  const handleClickAction = async (action, item) => {
    switch (action.id) {
      default:
        logger.warn('Unexpected action clicked:', { action, item });
        break;

      case ACTIONS_IDS.ADD:
      case ACTIONS_IDS.CREATE:
        paymentMethodEmbedUrlGet();
        break;

      case ACTIONS_IDS.REMOVE:
        await paymentMethodRemoveMany({ where: [{ id: item.id }] });
        break;

      case ACTIONS_IDS.MAKE_DEFAULT:
        paymentMethodUpdate({
          where: { id: item.id },
          params: {
            isDefault: true,
            token: item.token,
          },
        });
        break;
    }
  };

  // const actionInProgress = useMemo(
  //   () => (saveOpResult.isInProgress() ? [ACTIONS_IDS.SAVE] : []),
  //   [saveOpResult]
  // );

  const toolBarItems = useMemo(() => {
    return [
      // {
      //   id: 'keyword',
      //   type: ToolBarItem.types.COMPONENT,
      //   name: 'keyword',
      //   value: filters.keyword,
      //   component: Input,
      //   placeholder: getText('generic.search'),
      //   leftIcon: 'Search',
      // },
      { ...BUTTONS_WITH_NAMES.ADD, type: ToolBarItem.types.BUTTON },
    ];
  }, []);

  return {
    paymentMethod,
    handleClickAction,
    counter,
    setCounter,
    paymentMethodCreate,
    paymentMethodCreateLoading,
    paymentMethodCreateCalled,
    paymentMethodCreateReset,
    toolBarItems,
  };
};

export { usePaymentMethods };
