import { useState, useCallback } from 'react';
import { OpResult } from '@sdflc/api-helpers';
import { logger, OPERATIONS, useAppMutation, useAppQuery } from '../../utils';
import { appUserL10NQueries } from '../queries/appUserL10NQueries';

const useAppUserL10N = () => {
  const [appUserL10NSetResult, setAppUserL10NSetResult] = useState(
    new OpResult()
  );
  const [appUserL10NRemoveManyResult, setAppUserL10NRemoveManyResult] =
    useState(new OpResult());
  const [appUserL10Ns, setSppUserL10Ns] = useState(new OpResult());

  const handleDone = (operation) => (result) => {
    switch (operation) {
      default:
        logger.warn(
          'The "useAppUserL10N" hook got unexpected value of operation: ',
          operation
        );
        break;

      case OPERATIONS.LIST:
        setSppUserL10Ns(result);
        break;

      case OPERATIONS.SET:
        setAppUserL10NSetResult(result);
        // TODO: Update list of user contacts
        break;

      case OPERATIONS.REMOVE_MANY:
        setAppUserL10NRemoveManyResult(result);
        // TODO: Update list of user contacts
        break;
    }
  };

  /**
   * GRAPHQL CALLERS
   */

  const [
    mutationAppUserL10NSet,
    {
      called: appUserL10NSetCalled,
      loading: appUserL10NSetLoading,
      reset: appUserL10NSetReset,
    },
  ] = useAppMutation({
    queries: appUserL10NQueries,
    queryName: 'appUserL10NSet',
    onDone: handleDone(OPERATIONS.SET),
  });

  const [
    mutationappUserL10NRemoveMany,
    {
      called: appUserL10NRemoveManyCalled,
      loading: appUserL10NRemoveManyLoading,
      reset: appUserL10NRemoveManyReset,
    },
  ] = useAppMutation({
    queries: appUserL10NQueries,
    queryName: 'appUserL10NRemoveMany',
    onDone: handleDone(OPERATIONS.REMOVE_MANY),
  });

  const [
    queryAppUserL10NList,
    { called: appUserL10NListCalled, loading: appUserL10NListLoading },
  ] = useAppQuery({
    queries: appUserL10NQueries,
    queryName: 'appUserL10NList',
    onDone: handleDone(OPERATIONS.LIST),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const appUserL10NSet = useCallback(
    (variables) => {
      setAppUserL10NSetResult(
        appUserL10NSetResult.clearErrors().startLoading().clone()
      );
      mutationAppUserL10NSet({ variables });
    },
    [mutationAppUserL10NSet, appUserL10NSetResult]
  );

  const appUserL10NRemoveMany = useCallback(
    (variables) => {
      setAppUserL10NRemoveManyResult(
        appUserL10NRemoveManyResult.clearErrors().startLoading().clone()
      );
      mutationappUserL10NRemoveMany({ variables });
    },
    [mutationappUserL10NRemoveMany, appUserL10NRemoveManyResult]
  );

  const appUserL10NList = useCallback(
    (variables) => {
      setSppUserL10Ns(appUserL10Ns.clearErrors().startLoading().clone());
      queryAppUserL10NList({ variables });
    },
    [queryAppUserL10NList, appUserL10Ns]
  );

  return {
    appUserL10NSet,
    appUserL10NSetLoading,
    appUserL10NSetCalled,
    appUserL10NSetReset,
    appUserL10NSetResult,
    appUserL10NRemoveMany,
    appUserL10NRemoveManyLoading,
    appUserL10NRemoveManyCalled,
    appUserL10NRemoveManyReset,
    appUserL10NRemoveManyResult,
    appUserL10NList,
    appUserL10NListLoading,
    appUserL10NListCalled,
    appUserL10Ns,
  };
};

export { useAppUserL10N };
