import { gql } from '@apollo/client';

const ORDER_INVITATION_LIST = gql`
  query OrderInvitationList(
    $filter: OrderInvitationFilter
    $params: PaginationAndSorting
  ) {
    orderInvitationList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderId
        shopperId
        buyerComment
        order {
          orderNum
        }
      }
    }
  }
`;

const ORDER_INVITATION_GET_MANY = gql`
  query OrderInvitationGetMany($ids: [ID]) {
    orderInvitationCreateMany(ids: $ids) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderId
        shopperId
        buyerComment
        order {
          orderNum
        }
      }
    }
  }
`;

const ORDER_INVITATION_CREATE = gql`
  mutation OrderInvitationCreate($params: OrderInvitationInput) {
    orderInvitationCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderId
        shopperId
        buyerComment
        order {
          orderNum
        }
      }
    }
  }
`;

const ORDER_INVITATION_UPDATE = gql`
  mutation OrderInvitationUpdate(
    $where: OrderInvitationWhereInput
    $params: OrderInvitationInput
  ) {
    orderInvitationUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderId
        shopperId
        buyerComment
        order {
          orderNum
        }
      }
    }
  }
`;

const ORDER_INVITATION_REMOVE_MANY = gql`
  mutation OrderInvitationRemoveMany($where: [OrderInvitationWhereInput]) {
    orderInvitationRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const orderInvitationQueries = {
  orderInvitationList: ORDER_INVITATION_LIST,
  orderInvitationGetMany: ORDER_INVITATION_GET_MANY,
  orderInvitationCreate: ORDER_INVITATION_CREATE,
  orderInvitationUpdate: ORDER_INVITATION_UPDATE,
  orderInvitationRemoveMany: ORDER_INVITATION_REMOVE_MANY,
};

export { orderInvitationQueries };
