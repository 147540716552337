export const strReplace = (str, obj) => {
  if (!obj || typeof obj !== 'object' || !str) {
    return str;
  }

  let result = str;

  Object.keys(obj).forEach((key) => {
    result = result.replace(new RegExp(`:${key}`, 'g'), obj[key]);
  });

  return result;
};
