import React, { memo } from 'react';

import { OrderWithItemsAndSummary, OrderPaid } from 'components';

const PageOrderPaid = memo(() => {
  return (
    <OrderWithItemsAndSummary hideSummaryBox titleId={'pages.order.paid.title'}>
      <OrderPaid />
    </OrderWithItemsAndSummary>
  );
});

PageOrderPaid.displayName = 'PageOrderPaid';

export { PageOrderPaid };
