import { useState, useCallback } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';
import {
  logger,
  OperationManager,
  OPERATIONS,
  useAppMutation,
  useAppQuery,
} from '../../utils';
import {
  arrayOfObjectsToArray,
  arrayOfObjectsToSet,
} from '../../utils/transformers';
import { userContactQueries } from '../queries/userContact';

const useUserContact = () => {
  const [userContact, setUserContact] = useState(null);
  const [userContactMany, setUserContactMany] = useState([]);
  const [userContacts, setUserContacts] = useState([]);
  const [userContactsOps, setUserContactsOps] = useState({});
  const [userContactsOpsManager] = useState(new OperationManager());

  userContactsOpsManager.setStateControls(userContactsOps, setUserContactsOps);

  const handleDone = (operation) => (result, clientOptions) => {
    switch (operation) {
      default:
        logger.warn(
          'The "useUserContact" hook got unexpected value of operation: ',
          operation
        );
        break;

      case OPERATIONS.LIST:
        if (result.didSucceed()) {
          setUserContacts(result.getData());
        }

        userContactsOpsManager.setResult(
          OperationManager.defaultContext,
          OPERATIONS.LIST,
          result
        );

        break;

      case OPERATIONS.GET_MANY:
        {
          const contacts = result.getData();

          if (result.didSucceed()) {
            setUserContactMany(contacts);
          }

          userContactsOpsManager.setResult(
            arrayOfObjectsToArray({ data: contacts, fieldName: 'id' }),
            OPERATIONS.GET_MANY,
            result
          );
        }
        break;

      case OPERATIONS.CREATE:
        {
          const contact = result.getDataFirst();

          if (contact) {
            if (result.didSucceed()) {
              setUserContact(contact);
              setUserContacts((state) => {
                return [
                  contact,
                  ...state.map((item) => {
                    if (item.isDefault === true && contact.isDefault === true) {
                      return {
                        ...item,
                        isDefault: false,
                      };
                    }

                    return item;
                  }),
                ];
              });
            }
          }

          userContactsOpsManager.setResult(
            OperationManager.defaultContext,
            OPERATIONS.CREATE,
            result
          );
        }
        break;

      case OPERATIONS.UPDATE:
        {
          const contact = result.getDataFirst();

          if (contact) {
            if (result.didSucceed()) {
              setUserContact(contact);
              setUserContacts((state) => {
                return state.map((item) => {
                  if (item?.id === contact?.id) {
                    return contact;
                  } else if (
                    item.isDefault === true &&
                    contact.isDefault === true
                  ) {
                    // In case updated contact is default then remove the default flag from other contacts
                    return {
                      ...item,
                      isDefault: false,
                    };
                  }

                  return item;
                });
              });
            }
          }

          const id = clientOptions.variables.where.id || '';
          userContactsOpsManager.setResult(id, OPERATIONS.UPDATE, result);
        }
        break;

      case OPERATIONS.REMOVE_MANY:
        {
          const contacts = result.getData();

          if (result.didSucceed()) {
            const setContactIds = arrayOfObjectsToSet({
              data: contacts,
              fieldName: 'id',
            });

            setUserContacts((state) => {
              return state.filter((item) => {
                return !setContactIds.has(item?.id);
              });
            });
          }

          const ids = arrayOfObjectsToArray({
            data: clientOptions.variables.where,
            fieldName: 'id',
          });
          userContactsOpsManager.setResult(ids, OPERATIONS.REMOVE_MANY, result);
        }
        break;
    }
  };

  /**
   * GRAPHQL CALLERS
   */

  const [mutationUserContactCreate] = useAppMutation({
    queries: userContactQueries,
    queryName: 'userContactCreate',
    onDone: handleDone(OPERATIONS.CREATE),
  });

  const [mutationUserContactUpdate] = useAppMutation({
    queries: userContactQueries,
    queryName: 'userContactUpdate',
    onDone: handleDone(OPERATIONS.UPDATE),
  });

  const [mutationUserContactRemoveMany] = useAppMutation({
    queries: userContactQueries,
    queryName: 'userContactRemoveMany',
    onDone: handleDone(OPERATIONS.REMOVE_MANY),
  });

  const [queryUserContactList] = useAppQuery({
    queries: userContactQueries,
    queryName: 'userContactList',
    onDone: handleDone(OPERATIONS.LIST),
  });

  const [queryUserContactGetMany] = useAppQuery({
    queries: userContactQueries,
    queryName: 'userContactGetMany',
    onDone: handleDone(OPERATIONS.GET_MANY),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const userContactCreate = useCallback(
    (variables) => {
      userContactsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.CREATE,
        OP_RESULT_CODES.SAVING
      );

      mutationUserContactCreate({ variables });
    },
    [mutationUserContactCreate, userContactsOpsManager]
  );

  const userContactUpdate = useCallback(
    (variables) => {
      userContactsOpsManager.setStartOperation(
        variables?.where?.id,
        OPERATIONS.UPDATE,
        OP_RESULT_CODES.SAVING
      );

      mutationUserContactUpdate({ variables });
    },
    [mutationUserContactUpdate, userContactsOpsManager]
  );

  const userContactRemoveMany = useCallback(
    (variables) => {
      userContactsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.REMOVE_MANY,
        OP_RESULT_CODES.DELETING
      );

      mutationUserContactRemoveMany({ variables });
    },
    [mutationUserContactRemoveMany, userContactsOpsManager]
  );

  const userContactList = useCallback(
    (variables) => {
      userContactsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING
      );

      queryUserContactList({ variables });
    },
    [queryUserContactList, userContactsOpsManager]
  );

  const userContactGetMany = useCallback(
    (variables) => {
      userContactsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.GET_MANY,
        OP_RESULT_CODES.LOADING
      );

      queryUserContactGetMany({ variables });
    },
    [queryUserContactGetMany, userContactsOpsManager]
  );

  return {
    userContact,
    userContacts,
    userContactMany,

    userContactCreate,
    userContactGetMany,
    userContactUpdate,
    userContactRemoveMany,
    userContactList,

    userContactsOpsManager,
  };
};

export { useUserContact };
