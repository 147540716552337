import React from 'react';
import PropTypes from 'prop-types';
import { CommentsContext } from '../contexts';
import { useComments } from '../graphql/hooks';

const CommentsProvider = (props) => {
  const { children } = props;
  const hooks = useComments();

  return (
    <CommentsContext.Provider value={hooks}>
      {children}
    </CommentsContext.Provider>
  );
};

CommentsProvider.displayName = 'CommentsProvider';

CommentsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CommentsProvider };
