import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';

import {
  ItemsGroup,
  Button,
  ConfirmationButton,
  Skeleton,
  Tile,
  Flex,
} from '@sdflc/ui';

import { ACTIONS_IDS, colors } from '../../../config';
import { UserBadge } from '../../UserBadge';

import { EnableSwitch } from 'components/Controls';
import { OpResult } from '@sdflc/api-helpers';

const UserSubscribeListItemsListItem = memo((props) => {
  const { value, onClickAction, loading, removingResult, updatingResult } =
    props;

  const handleClickAction = useCallback(
    (action) => {
      if (check.function(onClickAction)) {
        onClickAction(action, value);
      } else {
        console.warn(
          'The "UserSubscribeListItemsListItem" component expected the "onClickAction" to be a function but got:',
          onClickAction
        );
      }
    },
    [onClickAction, value]
  );

  if (!value) {
    return null;
  }

  const updatingResult2use =
    updatingResult instanceof OpResult ? updatingResult : new OpResult();
  const removingResult2use =
    removingResult instanceof OpResult ? removingResult : new OpResult();
  const error = removingResult2use?.getErrorSummary() ?? '';

  return (
    <Tile mb={2} backgroundColor={colors.white}>
      <ItemsGroup direction='row'>
        <Flex>
          <Skeleton loading={loading}>
            <EnableSwitch
              value={value.status}
              hasInputContainer={false}
              title='Toggle subscription status'
              onChange={() =>
                handleClickAction({ id: ACTIONS_IDS.TOGGLE_STATUS })
              }
              inProgress={updatingResult2use.isInProgress()}
            />
          </Skeleton>
        </Flex>
        <Flex width={['100%', '100%', '50%', '50%', '40%']}>
          <Skeleton loading={loading}>
            <UserBadge appUser={value.subscribedForUser} />
          </Skeleton>
        </Flex>
        <Flex flex='1 1'>{error}</Flex>
        <Flex>
          <Skeleton loading={loading}>
            <ConfirmationButton
              icon='Delete'
              dimension={Button.DIMENSION_NAMES.SMALL}
              variant={Button.VARIANT_NAMES.DANGER}
              onConfirm={() => handleClickAction({ id: ACTIONS_IDS.REMOVE })}
            />
          </Skeleton>
        </Flex>
      </ItemsGroup>
    </Tile>
  );
});

UserSubscribeListItemsListItem.displayName = 'UserSubscribeListItemsListItem';

UserSubscribeListItemsListItem.propTypes = {
  value: PropTypes.shape({}),
  onClickAction: PropTypes.func,
  loading: PropTypes.bool,
  removingResult: PropTypes.any,
  updatingResult: PropTypes.any,
};

UserSubscribeListItemsListItem.defaultProps = {
  value: null,
};

export { UserSubscribeListItemsListItem };
