import { useState, useCallback } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';
import {
  arrayOfObjectsToArray,
  handleApiResult,
  OperationManager,
  OPERATIONS,
  useAppMutation,
  useAppQuery,
} from '../../utils';
import { orderItemQueries } from '../queries/orderItemQueries';

const useOrderItem = () => {
  const [orderItem, setOrderItem] = useState(null);
  const [orderItemsMany, setOrderItemsMany] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [orderItemsOps, setOrderItemsOps] = useState({});
  const [orderItemsOpsManager] = useState(new OperationManager());

  orderItemsOpsManager.setStateControls(orderItemsOps, setOrderItemsOps);

  const handleApiResultOpts = {
    hookName: 'useOrderItem',
    opsManager: orderItemsOpsManager,
    setItem: setOrderItem,
    setItemsMany: setOrderItemsMany,
    setItems: setOrderItems,
  };

  // /**
  //  * GRAPHQL CALLERS
  //  */

  const [mutationOrderItemCreate] = useAppMutation({
    queries: orderItemQueries,
    queryName: 'orderItemCreate',
    onDone: handleApiResult(OPERATIONS.CREATE, handleApiResultOpts),
  });

  const [mutationOrderItemUpdate] = useAppMutation({
    queries: orderItemQueries,
    queryName: 'orderItemUpdate',
    onDone: handleApiResult(OPERATIONS.UPDATE, handleApiResultOpts),
  });

  const [mutationOrderItemRemoveMany] = useAppMutation({
    queries: orderItemQueries,
    queryName: 'orderItemRemoveMany',
    onDone: handleApiResult(OPERATIONS.REMOVE_MANY, handleApiResultOpts),
  });

  const [queryOrderItemList] = useAppQuery({
    queries: orderItemQueries,
    queryName: 'orderItemList',
    onDone: handleApiResult(OPERATIONS.LIST, handleApiResultOpts),
  });

  const [queryOrderItemGetMany] = useAppQuery({
    queries: orderItemQueries,
    queryName: 'orderItemGetMany',
    onDone: handleApiResult(OPERATIONS.GET_MANY, handleApiResultOpts),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const orderItemCreate = useCallback(
    (variables) => {
      orderItemsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.CREATE,
        OP_RESULT_CODES.SAVING
      );

      mutationOrderItemCreate({ variables });
    },
    [mutationOrderItemCreate, orderItemsOpsManager]
  );

  const orderItemUpdate = useCallback(
    (variables) => {
      orderItemsOpsManager.setStartOperation(
        variables?.where?.id,
        OPERATIONS.UPDATE,
        OP_RESULT_CODES.SAVING
      );

      if (variables?.params?.status != null) {
        variables.params.status = Number(variables?.params?.status);
      }

      mutationOrderItemUpdate({ variables });
    },
    [mutationOrderItemUpdate, orderItemsOpsManager]
  );

  const orderItemRemoveMany = useCallback(
    (variables) => {
      orderItemsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.REMOVE_MANY,
        OP_RESULT_CODES.DELETING
      );

      mutationOrderItemRemoveMany({ variables });
    },
    [mutationOrderItemRemoveMany, orderItemsOpsManager]
  );

  const orderItemList = useCallback(
    (variables) => {
      orderItemsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING
      );

      queryOrderItemList({ variables });
    },
    [queryOrderItemList, orderItemsOpsManager]
  );

  const orderItemGetMany = useCallback(
    (variables) => {
      orderItemsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.GET_MANY,
        OP_RESULT_CODES.LOADING
      );

      queryOrderItemGetMany({ variables });
    },
    [queryOrderItemGetMany, orderItemsOpsManager]
  );

  return {
    orderItem,
    orderItems,
    orderItemsMany,

    orderItemCreate,
    orderItemUpdate,
    orderItemRemoveMany,
    orderItemList,
    orderItemGetMany,

    orderItemsOpsManager,
  };
};

export { useOrderItem };
