import { memo, useContext } from 'react';
import PropTypes from 'prop-types';

import { Panel, Alert, AlertText, Icon } from '@sdflc/ui';

import { ORDER_LIST_CATEGORIES } from 'config/orderCard';
import { LocalizationContext } from 'contexts';
import { colors } from 'config';

const NoOrders = memo((props) => {
  const { orderListCategory } = props;
  const { getText } = useContext(LocalizationContext);

  const textToShow = getText(`noOrders.${orderListCategory}`);

  return (
    <Panel mt={3} overflow='initial'>
      <Alert
        variant={Alert.VARIANT_NAMES.DEFAULT}
        dimension={Alert.DIMENSION_NAMES.LARGE}
      >
        <Icon name={'PriorityHigh'} color={colors.orange} iconSize={32} />
        <AlertText>{textToShow}</AlertText>
      </Alert>
    </Panel>
  );
});

NoOrders.displayName = 'NoOrders';

NoOrders.propTypes = {
  orderListCategory: PropTypes.oneOf(Object.values(ORDER_LIST_CATEGORIES)),
};

NoOrders.defaultProps = {
  orderListCategory: ORDER_LIST_CATEGORIES.NONE,
};

export { NoOrders };
