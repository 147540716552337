import {
  memo,
  Fragment,
  useContext,
  useMemo,
  useCallback,
  //useState,
} from 'react';
import PropTypes from 'prop-types';

import { Actions, Alert, Panel, PropsViewer } from '@sdflc/ui';

import { LocalizationContext } from '../../../contexts';

import { orderDetailsConfig } from './ordersHelpers';
import { buildOrderViewActions } from 'components/OrderCard/OrderCardHelpers';
import check from 'check-types';

const OrdersView = memo((props) => {
  const { order, /*updateOp, ordersOpsManager,*/ onClickAction } = props;
  const { getText } = useContext(LocalizationContext);
  //const [totalFormOpen, setTotalFormOpen] = useState(false);

  const handleClickAction = useCallback(
    (action, item) => {
      if (check.function(onClickAction)) {
        onClickAction(action, item);
      }
    },
    [onClickAction]
  );

  const { config, orderDetails, actions, statusForShopper, actionInProgress } =
    useMemo(() => {
      const orderDetails = {
        orderNum: order?.orderNum,
        formattedAddress: order?.userLocation?.formattedAddress,
        dueDate: order?.dueDate,
        deliveryCost: order?.deliveryCost,
        totalAmount: order?.totalAmount,
        currentStepId: order?.currentStepId,
        status: order?.status,
        buyerComment: order?.buyerComment,
        shopperComment: order?.shopperComment,
        buyerName: order?.userCreated?.username,
        shopperName: order?.shopper?.username,
      };

      return {
        config: orderDetailsConfig({ getText, order }),
        orderDetails,
        ...buildOrderViewActions({
          order,
          getText,
        }),
      };
    }, [getText, order]);

  return (
    <Fragment>
      <Panel mb={3}>
        <Panel>
          <PropsViewer
            config={config}
            data={orderDetails}
            propNameWidth={'170px'}
            propNameMinWidth={'170px'}
            propNameMaxWidth={'170px'}
            withBorder={false}
            withInnerBorder
          />
        </Panel>
        <Actions
          actions={actions}
          onClick={(action) => handleClickAction(action, order)}
          dimension='medium'
          actionInProgress={actionInProgress}
        />
        {!!statusForShopper && (
          <Alert variant='default'>{statusForShopper}</Alert>
        )}
      </Panel>
    </Fragment>
  );
});

OrdersView.displayName = 'OrdersView';

OrdersView.propTypes = {
  orderId: PropTypes.string,
};

OrdersView.defaultProps = {
  orderId: null,
};

export { OrdersView };
