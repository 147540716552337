import { useState, useCallback, useEffect } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';

import {
  arrayOfObjectsToArray,
  handleApiResult,
  OperationManager,
  OPERATIONS,
  useAppMutation,
  useAppQuery,
} from '../../utils';
import { uploadedFileQueries } from '../queries/uploadedFileQueries';

const queue = [];
const enqueued = new Set();

const useUploadedFile = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileMany, setUploadedFileMany] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFilesById, setUploadedFilesById] = useState({});
  const [uploadedFilesOps, setUploadedFilesOps] = useState({});
  const [uploadedFilesOpsManager] = useState(new OperationManager());

  uploadedFilesOpsManager.setStateControls(
    uploadedFilesOps,
    setUploadedFilesOps
  );

  const updateUploadedFilesById = (uploadedFiles) => {
    if (Array.isArray(uploadedFiles)) {
      const tmp = {};

      uploadedFiles.forEach((uploadedFile) => {
        tmp[uploadedFile.id] = uploadedFile;
      });

      setUploadedFilesById((state) => {
        return {
          ...state,
          ...tmp,
        };
      });
    }
  };

  const handleApiResultOpts = {
    hookName: 'useUploadedFile',
    opsManager: uploadedFilesOpsManager,
    setItem: (uploadedFile) => {
      setUploadedFile(uploadedFile);
      updateUploadedFilesById([uploadedFile]);
    },
    setItemsMany: (uploadedFiles) => {
      setUploadedFileMany(uploadedFiles);
      updateUploadedFilesById(uploadedFiles);
    },
    setItems: (uploadedFiles) => {
      setUploadedFiles(uploadedFiles);
      updateUploadedFilesById(uploadedFiles);
    },
  };

  /**
   * GRAPHQL CALLERS
   */

  const [mutationUploadedFileUpdate] = useAppMutation({
    queries: uploadedFileQueries,
    queryName: 'uploadedFileUpdate',
    onDone: handleApiResult(OPERATIONS.UPDATE, handleApiResultOpts),
  });

  const [mutationUploadedFileRemoveMany] = useAppMutation({
    queries: uploadedFileQueries,
    queryName: 'uploadedFileRemoveMany',
    onDone: handleApiResult(OPERATIONS.REMOVE_MANY, handleApiResultOpts),
  });

  const [queryUploadedFileList] = useAppQuery({
    queries: uploadedFileQueries,
    queryName: 'uploadedFileList',
    onDone: handleApiResult(OPERATIONS.LIST, handleApiResultOpts),
  });

  const [queryUploadedFileGetMany] = useAppQuery({
    queries: uploadedFileQueries,
    queryName: 'uploadedFileGetMany',
    onDone: handleApiResult(OPERATIONS.GET_MANY, handleApiResultOpts),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const uploadedFileUpdate = useCallback(
    (variables) => {
      uploadedFilesOpsManager.setStartOperation(
        variables?.where?.id,
        OPERATIONS.CREATE,
        OP_RESULT_CODES.SAVING
      );
      mutationUploadedFileUpdate({ variables });
    },
    [mutationUploadedFileUpdate, uploadedFilesOpsManager]
  );

  const uploadedFileRemoveMany = useCallback(
    (variables) => {
      uploadedFilesOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.REMOVE_MANY,
        OP_RESULT_CODES.DELETING
      );
      mutationUploadedFileRemoveMany({ variables });
    },
    [mutationUploadedFileRemoveMany, uploadedFilesOpsManager]
  );

  const uploadedFileList = useCallback(
    (variables) => {
      uploadedFilesOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING
      );

      queryUploadedFileList({ variables });
    },
    [queryUploadedFileList, uploadedFilesOpsManager]
  );

  const uploadedFileGetMany = useCallback(
    (variables) => {
      uploadedFilesOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.GET_MANY,
        OP_RESULT_CODES.LOADING
      );
      queryUploadedFileGetMany({ variables });
    },
    [queryUploadedFileGetMany, uploadedFilesOpsManager]
  );

  const uploadedFileGetManyEnque = (uploadedFileId) => {
    if (enqueued.has(uploadedFileId)) {
      return;
    }

    //logger.log('Enqueue request to get uploaded file', uploadedFileId);

    queue.push(uploadedFileId);
    enqueued.add(uploadedFileId);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (queue.length) {
        // logger.debug(
        //   'Call uploadedFileGetMany',
        //   JSON.stringify(queue, null, 2)
        // );
        uploadedFileGetMany({ ids: queue });
        queue.length = 0;
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, [uploadedFileGetMany]);

  return {
    uploadedFile,
    uploadedFileMany,
    uploadedFiles,
    uploadedFilesById,

    uploadedFileUpdate,
    uploadedFileRemoveMany,
    uploadedFileList,
    uploadedFileGetMany,
    uploadedFileGetManyEnque,

    uploadedFilesOpsManager,
  };
};

export { useUploadedFile };
