import { Fragment, memo } from 'react';
import { UserShoppingItems } from 'components';

const PageShoppingItems = memo(() => {
  return (
    <Fragment>
      <UserShoppingItems />
    </Fragment>
  );
});

PageShoppingItems.displayName = 'PageShoppingItems';

PageShoppingItems.propTypes = {};
PageShoppingItems.defaultProps = {};

export { PageShoppingItems };
