import { memo, useContext } from 'react';

import {
  Grid,
  GridItem,
  Caption,
  Icon,
  colors,
  Container,
  Centered,
} from '@sdflc/ui';

import { LocalizationContext } from 'contexts';

const STATUSES = {
  PROGRESS: 'PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

const STATUS_ICONS = {
  PROGRESS: { name: 'Pending', color: colors.yellow },
  SUCCESS: { name: 'Check', color: colors.green },
  FAILURE: { name: 'Close', color: colors.red },
};

const PageVerifyEmail = memo(() => {
  //const history = useHistory();
  const { getText } = useContext(LocalizationContext);

  const status = STATUSES.PROGRESS;

  return (
    <Container dimension='md'>
      <Grid>
        <GridItem width='100%'>
          <Centered>
            <Icon
              name={STATUS_ICONS[status].name}
              color={STATUS_ICONS[status].color}
              iconSize='64px'
            />
          </Centered>
        </GridItem>
        <GridItem width='100%'>
          <Centered>
            <Caption level={3}>
              {getText(`pages.verifyEmail.statuses.${status}`)}
            </Caption>
          </Centered>
        </GridItem>
      </Grid>
    </Container>
  );
});

PageVerifyEmail.displayName = 'PageVerifyEmail';

export { PageVerifyEmail };
