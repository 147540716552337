import { Fragment, memo, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';
import { Button, SimpleForm } from '@sdflc/ui';

import { logger, OPERATIONS } from 'utils';

import { Block } from '../../Block';

import { LocalizationContext /* , OrdersContext */ } from '../../../contexts';

import { orderPaymentFormSections } from './orderPaymentFormSections';
import { appSettings, ORDER_STATUSES, ACTIONS_IDS } from '../../../config';

//const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const OrdersEditPayment = memo((props) => {
  const { order, ordersOpsManager, onClickAction } = props;
  const { getText } = useContext(LocalizationContext);

  const orderId = order?.id;
  const updateOp = ordersOpsManager.get(orderId, OPERATIONS.UPDATE);

  const handleClickAction = useCallback(
    (action, item) => {
      if (check.function(onClickAction)) {
        onClickAction(action, item);
      }
    },
    [onClickAction]
  );

  const formCfg = useMemo(() => {
    const labels = getText('pages.orders.form.labels');
    const hints = getText('pages.orders.form.hints');

    const canChangeAddress =
      [ORDER_STATUSES.PROGRESS, ORDER_STATUSES.COMPLETED].indexOf(
        order.status
      ) === -1;

    orderPaymentFormSections[0].fields[0].componentProps.disabled =
      !canChangeAddress;

    return { labels, hints, sections: orderPaymentFormSections };
  }, [order, getText]);

  logger.log('Rendering OrdersEditPayment', { order, updateOp });

  return (
    <Block>
      <SimpleForm
        formProps={{ width: appSettings.defaultFormWidth }}
        formData={order}
        formCfg={formCfg}
        onSubmit={(formData) => {
          handleClickAction({ id: ACTIONS_IDS.SAVE }, formData);
        }}
        //onChange={action('Form Changed')}
        progress={updateOp.result.isInProgress()}
        buttons={
          <Fragment>
            <Button
              icon='Done'
              name={getText('buttons.save')}
              type='submit'
              progress={updateOp.result.isInProgress()}
            />
          </Fragment>
        }
      />
    </Block>
  );
});

OrdersEditPayment.displayName = 'OrdersEditPayment';

OrdersEditPayment.propTypes = {
  children: PropTypes.node,
  onLoad: PropTypes.func,
};

OrdersEditPayment.defaultProps = {};

export { OrdersEditPayment };
