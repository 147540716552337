import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { asyncMap } from '@apollo/client/utilities';
import { appSettings } from '../config/appSettings';

const cache = new InMemoryCache({
  addTypename: false,
});

const httpLink = new HttpLink({
  uri: appSettings.apiUrl,
  credentials: 'include',
  useGETForQueries: false,
  headers: {},
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers,
        'x-space-id': appSettings.authSpace,
      },
    };
  });

  return forward(operation);
});

const activityMiddleware = new ApolloLink((operation, forward) => {
  // add the recent-activity custom header to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      //'recent-activity': localStorage.getItem('_sd_lot') || null,
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `GraphQL error: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const preProcessResponse = new ApolloLink((operation, forward) => {
  return asyncMap(forward(operation), async (response) => {
    // let data = response.data;
    // if (data.price && data.currency === "USD") {
    //   data.price = await usdToEur(data.price);
    //   data.currency = "EUR";
    // }
    return response;
  });
});

const apolloClient = new ApolloClient({
  cache,
  link: from([
    authMiddleware,
    activityMiddleware,
    preProcessResponse,
    errorLink,
    httpLink,
  ]),
});

export { apolloClient };
