import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { LocalizationContext } from '../contexts';
import { getLocalization } from '../l10n';
import { escapeRegExp } from 'lodash';

const LocalizationProvider = (props) => {
  const { children } = props;
  const [pageTitle, setPageTitle] = useState('');

  const getText = useCallback((key, obj) => {
    let str = getLocalization('en', key);

    if (typeof obj === 'object') {
      Object.keys(obj ?? {}).forEach((prop) => {
        str = str.replace(
          new RegExp(`\\:${escapeRegExp(prop)}`, 'ig'),
          obj[prop]
        );
      });
    }

    return str;
  }, []);

  const value = useMemo(() => {
    return { getText, pageTitle, setPageTitle };
  }, [getText, pageTitle, setPageTitle]);

  return (
    <LocalizationContext.Provider value={value}>
      {children}
    </LocalizationContext.Provider>
  );
};

LocalizationProvider.displayName = 'LocalizationProvider';

LocalizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { LocalizationProvider };
