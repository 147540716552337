import React, { memo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { AspectRatioBox, Touchable } from '@sdflc/ui';

import { ImageViewerContext, UploadedFileContext } from 'contexts';
import { appSettings } from 'config';
import { logger } from 'utils';

const ReceiptImage = memo((props) => {
  const { receiptFileId, imageSize, blur } = props;
  const { uploadedFileGetManyEnque, uploadedFilesById } =
    useContext(UploadedFileContext);
  const { setViewImageIdx, viewImages } = useContext(ImageViewerContext);

  const uploadedFile = uploadedFilesById?.[receiptFileId];

  logger.debug('uploadedFile', uploadedFile);

  const handleClick = useCallback(() => {
    if (uploadedFile) {
      //logger.debug('uploadedFile', uploadedFile);
      const idx = viewImages.findIndex(
        (viewImage) => viewImage.id === receiptFileId
      );
      setViewImageIdx(idx);
    }
  }, [uploadedFile, viewImages, setViewImageIdx, receiptFileId]);

  const img =
    uploadedFile != null
      ? uploadedFile.fileUrl
      : appSettings.defaultReceiptImageUrl;

  if (uploadedFile === undefined && receiptFileId) {
    uploadedFileGetManyEnque(receiptFileId);
  }

  const imageToRender = (
    <AspectRatioBox
      ratio='1:1'
      width={imageSize ? `${imageSize}px` : '38px'}
      backgroundImage={img}
      borderRadius={'8px'}
      style={{ filter: blur ? 'blur(1px)' : '' }}
    />
  );

  return <Touchable onClick={handleClick}>{imageToRender}</Touchable>;
});

ReceiptImage.displayName = 'ReceiptImage';

ReceiptImage.propTypes = {
  receiptFileId: PropTypes.string,
  imageSize: PropTypes.number,
  blur: PropTypes.bool,
};

ReceiptImage.defaultProp = {
  imageSize: '38px',
  blur: false,
};

export { ReceiptImage };
