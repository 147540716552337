import { useState, useCallback } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';

import {
  arrayOfObjectsToArray,
  handleApiResult,
  OperationManager,
  OPERATIONS,
  useAppQuery,
} from '../../utils';
import { appUserQueries } from '../queries/appUserQueries';

const useApiAppUser = () => {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [usersOps, setUsersOps] = useState({});
  const [usersOpsManager] = useState(new OperationManager());

  usersOpsManager.setStateControls(usersOps, setUsersOps);

  const handleApiResultOpts = {
    hookName: 'useApiUser',
    opsManager: usersOpsManager,
    setItem: setUser,
    setItems: setUsers,
  };

  /**
   * GRAPHQL CALLERS
   */

  const [queryUserList] = useAppQuery({
    queries: appUserQueries,
    queryName: 'appUserList',
    onDone: handleApiResult(OPERATIONS.LIST, handleApiResultOpts),
  });

  const [queryUserGetMany] = useAppQuery({
    queries: appUserQueries,
    queryName: 'appUserGetMany',
    onDone: handleApiResult(OPERATIONS.LIST, handleApiResultOpts),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const userList = useCallback(
    (variables) => {
      usersOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING
      );

      queryUserList({ variables });
    },
    [queryUserList, usersOpsManager]
  );

  const userGetMany = useCallback(
    (variables) => {
      usersOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.GET_MANY,
        OP_RESULT_CODES.LOADING
      );

      queryUserGetMany({ variables });
    },
    [queryUserGetMany, usersOpsManager]
  );

  return {
    user,
    users,

    userList,
    userGetMany,

    usersOpsManager,
  };
};

export { useApiAppUser };
