import { FORM_INPUT_TYPES } from '@sdflc/ui';
import { DICTIONARIES_UIDS } from 'config/dictionariesUids';

import { SelectDictionaryItem } from '../..';

import { UserShoppingItemImage } from './UserShoppingItemImage';

export const userShoppingItemForm = [
  {
    fields: [
      {
        field: 'shoppingItemName',
        type: FORM_INPUT_TYPES.TEXT,
        required: true,
        width: ['100%'],
      },
      {
        field: 'pictureUrl',
        width: ['100%'],
        component: UserShoppingItemImage,
        componentProps: {
          needsData: true,
        },
      },
      {
        field: 'manufacturer',
        type: FORM_INPUT_TYPES.TEXT,
        width: ['100%'],
      },
      {
        field: 'shoppingItemDescription',
        type: FORM_INPUT_TYPES.TEXTAREA,
        width: ['100%'],
      },
      {
        field: 'categoryId',
        width: ['100%'],
        component: SelectDictionaryItem,
        componentProps: {
          dictionaryUid: DICTIONARIES_UIDS.STORE_CATEGORY_TYPES,
          placeholder: 'selectors.selectCategory.placeholder',
        },
      },
      {
        field: 'unitId',
        width: ['100%'],
        component: SelectDictionaryItem,
        componentProps: {
          dictionaryUid: DICTIONARIES_UIDS.UNIT_TYPES,
          placeholder: 'selectors.selectUnit.placeholder',
        },
      },
      {
        field: 'quantity',
        type: FORM_INPUT_TYPES.NUMBER,
        width: ['100%'],
        componentProps: {
          min: 0,
        },
      },
      {
        field: 'minPricePerUnit',
        type: FORM_INPUT_TYPES.NUMBER,
        width: ['100%'],
        componentProps: {
          min: 0,
        },
      },
      {
        field: 'maxPricePerUnit',
        type: FORM_INPUT_TYPES.NUMBER,
        width: ['100%'],
        componentProps: {
          min: 0,
        },
      },
    ],
  },
];
