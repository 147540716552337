import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Text,
  FormState,
  Form,
  FormInput,
  Grid,
  GridItem,
  Caption,
  Actions,
  Icon,
  colors,
  Alert,
  Button,
} from '@sdflc/ui';

import {
  appPages,
  APP_PAGE_KEYS,
  ACTIONS_IDS,
  BUTTONS_WITH_NAMES,
} from '../../config';
import { AuthContext, LocalizationContext } from 'contexts';
import { logger } from 'utils';

const PageForgotPassword = memo((props) => {
  const history = useHistory();
  const { getText } = useContext(LocalizationContext);
  const {
    resetPasswordRequest,
    authResetOp,
    resetPasswordRequestOpId,
    resetPasswordRequestOp,
  } = useContext(AuthContext);
  const [formData, setFormData] = useState({ username: '' });

  const { formCfg, actions } = useMemo(() => {
    const labels = getText('pages.forgotPassword.form.labels');
    const hints = getText('pages.forgotPassword.form.hints');
    const placeholders = getText('pages.forgotPassword.form.placeholders');

    const actions = [BUTTONS_WITH_NAMES.OK, BUTTONS_WITH_NAMES.SIGN_IN_ALT];

    return {
      formCfg: {
        labels,
        hints,
        placeholders,
      },
      actions,
    };
  }, [getText]);

  const handleClickButton = (action, submit) => {
    switch (action.id) {
      default:
        logger.warn(
          `The "handleClickButton" got an unexpected action:`,
          action
        );
        break;

      case ACTIONS_IDS.OK:
        submit();
        break;

      case ACTIONS_IDS.SIGN_IN:
        history.push(appPages[APP_PAGE_KEYS.SIGN_IN].url);
        break;
    }
  };

  const handleForgotPassword = useCallback(
    (submittedFormData) => {
      setFormData(submittedFormData);
      authResetOp(resetPasswordRequestOpId);
      resetPasswordRequest({ params: submittedFormData });
    },
    [resetPasswordRequestOpId, authResetOp, resetPasswordRequest]
  );

  const handleClickSignIn = useCallback(() => {
    history.push(appPages[APP_PAGE_KEYS.SIGN_IN].url);
  }, [history]);

  const errors = resetPasswordRequestOp.result.getErrorSummary('');
  const isInProgress = resetPasswordRequestOp.result.isInProgress('');
  const requested =
    resetPasswordRequestOp.called &&
    !isInProgress &&
    resetPasswordRequestOp.result.didSucceed();

  if (requested) {
    return (
      <Grid width={'600px'} maxWidth='90%'>
        <GridItem width='100%'>
          <Icon name='VpnKey' iconSize='22px' color={colors.blue} />
        </GridItem>
        <GridItem width='100%'>
          <Caption level={3}>
            {getText('pages.forgotPassword.requested.title')}
          </Caption>
        </GridItem>
        <GridItem width='100%'>
          <Text>{getText('pages.forgotPassword.requested.description')}</Text>
        </GridItem>
        <GridItem width='100%'>
          <Button
            variant='primary'
            name={getText('buttons.signIn')}
            dimension='medium'
            onClick={handleClickSignIn}
          />
        </GridItem>
      </Grid>
    );
  }

  return (
    <FormState data={formData} onSubmit={handleForgotPassword}>
      {({ data, handleChange, submit }) => {
        return (
          <Form width={'600px'} maxWidth='90%'>
            <Grid>
              <GridItem width='100%'>
                <Icon name='VpnKey' iconSize='22px' color={colors.blue} />
              </GridItem>
              <GridItem width='100%'>
                <Caption level={3}>
                  {getText('pages.forgotPassword.form.title')}
                </Caption>
              </GridItem>
              <GridItem width='100%'>
                <Text>{getText('pages.forgotPassword.form.description')}</Text>
              </GridItem>
              <GridItem width='100%'>
                <FormInput
                  required
                  labelData={formCfg.labels}
                  placeholderData={formCfg.placeholders}
                  hintData={formCfg.hints}
                  data={data}
                  fieldName='username'
                  onChange={handleChange}
                  opResult={resetPasswordRequestOp.result}
                  leftIcon='Person'
                />
              </GridItem>
              {!!errors && (
                <GridItem width='100%'>
                  <Alert variant='danger'>{errors}</Alert>
                </GridItem>
              )}
              <GridItem width='100%'>
                <Actions
                  actions={actions}
                  actionInProgress={isInProgress ? 'ok' : ''}
                  onClick={(button) => handleClickButton(button, submit)}
                  width='100%'
                />
              </GridItem>
            </Grid>
          </Form>
        );
      }}
    </FormState>
  );
});

PageForgotPassword.displayName = 'PageForgotPassword';

export { PageForgotPassword };
