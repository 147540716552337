import { Fragment, memo } from 'react';
import { Text } from '@sdflc/ui';
import { GenericAvatar } from 'components';

const PageTransactions = memo(() => {
  return (
    <Fragment>
      <Text>
        This page is for displaying user incoming and outgoing transactions as
        well as earned balance
        <GenericAvatar name='Chocolate Bar' />
      </Text>
    </Fragment>
  );
});

PageTransactions.displayName = 'PageTransactions';

export { PageTransactions };
