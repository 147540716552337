import { memo, useContext, useMemo, Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, ItemsGroup, Panel, Button, HR } from '@sdflc/ui';

import {
  LocalizationContext,
  UserShoppingItemContext,
} from '../../../contexts';

import { OrderItemsListItem } from './OrderItemsListItem';
import { logger } from 'utils';

const ITEMS_TO_SHOW = 20;

const tplNewOrderItem = Array.from({ length: 4 }).map((_, idx) => ({
  orderId: null,
  shoppingItemId: null,
  quantity: 1,
  unitId: 'package',
  minPricePerUnit: null,
  maxPricePerUnit: null,
  onlyIfOnSaleIndicator: false,
  notImportantIndicator: false,
  buyerComment: '',
  shopperComment: '',
  storeBrandId: null,
  actualQuantity: null,
  price: null,
  totalAmount: null,
}));

const OrderItemsList = memo((props) => {
  const {
    onClickAction,
    items,
    listOp,
    removingOps,
    orderId,
    readOnly,
    shopMode,
    order,
  } = props;
  const { getText } = useContext(LocalizationContext);
  const [showAll, setShowAll] = useState(false);
  const { userShoppingItems } = useContext(UserShoppingItemContext);
  const isLoading = listOp.result.isInProgress();

  const items2use = useMemo(() => {
    const existingItems = (items || []).reduce((acc, item) => {
      acc[item.shoppingItemId] = true;
      return acc;
    }, {});

    return userShoppingItems.reduce((acc, shoppingItem) => {
      if (!existingItems[shoppingItem.id]) {
        acc.push({
          id: null,
          orderId: orderId,
          shoppingItemId: shoppingItem.id,
          quantity: shoppingItem.quantity,
          unitId: shoppingItem.unitId,
          actualQuantity: 0,
          buyerComment: '',
          minPricePerUnit: shoppingItem.minPricePerUnit,
          maxPricePerUnit: shoppingItem.maxPricePerUnit,
          notImportantIndicator: false,
          onlyIfOnSaleIndicator: false,
          price: 0,
          totalAmount: 0,
          shopperComment: '',
          status: 100,
          shoppingItem: {
            shoppingItemName: shoppingItem.shoppingItemName,
            manufacturer: shoppingItem.manufacturer,
            minPricePerUnit: shoppingItem.minPricePerUnit,
            maxPricePerUnit: shoppingItem.maxPricePerUnit,
            pictureUrl: shoppingItem.pictureUrl,
          },
        });
      }
      return acc;
    }, []);
  }, [items, userShoppingItems, orderId]);

  if (listOp.called && !isLoading && listOp.result.didFail()) {
    return <Alert variant='danger'>{listOp.result.getErrorSummary()}</Alert>;
  }

  const orderItems = isLoading ? tplNewOrderItem : items;
  const itemsToShow = showAll ? items2use : items2use.slice(0, ITEMS_TO_SHOW);

  return (
    <Panel mt={2} p={1} overflow='initial'>
      <Fragment>
        <ItemsGroup wrapMode={'wrap'} alignItems='flex-start'>
          {orderItems.map((item, idx) => {
            return (
              <OrderItemsListItem
                key={`order-item-list-item-${item.id || idx}`}
                value={item}
                onClickAction={onClickAction}
                readOnly={readOnly}
                shopMode={shopMode}
                loading={isLoading}
                removingResult={removingOps[item.id]?.result}
                order={order}
              />
            );
          })}
          {!isLoading && orderItems.length === 0 && (
            <Alert variant='default'>
              {getText('pages.orderItems.startSelection')}
            </Alert>
          )}
        </ItemsGroup>
        {!isLoading && !shopMode && !readOnly && (
          <Fragment>
            {/* Rendering user shopping items for user to pick */}
            <HR mt={3} mb={3} />
            <ItemsGroup wrapMode={'wrap'} alignItems='flex-start'>
              {itemsToShow.map((item, idx) => {
                return (
                  <OrderItemsListItem
                    key={`order-item-list-item-${item.id || idx}`}
                    value={item}
                    onClickAction={onClickAction}
                    readOnly={readOnly}
                    shopMode={shopMode}
                  />
                );
              })}
            </ItemsGroup>
            {items2use.length > ITEMS_TO_SHOW && (
              <Panel mt={3}>
                <Button
                  variant='secondary'
                  name={getText(`buttons.${showAll ? 'showLess' : 'showAll'}`)}
                  icon={showAll ? 'ExpandLess' : 'ExpandMore'}
                  onClick={() => setShowAll(!showAll)}
                />
              </Panel>
            )}
          </Fragment>
        )}
      </Fragment>
    </Panel>
  );
});

OrderItemsList.displayName = 'OrderItemsList';

OrderItemsList.propTypes = {
  onClickAction: PropTypes.func,
  list: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  listOp: PropTypes.any,
  removingOps: PropTypes.any,
  orderId: PropTypes.string,
  readOnly: PropTypes.bool,
  shopMode: PropTypes.bool,
};

OrderItemsList.defaultProps = {
  onClickAction: null,
  items: [],
  readOnly: false,
  shopMode: false,
};

export { OrderItemsList };
