import React, {
  memo,
  Fragment,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { AppPageDrawer, PageDrawerBlock, Panel, Alert, Flex } from '@sdflc/ui';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';
import { OpResult } from '@sdflc/api-helpers/build';

import { CommentsContext, LocalizationContext } from '../../contexts';

import { Loading } from '../Loading';

import { InputComment } from '../Controls/InputComment';
import { CommentItem } from '../CommentItem';

const CommentsDrawer = memo((props) => {
  const { channelKey, onClose } = props;
  const { getText } = useContext(LocalizationContext);
  const {
    comment,
    comments,
    commentList,
    commentCreate,
    commentRemoveMany,
    commentReactionSet,
    commentReactionRemoveMany,
  } = useContext(CommentsContext);

  useEffect(() => {
    // Initial loading of comments for selected order item
    if (channelKey) {
      commentList(new OpResult(), {
        filter: {
          channelKey: channelKey,
        },
      });
    }
  }, [channelKey, commentList]);

  const handleClickSendComment = useCallback(
    (_, value, attachments) => {
      //console.log('attachments=', attachments);
      commentCreate({
        params: {
          channelKey: channelKey,
          commentText: value,
          createChannelIfNotExists: true,
          attachments,
        },
      });
    },
    [commentCreate, channelKey]
  );

  const handleClickRemoveComment = useCallback(
    (comment) => {
      commentRemoveMany({
        where: [{ id: comment.id }],
      });
    },
    [commentRemoveMany]
  );

  const handleClickAddCommentReaction = useCallback(
    (comment, reaction) => {
      commentReactionSet({
        params: {
          channelCommentId: comment.id,
          commentReaction: reaction,
        },
      });
    },
    [commentReactionSet]
  );

  const handleClickRemoveCommentReaction = useCallback(
    (commentReaction) => {
      commentReactionRemoveMany({
        where: [
          {
            id: commentReaction.commentReactionId,
          },
        ],
      });
    },
    [commentReactionRemoveMany]
  );

  return (
    <AppPageDrawer
      isOpen={!!channelKey}
      width='450px'
      maxWidth='100%'
      onClose={onClose}
      title={getText('generic.comments')}
    >
      {({ data, setData }) => {
        return (
          <Fragment>
            <PageDrawerBlock height='100%' padding={2}>
              <Flex height='100%' flexDirection='column' overflow='hidden'>
                <Flex flex='1 1' overflow='auto'>
                  <Panel width='100%' overflow='unset'>
                    {comments.isLoading() && <Loading />}
                    {comments.getData().length === 0 &&
                      !comments.isLoading() && (
                        <Alert variant='warning'>
                          {getText('pages.orderItemComments.noItems')}
                        </Alert>
                      )}
                    {[
                      OP_RESULT_CODES.OK,
                      OP_RESULT_CODES.VALIDATION_FAILED,
                      OP_RESULT_CODES.LOADING,
                    ].indexOf(comments.getCode()) === -1 && (
                      <Alert variant='danger'>
                        {comments.getErrorSummary('')}
                      </Alert>
                    )}
                    {comments.getData().length > 0 &&
                      comments.getData().map((comment) => {
                        if (!comment) {
                          return null;
                        }

                        return (
                          <CommentItem
                            key={comment.id}
                            comment={comment}
                            onRemove={handleClickRemoveComment}
                            onAddReaction={handleClickAddCommentReaction}
                            onRemoveReaction={handleClickRemoveCommentReaction}
                          />
                        );
                      })}
                  </Panel>
                </Flex>
                <InputComment
                  label={getText('pages.orderItemComments.labels.comment')}
                  onSubmit={handleClickSendComment}
                  progress={comment.isInProgress()}
                  disabled={comments.isLoading()}
                  result={comment}
                  channelKey={channelKey}
                />
              </Flex>
            </PageDrawerBlock>
          </Fragment>
        );
      }}
    </AppPageDrawer>
  );
});

CommentsDrawer.displayName = 'CommentsDrawer';

CommentsDrawer.propTypes = {
  channelKey: PropTypes.string,
  onClose: PropTypes.func,
};

export { CommentsDrawer };
