import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { AppPagesContext } from '../contexts';
import { appPages, appUrls } from 'config';
import { logger, strReplace } from 'utils';

const AppPagesProvider = (props) => {
  const { children } = props;
  const history = useHistory();

  const goToPage = useCallback(
    (pageKey, obj) => {
      if (appPages[pageKey]?.url) {
        history.push(strReplace(appPages[pageKey].url, obj));
      } else {
        logger.log(
          `The page '${pageKey}' was not found among the available pages or its URL is empty :`,
          appPages
        );
      }
    },
    [history]
  );

  const hooks = {
    appPages,
    appUrls,
    goToPage,
  };

  return (
    <AppPagesContext.Provider value={hooks}>
      {children}
    </AppPagesContext.Provider>
  );
};

AppPagesProvider.displayName = 'AppPagesProvider';

AppPagesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppPagesProvider };
