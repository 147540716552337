const MAP_CONTACT_TYPE_TO_ICON = {
  UNKNOWN: 'QuestionMark',
  PHONE: 'Call',
  MOBILE: 'Smartphone',
  FAX: 'Fax',
  WORK: 'Apartment',
  EMAIL: 'Email',
};

const MAP_LOCATION_TYPE_TO_ICON = {
  UNKNOWN: 'QuestionMark',
  HOME: 'Home',
  WORK: 'Apartment',
};

export const contactTypeToIcon = (contactTypeId) => {
  return (
    MAP_CONTACT_TYPE_TO_ICON[(contactTypeId || '').toUpperCase()] ||
    MAP_CONTACT_TYPE_TO_ICON['UNKNOWN']
  );
};

export const locationTypeToIcon = (locationTypeId) => {
  return (
    MAP_LOCATION_TYPE_TO_ICON[(locationTypeId || '').toUpperCase()] ||
    MAP_LOCATION_TYPE_TO_ICON['UNKNOWN']
  );
};
