import { FORM_INPUT_TYPES } from '@sdflc/ui';
import { DICTIONARIES_UIDS } from 'config/dictionariesUids';

import {
  SelectDictionaryItem,
  //SelectStoreBrand,
  SelectUserShoppingItem,
} from '../..';

export const orderItemFormSections = [
  {
    fields: [
      {
        field: 'shoppingItemId',
        required: true,
        width: ['100%'],
        component: SelectUserShoppingItem,
      },
      {
        field: 'unitId',
        width: ['100%'],
        component: SelectDictionaryItem,
        required: true,
        componentProps: {
          dictionaryUid: DICTIONARIES_UIDS.UNIT_TYPES,
        },
      },
      {
        field: 'quantity',
        type: FORM_INPUT_TYPES.NUMBER,
        required: true,
        width: ['100%'],
        componentProps: {
          min: 0,
        },
      },
      {
        field: 'minPricePerUnit',
        type: FORM_INPUT_TYPES.NUMBER,
        width: ['100%'],
        componentProps: {
          min: 0,
        },
      },
      {
        field: 'maxPricePerUnit',
        type: FORM_INPUT_TYPES.NUMBER,
        width: ['100%'],
        componentProps: {
          min: 0,
        },
      },
      {
        field: 'onlyIfOnSaleIndicator',
        type: FORM_INPUT_TYPES.CHECKBOX,
        width: ['100%'],
        componentProps: {
          min: 0,
        },
      },
      // {
      //   field: 'notImportantIndicator',
      //   type: FORM_INPUT_TYPES.CHECKBOX,
      //   width: ['100%'],
      // },
      // {
      //   field: 'storeBrandId',
      //   width: ['100%'],
      //   component: SelectStoreBrand,
      // },
      {
        field: 'buyerComment',
        type: FORM_INPUT_TYPES.TEXTAREA,
        width: ['100%'],
      },
    ],
  },
];
