import { gql } from '@apollo/client';

const STORE_BRAND_LIST = gql`
  query StoreBrandList(
    $filter: StoreBrandFilter
    $params: PaginationAndSorting
  ) {
    storeBrandList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        status
        storeTypeId
        l10n {
          lang
          storeBrandName
        }
      }
    }
  }
`;

const STORE_BRAND_GET_MANY = gql`
  query storeBrandGetMany($ids: [ID]) {
    storeBrandGetMany(ids: $ids) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        contactTypeId
        contactValue
        contactDescription
        status
        version
        createdAt
        updatedAt
      }
    }
  }
`;

const STORE_BRAND_CREATE = gql`
  mutation storeBrandCreate($params: storeBrandInput) {
    storeBrandCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        contactTypeId
        contactValue
        contactDescription
        status
        version
        createdAt
        updatedAt
      }
    }
  }
`;

const STORE_BRAND_UPDATE = gql`
  mutation storeBrandUpdate(
    $where: storeBrandWhereInput
    $params: storeBrandInput
  ) {
    storeBrandUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        contactTypeId
        contactValue
        contactDescription
        status
        version
        createdAt
        updatedAt
      }
    }
  }
`;

const STORE_BRAND_REMOVE_MANY = gql`
  mutation storeBrandRemoveMany($where: [storeBrandWhereInput]) {
    storeBrandRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        contactTypeId
        contactValue
        contactDescription
        status
        version
        createdAt
        updatedAt
      }
    }
  }
`;

const storeBrandQueries = {
  storeBrandList: STORE_BRAND_LIST,
  storeBrandGetMany: STORE_BRAND_GET_MANY,
  storeBrandCreate: STORE_BRAND_CREATE,
  storeBrandUpdate: STORE_BRAND_UPDATE,
  storeBrandRemoveMany: STORE_BRAND_REMOVE_MANY,
};

export { storeBrandQueries };
