import { FORM_INPUT_TYPES } from '@sdflc/ui';
import { DICTIONARIES_UIDS } from 'config/dictionariesUids';

import { SelectDictionaryItem } from '../..';

import { TotalAmount } from './TotalAmount';

export const orderItemFormSectionsForShopper = [
  {
    fields: [
      {
        field: 'unitId',
        width: ['100%'],
        component: SelectDictionaryItem,
        componentProps: {
          dictionaryUid: DICTIONARIES_UIDS.UNIT_TYPES,
        },
      },
      {
        field: 'quantity',
        type: FORM_INPUT_TYPES.NUMBER,
        required: true,
        width: ['100%'],
        componentProps: {
          disabled: true,
        },
      },
      {
        field: 'actualQuantity',
        type: FORM_INPUT_TYPES.NUMBER,
        required: true,
        width: ['100%'],
        componentProps: {
          min: 0,
        },
      },
      {
        field: 'price',
        type: FORM_INPUT_TYPES.NUMBER,
        width: ['100%'],
        componentProps: {
          min: 0,
        },
      },
      {
        field: 'totalAmount',
        component: TotalAmount,
        width: ['100%'],
        componentProps: {
          needsData: true,
        },
      },
      {
        field: 'status',
        width: ['100%'],
        component: SelectDictionaryItem,
        componentProps: {
          dictionaryUid: DICTIONARIES_UIDS.ORDER_ITEM_STATUS,
        },
      },
      {
        field: 'shopperComment',
        type: FORM_INPUT_TYPES.TEXTAREA,
        width: ['100%'],
      },
    ],
  },
];
