import { useToaster } from '@sdflc/ui';
import { APP_PAGE_KEYS } from 'config';
import { ORDER_STEPS } from 'config';
import { AppPagesContext, LocalizationContext } from 'contexts';
import { useContext, useEffect } from 'react';
import { logger, OPERATIONS } from 'utils';

export const useHandleOrderSetStepResult = (props) => {
  const { ordersOpsManager, orderId, setStepOp } = props;
  const { goToPage } = useContext(AppPagesContext);
  const { getText } = useContext(LocalizationContext);
  const toast = useToaster();

  useEffect(() => {
    if (setStepOp.called) {
      if (!setStepOp.result.isInProgress()) {
        ordersOpsManager.reset(orderId, OPERATIONS.ORDER_SET_STEP);
        if (setStepOp.result.didSucceed()) {
          const newStepId = setStepOp.result.getDataFirst()?.currentStepId;

          switch (newStepId) {
            default:
              logger.debug('Unsupported order step:', newStepId);
              break;

            case ORDER_STEPS.PICKED:
              goToPage(APP_PAGE_KEYS.ORDER_SHOP_ITEMS, {
                orderId: setStepOp.result.getDataFirst()?.id,
              });
              break;

            case ORDER_STEPS.DELIVERED:
              goToPage(APP_PAGE_KEYS.ORDER_DELIVERED, {
                orderId: setStepOp.result.getDataFirst()?.id,
              });
              break;

            case ORDER_STEPS.CONFIRMED:
              goToPage(APP_PAGE_KEYS.ORDER_CONFIRMED, {
                orderId: setStepOp.result.getDataFirst()?.id,
              });
              break;

            case ORDER_STEPS.PAID:
              goToPage(APP_PAGE_KEYS.ORDER_PAID, {
                orderId: setStepOp.result.getDataFirst()?.id,
              });
              break;

            case ORDER_STEPS.COMPLETE:
              goToPage(APP_PAGE_KEYS.ORDER_COMPLETE, {
                orderId: setStepOp.result.getDataFirst()?.id,
              });
              break;
          }
        } else {
          toast({
            message: getText('blocks.orders.errors.failedToSetOrderStep', {
              error: setStepOp.result.getErrorSummary(),
            }),
            variant: 'danger',
            timeout: 30,
          });
        }
      }
    }
  }, [setStepOp, goToPage, getText, orderId, ordersOpsManager, toast]);
};
