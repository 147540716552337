import React, {
  Fragment,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  Skeleton,
  Text,
  PropsViewer,
  Actions,
  Button,
  Row,
  ItemsGroup,
} from '@sdflc/ui';

import {
  LocalizationContext,
  AppPagesContext,
  CommentsContext,
} from 'contexts';

import { ORDER_STATUSES, ORDER_LIST_CATEGORIES } from 'config';

import { OrderCardWrapper } from './OrderCardBlock';
import { OrderCardBlock } from './OrderCardWrapper';
import { UserBadge } from '../UserBadge';

import { buildOrderViewActions, orderDetailsConfig } from './OrderCardHelpers';
import { OrderCardImages } from './OrderCardImages';
import { APP_PAGE_KEYS } from 'config';
import { isOrderPaymentMethodCash } from 'utils';

const containerStyles = {
  display: 'block',
};

const fakeItems = Array.from({ length: 5 }).fill({
  id: 0,
  pictureUrl: '',
});

const OrderCard = memo((props) => {
  const {
    order,
    isLoading,
    onClickAction,
    orderListCategory,
    width,
    removingOps,
    setStepOps,
  } = props;

  const { getText } = useContext(LocalizationContext);
  const { goToPage } = useContext(AppPagesContext);
  const { setChannelKey } = useContext(CommentsContext);
  const [imageSize, setImageSize] = useState(60);
  const imageHolderRef = useRef();
  const imagesToShow = 5;
  const imagesGap = 5;

  const isComplete =
    [ORDER_STATUSES.COMPLETED, ORDER_STATUSES.CANCELLED].indexOf(
      order.status
    ) !== -1;

  const config = useMemo(
    () => orderDetailsConfig({ getText, isComplete }),
    [getText, isComplete]
  );

  useEffect(() => {
    const resizeHandler = (evt) => {
      if (imageHolderRef.current) {
        const blockWidth = imageHolderRef.current.clientWidth;
        setImageSize(blockWidth / imagesToShow - imagesGap);
      }
    };

    window.addEventListener('resize', resizeHandler);

    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [imageHolderRef]);

  const { orderItems, mainImages } = useMemo(() => {
    const orderItems = Array.isArray(order.orderItemsPreview)
      ? order.orderItemsPreview.map((orderItem) => {
          return {
            quantity: orderItem.quantity,
            unitId: orderItem.unitId || orderItem.shoppingItem?.unitId,
            categoryId: orderItem.shoppingItem?.categoryId,
            pictureUrl: orderItem.shoppingItem?.pictureUrl,
            name: orderItem.shoppingItem?.shoppingItemName,
            manufacturer: orderItem.shoppingItem?.manufacturer,
          };
        })
      : fakeItems;

    const mainImages = orderItems.slice(0, imagesToShow);

    return { orderItems, mainImages };
  }, [order]);

  // if ([1000018].indexOf(order.orderNum) !== -1) {
  //   console.log(`OrderCard.order(${order.orderNum})`, order);
  // }

  const { orderDetails, actions, user2show, actionInProgress } = useMemo(() => {
    const orderDetails = {
      orderNum: order.orderNum,
      formattedAddress: order?.userLocation?.formattedAddress,
      dueDate: order?.dueDate,
      deliveryCost: order?.deliveryCost,
      orderAmount: order?.orderAmount,
      serviceTotalFees: order?.a,
      serviceFeeTotalAmount: order?.serviceFeeTotalAmount,
      orderAmountForBuyer: order?.totalAmountForBuyer,
      orderAmountForShopper: order?.totalAmountForShopper,
      totalAmount: order?.amIShopper
        ? order?.orderAmountForShopper
        : order?.totalAmountForBuyer,
      status: order?.status,
      buyerComment: order?.buyerComment,
      shopperComment: order?.shopperComment,
      // buyerName: orderData?.userCreated?.username,
      // shopperName: orderData?.shopper?.username,
      buyer: {
        ...order.userCreated,
        subtitle: getText('orderCard.buyer'),
      },
      shopper: order.shopper
        ? {
            ...order.shopper,
            subtitle: getText('orderCard.shopper'),
          }
        : null,
      storeBrand: order.storeBrand || {},
      paymentMethod: !isOrderPaymentMethodCash(order) ? 'Card' : 'Cash',
    };

    const { actions, actionInProgress } = buildOrderViewActions({
      order,
      getText,
      orderListCategory,
      removingOps,
      setStepOps,
    });

    let user2show = orderDetails.buyer;

    if (order.isMine && order.amIShopper) {
      user2show = {
        userRating: orderDetails.buyer.userRating,
        user: {
          avatarUrl: orderDetails.buyer.user.avatarUrl,
        },
        l10n: {
          displayName: getText('orderCard.youAre'),
        },
        subtitle: getText('orderCard.shopperAndBuyer'),
      };
    } else if (order.isMine) {
      user2show = orderDetails.shopper;
      if (!user2show) {
        user2show = {
          userRating: orderDetails.buyer.userRating,
          user: {
            avatarUrl: orderDetails.buyer.user.avatarUrl,
          },
          l10n: {
            displayName: getText('orderCard.youAre'),
          },
          subtitle: getText('orderCard.buyer'),
        };
      }
    } else if (order.amIShopper) {
      user2show = orderDetails.buyer;
    }

    return { orderDetails, actions, user2show, actionInProgress };
  }, [order, getText, orderListCategory, removingOps, setStepOps]);

  if (!order) {
    return null;
  }

  // logger.log(`OrderCard:`, {
  //   orderDetails,
  //   actions,
  //   user2show,
  //   actionInProgress,
  // });

  return (
    <OrderCardWrapper width={width}>
      {!!user2show && (
        <OrderCardBlock>
          <Skeleton loading={isLoading} containerStyles={containerStyles}>
            <ItemsGroup>
              <UserBadge appUser={user2show} />
              {!order.isMine && (
                <Button
                  icon='Message'
                  variant={Button.VARIANT_NAMES.SECONDARY}
                  dimension={Button.DIMENSION_NAMES.SMALL}
                  onClick={() => {
                    setChannelKey(`order-${order.id}`);
                  }}
                />
              )}
            </ItemsGroup>
          </Skeleton>
        </OrderCardBlock>
      )}
      {(orderListCategory === ORDER_LIST_CATEGORIES.IN_PROGRESS ||
        isComplete) && (
        <OrderCardBlock>
          <Skeleton loading={isLoading} containerStyles={containerStyles}>
            <Row alignItems='center' justifyContent='space-between'>
              <Text bold>{getText(`orderStatuses.${order.status}`)}</Text>
              <Text variant={Text.VARIANT_NAMES.SUCCESS}>
                {getText(`orderSteps.${order.currentStepId}`)}
              </Text>
            </Row>
          </Skeleton>
        </OrderCardBlock>
      )}
      <OrderCardBlock>
        <Skeleton loading={isLoading} containerStyles={containerStyles}>
          <Text bold mb={2}>
            {orderItems.length} {getText('orderCard.positions')}
          </Text>
        </Skeleton>
        {orderItems.length > 0 && (
          <Fragment>
            <Skeleton loading={isLoading} containerStyles={containerStyles}>
              <Text dimension='small'>
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {mainImages.map((orderItem) => orderItem.name).join(', ')}
                </div>
              </Text>
            </Skeleton>
            <Skeleton loading={false} containerStyles={containerStyles}>
              <OrderCardImages
                imageHolderRef={imageHolderRef}
                imageSize={imageSize}
                imagesToShow={imagesToShow}
                isLoading={isLoading}
                orderItems={orderItems}
                mainImages={mainImages}
              />
            </Skeleton>
          </Fragment>
        )}
      </OrderCardBlock>
      <OrderCardBlock>
        <Text bold mb={2}>
          {getText('orderCard.details')}
        </Text>
        <Skeleton loading={isLoading} containerStyles={containerStyles}>
          <PropsViewer
            config={config}
            data={orderDetails}
            propNameWidth={'150px'}
            propNameMinWidth={'150px'}
            propNameMaxWidth={'150px'}
            withBorder={false}
            withInnerBorder
          />
        </Skeleton>
      </OrderCardBlock>
      {actions.length > 0 && (
        <OrderCardBlock>
          <Skeleton loading={isLoading} containerStyles={containerStyles}>
            <Actions
              actions={actions}
              onClick={(action) => onClickAction(action, order)}
              actionInProgress={actionInProgress}
            />
          </Skeleton>
        </OrderCardBlock>
      )}
    </OrderCardWrapper>
  );
});

OrderCard.displayName = 'OrderCard';

OrderCard.propTypes = {
  order: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  orderListCategory: PropTypes.oneOf(Object.values(ORDER_LIST_CATEGORIES)),
  onClickAction: PropTypes.func,
  width: PropTypes.any,
  removingOps: PropTypes.any,
};

export { OrderCard };
