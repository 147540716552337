import { useState, useCallback } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';
import {
  handleApiResult,
  OperationManager,
  OPERATIONS,
  useAppMutation,
  useAppQuery,
} from '../../utils';
import { arrayOfObjectsToArray } from '../../utils/transformers';
import { userSubscribeListItemQueries } from '../queries/userSubscribeListItemQueries';

const useApiUserSubscribeListItem = () => {
  const [userSubscribeListItem, setUserSubscribeListItem] = useState(null);
  const [userSubscribeListItems, setUserSubscribeListItems] = useState([]);
  const [userSubscribeListItemsOps, setUserSubscribeListItemsOps] = useState(
    {}
  );
  const [userSubscribeListItemsOpsManager] = useState(new OperationManager());

  userSubscribeListItemsOpsManager.setStateControls(
    userSubscribeListItemsOps,
    setUserSubscribeListItemsOps
  );

  const handleApiResultOpts = {
    hookName: 'useApiUserSubscribeListItem',
    opsManager: userSubscribeListItemsOpsManager,
    setItem: setUserSubscribeListItem,
    setItems: setUserSubscribeListItems,
  };

  /**
   * GRAPHQL CALLERS
   */

  const [mutationUserSubscribeListItemCreate] = useAppMutation({
    queries: userSubscribeListItemQueries,
    queryName: 'userSubscribeListItemCreate',
    onDone: handleApiResult(OPERATIONS.CREATE, handleApiResultOpts),
  });

  const [mutationUserSubscribeListItemUpdate] = useAppMutation({
    queries: userSubscribeListItemQueries,
    queryName: 'userSubscribeListItemUpdate',
    onDone: handleApiResult(OPERATIONS.UPDATE, handleApiResultOpts),
  });

  const [mutationUserSubscribeListItemRemoveMany] = useAppMutation({
    queries: userSubscribeListItemQueries,
    queryName: 'userSubscribeListItemRemoveMany',
    onDone: handleApiResult(OPERATIONS.REMOVE_MANY, handleApiResultOpts),
  });

  const [queryUserSubscribeListItemList] = useAppQuery({
    queries: userSubscribeListItemQueries,
    queryName: 'userSubscribeListItemList',
    onDone: handleApiResult(OPERATIONS.LIST, handleApiResultOpts),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const userSubscribeListItemCreate = useCallback(
    (variables) => {
      userSubscribeListItemsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.CREATE,
        OP_RESULT_CODES.SAVING
      );

      mutationUserSubscribeListItemCreate({ variables });
    },
    [mutationUserSubscribeListItemCreate, userSubscribeListItemsOpsManager]
  );

  const userSubscribeListItemUpdate = useCallback(
    (variables) => {
      userSubscribeListItemsOpsManager.setStartOperation(
        variables?.where?.id,
        OPERATIONS.UPDATE,
        OP_RESULT_CODES.SAVING
      );

      mutationUserSubscribeListItemUpdate({ variables });
    },
    [mutationUserSubscribeListItemUpdate, userSubscribeListItemsOpsManager]
  );

  const userSubscribeListItemRemoveMany = useCallback(
    (variables) => {
      userSubscribeListItemsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.REMOVE_MANY,
        OP_RESULT_CODES.DELETING
      );

      mutationUserSubscribeListItemRemoveMany({ variables });
    },
    [mutationUserSubscribeListItemRemoveMany, userSubscribeListItemsOpsManager]
  );

  const userSubscribeListItemList = useCallback(
    (variables) => {
      userSubscribeListItemsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING
      );

      queryUserSubscribeListItemList({ variables });
    },
    [queryUserSubscribeListItemList, userSubscribeListItemsOpsManager]
  );

  return {
    userSubscribeListItem,
    userSubscribeListItems,

    userSubscribeListItemCreate,
    userSubscribeListItemUpdate,
    userSubscribeListItemRemoveMany,
    userSubscribeListItemList,

    userSubscribeListItemsOpsManager,
  };
};

export { useApiUserSubscribeListItem };
