import axios from 'axios';
import qs from 'qs';
import dayjs from 'dayjs';
import { toCamelCase } from './commonApiHelpers';
import { OpResult } from '@sdflc/api-helpers';

const processResponse = (response) => {
  const result = new OpResult({
    code: response.data.Code,
    data: toCamelCase(response.data.Data),
  });

  if (typeof response.data.Errors === 'object') {
    Object.keys(response.data.Errors).forEach((key) => {
      const errors = response.data.Errors[key].errors ?? [];

      errors.forEach((error) => {
        result.addError(key, error);
      });
    });
  }

  return result;
};

const preprocessData = (formData) => {
  const result = {};
  Object.keys(formData || {}).forEach((key) => {
    let value = formData[key];

    if (value instanceof Date) {
      value = dayjs(value).format('YYYY-MM-DD');
    }

    result[key] = value;
  });

  return result;
};

export const submitForm = async (formId, formData) => {
  const response = await axios.post(
    `https://api.formsubmits.com/forms/${formId}/submit`,
    qs.stringify(preprocessData(formData)),
    {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    }
  );

  return processResponse(response);
};
