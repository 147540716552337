import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Panel, Caption } from '@sdflc/ui';
import { LocalizationContext } from 'contexts';

const TitledSection = memo((props) => {
  const { title, distanceFromTitle, children, hidden } = props;
  const { getText } = useContext(LocalizationContext);

  if (hidden) {
    return null;
  }

  return (
    <Panel mb={3}>
      <Caption level={6}>{getText(title)}</Caption>
      <Panel mt={distanceFromTitle}>{children}</Panel>
    </Panel>
  );
});

TitledSection.displayName = 'TitledSection';

TitledSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  distanceFromTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hidden: PropTypes.bool,
};

TitledSection.defaultProp = {
  distanceFromTitle: 1,
};

export { TitledSection };
