import React from 'react';
import PropTypes from 'prop-types';
import { ToasterProvider } from '@sdflc/ui';

//import { AuthProvider } from './AuthProvider';
import { DictionaryProvider } from './DictionaryProvider';
import { LocalizationProvider } from './LocalizationProvider';
import { StoreBrandProvider } from './StoreBrandProvider';
import { UserShoppingItemProvider } from './UserShoppingItemProvider';
import { TRulesProvider } from './TRulesProvider';
import { ContentProvider } from './ContentProvider';
import { UserLocationProvider } from './UserLocationProvider';
import { PaymentMethodProvider } from './PaymentMethodProvider';
import { CommentsProvider } from './CommentsProvider';
import { OrderInvitationProvider } from './OrderInvitationProvider';
import { UploadedFileProvider } from './UploadedFileProvider';
import { ImageViewerProvider } from './ImageViewerProvider';

const GlobalProvider = (props) => {
  const { children } = props;

  return (
    <ToasterProvider>
      {/* <AuthProvider> */}
      <TRulesProvider>
        <LocalizationProvider>
          <DictionaryProvider>
            <ContentProvider>
              <UserLocationProvider>
                <PaymentMethodProvider>
                  <StoreBrandProvider>
                    <OrderInvitationProvider>
                      <CommentsProvider>
                        <UserShoppingItemProvider>
                          <UploadedFileProvider>
                            <ImageViewerProvider>
                              {children}
                            </ImageViewerProvider>
                          </UploadedFileProvider>
                        </UserShoppingItemProvider>
                      </CommentsProvider>
                    </OrderInvitationProvider>
                  </StoreBrandProvider>
                </PaymentMethodProvider>
              </UserLocationProvider>
            </ContentProvider>
          </DictionaryProvider>
        </LocalizationProvider>
      </TRulesProvider>
      {/* </AuthProvider> */}
    </ToasterProvider>
  );
};

GlobalProvider.displayName = 'GlobalProvider';

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { GlobalProvider };
