import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';
import {
  Flex,
  ItemsGroup,
  Text,
  Row,
  Badge,
  Button,
  ConfirmationButton,
  Skeleton,
} from '@sdflc/ui';

import { OrderItemImage } from '../../OrderItemImage';

import { ACTIONS_BUTTONS } from '../../../config';

import { LocalizationContext } from '../../../contexts';

import {
  StyledOrderItemCardWrapper,
  StyledOrderItemCardBlock,
  StyledOrderItemName,
  StyledOrderItemComment,
} from './styled';
import { OpResult } from '@sdflc/api-helpers';

const BUTTONS = ACTIONS_BUTTONS({ withNames: false });

const UserShoppingItemItem = memo((props) => {
  const { item, onClickAction, loading, removingResult } = props;

  const { getText } = useContext(LocalizationContext);

  if (!item) {
    return null;
  }

  const handleClickAction = (action) => {
    if (check.function(onClickAction)) {
      onClickAction(action, item);
    } else if (!onClickAction) {
      console.warn(
        'The "UserShoppingItemItem" component expected the "onClickAction" to be a function but got:',
        onClickAction
      );
    }
  };

  const {
    shoppingItemName,
    onlyIfOnSaleIndicator,
    shoppingItemDescription,
    // manufacturer,
    // categoryId,
    unitId,
    quantity,
    // minPricePerUnit,
    // maxPricePerUnit,
    pictureUrl,
  } = item;

  const removingResult2use =
    removingResult instanceof OpResult ? removingResult : null;
  const error = removingResult2use?.getErrorSummary() ?? '';

  return (
    <StyledOrderItemCardWrapper>
      <StyledOrderItemCardBlock>
        <ItemsGroup>
          <Skeleton loading={loading}>
            <OrderItemImage name={shoppingItemName} pictureUrl={pictureUrl} />
          </Skeleton>

          <Flex flexDirection='column' flex='1 1' overflow='hidden'>
            <Skeleton loading={loading}>
              <StyledOrderItemName title={shoppingItemName}>
                {shoppingItemName}
              </StyledOrderItemName>
              <Text>
                {quantity} {unitId}
              </Text>
            </Skeleton>
          </Flex>

          <Flex ml='auto'>
            <Skeleton loading={loading}>
              <ItemsGroup>
                <Button
                  {...BUTTONS.EDIT}
                  dimension={Button.DIMENSION_NAMES.SMALL}
                  onClick={() => handleClickAction(BUTTONS.EDIT)}
                />
                <ConfirmationButton
                  {...BUTTONS.REMOVE}
                  dimension={Button.DIMENSION_NAMES.SMALL}
                  onConfirm={() => handleClickAction(BUTTONS.REMOVE)}
                  confirmName={''}
                  cancelName={''}
                  progress={removingResult2use?.isInProgress()}
                />
              </ItemsGroup>
            </Skeleton>
          </Flex>
        </ItemsGroup>
      </StyledOrderItemCardBlock>
      <StyledOrderItemCardBlock>
        <Row>
          <Flex flex='1 1' overflow='hidden'>
            <Skeleton loading={loading}>
              <StyledOrderItemComment>
                {shoppingItemDescription ||
                  getText('pages.order.details.noBuyerComment')}
              </StyledOrderItemComment>
            </Skeleton>
          </Flex>
          <Flex>
            {onlyIfOnSaleIndicator && (
              <Badge
                variant={Badge.VARIANT_NAMES.DANGER}
                dimension={Badge.DIMENSION_NAMES.SMALL}
              >
                ON SALE
              </Badge>
            )}
          </Flex>
          {!!error && <Text variant={Text.VARIANT_NAMES.DANGER}>{error}</Text>}
        </Row>
      </StyledOrderItemCardBlock>
    </StyledOrderItemCardWrapper>
  );
});

UserShoppingItemItem.displayName = 'UserShoppingItemItem';

UserShoppingItemItem.propTypes = {
  item: PropTypes.shape({
    shoppingItemName: PropTypes.string,
    manufacturer: PropTypes.string,
    shoppingItemDescription: PropTypes.string,
    categoryId: PropTypes.string,
    unitId: PropTypes.string,
    quantity: PropTypes.number,
    minPricePerUnit: PropTypes.number,
    maxPricePerUnit: PropTypes.number,
    pictureUrl: PropTypes.string,
  }),
  onClickAction: PropTypes.func,
  loading: PropTypes.bool,
};

UserShoppingItemItem.defaultProps = {
  item: null,
};

export { UserShoppingItemItem };
