import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { ToolBarItem } from '@sdflc/ui';

import {
  logger,
  OperationManager,
  OPERATIONS,
  onlyPropsOfObject,
} from '../../../utils';
import { ACTIONS_BUTTONS, ACTIONS_IDS } from 'config';
import { useUserContact } from '../../../graphql/hooks';
import { LocalizationContext } from '../../../contexts';

import { userContactForm } from './userContactForm';

const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const tplNewUserContact = {
  contactTypeId: 'email',
  contactValue: '',
  contactDescription: '',
  status: 100,
};

const useUserContacts = () => {
  const { getText } = useContext(LocalizationContext);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const {
    userContact,
    userContacts,
    userContactList,
    userContactCreate,
    userContactUpdate,
    userContactRemoveMany,
    userContactsOpsManager,
  } = useUserContact();

  const contactId = formData?.id || '';
  const createOp = userContactsOpsManager.getCreateOp();
  const updateOp = userContactsOpsManager.get(contactId, OPERATIONS.UPDATE);
  const saveOp = contactId ? updateOp : createOp;
  const saveOpResult = saveOp.result;
  const removingOps = userContactsOpsManager.getOpValues(
    OPERATIONS.REMOVE_MANY
  );

  useEffect(() => {
    setOpen(!!formData);
  }, [formData]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of new user shoping item

    // User contact item is not being saved yet
    if (!createOp.called) {
      return;
    }

    // User contact create operation result is not in progress and succeeded
    if (!createOp.result.isInProgress() && createOp.result.didSucceed()) {
      userContactsOpsManager.reset(
        OperationManager.defaultContext,
        OPERATIONS.CREATE
      );
      setFormData(null);
    }
  }, [createOp, userContactsOpsManager]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of exising contact

    // Contact is not being saved yet
    if (!updateOp.called) {
      return;
    }

    // contact operation result is not in progress and succeeded
    if (!updateOp.result.isInProgress() && updateOp.result.didSucceed()) {
      userContactsOpsManager.reset(contactId, OPERATIONS.UPDATE);
      setFormData(null);
    }
  }, [updateOp, userContactsOpsManager, contactId]);

  const handleClickAction = useCallback(
    async (action, item) => {
      switch (action.id) {
        default:
          logger.warn('The "useUserContacts" got an unexpected action:', {
            action,
            item,
          });
          break;

        case ACTIONS_IDS.ADD:
        case ACTIONS_IDS.CREATE:
          userContactsOpsManager.reset(
            OperationManager.defaultContext,
            OPERATIONS.CREATE
          );
          setFormData(cloneDeep(tplNewUserContact));
          break;

        case ACTIONS_IDS.SAVE:
          if (!item.id) {
            userContactCreate({ params: item });
          } else {
            userContactUpdate({
              where: { id: item.id },
              params: onlyPropsOfObject(item, tplNewUserContact),
            });
          }
          break;

        case ACTIONS_IDS.CANCEL:
          setFormData(null);
          break;

        case ACTIONS_IDS.EDIT:
          userContactsOpsManager.reset(item?.id, OPERATIONS.UPDATE);
          setFormData(cloneDeep(item));
          break;

        case ACTIONS_IDS.REMOVE:
          userContactRemoveMany({ where: [{ id: item.id }] });
          break;

        case ACTIONS_IDS.MAKE_DEFAULT:
          userContactUpdate({
            where: { id: item.id },
            params: {
              isDefault: true,
            },
          });
          break;
      }
    },
    [
      userContactCreate,
      userContactUpdate,
      userContactRemoveMany,
      userContactsOpsManager,
    ]
  );

  const handleClickClose = useCallback(() => {
    handleClickAction({ id: ACTIONS_IDS.CANCEL }, null);
  }, [handleClickAction]);

  const formCfg = useMemo(() => {
    return {
      sections: userContactForm,
      labels: getText('pages.userContacts.form.labels'),
      hints: getText('pages.userContacts.form.hints'),
    };
  }, [getText]);

  const formActions = useMemo(() => [BUTTONS.SAVE, BUTTONS.CANCEL], []);
  const actionInProgress = useMemo(
    () => (saveOpResult.isInProgress() ? [ACTIONS_IDS.SAVE] : []),
    [saveOpResult]
  );

  const toolBarItems = useMemo(() => {
    return [
      // {
      //   id: 'keyword',
      //   type: ToolBarItem.types.COMPONENT,
      //   name: 'keyword',
      //   value: filters.keyword,
      //   component: Input,
      //   placeholder: getText('generic.search'),
      //   leftIcon: 'Search',
      // },
      { ...BUTTONS.ADD, type: ToolBarItem.types.BUTTON },
    ];
  }, []);

  return {
    open,
    userContact,
    userContacts,
    userContactList,
    formData,
    formCfg,
    formActions,
    setFormData,
    handleClickAction,
    handleClickClose,
    userContactsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOp,
    saveOpResult,
    removingOps,
  };
};

export { useUserContacts };
