import React from 'react';
import PropTypes from 'prop-types';
import { OrderInvitationContext } from '../contexts';
import { useOrderInvitation } from '../graphql/hooks';

const OrderInvitationProvider = (props) => {
  const { children } = props;
  const hooks = useOrderInvitation();

  return (
    <OrderInvitationContext.Provider value={hooks}>
      {children}
    </OrderInvitationContext.Provider>
  );
};

OrderInvitationProvider.displayName = 'OrderInvitationProvider';

OrderInvitationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { OrderInvitationProvider };
