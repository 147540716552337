import { gql } from '@apollo/client';

const USER_CONTACT_LIST = gql`
  query UserContactList(
    $filter: UserContactFilter
    $params: PaginationAndSorting
  ) {
    userContactList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        contactTypeId
        contactValue
        contactDescription
        isDefault
        status
        version
        createdAt
        updatedAt
      }
    }
  }
`;

const USER_CONTACT_GET_MANY = gql`
  query UserContactGetMany($ids: [ID]) {
    userContactGetMany(ids: $ids) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        contactTypeId
        contactValue
        contactDescription
        isDefault
        status
        version
        createdAt
        updatedAt
      }
    }
  }
`;

const USER_CONTACT_CREATE = gql`
  mutation UserContactCreate($params: UserContactInput) {
    userContactCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        contactTypeId
        contactValue
        contactDescription
        isDefault
        status
        version
        createdAt
        updatedAt
      }
    }
  }
`;

const USER_CONTACT_UPDATE = gql`
  mutation UserContactUpdate(
    $where: UserContactWhereInput
    $params: UserContactInput
  ) {
    userContactUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        contactTypeId
        contactValue
        contactDescription
        isDefault
        status
        version
        createdAt
        updatedAt
      }
    }
  }
`;

const USER_CONTACT_REMOVE_MANY = gql`
  mutation UserContactRemoveMany($where: [UserContactWhereInput]) {
    userContactRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const userContactQueries = {
  userContactList: USER_CONTACT_LIST,
  userContactGetMany: USER_CONTACT_GET_MANY,
  userContactCreate: USER_CONTACT_CREATE,
  userContactUpdate: USER_CONTACT_UPDATE,
  userContactRemoveMany: USER_CONTACT_REMOVE_MANY,
};

export { userContactQueries };
