import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Actions, Button, Flex, ItemsGroup, ValueCurrency } from '@sdflc/ui';
import check from 'check-types';

import { ACTIONS_IDS, EDIT_REMOVE_ACTIONS } from 'config';
import { ReceiptImage } from 'components/ReceiptImage';

const OrderReceiptListItem = memo((props) => {
  const { value, /*shopMode,*/ readOnly, onActionClick, orderReceiptRemoveOp } =
    props;
  const { amount, receiptFileId /*, storeBrandId*/ } = value || {};

  const handleActionClick = useCallback(
    (action) => {
      if (check.function(onActionClick)) {
        onActionClick(action, value);
      }
    },
    [value, onActionClick]
  );

  const actionInProgress = useMemo(() => {
    return orderReceiptRemoveOp.result.isInProgress() ? ACTIONS_IDS.REMOVE : '';
  }, [orderReceiptRemoveOp]);

  if (!value) {
    return null;
  }

  return (
    <ItemsGroup direction='row' distance={0} width='100%'>
      <Flex>
        <ReceiptImage receiptFileId={receiptFileId} />
      </Flex>
      <Flex flex='1 1' pl={1} pr={1}>
        <ValueCurrency value={amount} currencyCode={'CAD'} />
      </Flex>
      {!readOnly && (
        <Actions
          actions={EDIT_REMOVE_ACTIONS}
          onClick={handleActionClick}
          dimension={Button.DIMENSION_NAMES.SMALL}
          actionInProgress={actionInProgress}
        />
      )}
    </ItemsGroup>
  );
});

OrderReceiptListItem.displayName = 'OrderReceiptListItem';
OrderReceiptListItem.propTypes = {
  value: PropTypes.any,
  shopMode: PropTypes.bool,
  readOnly: PropTypes.bool,
  onActionClick: PropTypes.func,
};
OrderReceiptListItem.defaultProp = {};

export { OrderReceiptListItem };
