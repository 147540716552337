import React, {
  Fragment,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { OpResult } from '@sdflc/api-helpers';

import {
  ItemsGroup,
  Panel,
  Text,
  Button,
  FormDrawer,
  useToaster,
} from '@sdflc/ui';
import { Logo } from 'components/Logo';
import { colors, appSettings, ACTIONS_BUTTONS, ACTIONS_IDS } from 'config';
import { AuthContext, LocalizationContext } from 'contexts';
import { logger } from 'utils';

import { contactUsForm } from './contactUsForm';
import { submitForm } from 'utils/formSubmitsHelpers';
import pckg from '../../../package.json';

const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const EMPTY_FORM = {
  subject: '',
  message: '',
};

const AppFooter = memo(() => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ ...EMPTY_FORM });
  const [submitResult, setSubmitResult] = useState(new OpResult());
  const { getText } = useContext(LocalizationContext);
  const { appUser } = useContext(AuthContext);
  const toast = useToaster();

  const handleClickContactUs = useCallback(() => {
    setFormData({ ...EMPTY_FORM });
    setOpen(true);
  }, []);

  const formCfg = useMemo(() => {
    return {
      sections: contactUsForm,
      labels: getText('footer.contactUs.labels'),
      hints: getText('footer.contactUs.hints'),
      placeholders: getText('footer.contactUs.placeholders'),
    };
  }, [getText]);

  const handleClickAction = useCallback(
    async (action, item) => {
      switch (action.id) {
        default:
          logger.log(`The "ContactUs" form received unknown action:`, {
            action,
            item,
          });
          break;

        case ACTIONS_IDS.SAVE:
          logger.debug(
            `Submitting the data to the form '${appSettings.contactUsFormId}':`,
            item
          );
          setSubmitResult(new OpResult().startSaving());
          const result = await submitForm(appSettings.contactUsFormId, {
            ...item,
            name: appUser?.l10n?.displayName || 'N/A',
            username: appUser?.user?.username || 'N/A',
            email: appUser?.user?.email || 'N/A',
          });

          setSubmitResult(result);
          if (result.didSucceed()) {
            setOpen(false);
            toast({
              message:
                result.data[0]?.thankYouMessage || 'Thank you for the message',
              variant: 'success',
              timeout: 30,
            });
          }
          break;

        case ACTIONS_IDS.CANCEL:
          setOpen(false);
          break;
      }
    },
    [appUser, toast]
  );

  return (
    <Fragment>
      <Panel
        mt={3}
        paddingTop={4}
        paddingBottom={[90, 90, 4, 4]}
        paddingLeft={2}
        paddingRight={2}
        backgroundColor={colors.white}
        borderLeft={`solid 2px rgb(0 0 0 / 14%)`}
      >
        <ItemsGroup>
          <Logo />
          <Text dimension={Text.DIMENSION_NAMES.SMALL} color={colors.grey07}>
            BETA {pckg.version}, &copy; 2022 - {new Date().getFullYear()}
          </Text>
          <Panel ml='auto'>
            <Button
              variant={Button.VARIANT_NAMES.PRIMARY}
              name={getText('generic.contactUs')}
              icon='ContactSupport'
              onClick={handleClickContactUs}
            />
          </Panel>
        </ItemsGroup>
      </Panel>
      <FormDrawer
        isOpen={open}
        title={getText('footer.contactUs.title')}
        description={getText('footer.contactUs.description')}
        formData={formData}
        formCfg={formCfg}
        onClose={() => setOpen(false)}
        actions={[BUTTONS.SAVE, BUTTONS.CANCEL]}
        actionInProgress={submitResult.isInProgress() ? ACTIONS_IDS.SAVE : ''}
        onClickAction={handleClickAction}
        progress={submitResult.isInProgress()}
        opResult={submitResult}
      />
    </Fragment>
  );
});

AppFooter.displayName = 'AppFooter';

AppFooter.propTypes = {
  withName: PropTypes.bool,
};

AppFooter.defaultProps = {
  withName: true,
};

export { AppFooter };
