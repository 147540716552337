import { gql } from '@apollo/client';

const ORDER_RECEIPT_LIST = gql`
  query OrderReceiptList(
    $filter: OrderReceiptFilter
    $params: PaginationAndSorting
  ) {
    orderReceiptList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderId
        storeBrandId
        receiptFileId
        amount
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

const ORDER_RECEIPT_GET_MANY = gql`
  query OrderReceiptGetMany($ids: [ID]) {
    orderReceiptCreateMany(ids: $ids) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderId
        storeBrandId
        receiptFileId
        amount
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

const ORDER_RECEIPT_CREATE = gql`
  mutation OrderReceiptCreate($params: OrderReceiptInput) {
    orderReceiptCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderId
        storeBrandId
        receiptFileId
        amount
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

const ORDER_RECEIPT_UPDATE = gql`
  mutation OrderReceiptUpdate(
    $where: OrderReceiptWhereInput
    $params: OrderReceiptInput
  ) {
    orderReceiptUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderId
        storeBrandId
        receiptFileId
        amount
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

const ORDER_RECEIPT_REMOVE_MANY = gql`
  mutation OrderReceiptRemoveMany($where: [OrderReceiptWhereInput]) {
    orderReceiptRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const orderReceiptQueries = {
  orderReceiptList: ORDER_RECEIPT_LIST,
  orderReceiptGetMany: ORDER_RECEIPT_GET_MANY,
  orderReceiptCreate: ORDER_RECEIPT_CREATE,
  orderReceiptUpdate: ORDER_RECEIPT_UPDATE,
  orderReceiptRemoveMany: ORDER_RECEIPT_REMOVE_MANY,
};

export { orderReceiptQueries };
