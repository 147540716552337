import { useState, useCallback } from 'react';
import { OpResult } from '@sdflc/api-helpers';
import { logger, OPERATIONS, useAppMutation, useAppQuery } from '../../utils';
import { orderInvitationQueries } from '../queries/orderInvitationQueries';

const useOrderInvitation = () => {
  const [orderInvitation, setOrderInvitation] = useState(new OpResult());
  const [orderInvitations, setOrderInvitations] = useState(new OpResult());

  const handleDone = (operation) => (result) => {
    switch (operation) {
      default:
        logger.warn(
          'The "useOrderInvitation" hook got unexpected value of operation: ',
          operation
        );
        break;

      case OPERATIONS.LIST:
        setOrderInvitations(result);
        break;

      case OPERATIONS.GET_MANY:
        setOrderInvitations(result);
        break;

      case OPERATIONS.CREATE:
        setOrderInvitation(result);
        const newItem = result.getDataFirst();

        if (result.didSucceed()) {
          setOrderInvitations(
            new OpResult().setData([newItem, ...orderInvitations.getData()])
          );
        }
        break;

      case OPERATIONS.UPDATE:
        setOrderInvitation(result);

        if (result.didSucceed()) {
          const updItem = result.getDataFirst();

          setOrderInvitations(
            new OpResult().setData([
              ...orderInvitations.getData().map((item) => {
                if (updItem.id === item.id) {
                  return updItem;
                }
                return item;
              }),
            ])
          );
        }
        break;

      case OPERATIONS.REMOVE_MANY:
        setOrderInvitation(result);
        if (result.didSucceed()) {
          const ids = result.getData().map((item) => item.id);
          setOrderInvitations(
            new OpResult().setData([
              ...orderInvitations
                .getData()
                .filter((item) => ids.indexOf(item.id) === -1),
            ])
          );
        }
        break;
    }
  };

  /**
   * GRAPHQL CALLERS
   */

  const [
    mutationOrderInvitationCreate,
    {
      called: orderInvitationCreateCalled,
      loading: orderInvitationCreateLoading,
      reset: orderInvitationCreateReset,
    },
  ] = useAppMutation({
    queries: orderInvitationQueries,
    queryName: 'orderInvitationCreate',
    onDone: handleDone(OPERATIONS.CREATE),
  });

  const [
    mutationOrderInvitationUpdate,
    {
      called: orderInvitationUpdateCalled,
      loading: orderInvitationUpdateLoading,
      reset: orderInvitationUpdateReset,
    },
  ] = useAppMutation({
    queries: orderInvitationQueries,
    queryName: 'orderInvitationUpdate',
    onDone: handleDone(OPERATIONS.UPDATE),
  });

  const [
    mutationOrderInvitationRemoveMany,
    {
      called: orderInvitationRemoveManyCalled,
      loading: orderInvitationRemoveManyLoading,
      reset: orderInvitationRemoveManyReset,
    },
  ] = useAppMutation({
    queries: orderInvitationQueries,
    queryName: 'orderInvitationRemoveMany',
    onDone: handleDone(OPERATIONS.REMOVE_MANY),
  });

  const [
    queryOrderInvitationList,
    {
      called: orderInvitationListCalled,
      loading: orderInvitationListLoading,
      reset: orderInvitationListReset,
    },
  ] = useAppQuery({
    queries: orderInvitationQueries,
    queryName: 'orderInvitationList',
    onDone: handleDone(OPERATIONS.LIST),
  });

  const [
    queryOrderInvitationGetMany,
    {
      called: orderInvitationGetManyCalled,
      loading: orderInvitationGetManyLoading,
      reset: orderInvitationGetManyReset,
    },
  ] = useAppQuery({
    queries: orderInvitationQueries,
    queryName: 'orderInvitationGetMany',
    onDone: handleDone(OPERATIONS.GET_MANY),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const orderInvitationCreate = useCallback(
    (variables) => {
      setOrderInvitation(orderInvitation.clearErrors().startLoading().clone());
      mutationOrderInvitationCreate({ variables });
    },
    [mutationOrderInvitationCreate, orderInvitation]
  );

  const orderInvitationUpdate = useCallback(
    (variables) => {
      setOrderInvitation(orderInvitation.clearErrors().startLoading().clone());
      if (variables?.params?.status != null) {
        variables.params.status = Number(variables?.params?.status);
      }
      mutationOrderInvitationUpdate({ variables });
    },
    [mutationOrderInvitationUpdate, orderInvitation]
  );

  const orderInvitationRemoveMany = useCallback(
    (variables) => {
      setOrderInvitation(orderInvitation.clearErrors().startLoading().clone());
      mutationOrderInvitationRemoveMany({ variables });
    },
    [mutationOrderInvitationRemoveMany, orderInvitation]
  );

  const orderInvitationList = useCallback(
    (variables) => {
      setOrderInvitations(
        orderInvitations.clearErrors().startLoading().clone()
      );
      queryOrderInvitationList({ variables });
    },
    [queryOrderInvitationList, orderInvitations]
  );

  const orderInvitationGetMany = useCallback(
    (variables) => {
      setOrderInvitations(orderInvitation.clearErrors().startLoading().clone());
      queryOrderInvitationGetMany({ variables });
    },
    [queryOrderInvitationGetMany, orderInvitation]
  );

  return {
    orderInvitation,
    orderInvitations,
    orderInvitationCreate,
    orderInvitationCreateCalled,
    orderInvitationCreateLoading,
    orderInvitationCreateReset,
    orderInvitationUpdate,
    orderInvitationUpdateCalled,
    orderInvitationUpdateLoading,
    orderInvitationUpdateReset,
    orderInvitationRemoveMany,
    orderInvitationRemoveManyCalled,
    orderInvitationRemoveManyLoading,
    orderInvitationRemoveManyReset,
    orderInvitationList,
    orderInvitationListCalled,
    orderInvitationListLoading,
    orderInvitationListReset,
    orderInvitationGetMany,
    orderInvitationGetManyCalled,
    orderInvitationGetManyLoading,
    orderInvitationGetManyReset,
  };
};

export { useOrderInvitation };
