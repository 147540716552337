import { memo, useState, useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  EmptyLayout,
  AppPageDrawer,
  PageDrawerBlock,
  Panel,
  Flex,
  FlexFullHeightColumn,
  HiddenBelow,
  VisibleBelow,
  Button,
  RichText,
  ToasterContainer,
  HiddenAbove,
  Caption,
  Header,
  HeaderItem,
  ImageViewer,
} from '@sdflc/ui';

import {
  AppFooter,
  /*AppHeader,*/
  PageLoading,
  NavigationDrawer,
  CommentsDrawer,
} from 'components';

import {
  AuthContext,
  CommentsContext,
  ContentContext,
  DictionaryContext,
  ImageViewerContext,
  LocalizationContext,
} from '../../contexts';

import { AppPagesProvider } from '../../providers';
import { colors } from 'config';

const MainLayout = memo((props) => {
  const { children, page } = props;
  const history = useHistory();
  const { getText, pageTitle } = useContext(LocalizationContext);
  const { appUser, appUserLangGet } = useContext(AuthContext);
  const { dictionary, dictionaryItemList } = useContext(DictionaryContext);
  const { contentItemList, content } = useContext(ContentContext);
  const { channelKey, setChannelKey } = useContext(CommentsContext);
  const { resetViewImageIdx, viewImages, viewImageIdx } =
    useContext(ImageViewerContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);

  const handleClickToggeMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const handleClickGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleHelpClick = useCallback(() => {
    setHelpOpen(!helpOpen);
  }, [helpOpen]);

  useEffect(() => {
    if (!appUser) {
      return;
    }

    if (dictionary.getData().length) {
      return;
    }

    // Load all dictionaries on page load:
    // TODO: Late update SelectDictionaryItem and other related components to load data in lazy mode
    dictionaryItemList(
      {
        filter: {
          dictionaryUid: [],
        },
      },
      dictionary
    );
  }, [appUser]); // eslint-disable-line

  useEffect(() => {
    if (page.options?.helpContent) {
      contentItemList({
        filter: {
          itemId: page.options?.helpContent,
        },
        lang: appUserLangGet(),
      });
    }
  }, [page, contentItemList, appUserLangGet]);

  if (!appUser) {
    return <PageLoading />;
  }

  const helpText = content.getDataFieldValue(page.options?.helpContent);

  return (
    <AppPagesProvider>
      <EmptyLayout asFlex notScrollable height='100%'>
        <Flex
          width='100%'
          height='100%'
          flex='1 1'
          flexDirection='column'
          overflow='hidden'
        >
          {/* <AppHeader
            page={page}
            siteMap={siteMap}
            onMenuToggle={handleClickToggeMenu}
          /> */}
          <FlexFullHeightColumn overflow='hidden'>
            <Flex
              flexDirection='row'
              alignItems='flex-start'
              flex='1 1'
              height='100%'
            >
              <Flex alignSelf='stretch'>
                <HiddenBelow breakpoint='lg'>
                  <NavigationDrawer
                    isOpen={true}
                    //onClose={handleClickToggeMenu}
                  />
                </HiddenBelow>
                <HiddenAbove breakpoint='md'>
                  <NavigationDrawer
                    isTemporary
                    isOpen={menuOpen}
                    onClose={handleClickToggeMenu}
                  />
                </HiddenAbove>
              </Flex>
              <Flex
                flex='1 1'
                height='100%'
                overflow='hidden'
                flexDirection='column'
              >
                <Header>
                  {page?.options?.goBack ? (
                    <HeaderItem>
                      <Button
                        icon='ArrowBack'
                        variant='tertiary'
                        onClick={handleClickGoBack}
                      />
                    </HeaderItem>
                  ) : (
                    <VisibleBelow breakpoint='lg'>
                      <HeaderItem>
                        <Button
                          icon='Menu'
                          variant='tertiary'
                          onClick={handleClickToggeMenu}
                          hidden={menuOpen}
                        />
                      </HeaderItem>
                    </VisibleBelow>
                  )}
                  <HeaderItem>
                    <Caption level={3} noMargin>
                      {pageTitle || getText(page.name)}
                    </Caption>
                  </HeaderItem>
                  {!!page.options?.helpContent && !!helpText?.value && (
                    <HeaderItem marginLeft='auto'>
                      <Button
                        variant='tertiary'
                        name={getText('generic.help')}
                        icon='HelpCenter'
                        onClick={handleHelpClick}
                      />
                    </HeaderItem>
                  )}
                </Header>
                <Flex
                  flex='1 1'
                  height='100%'
                  overflow='auto'
                  flexDirection='column'
                >
                  <Flex flex='1 1' width='100%' flexDirection='column'>
                    {/* {!!page.options?.helpContent && !!helpText && (
                      <VisibleBelow breakpoint='lg'>
                        <Panel textAlign='right'>
                          <Button
                            variant='tertiary'
                            name={getText('generic.help')}
                            icon='HelpCenter'
                            onClick={handleHelpClick}
                          />
                        </Panel>
                      </VisibleBelow>
                    )} */}
                    <Panel padding={3} width='100%' height='100%'>
                      {children}
                    </Panel>
                  </Flex>
                  <AppFooter />
                </Flex>
              </Flex>
            </Flex>
          </FlexFullHeightColumn>
        </Flex>
        <AppPageDrawer
          isOpen={helpOpen}
          width='450px'
          maxWidth='100%'
          onClose={() => setHelpOpen(false)}
          title={getText('generic.help')}
        >
          {({ data, setData }) => {
            return (
              <PageDrawerBlock
                backgroundColor={colors.white}
                height='100%'
                padding={2}
              >
                <RichText text={helpText?.value} />
              </PageDrawerBlock>
            );
          }}
        </AppPageDrawer>
        <CommentsDrawer
          channelKey={channelKey}
          onClose={() => setChannelKey('')}
        />
        <ToasterContainer position='RIGHT_BOTTOM' />
        <ImageViewer
          isOpen={viewImageIdx > -1}
          startImageIdx={viewImageIdx}
          images={viewImages}
          onClose={resetViewImageIdx}
        />
      </EmptyLayout>
    </AppPagesProvider>
  );
});

MainLayout.displayName = 'MainLayout';

MainLayout.propTypes = {
  children: PropTypes.node,
};

MainLayout.defaultProps = {};

export { MainLayout };
