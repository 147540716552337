import { gql } from '@apollo/client';

const USER_SHOPPING_ITEM_LIST = gql`
  query UserShoppingItemList(
    $filter: UserShoppingItemFilter
    $params: PaginationAndSorting
  ) {
    userShoppingItemList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        categoryId
        shoppingItemName
        shoppingItemDescription
        manufacturer
        pictureUrl
        quantity
        unitId
        minPricePerUnit
        maxPricePerUnit
        createdAt
        createdBy
        updatedAt
        updatedBy
        userCreated {
          username
        }
        userUpdated {
          username
        }
      }
    }
  }
`;

const USER_SHOPPING_ITEM_GET_MANY = gql`
  query UserShoppingItemGetMany($ids: [ID]) {
    userShoppingItemGetMany(ids: $ids) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        categoryId
        shoppingItemName
        shoppingItemDescription
        manufacturer
        pictureUrl
        quantity
        unitId
        minPricePerUnit
        maxPricePerUnit
        createdAt
        createdBy
        updatedAt
        updatedBy
        userCreated {
          username
        }
        userUpdated {
          username
        }
      }
    }
  }
`;

const USER_SHOPPING_ITEM_CREATE = gql`
  mutation UserShoppingItemCreate($params: UserShoppingItemInput) {
    userShoppingItemCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        categoryId
        shoppingItemName
        shoppingItemDescription
        manufacturer
        pictureUrl
        quantity
        unitId
        minPricePerUnit
        maxPricePerUnit
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

const USER_SHOPPING_ITEM_UPDATE = gql`
  mutation UserShoppingItemUpdate(
    $params: UserShoppingItemInput
    $where: UserShoppingItemWhereInput
  ) {
    userShoppingItemUpdate(params: $params, where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        categoryId
        shoppingItemName
        shoppingItemDescription
        manufacturer
        pictureUrl
        quantity
        unitId
        minPricePerUnit
        maxPricePerUnit
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

const USER_SHOPPING_ITEM_REMOVE_MANY = gql`
  mutation UserShoppingItemRemoveMany($where: [UserShoppingItemWhereInput]) {
    userShoppingItemRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const userShoppingItemQueries = {
  userShoppingItemList: USER_SHOPPING_ITEM_LIST,
  userShoppingItemGetMany: USER_SHOPPING_ITEM_GET_MANY,
  userShoppingItemCreate: USER_SHOPPING_ITEM_CREATE,
  userShoppingItemUpdate: USER_SHOPPING_ITEM_UPDATE,
  userShoppingItemRemoveMany: USER_SHOPPING_ITEM_REMOVE_MANY,
};

export { userShoppingItemQueries };
