import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormFields,
  FormState,
  Grid,
  GridItem,
  FormInput,
  Input,
  Button,
  ErrorText,
} from '@sdflc/ui';

import { Loading } from '../../Loading';
import { LocalizationContext } from '../../../contexts';
import { Block } from 'components';

import { useUserNameEditor } from './useUserName';

const UserNameEditor = memo((props) => {
  const { hasTitle } = props;
  const { getText } = useContext(LocalizationContext);
  const { userNames, appUserL10Ns, handleSubmit, submitResult, submitLoading } =
    useUserNameEditor();

  const labels = getText('pages.userNameEditor.form.labels');
  const hints = getText('pages.userNameEditor.form.hints');
  const placeholders = getText('pages.userNameEditor.form.placeholders');

  return (
    <Block title={hasTitle ? getText('pages.userName.title') : ''}>
      {appUserL10Ns.isLoading() ? (
        <Loading />
      ) : (
        <FormState data={userNames[0]} onSubmit={handleSubmit}>
          {({ data, handleChange, submit }) => {
            return (
              <Form>
                <FormFields>
                  <Grid>
                    {/* <GridItem width={['25%', '25%', '10%', '10%']}>
                      <FormInput
                        labelData={labels}
                        hintData={hints}
                        placeholderData={placeholders}
                        data={data}
                        fieldName='lang'
                        onChange={handleChange}
                        opResult={submitResult}
                        readOnly
                      />
                    </GridItem> */}
                    <GridItem width={['75%', '75%', '90%', '90%']}>
                      <FormInput
                        labelData={labels}
                        hintData={hints}
                        placeholderData={placeholders}
                        data={data}
                        fieldName='displayName'
                        onChange={handleChange}
                        opResult={submitResult}
                        inputComponent={Input}
                      />
                    </GridItem>
                    {submitResult.hasErrors() && (
                      <GridItem>
                        <ErrorText>
                          {submitResult.getErrorSummary('')}
                        </ErrorText>
                      </GridItem>
                    )}
                    <GridItem width={['100%']}>
                      <Button
                        name={getText('buttons.save')}
                        icon='Check'
                        onClick={submit}
                        progress={submitLoading}
                      />
                    </GridItem>
                  </Grid>
                </FormFields>
              </Form>
            );
          }}
        </FormState>
      )}
    </Block>
  );
});

UserNameEditor.displayName = 'UserNameEditor';

UserNameEditor.propTypes = {
  user: PropTypes.shape({}),
  onDataChange: PropTypes.func,
  opResult: PropTypes.any,
  hasTitle: PropTypes.bool,
};

UserNameEditor.defaultProps = {
  contact: null,
  hasTitle: false,
};

export { UserNameEditor };
