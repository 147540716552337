import { memo } from 'react';
import PropTypes from 'prop-types';

import { StyledPanel } from './styled';

const ItemTile = memo((props) => {
  const { children } = props;

  return (
    <StyledPanel mb={2} padding='1' shadow='0 0 8px 0px rgb(0 0 0 / 14%)'>
      {children}
    </StyledPanel>
  );
});

ItemTile.displayName = 'ItemTile';

ItemTile.propTypes = {
  children: PropTypes.node,
};

ItemTile.defaultProps = {};

export { ItemTile };
