import { gql } from '@apollo/client';

const USER_LOCATION_LIST = gql`
  query UserLocationList(
    $filter: UserLocationFilter
    $params: PaginationAndSorting
  ) {
    userLocationList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderNo
        locationId
        locationTypeId
        notes
        isDefault
        createdAt
        updatedAt
        location {
          id
          countryId
          postalCode
          address2
          latitude
          longitude
          l10ns {
            id
            locationId
            lang
            address1
            address2Name
            city
            state
          }
        }
      }
    }
  }
`;

const USER_LOCATION_GET_MANY = gql`
  query UserLocationGetMany($ids: [ID]) {
    userLocationGetMany(ids: $ids) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderNo
        locationId
        locationTypeId
        notes
        isDefault
        createdAt
        updatedAt
        location {
          id
          countryId
          postalCode
          address2
          latitude
          longitude
          l10ns {
            id
            locationId
            lang
            address1
            address2Name
            city
            state
          }
        }
      }
    }
  }
`;

const USER_LOCATION_SET = gql`
  mutation UserLocationSet($params: [UserLocationInput]) {
    userLocationSet(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderNo
        locationId
        locationTypeId
        notes
        isDefault
        createdAt
        updatedAt
        location {
          id
          countryId
          postalCode
          address2
          latitude
          longitude
          l10ns {
            id
            locationId
            lang
            address1
            address2Name
            city
            state
          }
        }
      }
    }
  }
`;

const USER_LOCATION_CREATE = gql`
  mutation UserLocationCreate($params: UserLocationInput) {
    userLocationCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderNo
        locationId
        locationTypeId
        notes
        isDefault
        createdAt
        updatedAt
        location {
          id
          countryId
          postalCode
          address2
          latitude
          longitude
          l10ns {
            id
            locationId
            lang
            address1
            address2Name
            city
            state
          }
        }
      }
    }
  }
`;

const USER_LOCATION_UPDATE = gql`
  mutation UserLocationUpdate(
    $where: UserLocationWhereInput
    $params: UserLocationInput
  ) {
    userLocationUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderNo
        locationId
        locationTypeId
        notes
        isDefault
        createdAt
        updatedAt
        location {
          id
          countryId
          postalCode
          address2
          latitude
          longitude
          l10ns {
            id
            locationId
            lang
            address1
            address2Name
            city
            state
          }
        }
      }
    }
  }
`;

const USER_LOCATION_REMOVE_MANY = gql`
  mutation UserLocationRemoveMany($where: [UserLocationWhereInput]) {
    userLocationRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const userLocationQueries = {
  userLocationList: USER_LOCATION_LIST,
  userLocationGetMany: USER_LOCATION_GET_MANY,
  userLocationCreate: USER_LOCATION_CREATE,
  userLocationUpdate: USER_LOCATION_UPDATE,
  userLocationSet: USER_LOCATION_SET,
  userLocationRemoveMany: USER_LOCATION_REMOVE_MANY,
};

export { userLocationQueries };
