import { Fragment, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormDrawer } from '@sdflc/ui';

import { LocalizationContext } from '../../../contexts';

import { Block } from '../../Block';
import { AppToolbar } from '../../AppToolbar';

import { UserAllowListItemsList } from './UserAllowListItemsList';
import { useUserAllowListItems } from './useUserAllowListItems';

const UserAllowListItems = memo((props) => {
  const { hasTitle } = props;
  const { getText } = useContext(LocalizationContext);
  const {
    open,
    userAllowListItems,
    userAllowListItemList,
    formData,
    formCfg,
    formActions,
    handleClickAction,
    handleClickClose,
    userAllowListItemsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOpResult,
    updatingOps,
    removingOps,
  } = useUserAllowListItems();

  return (
    <Fragment>
      <Block title={hasTitle ? getText('blocks.userAllowListItem.title') : ''}>
        <AppToolbar items={toolBarItems} onClick={handleClickAction} />
        <UserAllowListItemsList
          onClickAction={handleClickAction}
          list={userAllowListItemList}
          items={userAllowListItems}
          listOp={userAllowListItemsOpsManager.getListOp()}
          updatingOps={updatingOps}
          removingOps={removingOps}
        />
      </Block>
      <FormDrawer
        isOpen={open}
        title={getText('blocks.userAllowListItem.form.title')}
        description=''
        formData={formData}
        formCfg={formCfg}
        onClose={handleClickClose}
        actions={formActions}
        actionInProgress={actionInProgress}
        onClickAction={handleClickAction}
        progress={saveOpResult.isInProgress()}
        opResult={saveOpResult}
      />
    </Fragment>
  );
});

UserAllowListItems.displayName = 'UserAllowListItems';

UserAllowListItems.propTypes = {
  children: PropTypes.node,
  hasTitle: PropTypes.bool,
};

UserAllowListItems.defaultProps = {
  hasTitle: false,
};

export { UserAllowListItems };
