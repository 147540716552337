import {
  memo,
  useEffect,
  useContext,
  Fragment,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { omit } from 'lodash';
import { useHistory } from 'react-router-dom';

import {
  ErrorText,
  FormState,
  Form,
  Grid,
  GridItem,
  CheckBox,
  FormInput,
  FormDescription,
  Actions,
  Caption,
  Label,
  LinkEx,
  Alert,
} from '@sdflc/ui';

import {
  APP_PAGE_KEYS,
  FULL_WIDTH_BUTTONS_WITH_NAMES,
  ACTIONS_IDS,
} from '../../config';
import { useAuth } from '../../graphql/hooks';
import { goToPage, logger } from '../../utils';
import { InputError } from '../../components';
import {
  AuthContext,
  ContentContext,
  LocalizationContext,
} from '../../contexts';

const ANNOUNCEMENT_SIGNUP_ID = 'announcement-signup';

const PageSignUp = memo(() => {
  const history = useHistory();
  const { signUp, signUpResult, signUpCalled } = useAuth();
  const { getText } = useContext(LocalizationContext);
  const { appUserLangGet } = useContext(AuthContext);
  const { contentItemList, content } = useContext(ContentContext);
  const [formData] = useState({
    username: '',
    password: '',
    repeatPassword: '',
    agreeWithTerms: false,
  });

  const { formCfg, actions } = useMemo(() => {
    const labels = getText('pages.signUp.form.labels');
    const hints = getText('pages.signUp.form.hints');
    const placeholders = getText('pages.signUp.form.placeholders');
    const actions = [
      FULL_WIDTH_BUTTONS_WITH_NAMES.SIGN_UP,
      FULL_WIDTH_BUTTONS_WITH_NAMES.SIGN_IN_ALT,
    ];

    return {
      formCfg: {
        labels,
        hints,
        placeholders,
      },
      actions,
    };
  }, [getText]);

  const handleClickButton = useCallback((action, submit) => {
    switch (action.id) {
      default:
        logger.warn('The "handleClickButton" got unexpected action:', action);
        break;

      case ACTIONS_IDS.SIGN_UP:
        submit();
        break;

      case ACTIONS_IDS.SIGN_IN:
        goToPage({ history: null, pageKey: APP_PAGE_KEYS.SIGN_IN });
        break;
    }
  }, []);

  const handleSignUp = useCallback(
    (formData) => {
      logger.debug('Sign Up Form Data:', formData);
      if (formData.agreeWithTerms) {
        signUp({ params: omit(formData, ['agreeWithTerms']) });
      }
    },
    [signUp]
  );

  useEffect(() => {
    contentItemList({
      filter: {
        itemId: ANNOUNCEMENT_SIGNUP_ID,
      },
      lang: appUserLangGet(),
    });
  }, [contentItemList, appUserLangGet]);

  useEffect(() => {
    if (signUpResult.getDataFirst({}).id) {
      setTimeout(() => {
        goToPage({ history: null, pageKey: APP_PAGE_KEYS.HOME });
      }, 100);
    }
  }, [history, signUpResult, signUpCalled]);

  const announcement = content.getDataFieldValue(ANNOUNCEMENT_SIGNUP_ID);

  return (
    <FormState data={formData} onSubmit={handleSignUp}>
      {({ data, handleChange, submit }) => {
        return (
          <Form width={'600px'} maxWidth='90%'>
            <Grid distance={2}>
              <GridItem width={['100%']}>
                <Caption level={3}>
                  {getText('pages.signUp.form.title')}
                </Caption>
              </GridItem>

              <GridItem width={['100%']}>
                <FormDescription>
                  {getText('pages.signUp.form.description')}
                </FormDescription>
              </GridItem>
              {announcement && (
                <GridItem width={['100%']}>
                  <Alert variant={announcement.variant}>
                    {announcement.value}
                  </Alert>
                </GridItem>
              )}
              <GridItem width={['100%']}>
                <FormInput
                  required
                  labelData={formCfg.labels}
                  placeholderData={formCfg.placeholders}
                  hintData={formCfg.hints}
                  data={data}
                  fieldName='username'
                  onChange={handleChange}
                  opResult={signUpResult}
                  leftIcon='Person'
                />
              </GridItem>
              <GridItem width={['100%']}>
                <FormInput
                  required
                  labelData={formCfg.labels}
                  placeholderData={formCfg.placeholders}
                  hintData={formCfg.hints}
                  data={data}
                  fieldName='email'
                  onChange={handleChange}
                  opResult={signUpResult}
                  type='email'
                  leftIcon='Email'
                />
              </GridItem>
              <GridItem width={['100%']}>
                <FormInput
                  required
                  labelData={formCfg.labels}
                  placeholderData={formCfg.placeholders}
                  hintData={formCfg.hints}
                  data={data}
                  fieldName='password'
                  onChange={handleChange}
                  opResult={signUpResult}
                  type='password'
                  leftIcon='Security'
                  autocomplete='just-make-it-off'
                />
              </GridItem>
              <GridItem width={['100%']}>
                <FormInput
                  required
                  labelData={formCfg.labels}
                  placeholderData={formCfg.placeholders}
                  hintData={formCfg.hints}
                  data={data}
                  fieldName='repeatPassword'
                  onChange={handleChange}
                  opResult={signUpResult}
                  type='password'
                  leftIcon='Security'
                  autocomplete='just-make-it-off'
                />
              </GridItem>
              <GridItem width={['100%']}>
                <CheckBox
                  required
                  id='agreeWithTerms'
                  name='agreeWithTerms'
                  // label={getText('pages.signUp.form.labels.agreeWithTerms')}
                  checked={data.agreeWithTerms}
                  onChange={handleChange}
                  hasInputContainer={false}
                >
                  <Label htmlFor='agreeWithTerms'>
                    <span>
                      {getText('pages.signUp.form.labels.agreeWithTerms')}
                    </span>
                    <span>&nbsp;</span>
                    <LinkEx
                      as='a'
                      href='https://shopadropa.com/terms'
                      target='_blank'
                    >
                      Terms and conditions
                    </LinkEx>
                  </Label>
                </CheckBox>
                <InputError result={signUpResult} name='agreeWithTerms' />
              </GridItem>
              {!signUpResult.isLoading() && signUpResult.hasErrors() && (
                <Fragment>
                  <ErrorText>{signUpResult.getFieldErrors('')}</ErrorText>
                </Fragment>
              )}
              <GridItem width={['100%']}>
                <Actions
                  actions={actions}
                  actionInProgress={
                    signUpResult.isLoading()
                      ? FULL_WIDTH_BUTTONS_WITH_NAMES.SIGN_UP.id
                      : ''
                  }
                  onClick={(button) => handleClickButton(button, submit)}
                />
              </GridItem>
            </Grid>
          </Form>
        );
      }}
    </FormState>
  );
});

PageSignUp.displayName = 'PageSignUp';

export { PageSignUp };
