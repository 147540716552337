import React, { memo } from 'react';
import { OrderWithItemsAndSummary, OrderDelivered } from 'components';

/**
 * Page component that shows delivered order overview
 */
const PageOrderDelivered = memo(() => {
  return (
    <OrderWithItemsAndSummary
      hideSummaryBox
      titleId={'pages.order.delivered.title'}
    >
      <OrderDelivered />
    </OrderWithItemsAndSummary>
  );
});

PageOrderDelivered.displayName = 'PageOrderDelivered';

export { PageOrderDelivered };
