import {
  Fragment,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Text,
  FormState,
  Form,
  FormInput,
  Grid,
  GridItem,
  Caption,
  Actions,
  Icon,
  colors,
  Panel,
  Button,
  Alert,
} from '@sdflc/ui';

import {
  appPages,
  APP_PAGE_KEYS,
  ACTIONS_IDS,
  BUTTONS_WITH_NAMES,
} from '../../config';
import { AuthContext, LocalizationContext } from 'contexts';
import { logger } from 'utils';

const PageResetPassword = memo((props) => {
  const history = useHistory();
  const { getText } = useContext(LocalizationContext);
  const { resetPassword, authResetOp, resetPasswordOpId, resetPasswordOp } =
    useContext(AuthContext);
  const [formData, setFormData] = useState({
    password: '',
    repeatPassword: '',
  });
  const search = new URLSearchParams(history.location.search);
  const requestId = search.get('requestId');

  const { formCfg, actions } = useMemo(() => {
    const labels = getText('pages.resetPassword.form.labels');
    const hints = getText('pages.resetPassword.form.hints');
    const placeholders = getText('pages.resetPassword.form.placeholders');
    const actions = [BUTTONS_WITH_NAMES.OK];

    return {
      formCfg: {
        labels,
        hints,
        placeholders,
      },
      actions,
    };
  }, [getText]);

  const handleClickButton = useCallback(
    (action, submit) => {
      switch (action.id) {
        default:
          logger.warn(
            `The "handleClickButton" got an unexpected action`,
            action
          );
          break;

        case ACTIONS_IDS.OK:
          submit();
          break;

        case ACTIONS_IDS.SIGN_IN:
          history.push(appPages[APP_PAGE_KEYS.SIGN_IN].url);
          break;
      }
    },
    [history]
  );

  const handleResetPassword = useCallback(
    (submittedFormData) => {
      console.log('Reset Password Form Data:', requestId, submittedFormData);
      setFormData(submittedFormData);
      authResetOp(resetPasswordOpId);
      resetPassword({ params: { ...submittedFormData, requestId } });
    },
    [resetPasswordOpId, authResetOp, resetPassword, requestId]
  );

  const handleClickForgotPassword = useCallback(() => {
    logger.debug('User forgot password, redirect to the forgot password page');
    history.push(appPages[APP_PAGE_KEYS.FORGOT_PASSWORD].url);
  }, [history]);

  const handleClickSignIn = useCallback(() => {
    history.push(appPages[APP_PAGE_KEYS.SIGN_IN].url);
  }, [history]);

  const errors =
    resetPasswordOp.result.getErrorSummary('') ||
    resetPasswordOp.result.getErrorSummary('requestId');
  const isInProgress = resetPasswordOp.result.isInProgress('');
  const requested =
    resetPasswordOp.called &&
    !isInProgress &&
    resetPasswordOp.result.didSucceed();

  if (requested) {
    return (
      <Grid width={'600px'} maxWidth='90%'>
        <GridItem width='100%'>
          <Icon name='VpnKey' iconSize='22px' color={colors.blue} />
        </GridItem>
        <GridItem width='100%'>
          <Caption level={3}>
            {getText('pages.resetPassword.succeeded.title')}
          </Caption>
        </GridItem>
        <GridItem width='100%'>
          <Text>{getText('pages.resetPassword.succeeded.description')}</Text>
        </GridItem>
        <GridItem width='100%'>
          <Button
            variant='primary'
            name={getText('buttons.signIn')}
            dimension='medium'
            onClick={handleClickSignIn}
          />
        </GridItem>
      </Grid>
    );
  }

  return (
    <FormState data={formData} onSubmit={handleResetPassword}>
      {({ data, handleChange, submit }) => {
        return (
          <Form width={'600px'} maxWidth='90%'>
            <Grid>
              <GridItem width='100%'>
                <Icon name='VpnKey' iconSize='22px' color={colors.blue} />
              </GridItem>
              {!!requestId ? (
                <Fragment>
                  <GridItem width='100%'>
                    <Caption level={3}>
                      {getText('pages.resetPassword.form.title')}
                    </Caption>
                  </GridItem>
                  <GridItem width='100%'>
                    <Text>
                      {getText('pages.resetPassword.form.description')}
                    </Text>
                  </GridItem>
                  <GridItem width='100%'>
                    <FormInput
                      required
                      labelData={formCfg.labels}
                      placeholderData={formCfg.placeholders}
                      hintData={formCfg.hints}
                      data={data}
                      fieldName='password'
                      onChange={handleChange}
                      opResult={resetPasswordOp.result}
                      type='password'
                      leftIcon='Security'
                    />
                  </GridItem>
                  <GridItem width='100%'>
                    <FormInput
                      required
                      labelData={formCfg.labels}
                      placeholderData={formCfg.placeholders}
                      hintData={formCfg.hints}
                      data={data}
                      fieldName='repeatPassword'
                      onChange={handleChange}
                      opResult={resetPasswordOp.result}
                      type='password'
                      leftIcon='Security'
                    />
                  </GridItem>
                  {!!errors && (
                    <GridItem width='100%'>
                      <Alert variant='danger'>{errors}</Alert>
                    </GridItem>
                  )}
                  <GridItem width='100%'>
                    <Actions
                      actions={actions}
                      actionInProgress={isInProgress ? 'ok' : ''}
                      onClick={(button) => handleClickButton(button, submit)}
                      width='100%'
                    />
                    <Panel pb={'64px'} />
                  </GridItem>
                </Fragment>
              ) : (
                <Fragment>
                  <GridItem width='100%'>
                    <Caption level={3}>
                      {getText('pages.resetPassword.errors.noRequestId.title')}
                    </Caption>
                  </GridItem>
                  <GridItem width='100%'>
                    <Text>
                      {getText(
                        'pages.resetPassword.errors.noRequestId.description'
                      )}
                    </Text>
                  </GridItem>
                  <GridItem width='100%'>
                    <Button
                      variant='primary'
                      name={getText('pages.resetPassword.buttons.resubmit')}
                      dimension='medium'
                      onClick={handleClickForgotPassword}
                    />
                  </GridItem>
                </Fragment>
              )}
            </Grid>
          </Form>
        );
      }}
    </FormState>
  );
});

PageResetPassword.displayName = 'PageResetPassword';

PageResetPassword.propTypes = {
  children: PropTypes.node,
};

PageResetPassword.defaultProps = {};

export { PageResetPassword };
