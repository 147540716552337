import { gql } from '@apollo/client';

const TRULES_TRANSFORM = gql`
  query Transform($params: TransformInput) {
    transform(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        transformGroupId
        transformString
        transformResult
        appliedTransformRuleIds
        appliedTransformRules {
          id
          ruleName
          ruleRegexp
        }
      }
    }
  }
`;

const TRULES_TRANSFORM_MANY = gql`
  query TransformMany($params: [TransformInput]) {
    transformMany(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        transformGroupId
        transformString
        transformResult
        appliedTransformRuleIds
      }
    }
  }
`;

const trulesQueries = {
  transform: TRULES_TRANSFORM,
  transformMany: TRULES_TRANSFORM_MANY,
};

export { trulesQueries };
