import React, { Fragment, memo, useCallback, useContext } from 'react';
//import PropTypes from 'prop-types';
import { Button, ButtonUpload, Panel } from '@sdflc/ui';

import { logger, uploadFile } from 'utils';
import { LocalizationContext, OrdersContext } from 'contexts';
import check from 'check-types';
import { useUploadedFile } from 'graphql/hooks/useUploadedFile';
import { ReceiptImage } from 'components/ReceiptImage';

const OrderReceiptUploader = memo((props) => {
  const { getText } = useContext(LocalizationContext);
  const { orderHooks } = useContext(OrdersContext);
  const { uploadedFileRemoveMany } = useUploadedFile();
  const { order } = orderHooks;
  const { id, name, value, onChange } = props;

  logger.log('OrderReceiptUploader.props', props);

  const handleChange = useCallback(
    async (_, value) => {
      logger.log('Upload receipt clicked', value);

      const uploadedFile = value[0];

      const formData = new FormData();

      formData.append('filePath', `order/${order.id}/receipts`);

      formData.append('originalFilename', uploadedFile.name);
      formData.append('name', `Receipt ${uploadedFile.name}`);
      formData.append('file', uploadedFile);
      formData.append('notes', '');
      formData.append('status', '100'); // Pending state, if not update to 100 then will be removed autmatically in some time

      logger.log('Start uploading', Array.from(formData.entries()));

      const uploadResult = await uploadFile({ formData });

      logger.log('Fila has been uploaded with the result:', uploadResult);

      if (uploadResult.didSucceed()) {
        logger.log('Uploaded file:', uploadResult.getDataFirst());
        if (check.function(onChange)) {
          onChange(name || id, uploadResult.getDataFirst().id);
        }
      } else {
        //setUploadError(uploadResult.getErrorSummary());
      }
    },
    [name, id, order, onChange]
  );

  const handleClickRemove = useCallback(() => {
    uploadedFileRemoveMany({ where: [{ id: value }] });

    if (check.function(onChange)) {
      onChange(name || id, null);
    }
  }, [uploadedFileRemoveMany, name, id, onChange, value]);

  if (!value) {
    return (
      <Fragment>
        <ButtonUpload
          icon={'Receipt'}
          name={getText('buttons.uploadReceipt')}
          onChange={handleChange}
        />
      </Fragment>
    );
  }

  return (
    <Panel>
      <ReceiptImage receiptFileId={value} imageSize={314} zoomable={true} />
      <Button
        icon='Delete'
        onClick={handleClickRemove}
        dimension={Button.DIMENSION_NAMES.SMALL}
        variant={Button.VARIANT_NAMES.DANGER}
      />
    </Panel>
  );
});

OrderReceiptUploader.displayName = 'OrderReceiptUploader';
OrderReceiptUploader.propTypes = {};
OrderReceiptUploader.defaultProp = {};

export { OrderReceiptUploader };
