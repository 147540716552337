import { Fragment } from 'react';

import * as layouts from '../layouts';
import * as pages from '../pages';
import { Redirect } from '../components';

const componentsMap = {
  Fragment,
  Redirect,
  ...layouts,
  ...pages,
};

export { componentsMap };
