import { OrdersContext } from 'contexts';
import React, { memo, useContext } from 'react';
//import PropTypes from 'prop-types';

import { OrderItems } from '../OrderItems';

const OrderViewDetails = memo((props) => {
  const { orderHooks } = useContext(OrdersContext);
  const { order } = orderHooks;

  return <OrderItems order={order} readOnly />;
});

OrderViewDetails.displayName = 'OrderViewDetails';
OrderViewDetails.propTypes = {};
OrderViewDetails.defaultProp = {};

export { OrderViewDetails };
