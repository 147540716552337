import { gql } from '@apollo/client';

const QUERY_LIST = gql`
  query UserBlockListItemList(
    $filter: UserBlockListItemFilter
    $params: PaginationAndSorting
  ) {
    userBlockListItemList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        blockedUserId
        status
        blockedUser {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
      }
    }
  }
`;

const MUTATION_CREATE = gql`
  mutation UserBlockListItemCreate($params: UserBlockListItemInput) {
    userBlockListItemCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        blockedUserId
        status
        blockedUser {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
      }
    }
  }
`;

const MUTATION_UPDATE = gql`
  mutation UserBlockListItemUpdate(
    $where: UserBlockListItemWhereInput
    $params: UserBlockListItemInput
  ) {
    userBlockListItemUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        blockedUserId
        status
        blockedUser {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
      }
    }
  }
`;

const MUTATION_REMOVE_MANY = gql`
  mutation UserBlockListItemRemoveMany($where: [UserBlockListItemWhereInput]) {
    userBlockListItemRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const userBlockListItemQueries = {
  userBlockListItemList: QUERY_LIST,
  userBlockListItemCreate: MUTATION_CREATE,
  userBlockListItemUpdate: MUTATION_UPDATE,
  userBlockListItemRemoveMany: MUTATION_REMOVE_MANY,
};

export { userBlockListItemQueries };
