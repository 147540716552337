import { defaultTheme } from '@sdflc/ui';
import { colors } from './colors';

const appTheme = {
  ...defaultTheme,
  GlobalStyles: {
    backgroundColor: colors.grey01,
  },
  EmptyPage: {
    ...defaultTheme.EmptyPage,
    backgroundColor: colors.grey01,
  },
  Header: {
    ...defaultTheme.Header,
    container: {
      ...defaultTheme.Header.container,
      borderRadius: 0,
      borderLeftWidth: '2px',
      borderLeftColor: `rgb(0 0 0 / 14%)`,
      borderLeftStyle: 'solid',
    },
  },
  FormField: {
    ...defaultTheme.FormField,
    container: {
      margin: 0,
    },
  },
  PropsViewer: {
    container: {},
    propRow: {},
    propNameColumn: {
      padding: 0,
      alignItems: 'center',
    },
    propName: {
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: 16,
      paddingLeft: 0,
    },
    propValueColumn: {
      padding: 1,
      justifyContent: 'flex-end',
      fontSize: 16,
      lineHeight: 16,
      overflow: 'hidden',
    },
    spacer: {
      padding: 1,
    },
    iconContainer: {
      width: '32px',
      minWidt: '32px',
    },
  },
  ValueDate: {
    ...defaultTheme.ValueDate,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    at: {
      ...defaultTheme.ValueDate.at,
      color: 'currentColor',
    },
  },
};

export { appTheme };
