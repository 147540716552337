import { FORM_INPUT_TYPES } from '@sdflc/ui';
import { SelectDictionaryItem } from 'components/Controls/SelectDictionaryItem';
import { DICTIONARIES_UIDS } from 'config/dictionariesUids';

export const addressFormSections = (args) => {
  const { autoCompleteRef } = args || {};

  return [
    {
      name: '',
      fields: [
        {
          field: 'locationTypeId',
          width: ['100%'],
          component: SelectDictionaryItem,
          componentProps: {
            dictionaryUid: DICTIONARIES_UIDS.USER_LOCATION_TYPES,
          },
        },
        {
          field: 'address1',
          type: FORM_INPUT_TYPES.TEXT,
          width: ['100%'],
          componentProps: {
            ref: autoCompleteRef,
          },
        },

        {
          field: 'address2Name',
          component: SelectDictionaryItem,
          componentProps: {
            dictionaryUid: DICTIONARIES_UIDS.ADDRESS2_TYPES,
          },
          width: ['50%'],
        },
        {
          field: 'address2',
          type: FORM_INPUT_TYPES.TEXT,
          width: ['50%'],
        },
        {
          field: 'city',
          type: FORM_INPUT_TYPES.TEXT,
          width: ['100%'],
        },
        {
          field: 'state',
          type: FORM_INPUT_TYPES.TEXT,
          width: ['100%'],
        },
        {
          field: 'postalCode',
          type: FORM_INPUT_TYPES.TEXT,
          width: ['100%'],
        },
        {
          field: 'countryId',
          type: FORM_INPUT_TYPES.TEXT,
          width: ['100%'],
        },
        {
          field: 'notes',
          type: FORM_INPUT_TYPES.TEXTAREA,
          width: ['100%'],
        },
      ],
    },
  ];
};
