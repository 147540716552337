import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Caption, Panel } from '@sdflc/ui';

const Block = memo((props) => {
  const { title, children } = props;

  return (
    <Panel mb={3} pb={3} overflow='initial'>
      {title && (
        <Caption level={4} pb={3}>
          {title}
        </Caption>
      )}
      <Panel overflow='initial'>{children}</Panel>
    </Panel>
  );
});

Block.displayName = 'Block';

Block.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

Block.defaultProps = {
  title: '',
};

export { Block };
