import { memo } from 'react';

import { OrderShop, OrderWithItemsAndSummary } from 'components';

/**
 * Page component when user is in the order shopping mode
 */
const PageShopOrderItems = memo(() => {
  return (
    <OrderWithItemsAndSummary shopMode titleId={'pages.order.shopping.title'}>
      <OrderShop />
    </OrderWithItemsAndSummary>
  );
});

PageShopOrderItems.displayName = 'PageShopOrderItems';

export { PageShopOrderItems };
