import { memo } from 'react';

import { ORDER_LIST_CATEGORIES } from '../../config';
import { Orders } from '../../components';

/**
 * Page component with a list of user's past orders
 */
const PageMyPastOrders = memo(() => {
  return (
    <Orders
      title='pages.myPastOrders.title'
      orderListCategory={ORDER_LIST_CATEGORIES.MY_PAST_ORDERS}
    />
  );
});

PageMyPastOrders.displayName = 'OrPageMyPastOrdersdersHistory';

export { PageMyPastOrders };
