import React, { Fragment, memo, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Caption,
  Panel,
  ValueDate,
  Text,
  RichText,
  ValueCurrency,
} from '@sdflc/ui';
import { AuthContext, LocalizationContext } from 'contexts';
import { getBuyerOrShopper, logger } from 'utils';
import { UserBadge } from 'components/UserBadge';
import { ORDER_STEPS } from 'config';
import { TitledSection } from 'components/TitledSection';
import { SectionWithShowMore } from 'components/SectionWithShowMore';
import { ORDER_COMPLETE } from 'config';
import { ORDER_CONFIRMED_OR_COMPLETE } from 'config';

const OrderMiniDetails = memo((props) => {
  const { order, orderItems } = props;
  const { getText } = useContext(LocalizationContext);
  const { appUser } = useContext(AuthContext);

  const { user, textId } = useMemo(() => {
    return getBuyerOrShopper({ order, appUser, oppositeSide: true });
  }, [order, appUser]);

  const buyerComment = useMemo(() => {
    const text = order?.buyerComment || '';
    if (!text) {
      return text;
    }

    // For the RichText:
    //return `<p>${text.split('\n').join('</p><p>')}</p>`;
    return text.split('\n').map((line) => (
      <Text key={line} dimension={Text.DIMENSION_NAMES.SMALL} mb={1}>
        {line}
      </Text>
    ));
  }, [order.buyerComment]);

  if (!order) {
    return null;
  }

  const amIShopperAndOwner = order.isMine && order.amIShopper;

  if ([1000027].indexOf(order.orderNum) !== -1) {
    logger.debug(`OrderMiniDetails.order(${order.orderNum})`, order);
  }

  return (
    <Panel>
      {user && (
        <TitledSection title={textId}>
          <UserBadge appUser={user} />
        </TitledSection>
      )}

      {!!buyerComment && (
        <TitledSection title={'entities.order.buyerComment'}>
          {/* <RichText text={buyerComment} /> */}
          <SectionWithShowMore>{buyerComment}</SectionWithShowMore>
        </TitledSection>
      )}

      <TitledSection title={'entities.order.locationId'}>
        <Text>{order.userLocation?.formattedAddress}</Text>
      </TitledSection>
      <TitledSection title={'entities.order.dueDate'}>
        <ValueDate value={order.dueDate} />
      </TitledSection>

      <TitledSection title={'entities.order.currentStepId'}>
        <Text>{getText(`orderSteps.${order.currentStepId}`)}</Text>
      </TitledSection>

      <TitledSection title={'entities.order.orderItemsQty'}>
        <Text>{orderItems?.length || 'N/A'}</Text>
      </TitledSection>

      {ORDER_CONFIRMED_OR_COMPLETE.includes(order.currentStepId) && (
        <Fragment>
          <TitledSection title={'entities.order.orderAmount'}>
            <ValueCurrency
              value={order.orderAmount}
              currencyCode={order.currency}
            />
          </TitledSection>
          {!amIShopperAndOwner && order.isMine && (
            <Fragment>
              <TitledSection title={'entities.order.serviceFeeTotalAmount'}>
                <ValueCurrency
                  value={order.serviceFeeTotalAmount}
                  currencyCode={order.currency}
                />
              </TitledSection>
              <TitledSection title={'entities.order.totalAmountForBuyer'}>
                <ValueCurrency
                  value={order.totalAmountForBuyer}
                  currencyCode={order.currency}
                />
              </TitledSection>
            </Fragment>
          )}
          {!amIShopperAndOwner && order.amIShopper && (
            <Fragment>
              <TitledSection title={'entities.order.deliveryCost'}>
                <ValueCurrency
                  value={order.deliveryCost}
                  currencyCode={order.currency}
                />
              </TitledSection>
              <TitledSection title={'entities.order.totalAmountForShopper'}>
                <ValueCurrency
                  value={order.totalAmountForShopper}
                  currencyCode={order.currency}
                />
              </TitledSection>
            </Fragment>
          )}
        </Fragment>
      )}
    </Panel>
  );
});

OrderMiniDetails.displayName = 'OrderMiniDetails';
OrderMiniDetails.propTypes = {
  order: PropTypes.shape({
    userLocation: PropTypes.shape({
      formattedAddress: PropTypes.string,
    }),
    dueDate: PropTypes.any,
  }),
};
OrderMiniDetails.defaultProp = {};

export { OrderMiniDetails };
