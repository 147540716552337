import check from 'check-types';

/**
 * Creates a set with unique values taken from the `fieldName` property of each element of data array
 * @param {Object} args An object { data, fieldName } where `data` is an array of objects and `fieldName` is name of object's prop to into a set
 * @returns a Set with unique values
 */
export const arrayOfObjectsToSet = (args) => {
  const { data, fieldName, buildValue } = args;
  const set = new Set();

  if (!Array.isArray(data)) {
    return set;
  }

  const func = check.function(buildValue)
    ? buildValue
    : (dataItem) => {
        return dataItem[fieldName];
      };

  return data.reduce((acc, dataItem) => {
    if (dataItem) {
      acc.add(func(dataItem));
    }
    return acc;
  }, set);
};

/**
 * Creates a key-value map object with unique values taken from the `fieldName` property of each element of data array
 * @param {Object} args An object { data, fieldName } where `data` is an array of objects and `fieldName` is name of object's prop to into a map object
 * @returns an key-value map object with unique values
 */
export const arrayOfObjectsToMap = (args) => {
  const { data, fieldName, buildKey } = args;
  const map = {};

  if (!Array.isArray(data)) {
    return map;
  }

  const func = check.function(buildKey)
    ? buildKey
    : (dataItem) => {
        return dataItem[fieldName];
      };

  return data.reduce((acc, dataItem) => {
    if (dataItem) {
      acc[func(dataItem)] = dataItem;
    }
    return acc;
  }, map);
};

/**
 * Returns an array with unique values taken from the `fieldName` property of each element of data array
 * @param {Object} args An object { data, fieldName } where `data` is an array of objects and `fieldName` is name of object's prop to into a set
 * @returns an array with unique values
 */
export const arrayOfObjectsToArray = (args) => {
  const { data, fieldName } = args;
  const arr = [];

  if (!Array.isArray(data)) {
    return arr;
  }

  return data.reduce((acc, dataItem) => {
    if (dataItem) {
      acc.push(dataItem[fieldName]);
    }
    return acc;
  }, arr);
};
