import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@sdflc/ui';
import { appSettings } from 'config/appSettings';

const PAYMENT_METHOD_TYPES = {
  visa: `${appSettings.paymentMethodsImagesRoot}/visa.svg`,
  mastercard: `${appSettings.paymentMethodsImagesRoot}/mastercard.svg`,
  maestro: `${appSettings.paymentMethodsImagesRoot}/maestro.svg`,
  amex: `${appSettings.paymentMethodsImagesRoot}/american-express.svg`,
  cash: `${appSettings.paymentMethodsImagesRoot}/cash.svg`,
};

const PaymentMethodType = memo((props) => {
  const { value } = props;
  return (
    <Image
      src={PAYMENT_METHOD_TYPES[value] || PAYMENT_METHOD_TYPES['cash']}
      alt={value}
      pr={2}
    />
  );
});

PaymentMethodType.displayName = 'PaymentMethodType';
PaymentMethodType.propTypes = {
  value: PropTypes.string,
};
PaymentMethodType.defaultProp = {};

export { PaymentMethodType };
