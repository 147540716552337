import { gql } from '@apollo/client';

const PAYMENT_METHOD_LIST = gql`
  query PaymentMethodList(
    $filter: PaymentMethodFilter
    $params: PaginationAndSorting
  ) {
    paymentMethodList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        token
        cardHolderName
        last4Digits
        expirationDate
        creditCardType
        isDefault
        status
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
    }
  }
`;

const PAYMENT_METHOD_GET_MANY = gql`
  query PaymentMethodGetMany($ids: [ID]) {
    paymentMethodGetMany(ids: $ids) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        token
        cardHolderName
        last4Digits
        expirationDate
        creditCardType
        isDefault
        status
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
    }
  }
`;

const PAYMENT_METHOD_CREATE = gql`
  mutation PaymentMethodCreate($params: PaymentMethodInput) {
    paymentMethodCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        token
        cardHolderName
        last4Digits
        expirationDate
        creditCardType
        isDefault
        status
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
    }
  }
`;

const PAYMENT_METHOD_UPDATE = gql`
  mutation PaymentMethodUpdate(
    $where: PaymentMethodWhereInput
    $params: PaymentMethodInput
  ) {
    paymentMethodUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        token
        cardHolderName
        last4Digits
        expirationDate
        creditCardType
        isDefault
        status
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
    }
  }
`;

const PAYMENT_METHOD_REMOVE_MANY = gql`
  mutation PaymentMethodRemoveMany($where: [PaymentMethodWhereInput]) {
    paymentMethodRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const PAYMENT_METHOD_EMBED_URL_GET = gql`
  query PaymentMethodEmbedUrlGet {
    paymentMethodEmbedUrlGet {
      code
      errors {
        name
        errors
        warnings
      }
      data
    }
  }
`;

const paymentMethodQueries = {
  paymentMethodList: PAYMENT_METHOD_LIST,
  paymentMethodGetMany: PAYMENT_METHOD_GET_MANY,
  paymentMethodCreate: PAYMENT_METHOD_CREATE,
  paymentMethodUpdate: PAYMENT_METHOD_UPDATE,
  paymentMethodRemoveMany: PAYMENT_METHOD_REMOVE_MANY,
  paymentMethodEmbedUrlGet: PAYMENT_METHOD_EMBED_URL_GET,
};

export { paymentMethodQueries };
