import { memo } from 'react';

import { OrderViewDetails, OrderWithItemsAndSummary } from 'components';

/**
 * Page component to view order details
 */
const PageViewOrder = memo(() => {
  return (
    <OrderWithItemsAndSummary readOnly titleId={'pages.order.view.title'}>
      <OrderViewDetails />
    </OrderWithItemsAndSummary>
  );
});

PageViewOrder.displayName = 'PageViewOrder';

export { PageViewOrder };
