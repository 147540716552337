import { FORM_INPUT_TYPES, Rating } from '@sdflc/ui';

export const orderReviewFormSections = [
  {
    fields: [
      {
        field: 'rating',
        component: Rating,
        width: ['100%'],
        componentProps: {
          leftIcon: 'AttachMoney',
          min: 1,
          max: 10,
        },
      },
      {
        field: 'review',
        width: ['100%'],
        type: FORM_INPUT_TYPES.TEXTAREA,
        componentProps: {
          rows: 5,
        },
      },
    ],
  },
];
