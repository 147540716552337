import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../contexts';
import { useAuth } from '../graphql/hooks';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';
import { appUrls } from 'config';
import { getUsername, goToPage, logger } from '../utils';

const AuthProvider = (props) => {
  const { children } = props;
  const hooks = useAuth();
  const { appMe, appUser, appUserResult, appMeCalled, refreshToken } = hooks;

  useEffect(() => {
    // Refresh auth token every 5 minutes
    setInterval(() => {
      if (appUser) {
        logger.debug('Refresh user token');
        refreshToken();
      } else {
        logger.debug('Could not refresh user token as appUser not initialized');
      }
    }, 60 * 1000 * 5);
  }, [appUser, refreshToken]);

  useEffect(() => {
    // Request user information every 10 minutes
    const intervalId = setInterval(() => {
      logger.debug('Request user details');
      appMe();
    }, 60 * 1000 * 32);

    logger.debug('Initial request user details');
    appMe();

    return () => {
      logger.debug('Stop requesting user details');
      clearInterval(intervalId);
    };
  }, [appMe]);

  useEffect(() => {
    const pageUrl = window?.location.pathname || '';
    const page = appUrls[pageUrl] ?? appUrls['*'];
    const isPublic = !!page?.options?.public;
    const pageNotFound = page?.pageKey === 'pageNotFound';

    // logger.log('===', {
    //   pageUrl,
    //   page: JSON.stringify(page, null, 2),
    //   isPublic,
    //   pageNotFound,
    // });

    if (
      (!isPublic || pageNotFound) &&
      appMeCalled &&
      !appUserResult.isLoading()
    ) {
      if (appUserResult.code !== OP_RESULT_CODES.OK) {
        const userData = getUsername();
        const redirectTo = userData ? 'signin' : 'signup';
        goToPage({ history: null, pageKey: redirectTo });
      } else {
        logger.log('Refresh user token');
        refreshToken();
      }
    }
  }, [appUserResult, appMeCalled, refreshToken]);

  useEffect(() => {
    logger.debug('AuthContext.appUser changed', appUser);
  }, [appUser]);

  return <AuthContext.Provider value={hooks}>{children}</AuthContext.Provider>;
};

AuthProvider.displayName = 'AuthProvider';

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProvider };
