import React, { Fragment, memo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
//import { Button } from '@sdflc/ui';

//import { LocalizationContext } from '../../../contexts';

//import { Block } from 'components/Block/Block';
//import { PaymentMethodsList } from './PaymentMethodsList';
import { usePaymentMethods } from './usePaymentMethods';
import { Loading } from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { AppPagesContext } from 'contexts';
import { logger } from 'utils';

const PaymentMethodCreated = memo((props) => {
  const { token /*, paymentMethod */ } = props;
  const history = useHistory();
  const { appPages } = useContext(AppPagesContext);
  //const { getText } = useContext(LocalizationContext);

  const {
    paymentMethod,
    paymentMethodCreate,
    paymentMethodCreateLoading,
    paymentMethodCreateCalled,
    paymentMethodCreateReset,
  } = usePaymentMethods();

  useEffect(() => {
    if (token) {
      logger.log('Create a card', token);
      paymentMethodCreate({ params: { token } });
    }
  }, [paymentMethodCreate, token, history, appPages]);

  useEffect(() => {
    if (paymentMethod.didSucceed() && paymentMethod.hasData()) {
      history.push(appPages['settings-payment-methods']?.url);
    }

    if (!paymentMethodCreateLoading && paymentMethodCreateCalled) {
      paymentMethodCreateReset();
    }
  }, [
    history,
    appPages,
    paymentMethod,
    paymentMethodCreateLoading,
    paymentMethodCreateCalled,
    paymentMethodCreateReset,
  ]);

  return (
    <Fragment>
      <Loading />
    </Fragment>
  );
});

PaymentMethodCreated.displayName = 'PaymentMethodCreated';

PaymentMethodCreated.propTypes = {
  token: PropTypes.string,
  paymentMethod: PropTypes.string,
};

PaymentMethodCreated.defaultProps = {};

export { PaymentMethodCreated };
