import { colors, mediaQueries } from '@sdflc/ui';
import styled from 'styled-components';
import {
  compose,
  position,
  layout,
  space,
  size,
  color,
  typography,
  shadow,
  border,
  background,
  flex,
} from 'styled-system';

const func = compose(
  layout,
  position,
  space,
  size,
  color,
  typography,

  border,
  background,

  shadow,
  flex
);

export const StyledOrderCardWrapper = styled.div`
  width: 350px;
  min-width: 90%;
  max-width: 90%;

  background-color: ${colors.white};
  border-radius: 16px;
  box-shadow: 0px 3px 2px rgba(11, 11, 12, 0.1);
  margin-bottom: 32px;

  ${func};

  ${mediaQueries.md} {
    min-width: 350px;
    max-width: 350px;
  }

  ${mediaQueries.lg} {
    min-width: 350px;
    max-width: 350px;
  }

  ${mediaQueries.xl} {
    min-width: 350px;
    max-width: 350px;
  }
`;

export const StyledOrderCardBlock = styled.div`
  padding: 12px;
  border-bottom: solid 1px ${colors.grey03};

  &:last-child {
    border-bottom: none;
  }
`;
