import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { AspectRatioBox, ProgressIndicator } from '@sdflc/ui';

import { TRulesContext } from 'contexts';
import { colors, appSettings } from 'config';
import { GenericAvatar } from 'components/GenericAvatar';

const OrderItemImage = memo((props) => {
  const { name, pictureUrl, imageSize, blur } = props;
  const { transformEnque, transforms } = useContext(TRulesContext);

  let img = pictureUrl;

  if (!img) {
    const transformResult =
      transforms?.[appSettings.orderItemImageTranslationRulesGroupId]?.[name];

    img = transformResult;
    // ? transformResult
    // : appSettings.defaultUserShoppingItemPictureUrl;

    if (!transformResult && name) {
      transformEnque({
        transformGroupId: appSettings.orderItemImageTranslationRulesGroupId,
        transformString: name,
      });
    }
  }

  if (!img) {
    return <GenericAvatar name={name} imageSize={imageSize} />;
  }

  return (
    <AspectRatioBox
      ratio='1:1'
      width={imageSize ? `${imageSize}px` : '50px'}
      backgroundImage={img !== true ? img : ''}
      borderRadius={'8px'}
      style={{ filter: blur ? 'blur(1px)' : '' }}
      centered
    >
      {img === true && (
        <ProgressIndicator size={24} kind='puff' color={colors.darkGrey} />
      )}
    </AspectRatioBox>
  );
});

OrderItemImage.displayName = 'OrderItemImage';

OrderItemImage.propTypes = {
  name: PropTypes.string,
  pictureUrl: PropTypes.string,
  imageSize: PropTypes.number,
  blur: PropTypes.bool,
};

OrderItemImage.defaultProp = {
  imageSize: '48px',
  blur: false,
};

export { OrderItemImage };
