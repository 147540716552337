import { gql } from '@apollo/client';

const ORDER_ITEM_LIST = gql`
  query OrderItemList($filter: OrderItemFilter, $params: PaginationAndSorting) {
    orderItemList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderId
        shoppingItemId
        quantity
        unitId
        actualQuantity
        buyerComment
        createdAt
        createdBy
        minPricePerUnit
        maxPricePerUnit
        notImportantIndicator
        onlyIfOnSaleIndicator
        price
        totalAmount
        shopperComment
        shoppingItemStatusId
        status
        storeBrand {
          storeTypeId
          l10n {
            storeBrandName
          }
        }
        order {
          orderNum
        }
        shoppingItem {
          shoppingItemName
          manufacturer
          minPricePerUnit
          maxPricePerUnit
          pictureUrl
        }
      }
    }
  }
`;

const ORDER_ITEM_GET_MANY = gql`
  query OrderItemGetMany($ids: [ID]) {
    orderItemGetMany(ids: $ids) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderId
        shoppingItemId
        quantity
        unitId
        actualQuantity
        buyerComment
        createdAt
        createdBy
        minPricePerUnit
        maxPricePerUnit
        notImportantIndicator
        onlyIfOnSaleIndicator
        price
        totalAmount
        shopperComment
        shoppingItemStatusId
        status
        storeBrand {
          storeTypeId
          l10n {
            storeBrandName
          }
        }
        order {
          orderNum
        }
        shoppingItem {
          shoppingItemName
          manufacturer
          minPricePerUnit
          maxPricePerUnit
          pictureUrl
        }
      }
    }
  }
`;

const ORDER_ITEM_CREATE = gql`
  mutation OrderItemCreate($params: OrderItemInput) {
    orderItemCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderId
        shoppingItemId
        quantity
        unitId
        actualQuantity
        buyerComment
        createdAt
        createdBy
        minPricePerUnit
        maxPricePerUnit
        notImportantIndicator
        onlyIfOnSaleIndicator
        price
        totalAmount
        shopperComment
        shoppingItemStatusId
        status
        storeBrand {
          storeTypeId
          l10n {
            storeBrandName
          }
        }
        order {
          orderNum
        }
        shoppingItem {
          shoppingItemName
          manufacturer
          minPricePerUnit
          maxPricePerUnit
          pictureUrl
        }
      }
    }
  }
`;

const ORDER_ITEM_UPDATE = gql`
  mutation OrderItemUpdate(
    $where: OrderItemWhereInput
    $params: OrderItemInput
  ) {
    orderItemUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        orderId
        shoppingItemId
        quantity
        unitId
        actualQuantity
        buyerComment
        createdAt
        createdBy
        minPricePerUnit
        maxPricePerUnit
        notImportantIndicator
        onlyIfOnSaleIndicator
        price
        totalAmount
        shopperComment
        shoppingItemStatusId
        status
        storeBrand {
          storeTypeId
          l10n {
            storeBrandName
          }
        }
        order {
          orderNum
        }
        shoppingItem {
          shoppingItemName
          manufacturer
          minPricePerUnit
          maxPricePerUnit
          pictureUrl
        }
      }
    }
  }
`;

const ORDER_ITEM_REMOVE_MANY = gql`
  mutation OrderItemRemoveMany($where: [OrderItemWhereInput]) {
    orderItemRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const orderItemQueries = {
  orderItemList: ORDER_ITEM_LIST,
  orderItemGetMany: ORDER_ITEM_GET_MANY,
  orderItemCreate: ORDER_ITEM_CREATE,
  orderItemUpdate: ORDER_ITEM_UPDATE,
  orderItemRemoveMany: ORDER_ITEM_REMOVE_MANY,
};

export { orderItemQueries };
