import { memo, Fragment, useMemo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';
import {
  ItemsGroup,
  Grid,
  GridItem,
  Button,
  ConfirmationButton,
  Input,
  HR,
  Badge,
  Tag,
  Skeleton,
  Text,
} from '@sdflc/ui';

import { SettingsRow } from '../../SettingsRow';
import { ACTIONS_BUTTONS } from '../../../config';

import { locationTypeToIcon } from 'utils/iconHelpers';
import { LocalizationContext } from 'contexts';
import { OpResult } from '@sdflc/api-helpers';

const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const width1 = ['100%', '100%', '100%', '100%'];
const width2 = ['100%', '100%', '100%', '50%'];

const UserLocationItem = memo((props) => {
  const { value, onClickAction, loading, removingResult } = props;
  const { getText } = useContext(LocalizationContext);
  const { location, locationTypeId, isDefault /*, status */ } = value || {};
  const {
    address1,
    address2,
    address2Name,
    city,
    state,
    // latitude,
    // longitude,
    countryId,
    postalCode,
  } = location || {};

  const handleClickAction = useCallback(
    (action) => {
      if (check.function(onClickAction)) {
        onClickAction(action, value);
      } else if (!onClickAction) {
        console.warn(
          'The "UserLocationItem" component expected the "onClickAction" to be a function but got:',
          onClickAction
        );
      }
    },
    [onClickAction, value]
  );

  const DefaultTag = useMemo(
    () =>
      isDefault ? (
        <Tag
          variant={Badge.VARIANT_NAMES.WARNING}
          dimension={Badge.DIMENSION_NAMES.SMALL}
        >
          {getText('generic.default')}
        </Tag>
      ) : null,
    [isDefault, getText]
  );

  if (!value) {
    return null;
  }

  const removingResult2use =
    removingResult instanceof OpResult ? removingResult : null;
  const error = removingResult2use?.getErrorSummary() ?? '';

  return (
    <Fragment>
      <SettingsRow name={locationTypeId}>
        <Grid>
          <GridItem width={width1}>
            <Skeleton loading={loading}>
              <Input
                leftIcon={locationTypeToIcon(locationTypeId)}
                value={`${
                  address2 ? `${address2Name} ${address2}, ` : ''
                }${address1}`}
                readOnly
                RightSide={DefaultTag}
              />
            </Skeleton>
          </GridItem>
          <GridItem width={width2}>
            <Skeleton loading={loading}>
              <Input value={city} readOnly />
            </Skeleton>
          </GridItem>
          <GridItem width={width2}>
            <Skeleton loading={loading}>
              <Input value={state} readOnly />
            </Skeleton>
          </GridItem>
          <GridItem width={width2}>
            <Skeleton loading={loading}>
              <Input value={postalCode} readOnly />
            </Skeleton>
          </GridItem>
          <GridItem width={width2}>
            <Skeleton loading={loading}>
              <Input value={countryId} readOnly />
            </Skeleton>
          </GridItem>
        </Grid>
        {!!onClickAction && (
          <Skeleton loading={loading}>
            <ItemsGroup>
              <Button
                {...BUTTONS.EDIT_ALT}
                dimension={Button.DIMENSION_NAMES.SMALL}
                onClick={() => handleClickAction(BUTTONS.EDIT_ALT)}
              />
              {!isDefault && (
                <Button
                  {...BUTTONS.MAKE_DEFAULT}
                  dimension={Button.DIMENSION_NAMES.SMALL}
                  onClick={() => handleClickAction(BUTTONS.MAKE_DEFAULT)}
                />
              )}
              <ConfirmationButton
                {...BUTTONS.REMOVE}
                dimension={Button.DIMENSION_NAMES.SMALL}
                onConfirm={() => handleClickAction(BUTTONS.REMOVE)}
                progress={removingResult2use?.isInProgress()}
              />
              {!!error && (
                <Text variant={Text.VARIANT_NAMES.DANGER}>{error}</Text>
              )}
            </ItemsGroup>
          </Skeleton>
        )}
      </SettingsRow>
      <HR />
    </Fragment>
  );
});

UserLocationItem.displayName = 'UserLocationItem';

UserLocationItem.propTypes = {
  value: PropTypes.shape({}),
  onClickAction: PropTypes.func,
  loading: PropTypes.bool,
  removingResult: PropTypes.any,
};

UserLocationItem.defaultProps = {
  userLocation: null,
};

export { UserLocationItem };
