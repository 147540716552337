import { memo, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';
import { omit } from 'lodash';
import { OpResult } from '@sdflc/api-helpers';
import {
  Actions,
  Flex,
  ItemsGroup,
  Text,
  Badge,
  Row,
  Button,
  Skeleton,
  VisibleBelow,
  VisibleAbove,
} from '@sdflc/ui';

import { getOrderItemActionButtons } from '../../../utils';
import { LocalizationContext } from '../../../contexts';
import { ACTIONS_IDS, ORDER_ITEM_STATUSES } from '../../../config';

import { OrderItemImage } from '../../OrderItemImage';

import {
  StyledOrderItemCardWrapper,
  StyledOrderItemCardBlock,
  StyledOrderItemName,
  StyledOrderItemComment,
  StyledMobileActionsContainer,
  StyledOrderItemCardBlockHead,
} from './styled';

const OrderItemsListItem = memo((props) => {
  const {
    value,
    onClickAction,
    loading,
    removingResult,
    readOnly,
    shopMode,
    order,
  } = props;
  const { getText } = useContext(LocalizationContext);
  const {
    unitId,
    quantity,
    // actualQuantity,
    // price,
    // totalAmount,
    // minPricePerUnit,
    // maxPricePerUnit,
    onlyIfOnSaleIndicator,
    //notImportantIndicator,
    shoppingItem,
    buyerComment,
    //shopperComment,
    //shoppingItemId,
  } = value || {};
  const { shoppingItemName, pictureUrl } = shoppingItem || {
    shoppingItemName: 'N/A',
  };

  const isTaken = value.status === ORDER_ITEM_STATUSES.TAKEN;

  const { mainActions, extraActions } = useMemo(() => {
    if (!check.function(onClickAction)) {
      return [];
    }

    const allActions = getOrderItemActionButtons({
      shopMode,
      readOnly,
      orderItem: value,
      order,
    });

    const mainActions = allActions.length > 0 ? [allActions.pop()] : [];
    const extraActions = allActions;

    return { mainActions, extraActions };
  }, [readOnly, shopMode, onClickAction, value, order]);

  const handleClickAction = useCallback(
    (action) => {
      if (check.function(onClickAction)) {
        let valueToUse = omit(value, ['shoppingItem']);

        if (action.id === ACTIONS_IDS.ORDER_ITEM_SELECT) {
          valueToUse = omit(valueToUse, ['id']);
        }

        onClickAction(action, valueToUse);
      } else {
        console.warn(
          'The "OrderItemsListItem" component expected the "onClickAction" to be a function but got:',
          onClickAction
        );
      }
    },
    [onClickAction, value]
  );

  const handleClickToggleSelection = useCallback(() => {
    handleClickAction({
      id: isTaken ? ACTIONS_IDS.RETURN_ORDER_ITEM : ACTIONS_IDS.TAKE_ORDER_ITEM,
    });
  }, [handleClickAction, isTaken]);

  const removingResult2use =
    removingResult instanceof OpResult ? removingResult : null;
  const error = removingResult2use?.getErrorSummary() ?? '';

  if (!value) {
    return null;
  }

  return (
    <StyledOrderItemCardWrapper>
      <StyledOrderItemCardBlockHead>
        <ItemsGroup>
          <Skeleton loading={loading}>
            <OrderItemImage name={shoppingItemName} pictureUrl={pictureUrl} />
          </Skeleton>
          <Flex flexDirection='column' flex='1 1' overflow='hidden'>
            <Skeleton loading={loading}>
              <StyledOrderItemName title={shoppingItemName}>
                {shoppingItemName}
              </StyledOrderItemName>
              <Text>
                {quantity} {unitId}
              </Text>
            </Skeleton>
          </Flex>
          {(mainActions.length > 0 || extraActions.length > 0) && (
            <Flex ml='auto'>
              <Skeleton loading={loading}>
                <VisibleAbove breakpoint='sm'>
                  <Actions
                    actions={extraActions}
                    onClick={handleClickAction}
                    dimension={Button.DIMENSION_NAMES.SMALL}
                    actionInProgress={removingResult2use?.isInProgress()}
                  />
                </VisibleAbove>
                <Actions
                  actions={mainActions}
                  onClick={handleClickAction}
                  dimension={Button.DIMENSION_NAMES.SMALL}
                  actionInProgress={removingResult2use?.isInProgress()}
                />
              </Skeleton>
            </Flex>
          )}
        </ItemsGroup>
      </StyledOrderItemCardBlockHead>
      {extraActions.length > 0 && (
        <VisibleBelow breakpoint='md'>
          <StyledOrderItemCardBlock>
            <Skeleton loading={loading}>
              <StyledMobileActionsContainer>
                <Actions
                  actions={extraActions}
                  onClick={handleClickAction}
                  dimension={Button.DIMENSION_NAMES.SMALL}
                  actionInProgress={removingResult2use?.isInProgress()}
                />
              </StyledMobileActionsContainer>
            </Skeleton>
          </StyledOrderItemCardBlock>
        </VisibleBelow>
      )}
      {shopMode && (
        <StyledOrderItemCardBlock>
          <Skeleton loading={loading}>
            <Button
              variant={
                isTaken
                  ? Button.VARIANT_NAMES.SECONDARY
                  : Button.VARIANT_NAMES.PRIMARY
              }
              dimension={Button.DIMENSION_NAMES.SMALL}
              icon={isTaken ? 'Close' : 'ShoppingCart'}
              name={getText(
                `buttons.${isTaken ? 'orderItemReturn' : 'orderItemTake'}`
              )}
              onClick={handleClickToggleSelection}
              width={'100%'}
            />
          </Skeleton>
        </StyledOrderItemCardBlock>
      )}
      {buyerComment || onlyIfOnSaleIndicator ? (
        <StyledOrderItemCardBlock>
          <Skeleton loading={loading}>
            <Row>
              <Flex flex='1 1' overflow='hidden'>
                <StyledOrderItemComment>
                  {buyerComment || <span>&nbsp;</span>}
                  {!!error && (
                    <Text variant={Text.VARIANT_NAMES.DANGER}>{error}</Text>
                  )}
                </StyledOrderItemComment>
              </Flex>
              <Flex>
                {onlyIfOnSaleIndicator && (
                  <Badge
                    variant={Badge.VARIANT_NAMES.DANGER}
                    dimension={Badge.DIMENSION_NAMES.SMALL}
                  >
                    {getText('generic.onSale')}
                  </Badge>
                )}
              </Flex>
            </Row>
          </Skeleton>
        </StyledOrderItemCardBlock>
      ) : (
        <VisibleAbove breakpoint='md'>
          <StyledOrderItemCardBlock>
            <Row>
              <Skeleton loading={loading}>
                <Flex flex='1 1' overflow='hidden'>
                  <StyledOrderItemComment>
                    <span>&nbsp;</span>
                  </StyledOrderItemComment>
                </Flex>
              </Skeleton>
            </Row>
          </StyledOrderItemCardBlock>
        </VisibleAbove>
      )}
    </StyledOrderItemCardWrapper>
  );
});

OrderItemsListItem.displayName = 'OrderItemsListItem';

OrderItemsListItem.propTypes = {
  value: PropTypes.shape({}),
  onClickAction: PropTypes.func,
  shopMode: PropTypes.bool,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
  removingResult: PropTypes.any,
};

OrderItemsListItem.defaultProps = {
  value: null,
  shopMode: false,
  readOnly: false,
  loading: false,
};

export { OrderItemsListItem };
