import { useCallback, useContext, useEffect, useState } from 'react';
import { cloneDeep, omit } from 'lodash';

import { useAppUserL10N } from 'graphql/hooks';
import { AuthContext } from 'contexts';

const tplNewAppUserL10N = {
  lang: 'en',
  displayName: '',
  preferredName: '',
};

const useUserNameEditor = (props) => {
  const [userNames, setUserNames] = useState([cloneDeep(tplNewAppUserL10N)]);
  const { appMe } = useContext(AuthContext);
  const {
    appUserL10NList,
    //appUserL10NListLoading,
    appUserL10NListCalled,
    appUserL10Ns,
    appUserL10NSet,
    appUserL10NSetLoading,
    appUserL10NSetCalled,
    appUserL10NSetReset,
    appUserL10NSetResult,
  } = useAppUserL10N();

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of new contact

    // Contact is not being saved yet
    if (appUserL10NListCalled) {
      return;
    }

    appUserL10NList({ filter: {} });
  }, [appUserL10NList, appUserL10NListCalled]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of new contact

    // Contact is not being saved yet
    if (!appUserL10NListCalled) {
      return;
    }

    if (appUserL10Ns.isLoading()) {
      return;
    }

    if (appUserL10Ns.didSucceed()) {
      if (Array.isArray(appUserL10Ns.data) && appUserL10Ns.data.length > 0) {
        setUserNames(appUserL10Ns.data);
      } else {
        setUserNames([cloneDeep(tplNewAppUserL10N)]);
      }
    }
  }, [setUserNames, appUserL10Ns, appUserL10NListCalled]);

  const handleSubmit = useCallback(
    (formData) => {
      appUserL10NSet({ params: [omit(formData, ['id'])] });
    },
    [appUserL10NSet]
  );

  useEffect(() => {
    if (
      appUserL10NSetCalled &&
      !appUserL10NSetLoading &&
      appUserL10NSetResult.didSucceed()
    ) {
      appUserL10NSetReset();
      appMe();
    }
  }, [
    appUserL10NSetResult,
    appUserL10NSetCalled,
    appUserL10NSetLoading,
    appUserL10NSetReset,
    appMe,
  ]);

  return {
    userNames,
    appUserL10Ns,
    handleSubmit,
    submitLoading: appUserL10NSetResult.isInProgress(),
    submitCalled: appUserL10NSetCalled,
    submitResult: appUserL10NSetResult,
    submitReset: appUserL10NSetReset,
  };
};

export { useUserNameEditor };
