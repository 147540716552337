import React, { memo } from 'react';

import { OrderWithItemsAndSummary, OrderComplete } from 'components';

const PageOrderComplete = memo(() => {
  return (
    <OrderWithItemsAndSummary
      hideSummaryBox
      titleId={'pages.order.complete.title'}
    >
      <OrderComplete />
    </OrderWithItemsAndSummary>
  );
});

PageOrderComplete.displayName = 'PageOrderComplete';

export { PageOrderComplete };
