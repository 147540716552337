import React, {
  memo,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import {
  PageDrawer,
  PageDrawerBlock,
  BasicNavigation,
  Flex,
  Button,
  colors,
} from '@sdflc/ui';

import { Logo } from 'components/Logo';
import { UserBadge } from 'components/UserBadge';

import { AuthContext, LocalizationContext } from 'contexts';
import { siteMap, appUrls } from 'config';
import check from 'check-types';

const NavigationDrawer = memo((props) => {
  const { isTemporary, isOpen, onClose } = props;
  const { getText } = useContext(LocalizationContext);
  const { appUser, signOut, signOutCalled, signOutLoading } =
    useContext(AuthContext);

  const handleClickSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  useEffect(() => {
    if (signOutCalled && !signOutLoading) {
      window.location.reload();
    }
  }, [signOutCalled, signOutLoading]);

  const menuItems = useMemo(() => {
    const menu = siteMap;

    const processItem = (items) => {
      return items
        .filter((page) => page.visible === true)
        .map((item) => {
          return {
            icon: item.icon,
            text: getText(item.name),
            to: item.url,
            items: processItem(item.items ?? []),
          };
        });
    };

    return processItem(menu);
  }, [getText]);

  const isActiveLink = useCallback((match, currentLocation) => {
    let isActive = false;

    if (!match) {
      return isActive;
    }

    const page = appUrls[match.url];

    if (match.isExact) {
      isActive = true;
    } else if (page?.activePattern) {
      isActive =
        currentLocation.pathname.match(new RegExp(page.activePattern, 'gim')) !=
        null;
    } else {
      isActive = match.url === currentLocation.pathname;
    }

    return isActive;
  }, []);

  return (
    <PageDrawer
      isOpen={isOpen}
      variant={
        isTemporary
          ? PageDrawer.variants.TEMPORARY
          : PageDrawer.variants.PERSISTENT
      }
      onClose={onClose}
      width={isOpen ? '100%' : '0px'}
      maxWidth='320px'
    >
      <PageDrawerBlock variant='header'>
        <Flex
          width='100%'
          alignItems='center'
          justifyContent='space-between'
          flexDirection='row'
        >
          <Logo size='small' />
          {check.function(onClose) && (
            <Button
              variant='tertiary'
              icon='Close'
              onClick={onClose}
              color={colors.blue}
            />
          )}
        </Flex>
      </PageDrawerBlock>
      <PageDrawerBlock variant='fullHeight'>
        <BasicNavigation
          direction='column'
          items={menuItems}
          paddingLeft={3}
          withIcons={true}
          isActiveLink={isActiveLink}
        />
      </PageDrawerBlock>
      <PageDrawerBlock variant='footer'>
        <Flex flexDirection='column' width={'100%'}>
          <UserBadge appUser={appUser} />
          <Button
            name={getText('buttons.signOut')}
            icon='Logout'
            variant={Button.VARIANT_NAMES.SECONDARY}
            dimension={Button.DIMENSION_NAMES.SMALL}
            width={'100%'}
            onClick={handleClickSignOut}
          />
        </Flex>
      </PageDrawerBlock>
    </PageDrawer>
  );
});

NavigationDrawer.displayName = 'NavigationDrawer';

NavigationDrawer.propTypes = {
  isTemporary: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export { NavigationDrawer };
