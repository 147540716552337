import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { LocalizationContext, OrdersContext } from 'contexts';
import { Button, Caption, ItemsGroup, Panel, Text } from '@sdflc/ui';
import { OPERATIONS } from 'utils';
import { ACTIONS_IDS } from 'config';
import { OrderReceiptListItem } from './OrderReceiptListItem';
import { TitledSection } from 'components/TitledSection';

const OrderReceipts = memo((props) => {
  const { shopMode, readOnly } = props;
  const {
    orderHooks,
    orderReceiptHooks,
    handleClickAction,
    handleClickReceiptAction,
  } = useContext(OrdersContext);
  const { getText } = useContext(LocalizationContext);
  const { orderReceipts, orderReceiptsOpsManager } = orderReceiptHooks;
  const { order } = orderHooks;

  return (
    <TitledSection
      title={'pages.orders.summaryBox.receipts'}
      distanceFromTitle={3}
    >
      {!readOnly && (
        <Button
          name={getText('buttons.add')}
          icon='Add'
          onClick={() =>
            handleClickAction({ id: ACTIONS_IDS.ADD_RECEIPT }, order)
          }
          variant={Button.VARIANT_NAMES.TERTIARY}
          dimension={Button.DIMENSION_NAMES.SMALL}
        />
      )}
      <ItemsGroup>
        {orderReceipts.map((orderReceipt) => {
          const orderReceiptRemoveOp = orderReceiptsOpsManager.get(
            orderReceipt.id,
            OPERATIONS.REMOVE_MANY
          );
          return (
            <OrderReceiptListItem
              key={orderReceipt.id}
              onActionClick={handleClickReceiptAction}
              value={orderReceipt}
              shopMode={shopMode}
              readOnly={readOnly || order.shopperId !== orderReceipt.createdBy}
              orderReceiptRemoveOp={orderReceiptRemoveOp}
            />
          );
        })}
        {orderReceipts.length === 0 && (
          <Text dimension={Text.DIMENSION_NAMES.SMALL}>
            {getText('blocks.receipts.noItems')}
          </Text>
        )}
      </ItemsGroup>
    </TitledSection>
  );
});

OrderReceipts.displayName = 'OrderReceipts';
OrderReceipts.propTypes = {
  shopMode: PropTypes.bool,
  readOnly: PropTypes.bool,
};
OrderReceipts.defaultProp = {};

export { OrderReceipts };
