import { memo, useMemo, useContext, useEffect } from 'react';
//import PropTypes from 'prop-types';
import { Select } from '@sdflc/ui';
import { StoreBrandContext, LocalizationContext } from 'contexts';

const SelectStoreBrand = memo((props) => {
  const {
    storeBrands,
    storeBrandList,
    storeBrandListCalled,
    storeBrandListLoading,
  } = useContext(StoreBrandContext);
  const { getText } = useContext(LocalizationContext);

  useEffect(() => {
    if (storeBrandListCalled || storeBrandListLoading) {
      return;
    }

    if (Array.isArray(storeBrands.data) && storeBrands.data.length > 0) {
      return;
    }

    storeBrandList({ filter: {} });
  }, [
    storeBrands,
    storeBrandList,
    storeBrandListCalled,
    storeBrandListLoading,
  ]);

  const options = useMemo(() => {
    const { data } = storeBrands;

    if (!Array.isArray(data)) {
      return [];
    }

    if (storeBrands.isLoading()) {
      return [];
    }

    return data.map((item) => {
      return {
        value: item.id,
        imageUrl: item.storeBrandLogoUrl,
        text: item.l10n?.storeBrandName ?? item.id,
      };
    });
  }, [storeBrands]);

  return (
    <Select
      {...props}
      options={options}
      loading={storeBrands.isLoading()}
      loadingMessage={getText('generic.loading')}
      placeholder={getText('selectors.selectStoreBrand.placeholder')}
    />
  );
});

SelectStoreBrand.displayName = 'SelectStoreBrand';

SelectStoreBrand.propTypes = {
  ...Select.propTypes,
};

SelectStoreBrand.defaultProps = {};

export { SelectStoreBrand };
