import React, { Fragment, memo, useContext, useMemo } from 'react';
import {
  ImageViewerContext,
  LocalizationContext,
  OrdersContext,
} from 'contexts';
import {
  Caption,
  Icon,
  Panel,
  Text,
  Grid,
  GridItem,
  FormInput,
  InputDateTime,
  Actions,
  Button,
} from '@sdflc/ui';
import { BUTTONS_WITH_NAMES, ACTIONS_IDS, colors } from 'config';

import { InputUser } from 'components/Controls/InputUser';
import { InputCurrency } from 'components/Controls/InputCurrency';
import { getOrderStepPagePreRequisites } from './ordersHelpers';

const OrderPaid = memo(() => {
  const { getText } = useContext(LocalizationContext);
  const { setViewImageIdx } = useContext(ImageViewerContext);
  const {
    orderHooks,
    orderItemHooks,
    orderReceiptHooks,
    isLoading,
    handleClickAction,
  } = useContext(OrdersContext);
  const { order } = orderHooks;
  const { orderItems } = orderItemHooks;
  const { orderReceipts } = orderReceiptHooks;

  const { orderDetails, labelData, hintData } = useMemo(() => {
    return getOrderStepPagePreRequisites({
      order,
      orderItems,
      getText,
      stepName: 'orderPaid',
    });
  }, [order, orderItems, getText]);

  if (isLoading) {
    return null;
  }

  // if (order.currentStepId !== ORDER_STEPS.PAID) {
  //   goToPage(APP_PAGE_KEYS.ORDER_DETAILS, {
  //     orderId: order.id,
  //   });
  // }

  return (
    <Panel>
      <Grid>
        <GridItem width={['100%', '100%', '100%', '80%', '80%']}>
          <Caption level={3}>
            <Icon
              name='CheckBox'
              iconSize={64}
              color={colors.darkBlue}
              pr={2}
            />
            {getText('blocks.orderPaid.header', order)}
          </Caption>
          <Caption level={4}>
            <Text>{getText('blocks.orderPaid.title', order)}</Text>
          </Caption>
          <Text mt={3}>{getText('blocks.orderPaid.subtitle', order)}</Text>
        </GridItem>
        <GridItem width={['100%', '100%', '100%', '20%', '20%']}>
          <Actions
            actions={[BUTTONS_WITH_NAMES.GO_TO_ORDER_VIEW]}
            onClick={handleClickAction}
            dimension={Button.DIMENSION_NAMES.MEDIUM}
          />
        </GridItem>
        <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
          <FormInput
            labelData={labelData}
            hintData={hintData}
            data={orderDetails}
            inputComponent={InputUser}
            fieldName='buyer'
            readOnly
            buttonName={order.isMine ? '' : 'Rate'}
            onClick={() => {
              handleClickAction({ id: ACTIONS_IDS.RATE_BUYER }, order);
            }}
          />
        </GridItem>
        <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
          <FormInput
            labelData={labelData}
            hintData={hintData}
            data={orderDetails}
            inputComponent={InputUser}
            fieldName='shopper'
            readOnly
            buttonName={order.amIShopper ? '' : 'Rate'}
            onClick={() => {
              handleClickAction({ id: ACTIONS_IDS.RATE_BUYER }, order);
            }}
          />
        </GridItem>
        <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
          <FormInput
            labelData={labelData}
            hintData={hintData}
            data={orderDetails}
            fieldName='deliveredTo'
            readOnly
            leftIcon='Room'
          />
        </GridItem>
        <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
          <FormInput
            labelData={labelData}
            hintData={hintData}
            data={orderDetails}
            inputComponent={InputDateTime}
            fieldName='deliveredAt'
            readOnly
          />
        </GridItem>
        <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
          <FormInput
            labelData={labelData}
            hintData={hintData}
            data={orderDetails}
            fieldName='orderItemsQty'
            readOnly
            leftIcon='FormatListNumbered'
          />
        </GridItem>
        <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
          <FormInput
            labelData={labelData}
            hintData={hintData}
            data={orderDetails}
            fieldName='orderItemsTakenQty'
            readOnly
            leftIcon='FormatListNumbered'
          />
        </GridItem>
        <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
          <FormInput
            labelData={labelData}
            hintData={hintData}
            data={orderDetails}
            fieldName='orderAmount'
            inputComponent={InputCurrency}
            readOnly
            currencyCode={orderDetails.currency}
            buttonName={
              orderReceipts.length ? getText('buttons.viewReceipts') : undefined
            }
            onClick={
              orderReceipts.length
                ? () => {
                    setViewImageIdx(0);
                  }
                : undefined
            }
          />
        </GridItem>
        <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
          <FormInput
            labelData={labelData}
            hintData={hintData}
            data={orderDetails}
            fieldName='deliveryCost'
            inputComponent={InputCurrency}
            readOnly
            currencyCode={orderDetails.currency}
          />
        </GridItem>
        {order.isMine && (
          <Fragment>
            <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
              <FormInput
                labelData={labelData}
                hintData={hintData}
                data={orderDetails}
                fieldName='serviceFeeTotalAmount'
                inputComponent={InputCurrency}
                readOnly
                currencyCode={orderDetails.currency}
              />
            </GridItem>
            <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
              <FormInput
                labelData={labelData}
                hintData={hintData}
                data={orderDetails}
                fieldName='totalAmountForBuyer'
                inputComponent={InputCurrency}
                readOnly
                currencyCode={orderDetails.currency}
              />
            </GridItem>
          </Fragment>
        )}
        {!order.isMine && order.amIShopper && (
          <Fragment>
            <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
              <FormInput
                labelData={labelData}
                hintData={hintData}
                data={orderDetails}
                fieldName='totalAmountForShopper'
                inputComponent={InputCurrency}
                readOnly
                currencyCode={orderDetails.currency}
              />
            </GridItem>
          </Fragment>
        )}
        <GridItem width={['100%', '100%', '100%', '50%', '50%']}>
          <FormInput
            labelData={labelData}
            hintData={hintData}
            data={orderDetails}
            fieldName='paymentMethod'
            readOnly
          />
        </GridItem>
      </Grid>
    </Panel>
  );
});

OrderPaid.displayName = 'OrderPaid';

export { OrderPaid };
