import { colors, mediaQueries } from '@sdflc/ui';
import styled from 'styled-components';
import {
  compose,
  position,
  layout,
  space,
  size,
  color,
  typography,
  shadow,
  border,
  background,
  flex,
} from 'styled-system';

const func = compose(
  layout,
  position,
  space,
  size,
  color,
  typography,

  border,
  background,

  shadow,
  flex
);

export const StyledOrderItemCardWrapper = styled.div`
  width: 100%;

  background-color: ${colors.white};
  border-radius: 16px;
  box-shadow: 0px 3px 2px rgba(11, 11, 12, 0.1);
  /* margin-bottom: 32px; */

  ${func}

  ${mediaQueries.md} {
    width: calc((100% / 2) - 10px);
  }

  ${mediaQueries.lg} {
    width: calc((100% / 4) - 10px);
  }
`;

export const StyledOrderItemCardBlock = styled.div`
  padding: 6px 12px;
  border-bottom: solid 1px ${colors.grey03};

  &:first-child {
    padding: 12px;
    border-bottom: none;
  }
`;

export const StyledOrderItemName = styled.div`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const StyledOrderItemComment = styled.div`
  font-size: 13px;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
