import { memo, useMemo, useContext, useEffect } from 'react';
//import PropTypes from 'prop-types';
import { Select } from '@sdflc/ui';
import { UserLocationContext, LocalizationContext } from 'contexts';
import { locationTypeToIcon } from 'utils/iconHelpers';

const SelectUserLocation = memo((props) => {
  const { getText } = useContext(LocalizationContext);
  const { userLocations, userLocationList, userLocationsOpsManager } =
    useContext(UserLocationContext);

  const listOp = userLocationsOpsManager.getListOp();

  useEffect(() => {
    // Load user locations if they were not yet loaded

    if (!listOp.called) {
      userLocationList({
        filter: {},
        params: {},
      });
    }
  }, [userLocationList, listOp]);

  const options = useMemo(() => {
    if (listOp.result.isLoading()) {
      return [];
    }

    if (
      listOp.called &&
      !listOp.result.isInProgress() &&
      listOp.result.didFail()
    ) {
      return [
        {
          value: '',
          text: listOp.result.getErrorSummary(),
          icon: '',
        },
      ];
    }

    return userLocations.map((item) => {
      return {
        value: item.id,
        text: item.formattedAddress ?? item.id,
        icon: locationTypeToIcon(item.locationTypeId),
      };
    });
  }, [userLocations, listOp]);

  return (
    <Select
      {...props}
      options={options}
      loading={listOp.result.isLoading()}
      loadingMessage={getText('generic.loading')}
      placeholder={getText('selectors.selectUserLocation.placeholder')}
    />
  );
});

SelectUserLocation.displayName = 'SelectUserLocation';

SelectUserLocation.propTypes = {
  ...Select.propTypes,
};

SelectUserLocation.defaultProps = {};

export { SelectUserLocation };
