import { FORM_INPUT_TYPES } from '@sdflc/ui';
import { SelectUserLocation, SelectStoreBrand } from 'components/Controls';

export const orderFormSections = [
  {
    fields: [
      {
        field: 'locationId',
        width: ['100%'],
        component: SelectUserLocation,
        componentProps: {},
      },
      {
        field: 'dueDate',
        type: FORM_INPUT_TYPES.DATETIME,
        required: true,
        width: ['100%'],
        componentProps: {
          timeStep: 30,
          time24h: true,
        },
      },
      {
        field: 'storeBrandId',
        width: ['100%'],
        component: SelectStoreBrand,
        componentProps: {},
      },
      {
        field: 'buyerComment',
        type: FORM_INPUT_TYPES.TEXTAREA,
        width: ['100%'],
        componentProps: {
          rows: 5,
        },
      },
    ],
  },
];
