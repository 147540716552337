import { memo } from 'react';
import PropTypes from 'prop-types';
import { Actions } from '@sdflc/ui';

const ButtonsSaveCancel = memo((props) => {
  const { onSave, onCancel } = props;

  const actions = [
    {
      id: 'save',
      variant: 'primary',
      name: 'Save',
      icon: 'Check',
    },
    {
      id: 'cancel',
      variant: 'tertiary',
      name: 'Cancel',
      icon: '',
    },
  ];

  return (
    <Actions
      actions={actions}
      dimension='default'
      onClick={(action) => {
        if (action.id === 'save' && typeof onSave === 'function') {
          onSave();
        } else if (typeof onSave === 'function') {
          onCancel();
        }
      }}
    />
  );
});

ButtonsSaveCancel.displayName = 'ButtonsSaveCancel';

ButtonsSaveCancel.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

ButtonsSaveCancel.defaultProps = {};

export { ButtonsSaveCancel };
