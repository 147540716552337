import React, { memo, useCallback, useContext } from 'react';
//import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  ButtonUpload,
  ItemsGroup,
  ConfirmationButton,
} from '@sdflc/ui';
import { AuthContext, LocalizationContext } from 'contexts';
import { logger } from 'utils';

const AvatarChange = memo(() => {
  const { getText } = useContext(LocalizationContext);
  const {
    appUser,
    // updateUser,
    // updateUserResult,
    // updateUserCalled,
    // updateUserLoading,
    // updateUserReset,
  } = useContext(AuthContext);

  const avatarUrl = appUser?.user?.avatarUrl;

  const handleChangeChooseFile = useCallback((name, value) => {
    console.log('Selected file', { name, value });
  }, []);

  const handleConfirmRemove = useCallback(() => {
    logger.debug('Remove the avatar');
  }, []);

  return (
    <ItemsGroup>
      <Avatar src={avatarUrl} dimension='large' variant='circle' />
      <ButtonUpload
        name={getText('buttons.chooseFile')}
        icon='CloudUpload'
        onChange={handleChangeChooseFile}
        variant={Button.VARIANT_NAMES.PRIMARY}
        acceptMimeTypes={['image/*']}
      />
      <ConfirmationButton
        name={getText('buttons.remove')}
        icon='Delete'
        onConfirm={handleConfirmRemove}
        variant={Button.VARIANT_NAMES.DANGER}
        hideTextOnBreakpoints={['xs', 'sm']}
      />
    </ItemsGroup>
  );
});

AvatarChange.displayName = 'AvatarChange';

AvatarChange.propTypes = {};

AvatarChange.defaultProp = {};

export { AvatarChange };
