import { mapArrayBy } from '@sdflc/utils';
import { logger } from './logger';

import { OperationManager } from './OperationManager';
import { OPERATIONS } from './operations';
import { arrayOfObjectsToArray, arrayOfObjectsToSet } from './transformers';
import check from 'check-types';
import { OP_RESULT_CODES, OpResult } from '@sdflc/api-helpers';
import { cloneDeep } from 'lodash';
import { processDttmFields } from './processDttmFields';

const DEFAULT_FIELD_ID = 'id';

export const handleApiResult = (operation, opts) => (result, clientOptions) => {
  const {
    hookName,
    opsManager,
    setItems,
    setItemsMany,
    setItem,
    transformItem,
    afterSetting,
    customOperations,
  } = opts || {};
  const transformItemToUse = check.function(transformItem)
    ? transformItem
    : (item) => item;
  const afterSettingToUse = check.function(afterSetting)
    ? afterSetting
    : (items) => {};
  const idField = opts?.idField || DEFAULT_FIELD_ID;

  if (check.function(customOperations?.[operation])) {
    logger.debug(`The "${hookName}" calls custom operation:`, operation);

    customOperations?.[operation](result, clientOptions);

    return;
  }

  switch (operation) {
    default:
      logger.warn(
        `The "${hookName}" hook got unexpected value of operation: `,
        operation
      );
      break;

    case OPERATIONS.LIST:
      if (result.didSucceed()) {
        const items = result.getData().map((item) => transformItemToUse(item));
        setItems(items);
        afterSettingToUse(items);
      }

      opsManager.setResult(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        result
      );

      break;

    case OPERATIONS.GET:
      {
        const item = result.getDataFirst();

        if (result.didSucceed()) {
          setItem(transformItemToUse(item));
        }

        opsManager.setResult(
          clientOptions?.variables?.id ?? item?.id,
          OPERATIONS.GET,
          result
        );
      }
      break;

    case OPERATIONS.GET_MANY:
      {
        const apiItems = result.getData();

        if (result.didSucceed()) {
          const items = apiItems.map((item) => transformItemToUse(item));
          setItemsMany(items);
          afterSettingToUse(items);
        }

        opsManager.setResult(
          arrayOfObjectsToArray({ data: apiItems, fieldName: 'id' }),
          OPERATIONS.GET_MANY,
          result
        );
      }
      break;

    case OPERATIONS.CREATE:
      {
        const apiItem = result.getDataFirst();

        if (apiItem) {
          if (result.didSucceed()) {
            const newItem = transformItemToUse(apiItem);
            setItem(newItem);
            setItems((state) => {
              return [newItem, ...state];
            });
          }
        }

        opsManager.setResult(
          OperationManager.defaultContext,
          OPERATIONS.CREATE,
          result
        );
      }
      break;

    case OPERATIONS.UPDATE:
      {
        const apiItem = result.getDataFirst();

        if (apiItem) {
          if (result.didSucceed()) {
            const newItem = transformItemToUse(apiItem);
            setItem(newItem);
            setItems((state) => {
              return state.map((item) => {
                return item?.[idField] === apiItem?.[idField] ? newItem : item;
              });
            });
          }
        }

        const id = clientOptions.variables.where.id || '';
        opsManager.setResult(id, OPERATIONS.UPDATE, result);
      }
      break;

    case OPERATIONS.SET:
      {
        const apiItems = result.getData();

        if (result.didSucceed()) {
          const mapApiItems = mapArrayBy(apiItems, idField);
          const setApiItems = new Set(
            mapApiItems.map((mapApiItem) => mapApiItem[idField])
          );

          setItems((state) => {
            const updItems = state.map((item) => {
              const id = item[idField];
              if (!mapApiItems[id]) {
                return item;
              }

              setApiItems.delete(id);

              return mapApiItems[id];
            });

            if (setApiItems.size()) {
              setApiItems.forEach((id) => {
                updItems.push(mapApiItems[id]);
              });
            }

            return updItems.map((upItem) => transformItemToUse(upItem));
          });
        }

        const ids = arrayOfObjectsToArray({
          data: clientOptions.variables.params,
          fieldName: idField,
        });

        opsManager.setResult(ids, OPERATIONS.SET, result);
      }
      break;

    case OPERATIONS.REMOVE_MANY:
      {
        const apiItems = result.getData();

        if (result.didSucceed()) {
          const setApiItemIds = arrayOfObjectsToSet({
            data: apiItems,
            fieldName: idField,
          });

          setItems((state) => {
            return state.filter((item) => {
              return !setApiItemIds.has(item?.id);
            });
          });
        }

        const ids = arrayOfObjectsToArray({
          data: clientOptions.variables.where,
          fieldName: idField,
        });

        opsManager.setResult(ids, OPERATIONS.REMOVE_MANY, result);
      }
      break;
  }
};

// TODO: THis function all the time generates new functions instead of guetting the same function references which causes extra re-renders
// export const useBuildCrudCalls = (args) => {
//   const { hookName, queries, handleApiResultOpts, mapQueryNameToOperation } =
//     args || {};

//   const resultFunctions = {};

//   Object.values(queries).forEach((query) => {
//     const operation = query.definitions?.[0]?.operation || 'N/A';
//     const queryName =
//       query.definitions?.[0]?.selectionSet?.selections?.[0]?.name?.value ||
//       'N/A';
//     const funcName = camelCase(`${operation}_${queryName}`);
//     const opId = mapQueryNameToOperation[queryName] || queryName;

//     switch (operation) {
//       default:
//         logger.warn(
//           `The "${hookName}" got a query "${queryName}" with unsupported operation "${operation}":`,
//           query
//         );
//         break;

//       case 'mutation':
//         {
//           // eslint-disable-next-line
//           const [mutationFunc] = useAppMutation({
//             queries: queries,
//             queryName,
//             onDone: handleApiResult(opId, handleApiResultOpts),
//           });
//           resultFunctions[funcName] = mutationFunc;
//         }
//         break;

//       case 'query':
//         {
//           // eslint-disable-next-line
//           const [queryFunc] = useAppQuery({
//             queries: queries,
//             queryName,
//             onDone: handleApiResult(opId, handleApiResultOpts),
//           });
//           resultFunctions[funcName] = queryFunc;
//         }
//         break;
//     }
//   });

//   return resultFunctions;
// };

export const processInPlaceResult = (args) => {
  const { response, queryName, variables, onDone, debug } = args ?? {};

  let result = null;

  if (debug) {
    logger.debug(`processInPlaceResult({ queryName: ${queryName} }).response`, {
      variables,
      response,
    });
  }

  if (response?.data) {
    result = new OpResult(cloneDeep(response.data[queryName]));
    processDttmFields(result);
  } else if (response?.error?.message) {
    result = new OpResult()
      .setData([])
      .setCode(OP_RESULT_CODES.NETWORK_ERROR)
      .addError('', response?.error.message);
  }

  if (check.function(onDone)) {
    onDone(result, { variables });
  }
};
