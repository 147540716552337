export const loadScript = (url, callback) => {
  return new Promise((resolve /*, reject*/) => {
    if (document.querySelector(`script[src="${url}"]`)) {
      resolve();
      return;
    }

    const script = document.createElement('script'); // create script tag
    script.type = 'text/javascript';

    // when script state is ready and loaded or complete we will call resolve
    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null;
          resolve();
        }
      };
    } else {
      script.onload = () => resolve();
    }

    script.src = url; // load by url
    document.getElementsByTagName('head')[0].appendChild(script); // append to head
  });
};

export const loadGoogleMapsPlacesApi = async (args) => {
  const { apiKey } = args || {};

  if (!apiKey) {
    console.warn(
      'The "loadGoogleMapsPlacesApi" function expects "apiKey" in its args object but got: ',
      apiKey
    );
  }

  await loadScript(
    `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
  );
};

export const handlePlaceSelect = async (updateQuery) => {
  const addressObject = window.autoComplete.getPlace(); // get place from google api
  updateQuery(addressObject);
};

// handle when the script is loaded we will assign autoCompleteRef with google maps place autocomplete
export const initGoogleMapsPlacesAutocomplete = ({
  onSelect,
  autoCompleteRef,
  countries,
}) => {
  // assign autoComplete with Google maps place one time
  window.autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {
      componentRestrictions: {
        country: Array.isArray(countries) ? countries : ['us', 'ca'],
      },
      fields: ['address_components', 'formatted_address', 'geometry'],
      types: ['address'],
    }
  );
  // specify what properties we will get from API
  window.autoComplete.setFields(['address_components', 'formatted_address']);
  // add a listener to handle when the place is selected
  window.autoComplete.addListener('place_changed', () =>
    handlePlaceSelect(onSelect)
  );
};

const addrComponentForm = {
  street_number: { from: 'short_name', to: 'streetNumber' },
  route: { from: 'long_name', to: 'streetName' },
  locality: { from: 'long_name', to: 'city' },
  administrative_area_level_1: { from: 'long_name', to: 'state' },
  country: [
    { from: 'long_name', to: 'country' },
    { from: 'short_name', to: 'countryId' },
  ],
  postal_code: { from: 'short_name', to: 'postalCode' },
  lng: { from: 'lng', to: 'longitude' },
  lat: { from: 'lat', to: 'latitude' },
  formatted_address: { from: 'formatted_address', to: 'formattedAddress' },
  address1: { to: 'address1' },
};

export const fillInAddress = (args) => {
  const { place } = args || {};
  const form = args.form || addrComponentForm;

  const addr = {};

  if (place == null || place.address_components == null || addr == null) {
    return addr;
  }

  addr[form['formatted_address']['to']] =
    place[form['formatted_address']['from']] || '';

  // Get each component of the address from the place details
  // and fill the corresponding field on the form.
  for (let i = 0; i < place.address_components.length; i++) {
    const addressType = place.address_components[i].types[0];

    if (form[addressType]) {
      if (form[addressType] instanceof Array) {
        for (let j = 0; j < form[addressType].length; j++) {
          if (form[addressType][j]['from']) {
            const val =
              place.address_components[i][form[addressType][j]['from']];
            //if (addr[form[addressType][j]['to']] != undefined)
            addr[form[addressType][j]['to']] = val;
          }
        }
      } else if (form[addressType]['from']) {
        const val = place.address_components[i][form[addressType]['from']];
        //if (addr[form[addressType]['to']] != undefined)
        addr[form[addressType]['to']] = val;
      }
    }
  }

  addr[form['address1']['to']] = `${addr[form['street_number']['to']] || ''} ${
    addr[form['route']['to']] || ''
  }`.trim();

  if (place.geometry) {
    addr[form['lng']['to']] = place.geometry.location.lng();
    addr[form['lat']['to']] = place.geometry.location.lat();
  }

  return addr;
};

export const diffObject = (obj1, obj2, exclude) => {
  const r = {};

  if (!exclude) {
    exclude = [];
  }

  for (var prop in obj1) {
    if (obj1.hasOwnProperty(prop) && prop !== '__proto__') {
      if (exclude.indexOf(obj1[prop]) !== -1) {
        continue;
      }

      if (!obj2.hasOwnProperty(prop)) {
        // check if obj2 has prop
        r[prop] = obj1[prop];
      } else if (obj1[prop] === Object(obj1[prop])) {
        // check if prop is object and
        // NOT a JavaScript engine object (i.e. __proto__), if so, recursive diff
        var difference = diffObject(obj1[prop], obj2[prop]);
        if (Object.keys(difference).length > 0) r[prop] = difference;
      } else if (obj1[prop] !== obj2[prop]) {
        // check if obj1 and obj2 are equal
        if (obj1[prop] === undefined) {
          r[prop] = 'undefined';
        } else if (obj1[prop] === null) {
          r[prop] = null;
        } else if (typeof obj1[prop] === 'function') {
          r[prop] = 'function';
        } else if (typeof obj1[prop] === 'object') {
          r[prop] = 'object';
        } else {
          r[prop] = obj1[prop];
        }
      }
    }
  }

  return r;
};

export const diffObjectProps = (obj1, obj2, exclude) => {
  const diffObj = diffObject(obj1, obj2, exclude);
  return Object.keys(diffObj);
};
