import { Fragment, memo, useContext } from 'react';
import PropTypes from 'prop-types';

import { FormDrawer } from '@sdflc/ui';

import { AppToolbar } from 'components/AppToolbar';
import { Block } from 'components/Block';

import { LocalizationContext } from '../../../contexts';

import { UserShoppingItemsList } from './UserShoppingItemsList';

import { useUserShoppingItems } from './useUserShoppingItems';

const UserShoppingItems = memo((props) => {
  const { hasTitle } = props;
  const { getText } = useContext(LocalizationContext);
  const {
    open,
    userShoppingItems,
    userShoppingItemList,
    formData,
    formCfg,
    formActions,
    handleClickAction,
    handleClickClose,
    userShoppingItemsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOpResult,
    removingOps,
  } = useUserShoppingItems();

  return (
    <Fragment>
      <Block title={hasTitle ? getText('pages.userShoppingItems.title') : ''}>
        <AppToolbar items={toolBarItems} onClick={handleClickAction} />
        <UserShoppingItemsList
          onClickAction={handleClickAction}
          list={userShoppingItemList}
          items={userShoppingItems}
          listOp={userShoppingItemsOpsManager.getListOp()}
          removingOps={removingOps}
        />
      </Block>
      <FormDrawer
        isOpen={open}
        title={getText('pages.userShoppingItems.form.title.add')}
        description=''
        formData={formData}
        formCfg={formCfg}
        onClose={handleClickClose}
        actions={formActions}
        actionInProgress={actionInProgress}
        onClickAction={handleClickAction}
        progress={saveOpResult.isInProgress()}
        opResult={saveOpResult}
      />
    </Fragment>
  );
});

UserShoppingItems.displayName = 'UserShoppingItems';

UserShoppingItems.propTypes = {
  children: PropTypes.node,
  hasTitle: PropTypes.bool,
};

UserShoppingItems.defaultProps = {
  hasTitle: false,
};

export { UserShoppingItems };
