import { Fragment, memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Orders } from '../../components';
import { getLocation } from '../../utils/geoHelpers';
import { useToaster } from '@sdflc/ui';
import { ORDER_LIST_CATEGORIES } from 'config/orderCard';

/**
 * Page component with a list of orders available to pick by shoppers
 */
const PageOrdersToPick = memo(() => {
  const [position, setPosition] = useState();
  const [timestamp, setTimestamp] = useState(1);
  const toast = useToaster();

  useEffect(() => {
    const getData = async () => {
      const result = await getLocation();
      if (result.didSucceed()) {
        const data = result.getDataFirst();

        setPosition(data);
      } else {
        toast({
          message: `Failed to get coordinates: ${result.getErrorSummary()}`,
          variant: 'danger',
          timeout: 15,
        });
      }
      setTimestamp(new Date().getTime());
    };
    getData();
  }, [setPosition, toast]);

  return (
    <Fragment>
      <Orders
        key={timestamp}
        title='pages.publishedOrders.title'
        selectDistance
        showDistance
        orderListCategory={ORDER_LIST_CATEGORIES.PUBLISHED}
        orderListFilter={{
          userCoordinates: {
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
          },
        }}
        orderListPagination={{
          pagination: {
            page: 1,
            pageSize: 100,
          },
          sortBy: [
            {
              name: 'order.created_at',
              order: 'DESC',
            },
          ],
        }}
      />
    </Fragment>
  );
});

PageOrdersToPick.displayName = 'PageOrdersToPick';

PageOrdersToPick.propTypes = {
  children: PropTypes.node,
};

PageOrdersToPick.defaultProps = {};

export { PageOrdersToPick };
