import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { ToolBarItem } from '@sdflc/ui';

import { logger, onlyPropsOfObject, OperationManager, OPERATIONS } from 'utils';
import { ACTIONS_BUTTONS, ACTIONS_IDS } from 'config';

import { useUserLocation } from 'graphql/hooks';

import { userLocationForm } from './userLocationForm';
import { LocalizationContext } from 'contexts';

const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const tplNewUserLocation = {
  locationId: null,
  locationTypeId: 'home',
  notes: '',
  isDefault: false,
  location: {
    countryId: null,
    postalCode: '',
    address2: '',
    longitude: '',
    latitude: '',
    status: 100,
    l10n: {
      locationId: null,
      lang: 'en',
      state: '',
      city: '',
      address1: '',
      address2Name: '',
    },
  },
};

const useUserLocations = (props) => {
  const { getText } = useContext(LocalizationContext);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const {
    userLocation,
    userLocations,
    userLocationList,
    userLocationCreate,
    userLocationUpdate,
    userLocationRemoveMany,
    userLocationsOpsManager,
  } = useUserLocation();

  const locationId = formData?.id || '';
  const createOp = userLocationsOpsManager.getCreateOp();
  const updateOp = userLocationsOpsManager.get(locationId, OPERATIONS.UPDATE);
  const saveOp = locationId ? updateOp : createOp;
  const saveOpResult = saveOp.result;
  const removingOps = userLocationsOpsManager.getOpValues(
    OPERATIONS.REMOVE_MANY
  );

  useEffect(() => {
    logger.debug(`${!!formData ? 'Open' : 'Close'} drawer`);
    setOpen(!!formData);
  }, [formData]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of new user location item

    // User location item is not being saved yet
    if (!createOp.called) {
      return;
    }

    // User location create operation result is not in progress and succeeded
    if (!createOp.result.isInProgress() && createOp.result.didSucceed()) {
      userLocationsOpsManager.reset(
        OperationManager.defaultContext,
        OPERATIONS.CREATE
      );
      setFormData(null);
    }
  }, [createOp, userLocationsOpsManager]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of exising location

    // Contact is not being saved yet
    if (!updateOp.called) {
      return;
    }

    // location operation result is not in progress and succeeded
    if (!updateOp.result.isInProgress() && updateOp.result.didSucceed()) {
      userLocationsOpsManager.reset(locationId, OPERATIONS.UPDATE);
      setFormData(null);
    }
  }, [updateOp, userLocationsOpsManager, locationId]);

  const handleClickAction = useCallback(
    async (action, item) => {
      switch (action.id) {
        default:
          logger.warn('The "useUserLocations" got an unexpected action:', {
            action,
            item,
          });
          break;

        case ACTIONS_IDS.ADD:
        case ACTIONS_IDS.CREATE:
          userLocationsOpsManager.reset(
            OperationManager.defaultContext,
            OPERATIONS.CREATE
          );
          const toAdd = cloneDeep(tplNewUserLocation);
          toAdd.location.locationTypeId = tplNewUserLocation.locationTypeId;

          setFormData(toAdd);
          break;

        case ACTIONS_IDS.SAVE:
          const userLocation = {
            id: item.id,
            locationId: item.locationId,
            locationTypeId: item.locationId
              ? item.location.locationTypeId
              : item.locationTypeId,
            notes: item.location.notes,
            location: {
              countryId: item.location.countryId,
              postalCode: item.location.postalCode,
              address2: item.location.address2,
              longitude: `${item.location.longitude}`,
              latitude: `${item.location.latitude}`,
              status: 100,
              l10n: {
                locationId: null,
                lang: 'en',
                state: item.location.state,
                city: item.location.city,
                address1: item.location.address1,
                address2Name: item.location.address2Name,
              },
            },
          };

          if (!userLocation.id) {
            userLocationCreate({ params: userLocation });
          } else {
            userLocationUpdate({
              where: { id: userLocation.id },
              params: onlyPropsOfObject(userLocation, tplNewUserLocation),
            });
          }
          break;

        case ACTIONS_IDS.CANCEL:
          setFormData(null);
          break;

        case ACTIONS_IDS.EDIT:
          userLocationsOpsManager.reset(item?.id, OPERATIONS.UPDATE);
          const toEdit = cloneDeep(item);
          toEdit.location.locationTypeId = item.locationTypeId;
          setFormData(toEdit);
          break;

        case ACTIONS_IDS.REMOVE:
          userLocationRemoveMany({ where: [{ id: item.id }] });
          break;

        case ACTIONS_IDS.MAKE_DEFAULT:
          userLocationUpdate({
            where: { id: item.id },
            params: {
              isDefault: true,
            },
          });
          break;
      }
    },
    [
      userLocationCreate,
      userLocationUpdate,
      userLocationRemoveMany,
      userLocationsOpsManager,
    ]
  );

  const handleClickClose = useCallback(() => {
    handleClickAction({ id: ACTIONS_IDS.CANCEL }, null);
  }, [handleClickAction]);

  const formCfg = useMemo(() => {
    return {
      sections: userLocationForm,
      labels: getText('pages.userContacts.form.labels'),
      hints: getText('pages.userContacts.form.hints'),
    };
  }, [getText]);

  const formActions = useMemo(() => [BUTTONS.SAVE, BUTTONS.CANCEL], []);
  const actionInProgress = useMemo(
    () => (saveOpResult.isInProgress() ? [ACTIONS_IDS.SAVE] : []),
    [saveOpResult]
  );

  const toolBarItems = useMemo(() => {
    return [
      // {
      //   id: 'keyword',
      //   type: ToolBarItem.types.COMPONENT,
      //   name: 'keyword',
      //   value: filters.keyword,
      //   component: Input,
      //   placeholder: getText('generic.search'),
      //   leftIcon: 'Search',
      // },
      { ...BUTTONS.ADD, type: ToolBarItem.types.BUTTON },
    ];
  }, []);

  return {
    open,
    userLocation,
    userLocations,
    userLocationList,
    formData,
    formCfg,
    formActions,
    setFormData,
    handleClickAction,
    handleClickClose,
    userLocationsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOp,
    saveOpResult,
    removingOps,
  };
};

export { useUserLocations };
