import React, { memo, useContext } from 'react';
//import PropTypes from 'prop-types';
import { Tabs } from '@sdflc/ui';
import { OrderItems } from '../OrderItems';

import { LocalizationContext, OrdersContext } from 'contexts';
import { OPERATIONS } from 'utils';
import { OrdersEdit } from './OrdersEdit';
import { OrdersEditPayment } from './OrdersEditPayment';

const OrderAndItemsEdit = memo((props) => {
  const { getText } = useContext(LocalizationContext);
  const { orderId, orderHooks, handleClickAction } = useContext(OrdersContext);
  const { order, ordersOpsManager } = orderHooks;

  const updateOp = ordersOpsManager.get(orderId, OPERATIONS.UPDATE);

  return (
    <Tabs>
      <Tabs.Tab
        id='tab-order-items'
        name={getText('pages.orders.tabs.orderItems')}
        icon='ListAlt'
      >
        <OrderItems />
      </Tabs.Tab>
      <Tabs.Tab
        id='tab-order-edit'
        name={getText('pages.orders.tabs.deliveryInfo')}
        icon='LocalShipping'
      >
        <OrdersEdit
          updateOp={updateOp}
          order={order}
          onClickAction={handleClickAction}
          ordersOpsManager={ordersOpsManager}
        />
      </Tabs.Tab>
      <Tabs.Tab
        id='tab-order-edit'
        name={getText('pages.orders.tabs.paymentInfo')}
        icon='AccountBalanceWallet'
      >
        <OrdersEditPayment
          updateOp={updateOp}
          order={order}
          onClickAction={handleClickAction}
          ordersOpsManager={ordersOpsManager}
        />
      </Tabs.Tab>
      {/* <Tabs.Tab
        id='tab-order-invitations'
        name={getText('pages.orders.tabs.orderInvite')}
        icon='AssignmentInd'
      >
        <OrderInvite orderId={order.id} />
      </Tabs.Tab> */}
    </Tabs>
  );
});

OrderAndItemsEdit.displayName = 'OrderAndItemsEdit';
OrderAndItemsEdit.propTypes = {};
OrderAndItemsEdit.defaultProp = {};

export { OrderAndItemsEdit };
