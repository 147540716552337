import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Icon, Text, ItemsGroup, Flex, colors } from '@sdflc/ui';

import { Container } from './styled';
import check from 'check-types';

const ActionSuggestion = memo((props) => {
  const { iconName, text, disabled, onClick, tooltip, containerStyles } = props;

  return (
    <Container
      onClick={onClick}
      disabled={check.function(onClick) ? disabled : true}
      title={tooltip}
      {...(containerStyles || {})}
    >
      <ItemsGroup>
        <Flex flex='0 0'>
          <Icon name={iconName} size='32px' color={colors.blue} />
        </Flex>
        <Flex flex='1 1'>
          <Text dimension='medium'>{text}</Text>
        </Flex>
        <Flex flex='0 0'>
          <Icon name={'ChevronRight'} size='32px' color={colors.grey06} />
        </Flex>
      </ItemsGroup>
    </Container>
  );
});

ActionSuggestion.displayName = 'ActionSuggestion';

ActionSuggestion.propTypes = {
  iconName: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  containerStyles: PropTypes.shape({}),
};

ActionSuggestion.defaultProp = {};

export { ActionSuggestion };
