import { memo, useMemo, useContext, useEffect, useState } from 'react';
//import PropTypes from 'prop-types';
import { Panel, Select, Avatar } from '@sdflc/ui';

import { LocalizationContext } from 'contexts';
import { useApiAppUser } from 'graphql/hooks/useApiAppUser';

const SelectUser = memo((props) => {
  const { getText } = useContext(LocalizationContext);
  const { userList, usersOpsManager } = useApiAppUser();
  const [keyword /*, setKeyword*/] = useState('');

  const listOp = usersOpsManager.getListOp();

  useEffect(() => {
    // Load users if they were not yet loaded
    if (!listOp.called) {
      userList({
        filter: {
          keyword,
        },
        params: {
          pagination: {
            page: 1,
            pageSize: 100,
          },
        },
      });
    }
  }, [userList, listOp, keyword]);

  const options = useMemo(() => {
    if (listOp.result.isLoading()) {
      return [];
    }

    if (
      listOp.called &&
      !listOp.result.isInProgress() &&
      listOp.result.didFail()
    ) {
      return [
        {
          value: '',
          text: listOp.result.getErrorSummary(),
          icon: '',
        },
      ];
    }

    const users = listOp.result.data;

    return users.map((item) => {
      return {
        value: item.id,
        text: item.l10n?.displayName || item.user?.username,
        leftDecorator: (
          <Panel pr='2'>
            <Avatar
              src={item.user?.avatarUrl}
              dimension='small'
              variant='circle'
            />
          </Panel>
        ),
      };
    });
  }, [listOp]);

  return (
    <Select
      {...props}
      options={options}
      loading={listOp.result.isLoading()}
      loadingMessage={getText('generic.loading')}
      placeholder={getText('selectors.selectUser.placeholder')}
    />
  );
});

SelectUser.displayName = 'SelectUser';

SelectUser.propTypes = {
  ...Select.propTypes,
};

SelectUser.defaultProps = {};

export { SelectUser };
