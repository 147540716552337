import { get } from 'lodash';
import { en } from './en';

export const getLocalization = (lang, key) => {
  switch (lang) {
    case 'en':
      return get(en, key) || key;

    default:
      return key;
  }
};
