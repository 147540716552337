import React from 'react';
import PropTypes from 'prop-types';
import { UploadedFileContext } from '../contexts';
import { useUploadedFile } from '../graphql/hooks';

const UploadedFileProvider = (props) => {
  const { children } = props;
  const hooks = useUploadedFile();

  return (
    <UploadedFileContext.Provider value={hooks}>
      {children}
    </UploadedFileContext.Provider>
  );
};

UploadedFileProvider.displayName = 'UploadedFileProvider';

UploadedFileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UploadedFileProvider };
