import { FORM_INPUT_TYPES } from '@sdflc/ui';
import { DICTIONARIES_UIDS } from 'config/dictionariesUids';

import { SelectDictionaryItem } from '../..';

export const userContactForm = [
  {
    fields: [
      {
        field: 'contactTypeId',
        width: ['100%'],
        component: SelectDictionaryItem,
        componentProps: {
          dictionaryUid: DICTIONARIES_UIDS.CONTACT_TYPES,
        },
      },
      {
        field: 'contactValue',
        type: FORM_INPUT_TYPES.TEXT,
        required: true,
        width: ['100%'],
      },
      {
        field: 'contactDescription',
        type: FORM_INPUT_TYPES.TEXTAREA,
        width: ['100%'],
      },
    ],
  },
];
