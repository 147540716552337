import { Fragment, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormDrawer } from '@sdflc/ui';

import { LocalizationContext } from '../../../contexts';

import { Block } from '../../Block';
import { AppToolbar } from '../../AppToolbar';

import { BankAccountsList } from './BankAccountsList';
import { useBankAccounts } from './useBankAccounts';

const BankAccounts = memo((props) => {
  const { hasTitle } = props;
  const { getText } = useContext(LocalizationContext);
  const {
    open,
    bankAccounts,
    bankAccountList,
    formData,
    formCfg,
    formActions,
    handleClickAction,
    handleClickClose,
    bankAccountsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOpResult,
    removingOps,
  } = useBankAccounts();

  return (
    <Fragment>
      <Block title={hasTitle ? getText('pages.bankAccounts.title') : ''}>
        <AppToolbar items={toolBarItems} onClick={handleClickAction} />
        <BankAccountsList
          onClickAction={handleClickAction}
          list={bankAccountList}
          items={bankAccounts}
          listOp={bankAccountsOpsManager.getListOp()}
          removingOps={removingOps}
        />
      </Block>
      <FormDrawer
        isOpen={open}
        title={getText('pages.bankAccounts.form.title.add')}
        description=''
        formData={formData}
        formCfg={formCfg}
        onClose={handleClickClose}
        actions={formActions}
        actionInProgress={actionInProgress}
        onClickAction={handleClickAction}
        progress={saveOpResult.isInProgress()}
        opResult={saveOpResult}
      />
    </Fragment>
  );
});

BankAccounts.displayName = 'BankAccounts';

BankAccounts.propTypes = {
  children: PropTypes.node,
  hasTitle: PropTypes.bool,
};

BankAccounts.defaultProps = {
  hasTitle: false,
};

export { BankAccounts };
