import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Caption,
  Centered,
  colors,
  Container,
  Image,
  Text,
} from '@sdflc/ui';

import { CenteredLayout } from '../../layouts';
import { appSettings, APP_PAGE_KEYS } from '../../config';
import { goToPage } from '../../utils';

import { FullHeightContainer } from './styled';
import { LocalizationContext } from 'contexts';

const PageNotFound = memo((props) => {
  const { getText } = useContext(LocalizationContext);

  return (
    <CenteredLayout {...props}>
      <FullHeightContainer>
        <Container dimension='md'>
          <Centered>
            <Image
              src={appSettings.pageNotFoundBgImage}
              height={'60vh'}
              width='auto'
            />
            <Caption level={1}>{getText('pages.notFound.title')}</Caption>
            <Text color={colors.grey09} mt={5} textAlign='center'>
              {getText('pages.notFound.description')}
            </Text>
            <Button
              mt={5}
              dimension={Button.DIMENSION_NAMES.MEDIUM}
              variant={Button.VARIANT_NAMES.PRIMARY}
              name={getText('buttons.goHome')}
              icon={'Home'}
              onClick={() => {
                goToPage({ pageKey: APP_PAGE_KEYS.HOME });
              }}
            />
          </Centered>
        </Container>
      </FullHeightContainer>
    </CenteredLayout>
  );
});

PageNotFound.displayName = 'PageNotFound';

PageNotFound.propTypes = {
  children: PropTypes.node,
};

PageNotFound.defaultProps = {};

export { PageNotFound };
