import { useState, useCallback } from 'react';
import { OpResult } from '@sdflc/api-helpers';

import { logger, OPERATIONS, useAppMutation, useAppQuery } from '../../utils';
import { storeBrandQueries } from '../queries/storeBrandQueries';

const useStoreBrand = () => {
  const [storeBrand, setStoreBrand] = useState(new OpResult());
  const [storeBrands, setStoreBrands] = useState(new OpResult());

  const handleDone = (operation) => (result) => {
    switch (operation) {
      default:
        logger.warn(
          'The "useStoreBrand" hook got unexpected value of operation: ',
          operation
        );
        break;

      case OPERATIONS.LIST:
        setStoreBrands(result);
        break;

      case OPERATIONS.GET_MANY:
        setStoreBrands(result);
        break;

      case OPERATIONS.CREATE:
        setStoreBrand(result);
        // TODO: Update list of user contacts
        break;

      case OPERATIONS.UPDATE:
        setStoreBrand(result);
        // TODO: Update list of user contacts
        break;

      case OPERATIONS.REMOVE_MANY:
        setStoreBrand(result);
        // TODO: Update list of user contacts
        break;
    }
  };

  /**
   * GRAPHQL CALLERS
   */

  const [
    mutationStoreBrandCreate,
    {
      called: storeBrandCreateCalled,
      loading: storeBrandCreateLoading,
      reset: storeBrandCreateReset,
    },
  ] = useAppMutation({
    queries: storeBrandQueries,
    queryName: 'storeBrandCreate',
    onDone: handleDone(OPERATIONS.CREATE),
  });

  const [
    mutationStoreBrandUpdate,
    {
      called: storeBrandUpdateCalled,
      loading: storeBrandUpdateLoading,
      reset: storeBrandUpdateReset,
    },
  ] = useAppMutation({
    queries: storeBrandQueries,
    queryName: 'storeBrandUpdate',
    onDone: handleDone(OPERATIONS.UPDATE),
  });

  const [
    mutationStoreBrandRemoveMany,
    {
      called: storeBrandRemoveManyCalled,
      loading: storeBrandRemoveManyLoading,
      reset: storeBrandRemoveManyReset,
    },
  ] = useAppMutation({
    queries: storeBrandQueries,
    queryName: 'storeBrandRemoveMany',
    onDone: handleDone(OPERATIONS.REMOVE_MANY),
  });

  const [
    queryStoreBrandList,
    {
      called: storeBrandListCalled,
      loading: storeBrandListLoading,
      reset: storeBrandListReset,
    },
  ] = useAppQuery({
    queries: storeBrandQueries,
    queryName: 'storeBrandList',
    onDone: handleDone(OPERATIONS.LIST),
  });

  const [
    queryStoreBrandGetMany,
    {
      called: storeBrandGetManyCalled,
      loading: storeBrandGetManyLoading,
      reset: storeBrandGetManyReset,
    },
  ] = useAppQuery({
    queries: storeBrandQueries,
    queryName: 'storeBrandGetMany',
    onDone: handleDone(OPERATIONS.GET_MANY),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const storeBrandCreate = useCallback(
    (variables) => {
      setStoreBrand(storeBrand.clearErrors().startLoading().clone());
      mutationStoreBrandCreate({ variables });
    },
    [mutationStoreBrandCreate, storeBrand]
  );

  const storeBrandUpdate = useCallback(
    (variables) => {
      setStoreBrand(storeBrand.clearErrors().startLoading().clone());
      mutationStoreBrandUpdate({ variables });
    },
    [mutationStoreBrandUpdate, storeBrand]
  );

  const storeBrandRemoveMany = useCallback(
    (variables) => {
      setStoreBrand(storeBrand.clearErrors().startLoading().clone());
      mutationStoreBrandRemoveMany({ variables });
    },
    [mutationStoreBrandRemoveMany, storeBrand]
  );

  const storeBrandList = useCallback(
    (variables) => {
      setStoreBrands(storeBrands.clearErrors().startLoading().clone());
      queryStoreBrandList({ variables });
    },
    [queryStoreBrandList, storeBrands]
  );

  const storeBrandGetMany = useCallback(
    (variables) => {
      setStoreBrands(storeBrand.clearErrors().startLoading().clone());
      queryStoreBrandGetMany({ variables });
    },
    [queryStoreBrandGetMany, storeBrand]
  );

  return {
    storeBrand,
    storeBrands,
    storeBrandCreate,
    storeBrandCreateCalled,
    storeBrandCreateLoading,
    storeBrandCreateReset,
    storeBrandUpdate,
    storeBrandUpdateCalled,
    storeBrandUpdateLoading,
    storeBrandUpdateReset,
    storeBrandRemoveMany,
    storeBrandRemoveManyCalled,
    storeBrandRemoveManyLoading,
    storeBrandRemoveManyReset,
    storeBrandList,
    storeBrandListCalled,
    storeBrandListLoading,
    storeBrandListReset,
    storeBrandGetMany,
    storeBrandGetManyCalled,
    storeBrandGetManyLoading,
    storeBrandGetManyReset,
  };
};

export { useStoreBrand };
