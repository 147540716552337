import styled from 'styled-components';

import { colors, mediaQueries } from '@sdflc/ui';

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
`;

export const ImageSide = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: ${({ bgImage }) =>
    bgImage ? `url('${bgImage}')` : 'none'};

  position: absolute;
  right: 0;
  top: 0;
  width: calc(50% + 20px);
  height: 100%;

  ${mediaQueries.xs} {
    position: relative;
    width: 100%;
    height: 170px;
  }

  ${mediaQueries.sm} {
    position: relative;
    width: 100%;
    height: 170px;
  }
`;

export const ContentSide = styled.div`
  background-color: ${colors.white};
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mediaQueries.xs} {
    top: 150px;
    width: 100%;
    height: auto;
    padding-top: 50px;
    border: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  ${mediaQueries.sm} {
    top: 150px;
    width: 100%;
    height: auto;
    padding-top: 50px;
    border: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`;

export const ContentBody = styled.div`
  width: 100%;
  flex: 1 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  ${mediaQueries.xs} {
    max-height: unset;
    padding-bottom: 100px;
  }

  ${mediaQueries.sm} {
    max-height: unset;
    padding-bottom: 100px;
  }
`;
