import { Fragment, memo, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';
import { Button, SimpleForm } from '@sdflc/ui';

import { logger, OPERATIONS } from '../../../utils';

import { Block } from '../../Block';

import { LocalizationContext /* , OrdersContext */ } from '../../../contexts';

import { orderFormSections } from './orderFormSections';
import { appSettings, ACTIONS_IDS, ORDER_STATUSES } from '../../../config';

//const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const OrdersEdit = memo((props) => {
  const { order, ordersOpsManager, onClickAction } = props;
  const { getText } = useContext(LocalizationContext);

  const orderId = order?.id;
  const updateOp = ordersOpsManager.get(orderId, OPERATIONS.UPDATE);

  const handleClickAction = useCallback(
    (action, item) => {
      if (check.function(onClickAction)) {
        onClickAction(action, item);
      }
    },
    [onClickAction]
  );

  const formCfg = useMemo(() => {
    const labels = getText('pages.orders.form.labels');
    const hints = getText('pages.orders.form.hints');

    // If order is in progress or completed then user cannot change some of the fields:
    const canChange =
      [ORDER_STATUSES.PROGRESS, ORDER_STATUSES.COMPLETED].indexOf(
        order.status
      ) === -1;

    orderFormSections[0].fields.forEach((fieldItem) => {
      if (['locationId', 'dueDate', 'storeBrandId'].includes(fieldItem.field)) {
        fieldItem.componentProps.disabled = !canChange;
      }
    });

    return { labels, hints, sections: orderFormSections };
  }, [order, getText]);

  logger.log('Rendering OrdersEdit', { order, updateOp });

  return (
    <Block>
      <SimpleForm
        formProps={{ width: appSettings.defaultFormWidth }}
        formData={order}
        formCfg={formCfg}
        onSubmit={(formData) => {
          handleClickAction({ id: ACTIONS_IDS.SAVE }, formData);
        }}
        //onChange={action('Form Changed')}
        progress={updateOp.result.isInProgress()}
        buttons={
          <Fragment>
            <Button
              icon='Done'
              name={getText('buttons.save')}
              type='submit'
              progress={updateOp.result.isInProgress()}
            />
          </Fragment>
        }
      />
    </Block>
  );
});

OrdersEdit.displayName = 'OrdersEdit';

OrdersEdit.propTypes = {
  children: PropTypes.node,
  onLoad: PropTypes.func,
};

OrdersEdit.defaultProps = {};

export { OrdersEdit };
