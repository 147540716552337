import { gql } from '@apollo/client';

const COMMENTS_LIST = gql`
  query ChannelCommentList(
    $filter: ChannelCommentFilter
    $params: PaginationAndSortig
  ) {
    channelCommentList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        channelId
        commenterCookieId
        commentText
        channelCommentId
        hasReplies
        createdAt
        updatedAt
        createdBy
        updatedBy
        userCreated {
          username
          avatarUrl
        }
        reactions {
          id
          commentReaction
          createdBy
          userCreated {
            username
            avatarUrl
          }
        }
        attachments {
          id
          attachmentUrl
          attachmentMimeType
          status
          version
          createdBy
        }
      }
    }
  }
`;

const COMMENTS_GET_MANY = gql`
  query ChannelCommentGetMany($ids: [ID]) {
    channelCommentGetMany(ids: $ids) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        channelId
        commenterCookieId
        commentText
        channelCommentId
        hasReplies
        createdAt
        updatedAt
        createdBy
        updatedBy
        userCreated {
          username
          avatarUrl
        }
        reactions {
          id
          commentReaction
          createdBy
          userCreated {
            username
            avatarUrl
          }
        }
        attachments {
          id
          attachmentUrl
          attachmentMimeType
          status
          version
          createdBy
        }
      }
    }
  }
`;

const COMMENTS_CREATE = gql`
  mutation ChannelCommentCreate($params: ChannelCommentInput) {
    channelCommentCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        channelId
        commenterCookieId
        commentText
        channelCommentId
        hasReplies
        createdAt
        updatedAt
        createdBy
        updatedBy
        userCreated {
          username
          avatarUrl
        }
        reactions {
          id
          commentReaction
          createdBy
          userCreated {
            username
            avatarUrl
          }
        }
        attachments {
          id
          attachmentUrl
          attachmentMimeType
          status
          version
          createdBy
        }
      }
    }
  }
`;

const COMMENTS_UPDATE = gql`
  mutation ChannelCommentUpdate(
    $where: ChannelCommentWhereInput
    $params: ChannelCommentInput
  ) {
    channelCommentUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        channelId
        commenterCookieId
        commentText
        channelCommentId
        hasReplies
        createdAt
        updatedAt
        createdBy
        updatedBy
        userCreated {
          username
          avatarUrl
        }
        reactions {
          id
          commentReaction
          createdBy
          userCreated {
            username
            avatarUrl
          }
        }
        attachments {
          id
          attachmentUrl
          attachmentMimeType
          status
          version
          createdBy
        }
      }
    }
  }
`;

const COMMENTS_REMOVE_MANY = gql`
  mutation ChannelCommentRemoveMany($where: [ChannelCommentWhereInput]) {
    channelCommentRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const commentsQueries = {
  channelCommentList: COMMENTS_LIST,
  channelCommentGetMany: COMMENTS_GET_MANY,
  channelCommentCreate: COMMENTS_CREATE,
  channelCommentUpdate: COMMENTS_UPDATE,
  channelCommentRemoveMany: COMMENTS_REMOVE_MANY,
};

export { commentsQueries };
