import { useState, useCallback } from 'react';
import { OpResult } from '@sdflc/api-helpers';
import { logger, OPERATIONS, useAppMutation, useAppQuery } from '../../utils';
import { paymentMethodQueries } from '../queries/paymentMethodQueries';

const usePaymentMethod = () => {
  const [paymentMethod, setPaymentMethod] = useState(new OpResult());
  const [paymentMethods, setPaymentMethods] = useState(new OpResult());
  const [paymentMethodEmbedUrl, setPaymentMethodEmbedUrl] = useState(
    new OpResult()
  );

  const handleDone = (operation) => (result) => {
    switch (operation) {
      default:
        logger.warn(
          'The "usePaymentMethod" hook got unexpected value of operation: ',
          operation
        );
        break;

      case OPERATIONS.LIST:
        setPaymentMethods(result);
        break;

      case OPERATIONS.GET_MANY:
        setPaymentMethods(result);
        break;

      case OPERATIONS.CREATE:
        setPaymentMethod(result);
        const newItem = result.getDataFirst();
        console.log('Payment method has been created:', result);
        if (result.didSucceed()) {
          setPaymentMethods(
            new OpResult().setData([newItem, ...paymentMethods.getData()])
          );
        }
        break;

      case OPERATIONS.UPDATE:
        setPaymentMethod(result);

        if (result.didSucceed()) {
          const updItem = result.getDataFirst();

          setPaymentMethods(
            new OpResult().setData([
              ...paymentMethods.getData().map((item) => {
                if (updItem.id === item.id) {
                  return updItem;
                }
                return item;
              }),
            ])
          );
        }
        break;

      case OPERATIONS.REMOVE_MANY:
        setPaymentMethod(result);
        if (result.didSucceed()) {
          const ids = result.getData().map((item) => item.id);
          setPaymentMethods(
            new OpResult().setData([
              ...paymentMethods
                .getData()
                .filter((item) => ids.indexOf(item.id) === -1),
            ])
          );
        }
        break;
    }
  };

  /**
   * GRAPHQL CALLERS
   */

  const [
    mutationPaymentMethodCreate,
    {
      called: paymentMethodCreateCalled,
      loading: paymentMethodCreateLoading,
      reset: paymentMethodCreateReset,
    },
  ] = useAppMutation({
    queries: paymentMethodQueries,
    queryName: 'paymentMethodCreate',
    onDone: handleDone(OPERATIONS.CREATE),
  });

  const [
    mutationPaymentMethodUpdate,
    {
      called: paymentMethodUpdateCalled,
      loading: paymentMethodUpdateLoading,
      reset: paymentMethodUpdateReset,
    },
  ] = useAppMutation({
    queries: paymentMethodQueries,
    queryName: 'paymentMethodUpdate',
    onDone: handleDone(OPERATIONS.UPDATE),
  });

  const [
    mutationPaymentMethodRemoveMany,
    {
      called: paymentMethodRemoveManyCalled,
      loading: paymentMethodRemoveManyLoading,
      reset: paymentMethodRemoveManyReset,
    },
  ] = useAppMutation({
    queries: paymentMethodQueries,
    queryName: 'paymentMethodRemoveMany',
    onDone: handleDone(OPERATIONS.REMOVE_MANY),
  });

  const [
    queryPaymentMethodList,
    {
      called: paymentMethodListCalled,
      loading: paymentMethodListLoading,
      reset: paymentMethodListReset,
    },
  ] = useAppQuery({
    queries: paymentMethodQueries,
    queryName: 'paymentMethodList',
    onDone: handleDone(OPERATIONS.LIST),
  });

  const [
    queryPaymentMethodGetMany,
    {
      called: paymentMethodGetManyCalled,
      loading: paymentMethodGetManyLoading,
      reset: paymentMethodGetManyReset,
    },
  ] = useAppQuery({
    queries: paymentMethodQueries,
    queryName: 'paymentMethodGetMany',
    onDone: handleDone(OPERATIONS.GET_MANY),
  });

  const [
    queryPaymentMethodEmbedUrlGet,
    { called: paymentMethodEmbedUrlGetCalled },
  ] = useAppQuery({
    queries: paymentMethodQueries,
    queryName: 'paymentMethodEmbedUrlGet',
    onDone: (result) => {
      if (result.didFail()) {
        // TODO:
      }

      setPaymentMethodEmbedUrl(result);
    },
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const paymentMethodCreate = useCallback(
    (variables) => {
      setPaymentMethod(
        new OpResult().setData(variables?.params).startLoading()
      );
      mutationPaymentMethodCreate({ variables });
    },
    [mutationPaymentMethodCreate]
  );

  const paymentMethodUpdate = useCallback(
    (variables) => {
      setPaymentMethod(
        new OpResult().setData(variables?.params).startLoading()
      );
      if (variables?.params?.status != null) {
        variables.params.status = Number(variables?.params?.status);
      }
      mutationPaymentMethodUpdate({ variables });
    },
    [mutationPaymentMethodUpdate]
  );

  const paymentMethodRemoveMany = useCallback(
    (variables) => {
      setPaymentMethod(paymentMethod.clearErrors().startLoading().clone());
      mutationPaymentMethodRemoveMany({ variables });
    },
    [mutationPaymentMethodRemoveMany, paymentMethod]
  );

  const paymentMethodList = useCallback(
    (variables) => {
      setPaymentMethods(paymentMethods.clearErrors().startLoading().clone());
      queryPaymentMethodList({ variables });
    },
    [queryPaymentMethodList, paymentMethods]
  );

  const paymentMethodGetMany = useCallback(
    (variables) => {
      setPaymentMethods(paymentMethod.clearErrors().startLoading().clone());
      queryPaymentMethodGetMany({ variables });
    },
    [queryPaymentMethodGetMany, paymentMethod]
  );

  const paymentMethodEmbedUrlGet = useCallback(
    (variables) => {
      setPaymentMethodEmbedUrl(new OpResult().startLoading());
      queryPaymentMethodEmbedUrlGet({ variables });
    },
    [queryPaymentMethodEmbedUrlGet]
  );

  return {
    paymentMethod,
    paymentMethods,
    paymentMethodEmbedUrl,
    paymentMethodCreate,
    paymentMethodCreateCalled,
    paymentMethodCreateLoading,
    paymentMethodCreateReset,
    paymentMethodUpdate,
    paymentMethodUpdateCalled,
    paymentMethodUpdateLoading,
    paymentMethodUpdateReset,
    paymentMethodRemoveMany,
    paymentMethodRemoveManyCalled,
    paymentMethodRemoveManyLoading,
    paymentMethodRemoveManyReset,
    paymentMethodList,
    paymentMethodListCalled,
    paymentMethodListLoading,
    paymentMethodListReset,
    paymentMethodGetMany,
    paymentMethodGetManyCalled,
    paymentMethodGetManyLoading,
    paymentMethodGetManyReset,

    paymentMethodEmbedUrlGet,
    paymentMethodEmbedUrlGetCalled,
  };
};

export { usePaymentMethod };
