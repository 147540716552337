import React from 'react';
import PropTypes from 'prop-types';
import { PaymentMethodContext } from '../contexts';
import { usePaymentMethod } from '../graphql/hooks';

const PaymentMethodProvider = (props) => {
  const { children } = props;
  const hooks = usePaymentMethod();

  return (
    <PaymentMethodContext.Provider value={hooks}>
      {children}
    </PaymentMethodContext.Provider>
  );
};

PaymentMethodProvider.displayName = 'PaymentMethodProvider';

PaymentMethodProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PaymentMethodProvider };
