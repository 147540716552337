import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Text, ItemsGroup, Flex } from '@sdflc/ui';
import { UserRating } from 'components/UserRating';
import { appSettings } from 'config';

const UserBadge = memo((props) => {
  const { appUser, value } = props;

  if (!appUser && !value) {
    return null;
  }

  const { user = {}, l10n = {}, userRating, subtitle } = appUser ?? value ?? {};
  const avatarUrl = user.avatarUrl || appSettings.defaultUserAvatarUrl;
  const name = l10n.displayName || user.username;

  return (
    <ItemsGroup wrapMode='nowrap' alignItems='center' flex='1 1'>
      <Flex flex='0 0'>
        <Avatar src={avatarUrl} dimension='small' variant='circle' />
      </Flex>
      <Flex flexDirection='column' alignItems='flex-start' width='100%'>
        <Flex flexDirection='row' alignItems='center' width='100%'>
          <Flex flex='1 1'>
            <Text bold dimension='small'>
              {name}
            </Text>
          </Flex>
        </Flex>
        {!!subtitle && <Text dimension='small'>{subtitle}</Text>}
      </Flex>
      {userRating != null && (
        <Flex flex='0 0'>
          <UserRating value={userRating} />
        </Flex>
      )}
    </ItemsGroup>
  );
});

UserBadge.displayName = 'UserBadge';

UserBadge.propTypes = {
  appUser: PropTypes.shape({
    user: PropTypes.shape({
      username: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
    userRating: PropTypes.number,
    subtitle: PropTypes.string,
  }),
};

export { UserBadge };
