import { memo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, Panel, ItemsGroup } from '@sdflc/ui';

import { LocalizationContext } from '../../../contexts';
import { UserShoppingItemItem } from './UserShoppingItemItem';
import { logger } from 'utils';
import check from 'check-types';

const tplUserShoppingItems = Array.from({ length: 8 }).map((_, idx) => ({
  id: idx,
  name: idx,
  pictureUrl: '',
}));

const UserShoppingItemsList = memo((props) => {
  const { onClickAction, list, items, listOp, removingOps } = props;
  const { getText } = useContext(LocalizationContext);

  useEffect(() => {
    // Load user shopping items if they were not yet loaded

    if (!listOp.called && check.function(list)) {
      list({
        filter: {},
        params: {},
      });
    }
  }, [list, listOp]);

  if (
    listOp.called &&
    !listOp.result.isInProgress() &&
    listOp.result.didFail()
  ) {
    return <Alert variant='danger'>{listOp.result.getErrorSummary()}</Alert>;
  }

  const items2use = listOp.result?.isInProgress()
    ? tplUserShoppingItems
    : items;

  logger.log('Rendering UserShoppingItemsList');

  return (
    <Panel mt={2} p={1} overflow='initial'>
      <ItemsGroup wrapMode={'wrap'} alignItems='flex-start'>
        {items2use.map((item) => {
          return (
            <UserShoppingItemItem
              key={item.id}
              item={item}
              onClickAction={onClickAction}
              loading={listOp.result?.isInProgress()}
              removingResult={removingOps[item.id]?.result}
            />
          );
        })}
        {!listOp.result?.isInProgress() && items.length === 0 && (
          <Alert variant='default'>{getText('pages.items.noItems')}</Alert>
        )}
      </ItemsGroup>
    </Panel>
  );
});

UserShoppingItemsList.displayName = 'UserShoppingItemsList';

UserShoppingItemsList.propTypes = {
  onClickAction: PropTypes.func,
  list: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  listOp: PropTypes.shape({}),
};

UserShoppingItemsList.defaultProps = {};

export { UserShoppingItemsList };
