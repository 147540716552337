import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { ToolBarItem } from '@sdflc/ui';

import {
  logger,
  OperationManager,
  OPERATIONS,
  onlyPropsOfObject,
} from '../../../utils';
import { ACTIONS_BUTTONS, ACTIONS_IDS } from 'config';
import { useApiUserBlockListItem } from '../../../graphql/hooks';
import { LocalizationContext } from '../../../contexts';

import { userBlockListItemForm } from './userBlockListItemForm';

const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const tplNewUserBlockListItem = {
  blockedUserId: null,
  status: 100,
};

const useUserBlockListItems = () => {
  const { getText } = useContext(LocalizationContext);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const {
    userBlockListItem,
    userBlockListItems,
    userBlockListItemList,
    userBlockListItemCreate,
    userBlockListItemUpdate,
    userBlockListItemRemoveMany,
    userBlockListItemsOpsManager,
  } = useApiUserBlockListItem();

  const contactId = formData?.id || '';
  const createOp = userBlockListItemsOpsManager.getCreateOp();
  const updateOp = userBlockListItemsOpsManager.get(
    contactId,
    OPERATIONS.UPDATE
  );
  const saveOp = contactId ? updateOp : createOp;
  const saveOpResult = saveOp.result;
  const updatingOps = userBlockListItemsOpsManager.getOpValues(
    OPERATIONS.UPDATE
  );
  const removingOps = userBlockListItemsOpsManager.getOpValues(
    OPERATIONS.REMOVE_MANY
  );

  useEffect(() => {
    setOpen(!!formData);
  }, [formData]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of new item

    // Item is not being saved yet
    if (!createOp.called) {
      return;
    }

    // Create operation result is not in progress and succeeded
    if (!createOp.result.isInProgress() && createOp.result.didSucceed()) {
      userBlockListItemsOpsManager.reset(
        OperationManager.defaultContext,
        OPERATIONS.CREATE
      );
      setFormData(null);
    }
  }, [createOp, userBlockListItemsOpsManager]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of exising item

    // Item is not being saved yet
    if (!updateOp.called) {
      return;
    }

    // Operation result is not in progress and succeeded
    if (!updateOp.result.isInProgress() && updateOp.result.didSucceed()) {
      userBlockListItemsOpsManager.reset(contactId, OPERATIONS.UPDATE);
      setFormData(null);
    }
  }, [updateOp, userBlockListItemsOpsManager, contactId]);

  const handleClickAction = useCallback(
    async (action, item) => {
      switch (action.id) {
        default:
          logger.warn('The "useUserBlockListItems" got an unexpected action:', {
            action,
            item,
          });
          break;

        case ACTIONS_IDS.ADD:
        case ACTIONS_IDS.CREATE:
          userBlockListItemsOpsManager.reset(
            OperationManager.defaultContext,
            OPERATIONS.SET
          );
          setFormData(cloneDeep(tplNewUserBlockListItem));
          break;

        case ACTIONS_IDS.SAVE:
          if (!item.id) {
            userBlockListItemCreate({ params: item });
          } else {
            userBlockListItemUpdate({
              where: { id: item.id },
              params: onlyPropsOfObject(item, tplNewUserBlockListItem),
            });
          }
          break;

        case ACTIONS_IDS.CANCEL:
          setFormData(null);
          break;

        case ACTIONS_IDS.EDIT:
          userBlockListItemsOpsManager.reset(
            OperationManager.defaultContext,
            OPERATIONS.UPDATE
          );
          setFormData(cloneDeep(item));
          break;

        case ACTIONS_IDS.REMOVE:
          userBlockListItemRemoveMany({ where: [{ id: item.id }] });
          break;

        case ACTIONS_IDS.TOGGLE_STATUS:
          {
            const toUpd = onlyPropsOfObject(item, tplNewUserBlockListItem);
            toUpd.status = item.status === 100 ? 0 : 100;
            userBlockListItemUpdate({
              where: { id: item.id },
              params: toUpd,
            });
          }
          break;
      }
    },
    [
      userBlockListItemCreate,
      userBlockListItemUpdate,
      userBlockListItemRemoveMany,
      userBlockListItemsOpsManager,
    ]
  );

  const handleClickClose = useCallback(() => {
    handleClickAction({ id: ACTIONS_IDS.CANCEL }, null);
  }, [handleClickAction]);

  const formCfg = useMemo(() => {
    return {
      sections: userBlockListItemForm,
      labels: getText('blocks.userBlockListItem.form.labels'),
      hints: getText('pages.userBlockListItem.form.hints'),
      placeholders: getText('pages.userBlockListItem.form.placeholders'),
    };
  }, [getText]);

  const formActions = useMemo(() => [BUTTONS.SAVE, BUTTONS.CANCEL], []);
  const actionInProgress = useMemo(
    () => (saveOpResult.isInProgress() ? [ACTIONS_IDS.SAVE] : []),
    [saveOpResult]
  );

  const toolBarItems = useMemo(() => {
    return [
      // {
      //   id: 'keyword',
      //   type: ToolBarItem.types.COMPONENT,
      //   name: 'keyword',
      //   value: filters.keyword,
      //   component: Input,
      //   placeholder: getText('generic.search'),
      //   leftIcon: 'Search',
      // },
      { ...BUTTONS.ADD, type: ToolBarItem.types.BUTTON },
    ];
  }, []);

  return {
    open,
    userBlockListItem,
    userBlockListItems,
    userBlockListItemList,
    formData,
    formCfg,
    formActions,
    setFormData,
    handleClickAction,
    handleClickClose,
    userBlockListItemsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOp,
    saveOpResult,
    updatingOps,
    removingOps,
  };
};

export { useUserBlockListItems };
