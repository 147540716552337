import { useState, useCallback } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';
import {
  handleApiResult,
  OperationManager,
  OPERATIONS,
  processApiUserLocation,
  useAppMutation,
  useAppQuery,
} from '../../utils';
import { arrayOfObjectsToArray } from '../../utils/transformers';
import { orderSubscriptionByDistanceQueries } from '../queries/orderSubscriptionByDistanceQueries';

const useApiOrderSubscriptionByDistance = () => {
  const [orderSubscriptionByDistance, setOrderSubscriptionByDistance] =
    useState(null);
  const [orderSubscriptionByDistances, setOrderSubscriptionByDistances] =
    useState([]);
  const [orderSubscriptionByDistancesOps, setOrderSubscriptionByDistancesOps] =
    useState({});
  const [orderSubscriptionByDistancesOpsManager] = useState(
    new OperationManager()
  );

  orderSubscriptionByDistancesOpsManager.setStateControls(
    orderSubscriptionByDistancesOps,
    setOrderSubscriptionByDistancesOps
  );

  const handleApiResultOpts = {
    hookName: 'useOrderSubscriptionByDistance',
    opsManager: orderSubscriptionByDistancesOpsManager,
    setItem: setOrderSubscriptionByDistance,
    setItems: setOrderSubscriptionByDistances,
    transformItem: (item) => {
      return {
        ...item,
        userLocation: processApiUserLocation(item?.userLocation),
      };
    },
  };

  /**
   * GRAPHQL CALLERS
   */

  const [mutationOrderSubscriptionByDistanceCreate] = useAppMutation({
    queries: orderSubscriptionByDistanceQueries,
    queryName: 'orderSubscriptionByDistanceCreate',
    onDone: handleApiResult(OPERATIONS.CREATE, handleApiResultOpts),
  });

  const [mutationOrderSubscriptionByDistanceUpdate] = useAppMutation({
    queries: orderSubscriptionByDistanceQueries,
    queryName: 'orderSubscriptionByDistanceUpdate',
    onDone: handleApiResult(OPERATIONS.UPDATE, handleApiResultOpts),
  });

  const [mutationOrderSubscriptionByDistanceRemoveMany] = useAppMutation({
    queries: orderSubscriptionByDistanceQueries,
    queryName: 'orderSubscriptionByDistanceRemoveMany',
    onDone: handleApiResult(OPERATIONS.REMOVE_MANY, handleApiResultOpts),
  });

  const [queryOrderSubscriptionByDistanceList] = useAppQuery({
    queries: orderSubscriptionByDistanceQueries,
    queryName: 'orderSubscriptionByDistanceList',
    onDone: handleApiResult(OPERATIONS.LIST, handleApiResultOpts),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const orderSubscriptionByDistanceCreate = useCallback(
    (variables) => {
      orderSubscriptionByDistancesOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.CREATE,
        OP_RESULT_CODES.SAVING
      );

      mutationOrderSubscriptionByDistanceCreate({ variables });
    },
    [
      mutationOrderSubscriptionByDistanceCreate,
      orderSubscriptionByDistancesOpsManager,
    ]
  );

  const orderSubscriptionByDistanceUpdate = useCallback(
    (variables) => {
      orderSubscriptionByDistancesOpsManager.setStartOperation(
        variables?.where?.id,
        OPERATIONS.UPDATE,
        OP_RESULT_CODES.SAVING
      );

      mutationOrderSubscriptionByDistanceUpdate({ variables });
    },
    [
      mutationOrderSubscriptionByDistanceUpdate,
      orderSubscriptionByDistancesOpsManager,
    ]
  );

  const orderSubscriptionByDistanceRemoveMany = useCallback(
    (variables) => {
      orderSubscriptionByDistancesOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.REMOVE_MANY,
        OP_RESULT_CODES.DELETING
      );

      mutationOrderSubscriptionByDistanceRemoveMany({ variables });
    },
    [
      mutationOrderSubscriptionByDistanceRemoveMany,
      orderSubscriptionByDistancesOpsManager,
    ]
  );

  const orderSubscriptionByDistanceList = useCallback(
    (variables) => {
      orderSubscriptionByDistancesOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING
      );

      queryOrderSubscriptionByDistanceList({ variables });
    },
    [
      queryOrderSubscriptionByDistanceList,
      orderSubscriptionByDistancesOpsManager,
    ]
  );

  return {
    orderSubscriptionByDistance,
    orderSubscriptionByDistances,

    orderSubscriptionByDistanceCreate,
    orderSubscriptionByDistanceUpdate,
    orderSubscriptionByDistanceRemoveMany,
    orderSubscriptionByDistanceList,

    orderSubscriptionByDistancesOpsManager,
  };
};

export { useApiOrderSubscriptionByDistance };
