import { gql } from '@apollo/client';

const USER_UPDATE = gql`
  mutation UserUpdate($where: UserWhereInput, $params: UserInput) {
    userUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        avatarUrl
      }
    }
  }
`;

const userQueries = {
  userUpdate: USER_UPDATE,
};

export { userQueries };
