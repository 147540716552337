import { memo } from 'react';
import PropTypes from 'prop-types';
import { AppPagesProvider } from 'providers';
import { EmptyLayout as EmptyLayoutUi } from '@sdflc/ui';

const EmptyLayout = memo((props) => {
  const { children } = props;

  return (
    <AppPagesProvider>
      <EmptyLayoutUi asFlex notScrollable height='100%'>
        {children}
      </EmptyLayoutUi>
    </AppPagesProvider>
  );
});

EmptyLayout.displayName = 'EmptyLayout';

EmptyLayout.propTypes = {
  children: PropTypes.node,
};

EmptyLayout.defaultProps = {};

export { EmptyLayout };
