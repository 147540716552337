import { gql } from '@apollo/client';

const COMMENT_REACTION_SET = gql`
  mutation CommentReactionSet($params: CommentReactionInput) {
    commentReactionSet(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        channelCommentId
        commentReaction
        status
        createdBy
        userCreated {
          username
        }
      }
    }
  }
`;

const COMMENT_REACTION_REMOVE_MANY = gql`
  mutation CommentReactionRemoveMany($where: [CommentReactionWhereInput]) {
    commentReactionRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        channelCommentId
      }
    }
  }
`;

const commentReactionQueries = {
  commentReactionSet: COMMENT_REACTION_SET,
  commentReactionRemoveMany: COMMENT_REACTION_REMOVE_MANY,
};

export { commentReactionQueries };
