import { gql } from '@apollo/client';

const ORDER_LIST = gql`
  query OrderList($filter: OrderFilter, $params: PaginationAndSorting) {
    orderList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        storeBrandId
        paymentMethodId
        locationId
        deliveryCost
        orderAmount
        serviceFeeTotalAmount
        totalAmountForShopper
        totalAmountForBuyer
        dueDate
        placedAt
        currentStepId
        currentStepNum
        currentStepStartAt
        shopperId
        orderNum
        buyerComment
        shopperComment
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        isMine
        amIShopper
        shopper {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
        userCreated {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
        orderItemsPreview {
          id
          unitId
          quantity
          minPricePerUnit
          maxPricePerUnit
          onlyIfOnSaleIndicator
          notImportantIndicator
          buyerComment
          shopperComment
          shoppingItemStatusId
          storeBrandId
          actualQuantity
          price
          totalAmount
          status
          shoppingItem {
            id
            shoppingItemName
            manufacturer
            shoppingItemDescription
            categoryId
            unitId
            quantity
            minPricePerUnit
            maxPricePerUnit
            pictureUrl
          }
        }
        storeBrand {
          storeTypeId
          storeBrandLogoUrl
          l10n {
            storeBrandName
          }
        }
        userLocation {
          locationTypeId
          notes
          location {
            id
            countryId
            postalCode
            address2
            latitude
            longitude
            l10ns {
              id
              locationId
              lang
              address1
              address2Name
              city
              state
            }
          }
        }
      }
    }
  }
`;

const ORDER_GET = gql`
  query OrderGet($id: ID) {
    orderGet(id: $id) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        storeBrandId
        paymentMethodId
        locationId
        deliveryCost
        orderAmount
        serviceFeeTotalAmount
        totalAmountForShopper
        totalAmountForBuyer
        dueDate
        placedAt
        currentStepId
        currentStepNum
        currentStepStartAt
        shopperId
        orderNum
        buyerComment
        shopperComment
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        isMine
        amIShopper
        shopper {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
        userCreated {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
        userLocation {
          locationTypeId
          notes
          location {
            id
            countryId
            postalCode
            address2
            latitude
            longitude
            l10ns {
              id
              locationId
              lang
              address1
              address2Name
              city
              state
            }
          }
        }
        storeBrand {
          storeTypeId
          storeBrandLogoUrl
          l10n {
            storeBrandName
          }
        }
      }
    }
  }
`;

const ORDER_GET_MANY = gql`
  query OrderGetMany($ids: [ID]) {
    orderGetMany(ids: $ids) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        storeBrandId
        paymentMethodId
        locationId
        deliveryCost
        orderAmount
        serviceFeeTotalAmount
        totalAmountForShopper
        totalAmountForBuyer
        dueDate
        placedAt
        currentStepId
        currentStepNum
        currentStepStartAt
        shopperId
        orderNum
        buyerComment
        shopperComment
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        isMine
        amIShopper
        shopper {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
        userCreated {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
        userLocation {
          locationTypeId
          notes
          location {
            id
            countryId
            postalCode
            address2
            latitude
            longitude
            l10ns {
              id
              locationId
              lang
              address1
              address2Name
              city
              state
            }
          }
        }
        storeBrand {
          storeTypeId
          storeBrandLogoUrl
          l10n {
            storeBrandName
          }
        }
      }
    }
  }
`;

const ORDER_CREATE = gql`
  mutation OrderCreate($params: OrderInput) {
    orderCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        storeBrandId
        paymentMethodId
        locationId
        deliveryCost
        orderAmount
        serviceFeeTotalAmount
        totalAmountForShopper
        totalAmountForBuyer
        dueDate
        placedAt
        currentStepId
        currentStepNum
        currentStepStartAt
        shopperId
        orderNum
        buyerComment
        shopperComment
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        isMine
        amIShopper
        shopper {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
        userCreated {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
        userLocation {
          locationTypeId
          notes
          location {
            id
            countryId
            postalCode
            address2
            latitude
            longitude
            l10ns {
              id
              locationId
              lang
              address1
              address2Name
              city
              state
            }
          }
        }
        storeBrand {
          storeTypeId
          storeBrandLogoUrl
          l10n {
            storeBrandName
          }
        }
      }
    }
  }
`;

const ORDER_UPDATE = gql`
  mutation OrderUpdate($where: OrderWhereInput, $params: OrderInput) {
    orderUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        storeBrandId
        paymentMethodId
        locationId
        deliveryCost
        orderAmount
        serviceFeeTotalAmount
        totalAmountForShopper
        totalAmountForBuyer
        dueDate
        placedAt
        currentStepId
        currentStepNum
        currentStepStartAt
        shopperId
        orderNum
        buyerComment
        shopperComment
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        isMine
        amIShopper
        shopper {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
        userCreated {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
        userLocation {
          locationTypeId
          notes
          location {
            id
            countryId
            postalCode
            address2
            latitude
            longitude
            l10ns {
              id
              locationId
              lang
              address1
              address2Name
              city
              state
            }
          }
        }
        storeBrand {
          storeTypeId
          storeBrandLogoUrl
          l10n {
            storeBrandName
          }
        }
      }
    }
  }
`;

const ORDER_REMOVE_MANY = gql`
  mutation OrderRemoveMany($where: [OrderWhereInput]) {
    orderRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const ORDER_SET_STEP = gql`
  mutation OrderSetStep($params: OrderSetStepInput) {
    orderSetStep(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        storeBrandId
        paymentMethodId
        locationId
        deliveryCost
        orderAmount
        serviceFeeTotalAmount
        totalAmountForShopper
        totalAmountForBuyer
        dueDate
        placedAt
        currentStepId
        currentStepNum
        currentStepStartAt
        shopperId
        orderNum
        buyerComment
        shopperComment
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        isMine
        amIShopper
        orderItemsPreview {
          id
          unitId
          quantity
          minPricePerUnit
          maxPricePerUnit
          onlyIfOnSaleIndicator
          notImportantIndicator
          buyerComment
          shopperComment
          shoppingItemStatusId
          storeBrandId
          actualQuantity
          price
          totalAmount
          status
          shoppingItem {
            id
            shoppingItemName
            manufacturer
            shoppingItemDescription
            categoryId
            unitId
            quantity
            minPricePerUnit
            maxPricePerUnit
            pictureUrl
          }
        }
        shopper {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
        userCreated {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
        userLocation {
          locationTypeId
          notes
          location {
            id
            countryId
            postalCode
            address2
            latitude
            longitude
            l10ns {
              id
              locationId
              lang
              address1
              address2Name
              city
              state
            }
          }
        }
        storeBrand {
          storeTypeId
          storeBrandLogoUrl
          l10n {
            storeBrandName
          }
        }
      }
    }
  }
`;

const orderQueries = {
  orderList: ORDER_LIST,
  orderGet: ORDER_GET,
  orderGetMany: ORDER_GET_MANY,
  orderCreate: ORDER_CREATE,
  orderUpdate: ORDER_UPDATE,
  orderRemoveMany: ORDER_REMOVE_MANY,
  orderSetStep: ORDER_SET_STEP,
};

export { orderQueries };
