import { memo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Image } from '@sdflc/ui';
import { appSettings } from '../../config';

import { mapSizes, sizesNames } from './logoSizes';

const Logo = memo((props) => {
  const { size /*, withName */ } = props;

  return (
    <Flex flexDirection='row' alignItems='center'>
      <Image src={appSettings.logoOnLightBg} height={mapSizes[size]} />
    </Flex>
  );
});

Logo.displayName = 'Logo';

Logo.sizes = Object.keys(sizesNames);

Logo.propTypes = {
  size: PropTypes.oneOf(Logo.sizes),
  withName: PropTypes.bool,
};

Logo.defaultProps = {
  size: Logo.sizes[1],
  withName: false,
};

export { Logo };
