import { memo, useMemo, useContext, useEffect } from 'react';
//import PropTypes from 'prop-types';
import { Select } from '@sdflc/ui';
import { PaymentMethodContext, LocalizationContext } from 'contexts';
import { PaymentMethodType } from 'components/PaymentMethodType';
import dayjs from 'dayjs';
import { PAYMENT_METHOD_ID_CASH } from 'config';

const SelectPaymentMethod = memo((props) => {
  const {
    paymentMethods,
    paymentMethodList,
    paymentMethodListCalled,
    paymentMethodListLoading,
  } = useContext(PaymentMethodContext);
  const { getText } = useContext(LocalizationContext);

  useEffect(() => {
    if (paymentMethodListCalled || paymentMethodListLoading) {
      return;
    }

    if (paymentMethods.didSucceedAndHasData()) {
      return;
    }

    paymentMethodList({ filter: {} });
  }, [
    paymentMethods,
    paymentMethodList,
    paymentMethodListCalled,
    paymentMethodListLoading,
  ]);

  const options = useMemo(() => {
    const { data } = paymentMethods;

    if (!Array.isArray(data)) {
      return [];
    }

    if (paymentMethods.isLoading()) {
      return [];
    }

    return [
      {
        value: PAYMENT_METHOD_ID_CASH,
        text: getText('selectors.selectPaymentMethod.cash'),
        leftDecorator: <PaymentMethodType value={'cash'} />,
      },
      ...data.map((item) => {
        const text = [
          item.cardHolderName,
          `xxxx ${item.last4Digits.slice(-4)}`,
          `Exp ${dayjs(Number(item.expirationDate)).format('MM/YYYY')}`,
        ].join(', ');

        return {
          value: item.id,
          text,
          leftDecorator: (
            <PaymentMethodType value={item.creditCardType} pr={1} />
          ),
        };
      }),
    ];
  }, [paymentMethods, getText]);

  return (
    <Select
      {...props}
      options={options}
      loading={paymentMethods.isLoading()}
      loadingMessage={getText('generic.loading')}
      placeholder={getText('selectors.selectPaymentMethod.placeholder')}
    />
  );
});

SelectPaymentMethod.displayName = 'SelectPaymentMethod';
SelectPaymentMethod.propTypes = {};
SelectPaymentMethod.defaultProp = {};

export { SelectPaymentMethod };
