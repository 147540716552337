import { escapeRegExp } from 'lodash';

export const onlyPropsOfObject = (src, dst) => {
  return Object.keys(dst || {}).reduce((acc, propName) => {
    if (src[propName] !== undefined) {
      acc[propName] = src[propName];
    }
    return acc;
  }, {});
};

export const hasStringMatch = (str, pattern) => {
  if (!str || !pattern) {
    return false;
  }

  return str.search(new RegExp(escapeRegExp(pattern), 'i')) !== -1;
};
