import { memo, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { TextArea, Panel, Button, Label, ItemsGroup, Alert } from '@sdflc/ui';

import { LocalizationContext } from '../../../contexts';

import { StyledInputFile } from './styled';

import { uploadFile } from '../../../utils/microserviceStorageApi';

const InputComment = memo((props) => {
  const {
    label,
    name,
    value,
    onSubmit,
    progress,
    disabled,
    commentsForOrderItemId,
    result,
  } = props;
  const { getText } = useContext(LocalizationContext);
  const [commentText, setTextComment] = useState(props.value);
  const [submitted, setSubmitted] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const refFile = useRef();

  useEffect(() => {
    setTextComment(value);
  }, [value]);

  useEffect(() => {
    if (submitted && !result.isInProgress() && result.didSucceed()) {
      setTextComment('');
      setAttachments([]);
    }
  }, [submitted, result]);

  const handleOnChangeFile = async (event) => {
    const uploadedFile = event.target.files[0];

    const formData = new FormData();

    formData.append(
      'filePath',
      `orderItems/${commentsForOrderItemId}/comments/pending`
    );

    formData.append('originalFilename', uploadedFile.name);
    formData.append('name', uploadedFile.name);
    formData.append('file', uploadedFile);
    formData.append('notes', '');
    formData.append('status', '50'); // Pending state, if not update to 100 then will be removed autmatically in some time

    console.log('Start uploading', Array.from(formData.entries()));

    setUploadError('');

    const uploadResult = await uploadFile({ formData });

    if (uploadResult.didSucceed()) {
      console.log('Uploaded file:', uploadResult.getDataFirst());
      setAttachments([...attachments, uploadResult.getDataFirst()]);
    } else {
      setUploadError(uploadResult.getErrorSummary());
    }
  };

  return (
    <Panel mt={3}>
      {!!label && <Label>{label}</Label>}
      <TextArea
        name={name}
        value={commentText}
        onChange={(_, value) => {
          setSubmitted(false);
          setTextComment(value);
        }}
        disabled={disabled}
      />
      {attachments.length > 0 &&
        attachments.map((attachment) => {
          return <span>{attachment.name}</span>;
        })}
      {!!uploadError && <Alert variant='danger'>{uploadError}</Alert>}
      <ItemsGroup mt={2}>
        <Button
          icon='Send'
          name={getText('generic.send')}
          onClick={() => {
            if (typeof onSubmit === 'function') {
              onSubmit(
                name,
                commentText,
                attachments.map((attachment, idx) => {
                  return {
                    attachmentUrl: attachment.fileUrl,
                    attachmentMimeType: attachment.mimeType,
                  };
                })
              );
              setSubmitted(true);
            }
          }}
          progress={progress}
          disabled={disabled || !commentText}
        />
        <Button
          icon='Attachment'
          title='Attach a file/image'
          variant='tertiary'
          onClick={() => {
            if (refFile.current) {
              refFile.current.click();
            }
          }}
        />
        <StyledInputFile
          type='file'
          ref={refFile}
          onChange={handleOnChangeFile}
        />
      </ItemsGroup>
    </Panel>
  );
});

InputComment.displayName = 'InputComment';

InputComment.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onSubmit: PropTypes.func,
  progress: PropTypes.bool,
  disabled: PropTypes.bool,
  result: PropTypes.any,
};

InputComment.defaultProps = {
  name: '',
};

export { InputComment };
