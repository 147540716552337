import { Fragment, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormDrawer } from '@sdflc/ui';

import { LocalizationContext } from '../../../contexts';

import { Block } from '../../Block';
import { AppToolbar } from '../../AppToolbar';

import { UserBlockListItemsList } from './UserBlockListItemsList';
import { useUserBlockListItems } from './useUserBlockListItems';

const UserBlockListItems = memo((props) => {
  const { hasTitle } = props;
  const { getText } = useContext(LocalizationContext);
  const {
    open,
    userBlockListItems,
    userBlockListItemList,
    formData,
    formCfg,
    formActions,
    handleClickAction,
    handleClickClose,
    userBlockListItemsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOpResult,
    updatingOps,
    removingOps,
  } = useUserBlockListItems();

  return (
    <Fragment>
      <Block title={hasTitle ? getText('blocks.userBlockListItem.title') : ''}>
        <AppToolbar items={toolBarItems} onClick={handleClickAction} />
        <UserBlockListItemsList
          onClickAction={handleClickAction}
          list={userBlockListItemList}
          items={userBlockListItems}
          listOp={userBlockListItemsOpsManager.getListOp()}
          updatingOps={updatingOps}
          removingOps={removingOps}
        />
      </Block>
      <FormDrawer
        isOpen={open}
        title={getText('blocks.userBlockListItem.form.title')}
        description=''
        formData={formData}
        formCfg={formCfg}
        onClose={handleClickClose}
        actions={formActions}
        actionInProgress={actionInProgress}
        onClickAction={handleClickAction}
        progress={saveOpResult.isInProgress()}
        opResult={saveOpResult}
      />
    </Fragment>
  );
});

UserBlockListItems.displayName = 'UserBlockListItems';

UserBlockListItems.propTypes = {
  children: PropTypes.node,
  hasTitle: PropTypes.bool,
};

UserBlockListItems.defaultProps = {
  hasTitle: false,
};

export { UserBlockListItems };
