import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AuthContext, UserLocationContext } from '../contexts';
import { useUserLocation } from '../graphql/hooks';

const UserLocationProvider = (props) => {
  const { children } = props;
  const hooks = useUserLocation();
  const { appUser } = useContext(AuthContext);
  const { userLocationList } = hooks;

  useEffect(() => {
    if (appUser && appUser.id) {
      userLocationList({ filter: {}, params: {} });
    }
  }, [appUser]); // eslint-disable-line

  return (
    <UserLocationContext.Provider value={hooks}>
      {children}
    </UserLocationContext.Provider>
  );
};

UserLocationProvider.displayName = 'UserLocationProvider';

UserLocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserLocationProvider };
