import { FORM_INPUT_TYPES } from '@sdflc/ui';
import { SelectUserLocation } from 'components/Controls';

export const bankAccountForm = [
  {
    fields: [
      {
        field: 'countryId',
        width: ['100%'],
        type: FORM_INPUT_TYPES.TEXT,
        componentProps: {
          disabled: true,
        },
      },
      {
        field: 'institutionNumber',
        width: ['100%'],
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        field: 'transitNumber',
        type: FORM_INPUT_TYPES.TEXT,
        width: ['100%'],
      },
      {
        field: 'accountNumber',
        type: FORM_INPUT_TYPES.TEXT,
        width: ['100%'],
      },
      {
        field: 'locationId',
        component: SelectUserLocation,
        width: ['100%'],
      },
    ],
  },
];
