import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ImageViewerContext } from '../contexts';

const ImageViewerProvider = (props) => {
  const { children } = props;
  const [viewImages, setViewImages] = useState([]);
  const [viewImageIdx, setViewImageIdx] = useState(-1);

  const resetViewImageIdx = useCallback(() => {
    setViewImageIdx(-1);
  }, []);

  const hooks = useMemo(() => {
    return {
      viewImages,
      viewImageIdx,
      setViewImages,
      setViewImageIdx,
      resetViewImageIdx,
    };
  }, [viewImages, viewImageIdx, resetViewImageIdx]);

  return (
    <ImageViewerContext.Provider value={hooks}>
      {children}
    </ImageViewerContext.Provider>
  );
};

ImageViewerProvider.displayName = 'ImageViewerProvider';

ImageViewerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ImageViewerProvider };
