import { gql } from '@apollo/client';

const APP_USER_L10N_LIST = gql`
  query AppUserL10NList(
    $filter: AppUserL10NFilter
    $params: PaginationAndSorting
  ) {
    appUserL10NList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        lang
        displayName
        preferredName
      }
    }
  }
`;

const APP_USER_L10N_SET = gql`
  mutation AppUserL10NSet($params: [AppUserL10NInput]) {
    appUserL10NSet(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        lang
        displayName
        preferredName
      }
    }
  }
`;

const APP_USER_L10N_REMOVE_MANY = gql`
  mutation AppUserL10NRemoveMany($where: [AppUserL10NWhereInput]) {
    appUserL10NRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        lang
        displayName
        preferredName
      }
    }
  }
`;

const appUserL10NQueries = {
  appUserL10NList: APP_USER_L10N_LIST,
  appUserL10NSet: APP_USER_L10N_SET,
  appUserL10NRemoveMany: APP_USER_L10N_REMOVE_MANY,
};

export { appUserL10NQueries };
