import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { AspectRatioBox, Text } from '@sdflc/ui';
import { invertColor, randomColor } from 'utils';

const colorsMap = {};

const GenericAvatar = memo((props) => {
  const { name, imageSize } = props;

  const text = (name || '')
    .split(' ')
    .map((item) => item[0])
    .join('')
    .slice(0, 3);

  const key = (name || '')
    .split(' ')
    .map((item) => `${item[0]}${item.length}`)
    .join('');

  let colorToUse = colorsMap[key];

  if (!colorToUse) {
    const color = randomColor();

    colorToUse = {
      textColor: invertColor(color, true),
      backgroundColor: color,
    };

    colorsMap[key] = colorToUse;
  }

  return (
    <AspectRatioBox
      ratio='1:1'
      width={imageSize ? `${imageSize}px` : '50px'}
      backgroundColor={colorToUse.backgroundColor}
      borderRadius={'8px'}
      centered
    >
      <Text color={colorToUse.textColor} style={{ textTransform: 'uppercase' }}>
        {text}
      </Text>
    </AspectRatioBox>
  );
});

GenericAvatar.displayName = 'GenericAvatar';

GenericAvatar.propTypes = {
  name: PropTypes.string,
  imageSize: PropTypes.number,
};

GenericAvatar.defaultProp = {
  imageSize: '48px',
};

export { GenericAvatar };
