import { memo } from 'react';
import PropTypes from 'prop-types';
import { AppPagesProvider } from 'providers';

const PrintLayout = memo((props) => {
  const { children } = props;
  return <AppPagesProvider>{children}</AppPagesProvider>;
});

PrintLayout.displayName = 'PrintLayout';

PrintLayout.propTypes = {
  children: PropTypes.node,
};

PrintLayout.defaultProps = {};

export { PrintLayout };
