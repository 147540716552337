import { memo } from 'react';
// import PropTypes from 'prop-types';
// import { Centered, Panel, VisibleBelow } from '@sdflc/ui';
// import { Logo } from 'components/Logo';
// import { colors } from 'config';
// import {
//   AuthContext,
//   ContentContext,
//   DictionaryContext,
//   LocalizationContext,
// } from 'contexts';
// import { useHistory } from 'react-router-dom';

const AppSideBarMenu = memo((props) => {
  // const { children, siteMap, page } = props;
  // const history = useHistory();
  // const { getText } = useContext(LocalizationContext);
  // const { appUser, appUserLangGet } = useContext(AuthContext);
  // const { dictionary, dictionaryItemList } = useContext(DictionaryContext);
  // const { contentItemList, content } = useContext(ContentContext);
  // const [menuOpen, setMenuOpen] = useState(false);
  // const [helpOpen, setHelpOpen] = useState(false);

  // const menuItems = useMemo(() => {
  //   const menu = siteMap.filter((page) => page.visible === true);

  //   const processItem = (items) => {
  //     return items.map((item) => {
  //       return {
  //         icon: item.icon,
  //         text: getText(item.name),
  //         to: item.url,
  //         items: processItem(item.items ?? []),
  //       };
  //     });
  //   };

  //   return processItem(menu);
  // }, [siteMap, getText]);

  return null;
});

AppSideBarMenu.displayName = 'AppSideBarMenu';

AppSideBarMenu.propTypes = {};
AppSideBarMenu.defaultProps = {};

export { AppSideBarMenu };
