import { OpResult } from '@sdflc/api-helpers';
import check from 'check-types';
import { castArray } from 'lodash';

import { OPERATIONS } from './operations';
import { arrayOfObjectsToSet } from './transformers';

class OperationManager {
  static defaultContext = '';

  constructor(props) {
    this.ops = {};
    this.opsByOperations = {};

    if (typeof props?.ops === 'object') {
      this.ops = props.ops;
    }

    this.unknownOp = Object.freeze(this.buildOpValue());
  }

  setState(state) {
    this.ops = state;
  }

  setStateControls(ops, setStateFunc) {
    this.ops = ops;
    if (check.function(setStateFunc)) {
      this.setState = setStateFunc;
    }
  }

  clone() {
    return this;
    // TODO:
    // return new OperationManager({
    //   opIds: this.opIds,
    //   unknownOp: this.unknownOp,
    // });
  }

  buildOpId(contextId, operation) {
    return `${operation}|${contextId}`;
  }

  buildOpValue(args) {
    const { called, variables, result } = args || {};

    return {
      called: called != null ? called : false,
      variables: typeof variables === 'object' ? variables : {},
      result: result instanceof OpResult ? result : new OpResult(),
    };
  }

  get(contextId, operation) {
    const opId = this.buildOpId(contextId, operation);
    const opValue = this.ops[opId];

    if (opValue) {
      return opValue;
    }

    return this.unknownOp;
  }

  set(contextIds, operation, opValue) {
    this.opsByOperations[operation] = null;
    this.setState((state) => {
      castArray(contextIds).forEach((contextId) => {
        const opId = this.buildOpId(contextId, operation);

        if (!state[opId]) {
          state[opId] = this.buildOpValue(opValue);
        } else {
          const { called, variables, result } = opValue || {};

          if (called != null) {
            state[opId].called = called;
          }

          if (typeof variables === 'object') {
            state[opId].variables = variables;
          }

          if (result instanceof OpResult) {
            state[opId].result = result;
          }

          if (called != null || !!variables || !!result) {
            // create a new object reference so re-render happens
            state[opId] = { ...state[opId] };
          }
        }
      });

      return { ...state };
    });

    return this;
  }

  setCalled(contextIds, operation, called) {
    this.opsByOperations[operation] = null;
    return this.set(contextIds, operation, { called });
  }

  setVariables(contextIds, operation, variables) {
    this.opsByOperations[operation] = null;
    return this.set(contextIds, operation, { variables });
  }

  setResult(contextIds, operation, result) {
    this.opsByOperations[operation] = null;
    return this.set(contextIds, operation, { result });
  }

  setStartOperation(contextIds, operation, resultCode, variables) {
    this.opsByOperations[operation] = null;

    return this.set(contextIds, operation, {
      called: true,
      variables,
      result: new OpResult().setCode(resultCode),
    });
  }

  reset(contextIds, operation) {
    const setOpIds = arrayOfObjectsToSet({
      data: contextIds ? castArray(contextIds) : [],
      buildValue: (dataItem) => this.buildOpId(dataItem, operation),
    });

    this.setState((state) => {
      const newState = Object.keys(state).reduce((ops, currentOpId) => {
        if (!setOpIds.has(currentOpId)) {
          ops[currentOpId] = state[currentOpId];
        }

        return ops;
      }, {});

      return newState;
    });

    this.opsByOperations[operation] = null;

    return this;
  }

  getCreateOp() {
    return this.get(OperationManager.defaultContext, OPERATIONS.CREATE);
  }

  getListOp() {
    return this.get(OperationManager.defaultContext, OPERATIONS.LIST);
  }

  getOpContextIds(operation) {
    return new Set(
      Object.keys(this.ops)
        .filter((opId) => opId.startsWith(operation))
        .map((opId) => opId.split('|')[1])
    );
  }

  getOpValues(operation) {
    if (this.opsByOperations[operation]) {
      return this.opsByOperations[operation];
    }

    this.opsByOperations[operation] = Object.keys(this.ops)
      .filter((opId) => opId.startsWith(operation))
      .reduce((acc, opId) => {
        acc[opId.split('|')[1]] = this.ops[opId];
        return acc;
      }, {});

    return this.opsByOperations[operation];
  }
}

export { OperationManager };
