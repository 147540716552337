import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Text } from '@sdflc/ui';
import { colors } from 'config';
import check from 'check-types';

const InputCurrency = memo((props) => {
  const { currencyCode, buttonName, onClick, ...rest } = props;

  const CurrencyBlock = useMemo(
    () => (
      <Text dimension={Text.DIMENSION_NAMES.SMALL} color={colors.grey07}>
        {currencyCode}
      </Text>
    ),
    [currencyCode]
  );

  const extraButton = useMemo(() => {
    if (!buttonName || !check.function(onClick)) {
      return null;
    }

    return (
      <Button
        name={buttonName}
        onClick={onClick}
        dimension={Button.DIMENSION_NAMES.SMALL}
        variant={Button.VARIANT_NAMES.TERTIARY}
      />
    );
  }, [buttonName, onClick]);

  return <Input {...rest} LeftSide={CurrencyBlock} RightSide={extraButton} />;
});

InputCurrency.displayName = 'InputCurrency';
InputCurrency.propTypes = {
  currencyCode: PropTypes.string,
  buttonName: PropTypes.string,
  onClick: PropTypes.func,
};
InputCurrency.defaultProp = {};

export { InputCurrency };
