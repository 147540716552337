import React, { memo } from 'react';
import { ProgressIndicator } from '@sdflc/ui';
import { colors } from '../../config/colors';

const Loading = memo(() => {
  return <ProgressIndicator size={64} kind='puff' color={colors.darkGrey} />;
});

Loading.displayName = 'Loading';

export { Loading };
