import check from 'check-types';
import { ValueDate, ValueCurrency } from '@sdflc/ui';

import { StoreBrandBadge } from 'components/StoreBrandBadge';
import {
  ACTIONS_BUTTONS,
  ACTIONS_IDS,
  ORDER_STATUSES,
  ORDER_STEPS,
  ORDER_LIST_CATEGORIES,
} from 'config';
import { stepIdToActionId } from 'utils';

export const orderDetailsConfig = (args) => {
  const { getText, isComplete } = args;

  if (!check.function(getText)) {
    console.debug(
      `The "orderDetailsConfig" expected the 'getText' to be a function but got:`,
      getText
    );
    return null;
  }

  const config = {
    orderNum: {
      label: getText('pages.order.details.orderNo'),
      leftIcon: 'Tag',
    },
    storeBrand: {
      label: getText('pages.order.details.storeBrand'),
      leftIcon: 'Storefront',
      onValueRender: ({ value }) => {
        return <StoreBrandBadge storeBrand={value} />;
      },
    },
    formattedAddress: {
      label: getText('pages.order.details.address'),
      leftIcon: 'LocationOn',
      onValueRender: ({ value }) => {
        return (
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value || 'N/A'}
          </div>
        );
      },
    },

    deliveryCost: {
      label: getText('pages.order.details.deliveryCost'),
      leftIcon: 'Paid',
      onValueRender: ({ value }) => {
        return (
          <ValueCurrency
            value={value}
            currencyCode='CAD'
            noValueMessage='N/A'
          />
        );
      },
    },
    paymentMethod: {
      label: getText('pages.order.details.paymentMethod'),
      leftIcon: 'CreditCard',
    },
  };

  if (isComplete) {
    config['totalAmount'] = {
      label: getText('pages.order.details.totalAmount'),
      leftIcon: 'Paid',
      onValueRender: ({ value }) => {
        return (
          <ValueCurrency
            value={value}
            currencyCode='CAD'
            noValueMessage='N/A'
          />
        );
      },
    };
  } else {
    config['dueDate'] = {
      label: getText('pages.order.details.due'),
      leftIcon: 'Schedule',
      onValueRender: ({ value }) => {
        return <ValueDate value={value} />;
      },
    };
  }

  return config;
};

export const buildOrderViewActions = (args) => {
  const { order, getText, orderListCategory /*, removingOps*/, setStepOps } =
    args || {};

  const buttons = ACTIONS_BUTTONS({ withNames: true, getText, width: '100%' });
  const orderActions = [];

  let statusForShopper = '';
  let actionInProgress = '';

  if (!order) {
    return { actions: [], orderActions, statusForShopper };
  }

  const setStepOp = setStepOps?.[order.id];

  if (setStepOp) {
    const stepId = setStepOp.result.isInProgress()
      ? setStepOp.variables.params.stepId
      : '';

    actionInProgress = stepIdToActionId(stepId);
  }

  if (order.orderNum === 1000087) {
    console.log('=== order', order);
    console.log('=== orderListCategory', orderListCategory);
  }

  switch (orderListCategory) {
    default:
    case ORDER_LIST_CATEGORIES.NONE:
      break;

    case ORDER_LIST_CATEGORIES.MY_ORDERS:
      orderActions.push(ACTIONS_IDS.EDIT);
      break;

    case ORDER_LIST_CATEGORIES.IN_PROGRESS:
      switch (order?.currentStepId) {
        default:
          break;

        case ORDER_STEPS.AVAILABLE:
          orderActions.push(ACTIONS_IDS.PICK);
          break;

        case ORDER_STEPS.PICKED:
          orderActions.push(ACTIONS_IDS.SHOP_ORDER_ITEMS);
          break;

        case ORDER_STEPS.IN_STORE:
          orderActions.push(ACTIONS_IDS.SHOP_ORDER_ITEMS);

          break;

        case ORDER_STEPS.ON_THE_WAY:
          orderActions.push(ACTIONS_IDS.GET_DIRECTIONS);
          orderActions.push(ACTIONS_IDS.DELIVERED);
          break;

        case ORDER_STEPS.DELIVERED:
          if (order?.isMine) {
            orderActions.push(ACTIONS_IDS.CONFIRM);
            orderActions.push(ACTIONS_IDS.DISPUTE);
          } else {
            orderActions.push(ACTIONS_IDS.GO_TO_ORDER_VIEW);
            statusForShopper = getText('orderSteps.waitingForConfirmation');
          }
          break;

        case ORDER_STEPS.CONFIRMED:
          if (!order?.isMine) {
            orderActions.push(ACTIONS_IDS.CONFIRM_PAYMENT);
          } else {
            statusForShopper = getText(
              'orderSteps.waitingForPaymentConfirmation'
            );
          }
          break;
      }

      // if (order?.currentStepId !== ORDER_STEPS.CONFIRMED) {
      //   orderActions.push(ACTIONS_IDS.SHOP_ORDER_ITEMS);
      // } else {
      //   orderActions.push(ACTIONS_IDS.VIEW);
      // }
      if (order?.isMine) {
        if (order?.currentStepId !== ORDER_STEPS.DELIVERED) {
          orderActions.push(ACTIONS_IDS.EDIT);
        }
      }
      //orderActions.push(ACTIONS_IDS.CANCEL_ACTION);
      break;

    case ORDER_LIST_CATEGORIES.PUBLISHED:
      orderActions.push(ACTIONS_IDS.VIEW);
      break;

    case ORDER_LIST_CATEGORIES.MY_PAST_ORDERS:
      orderActions.push(ACTIONS_IDS.GO_TO_ORDER_VIEW);
      break;

    case ORDER_LIST_CATEGORIES.SHOPPER_COMPLETED:
      orderActions.push(ACTIONS_IDS.GO_TO_ORDER_VIEW);
      break;
  }

  let actions = orderActions.map((actionId) => buttons[actionId]);

  if (actions.length >= 2) {
    const extra = actions.length % 2 === 0 ? 0 : 1;
    const newWidth = `calc(${100 / (actions.length - extra)}% - 5px)`;
    actions = actions.map((action, idx) => {
      if (extra !== 0 && idx === 0) {
        return action;
      }

      return {
        ...action,
        width: newWidth,
      };
    });
  }

  return { actions, statusForShopper, actionInProgress };
};
