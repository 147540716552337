import { gql } from '@apollo/client';

const AUTH_SIGNIN = gql`
  mutation ($params: SignInInput) {
    signIn(params: $params) {
      code
      errors {
        name
        errors
      }
      data {
        id
        username
        avatarUrl
        status
        userAccounts {
          accountId
          userRoleId
        }
      }
    }
  }
`;

const AUTH_REFRESH = gql`
  mutation RefreshToken {
    refreshToken {
      code
      errors {
        name
        errors
        warnings
      }
    }
  }
`;

const AUTH_SIGNUP = gql`
  mutation AppSignUp($params: AppSignUpInput) {
    appSignUp(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userRating
        l10ns {
          displayName
        }
        user {
          id
          username
          avatarUrl
          token
          userAccounts {
            accountId
            userRoleId
          }
        }
      }
    }
  }
`;

const AUTH_SIGNOUT = gql`
  mutation SignOut {
    signOut {
      code
      errors {
        name
        errors
        warnings
      }
    }
  }
`;

const AUTH_ME = gql`
  query AppMe {
    appMe {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userRating
        l10n {
          displayName
        }
        user {
          avatarUrl
          birthday
          username
          email
          emailConfirmation
          firstName
          middleName
          lastName
          timeZone
          uiLanguage
          status
          userAccounts {
            accountId
            userRoleId
          }
        }
      }
    }
  }
`;

const RESET_PASSWORD_REQUEST = gql`
  mutation ResetPasswordRequest($params: ResetPasswordRequestInput) {
    resetPasswordRequest(params: $params) {
      code
      errors {
        name
        errors
      }
      data
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($params: ResetPasswordInput) {
    resetPassword(params: $params) {
      code
      errors {
        name
        errors
      }
      data
    }
  }
`;

const authQueries = {
  signIn: AUTH_SIGNIN,
  refreshToken: AUTH_REFRESH,
  appSignUp: AUTH_SIGNUP,
  appMe: AUTH_ME,
  signOut: AUTH_SIGNOUT,
  resetPasswordRequest: RESET_PASSWORD_REQUEST,
  resetPassword: RESET_PASSWORD,
};

export { authQueries };
