import React, {
  Fragment,
  memo,
  useState,
  useContext,
  useCallback,
} from 'react';
//import PropTypes from 'prop-types';

import { SimpleForm, Button } from '@sdflc/ui';

import { Block } from 'components/Block/Block';

import { AuthContext, LocalizationContext } from '../../../contexts';

import { passwordChangeForm } from './passwordChangeForm';

const defaultFormData = {
  password: '',
  repeatPassword: '',
};

const PasswordChange = memo(() => {
  const { getText } = useContext(LocalizationContext);
  const [formData] = useState(defaultFormData);
  const {
    appUser,
    updateUser,
    updateUserResult,
    // updateUserCalled,
    // updateUserLoading,
    // updateUserReset,
  } = useContext(AuthContext);

  const labels = getText('forms.passwordChange.labels');
  const hints = getText('forms.passwordChange.hints');
  const placeholders = getText('forms.passwordChange.placeholders');

  const handleSubmit = useCallback(
    (formData) => {
      updateUser({
        params: formData,
        where: {
          id: appUser.id,
        },
      });
    },
    [appUser, updateUser]
  );

  return (
    <Block>
      <SimpleForm
        title={getText('')}
        description={getText('')}
        formData={formData}
        formCfg={{
          sections: passwordChangeForm,
          labels,
          placeholders,
          hints,
        }}
        onSubmit={handleSubmit}
        progress={updateUserResult.isInProgress()}
        opResult={updateUserResult}
        buttons={
          <Fragment>
            <Button
              icon='Done'
              name='Save'
              type='submit'
              progress={updateUserResult.isInProgress()}
            />
          </Fragment>
        }
      />
    </Block>
  );
});

PasswordChange.displayName = 'PasswordChange';

PasswordChange.propTypes = {};

PasswordChange.defaultProp = {};

export { PasswordChange };
