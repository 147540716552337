import { camelCase, snakeCase } from 'lodash';

/**
 * Converts properties to camel case
 * @param {*} obj
 * @returns
 */
export const toCamelCase = (obj) => {
  let objCamelCase = obj;

  if (Array.isArray(obj)) {
    objCamelCase = obj.map((v) => toCamelCase(v));
  } else if (obj != null && obj.constructor === Object) {
    objCamelCase = Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: toCamelCase(obj[key]),
      }),
      {}
    );
  }

  return objCamelCase;
};

/**
 * Converts properties to snake case
 * @param {*} obj
 * @returns
 */
export const toSnakeCase = (obj) => {
  let objSnakeCase = obj;

  if (Array.isArray(obj)) {
    objSnakeCase = obj.map((v) => toSnakeCase(v));
  } else if (obj != null && obj.constructor === Object) {
    objSnakeCase = Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeCase(key)]: toSnakeCase(obj[key]),
      }),
      {}
    );
  }

  return objSnakeCase;
};
