import { Fragment, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormDrawer } from '@sdflc/ui';

import { LocalizationContext } from '../../../contexts';

import { OrderItemsProvider } from '../../../providers';
import { withProvider } from '../../../hoc';
import { logger } from '../../../utils';

import { Block } from 'components/Block';
import { AppToolbar } from 'components/AppToolbar';

import { OrderItemsList } from './OrderItemsList';
import { useOrderItems } from './useOrderItems';
import { colors } from 'config';

const OrderItemsComponent = memo((props) => {
  const { hasTitle, readOnly, orderId, shopMode, order } = props;
  const { getText } = useContext(LocalizationContext);
  const {
    open,
    orderItems,
    orderItemList,
    formData,
    formCfg,
    formTitle,
    formActions,
    handleClickAction,
    handleClickClose,
    orderItemsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOpResult,
    removingOps,
  } = useOrderItems(props);

  if (formData) {
    formData.orderId = orderId;
  }

  return (
    <Fragment>
      <Block title={hasTitle ? getText('pages.orderItems.title') : ''}>
        {!readOnly && (
          <AppToolbar
            items={toolBarItems}
            onClick={handleClickAction}
            backgroundColor={colors.grey01}
          />
        )}
        <OrderItemsList
          onClickAction={handleClickAction}
          list={orderItemList}
          items={orderItems}
          listOp={orderItemsOpsManager.getListOp()}
          removingOps={removingOps}
          readOnly={readOnly}
          shopMode={shopMode}
          orderId={orderId}
          order={order}
        />
      </Block>
      <FormDrawer
        isOpen={open}
        title={formTitle}
        description=''
        formData={formData}
        formCfg={formCfg}
        onClose={handleClickClose}
        actions={formActions}
        actionInProgress={actionInProgress}
        onClickAction={handleClickAction}
        progress={saveOpResult.isInProgress()}
        opResult={saveOpResult}
        onChange={(formData) => {
          logger.debug('formData changed', formData);
          // TODO: add updateFormData method into onChange to update data properly
          //const item = userShoppingItemsMap[formData.shoppingItemId];
          // if (item) {
          //   formData.unitId = item.unitId;
          //   formData.quantity = item.quantity;
          //   formData.minPricePerUnit = item.minPricePerUnit;
          //   formData.maxPricePerUnit = item.maxPricePerUnit;
          // }
        }}
      />
    </Fragment>
  );
});

OrderItemsComponent.displayName = 'OrderItemsComponent';

OrderItemsComponent.propTypes = {
  orderId: PropTypes.string,
  children: PropTypes.node,
  hasTitle: PropTypes.bool,
  readOnly: PropTypes.bool,
  shopMode: PropTypes.bool,
};

OrderItemsComponent.defaultProps = {
  hasTitle: false,
  readOnly: false,
  shopMode: false,
};

const OrderItems = withProvider(OrderItemsComponent, OrderItemsProvider);

export { OrderItems };
