import { memo } from 'react';
import PropTypes from 'prop-types';
import { EmptyLayout, Panel, Text, Row } from '@sdflc/ui';

import { AppPagesProvider } from 'providers';
import { Logo } from 'components';

import pckg from '../../../package.json';

import { Container, ContentSide, ContentBody, ImageSide } from './styled';
import { colors } from 'config';

const SplitScreenLayout = memo((props) => {
  const { page, children, bgImage } = props;
  const { options } = page || {};

  return (
    <AppPagesProvider>
      <EmptyLayout asFlex notScrollable height='100%'>
        <Container>
          <ImageSide bgImage={bgImage || options.bgImage} />
          <ContentSide>
            <Panel p={3}>
              <Row>
                <Logo size='small' />
                <Text
                  pl={3}
                  dimension={Text.DIMENSION_NAMES.SMALL}
                  color={colors.grey07}
                >
                  BETA {pckg.version}
                </Text>
              </Row>
            </Panel>
            <ContentBody>{children}</ContentBody>
          </ContentSide>
        </Container>
      </EmptyLayout>
    </AppPagesProvider>
  );
});

SplitScreenLayout.displayName = 'SplitScreenLayout';

SplitScreenLayout.propTypes = {
  children: PropTypes.node,
  bgImage: PropTypes.string,
};

SplitScreenLayout.defaultProps = {};

export { SplitScreenLayout };
