import { EnableSwitch, SelectUser } from '../..';

export const userAllowListItemForm = [
  {
    fields: [
      {
        field: 'allowedUserId',
        width: ['100%'],
        component: SelectUser,
        componentProps: {},
      },
      {
        field: 'status',
        component: EnableSwitch,
        width: ['100%'],
      },
    ],
  },
];
