import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import {
  Button,
  ButtonUpload,
  FormField,
  ItemsGroup,
  Label,
  useToaster,
} from '@sdflc/ui';

import { OrderItemImage } from 'components/OrderItemImage';
import { logger } from 'utils';

const UserShoppingItemImage = memo(
  (props) => {
    const toast = useToaster();
    const { label, required, name, /*value, onChange,*/ data } = props;
    const { shoppingItemName } = data || {};

    // TODO: Add ability to upload image for the item
    const handleChangeChooseFile = useCallback(
      (name, value) => {
        logger.log('Upload an image here', { name, value });

        toast({
          message: "Sorry, uploading user's images is not yet supported",
          variant: 'warning',
          timeout: 30,
        });
      },
      [toast]
    );

    return (
      <FormField width='100%'>
        {!!label && (
          <Label htmlFor={name} required={required}>
            {label}
          </Label>
        )}
        <ItemsGroup>
          <OrderItemImage name={shoppingItemName} pictureUrl={''} />
          <ButtonUpload
            icon='CloudUpload'
            acceptMimeTypes={['image/*']}
            variant={Button.VARIANT_NAMES.SECONDARY}
            onChange={handleChangeChooseFile}
            title='Not supported yet'
          />
        </ItemsGroup>
      </FormField>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
  }
);

UserShoppingItemImage.displayName = 'UserShoppingItemImage';

UserShoppingItemImage.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  data: PropTypes.any,
};

UserShoppingItemImage.defaultProps = {};

export { UserShoppingItemImage };
