import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';
import { Panel, SimpleForm } from '@sdflc/ui';

import { LocalizationContext } from '../../../contexts';
import {
  loadGoogleMapsPlacesApi,
  initGoogleMapsPlacesAutocomplete,
  fillInAddress,
  diffObjectProps,
} from 'utils/misc';
import { cloneDeep, defaults, intersection, pick } from 'lodash';
import { appSettings } from 'config';

import { addressFormSections } from './addressFormSections';

// This is a list of fields which when changed manually should cause
// clearing of longitude and latitude
const IMPORTANT_LOCATION_FIELDS = [
  'address1',
  'countryId',
  'city',
  'state',
  'postalCode',
];

const EMPTY_ADDRESS = {
  address1: '',
  address2: '',
  address2Name: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  countryId: '',
  longitude: null,
  latitude: null,
  formattedAddress: '',
  streetName: '',
  streetNumber: '',
  selected: false,
  locationTypeId: 'home',
  notes: '',
};

const InputAddress = memo((props) => {
  const { name, onChange, value, countries } = props;
  const { getText } = useContext(LocalizationContext);
  const [data, setData] = useState(EMPTY_ADDRESS);
  const autoCompleteRef = useRef();
  const [sections] = useState(addressFormSections({}));
  const [ready, setReady] = useState(false);

  const handleSubmit = useCallback(
    (formData) => {
      if (!formData.selected) {
        const { address1, city, state, postalCode, country, countryId } =
          formData;
        formData.formattedAddress = [
          address1,
          city,
          state,
          postalCode,
          country || countryId,
        ]
          .filter((item) => !!item)
          .join(', ');
      }

      if (check.function(onChange)) {
        onChange(name, formData);
      }
    },
    [onChange, name]
  );

  useEffect(() => {
    const tplData = cloneDeep(EMPTY_ADDRESS);
    tplData.selected = true;
    const newData = defaults(pick(value, Object.keys(EMPTY_ADDRESS)), tplData);
    setData(newData);
  }, [value]);

  useEffect(() => {
    const getData = async () => {
      await loadGoogleMapsPlacesApi({ apiKey: appSettings.googleApiKey });

      initGoogleMapsPlacesAutocomplete({
        onSelect: (place) => {
          const newData = fillInAddress({ place });
          setData(newData);
          handleSubmit(newData);
        },
        autoCompleteRef,
        countries,
      });
    };

    if (ready) {
      getData();
    }
  }, [countries, ready, autoCompleteRef]); // eslint-disable-line

  const labels = getText('forms.address.labels');
  const hints = getText('forms.address.hints');
  const placeholders = getText('forms.address.placeholders');

  if (!sections[0].fields[1].componentProps.ref) {
    sections[0].fields[1].componentProps.ref = autoCompleteRef;
  } else if (!ready) {
    setReady(true);
  }

  return (
    <Panel width={'100%'}>
      <SimpleForm
        formData={data}
        formCfg={{
          sections,
          labels,
          hints,
          placeholders,
        }}
        onChange={(formData) => {
          // With manual entry we should reset latitude and longitude
          const diffProps = diffObjectProps(data, formData);
          const shouldClearSelected = intersection(
            diffProps,
            IMPORTANT_LOCATION_FIELDS
          );

          if (shouldClearSelected.length > 0) {
            // If we changed any field except address2 then we need to clear latitude/longitude
            formData.selected = false;
            formData.latitude = null;
            formData.longitude = null;
            //console.log('Clear latitude/longitude');
          }
          handleSubmit(formData);
        }}
        onSubmit={handleSubmit}
      />
    </Panel>
  );
});

InputAddress.displayName = 'InputAddress';

InputAddress.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  countries: PropTypes.arrayOf(PropTypes.string),
};

InputAddress.defaultProps = {
  countries: ['us', 'ca'],
};

export { InputAddress };
