import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Panel } from '@sdflc/ui';

const SectionWithShowMore = memo((props) => {
  const { initialHeight, children } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  return (
    <Panel>
      <Panel height={isOpen ? 'auto' : initialHeight} overflow='hidden'>
        {children}
      </Panel>
      <Button
        dimension={Button.DIMENSION_NAMES.SMALL}
        variant={Button.VARIANT_NAMES.TERTIARY}
        name={isOpen ? 'Show less' : 'Show more'}
        onClick={handleClick}
      />
    </Panel>
  );
});

SectionWithShowMore.displayName = 'SectionWithShowMore';
SectionWithShowMore.propTypes = {
  initialHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};
SectionWithShowMore.defaultProps = {
  initialHeight: `25px`,
};

export { SectionWithShowMore };
