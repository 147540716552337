import { memo, useEffect, useState } from 'react';
//import PropTypes from 'prop-types';
import { Input } from '@sdflc/ui';
import check from 'check-types';

const TotalAmount = memo((props) => {
  const { value } = props;
  const [data, setData] = useState('');

  useEffect(() => {
    const newData = (
      Number(props?.data?.actualQuantity) * Number(props?.data?.price)
    ).toFixed(2);

    if (newData !== data || value === null) {
      setData(newData);

      if (check.function(props.onChange)) {
        props.onChange(props.name || props.id, Number(newData));
      }
    }
  }, [value, data, props]);

  return <Input {...props} type='number' disabled={true} />;
});

TotalAmount.displayName = 'TotalAmount';

TotalAmount.propTypes = {
  ...Input.propTypes,
};

TotalAmount.defaultProps = {
  ...Input.defaultProps,
};

export { TotalAmount };
