import { Fragment, memo, useState, useEffect, useContext } from 'react';
import { ItemsGroup, Panel, useToaster } from '@sdflc/ui';

import { Orders, ActionSuggestion } from '../../components';

import { getLocation } from '../../utils/geoHelpers';

import {
  ACTIONS_IDS,
  ORDER_LIST_CATEGORIES,
  SHOW_USER_MODES,
} from '../../config';

import {
  AppPagesContext,
  AuthContext,
  LocalizationContext,
  UserLocationContext,
} from '../../contexts';

const PageHome = memo(() => {
  const [position, setPosition] = useState();
  const [timestamp, setTimestamp] = useState(1);
  const { userLocations, userLocationsOpsManager } =
    useContext(UserLocationContext);
  const { appUser } = useContext(AuthContext);
  const { goToPage } = useContext(AppPagesContext);
  const { getText } = useContext(LocalizationContext);
  const toast = useToaster();

  useEffect(() => {
    const getData = async () => {
      const result = await getLocation();
      if (result.didSucceed()) {
        const data = result.getDataFirst();

        setPosition(data);
      } else {
        toast({
          message: `Failed to get coordinates: ${result.getErrorSummary()}`,
          variant: 'danger',
          timeout: 15,
        });
      }
      setTimestamp(new Date().getTime());
    };
    getData();
  }, [setPosition, toast]);

  const suggestions = [];

  const userLocationsListOp = userLocationsOpsManager.getListOp();
  if (!userLocationsListOp.result.isLoading() && userLocations.length === 0) {
    // user needs add a location
    suggestions.push({
      iconName: 'HomeWork',
      text: getText('suggestions.addLocation'),
      onClick: () => {
        goToPage('settings-addresses');
      },
    });
  }

  if (appUser && appUser?.l10n?.displayName === appUser?.user?.username) {
    // user needs add a location
    suggestions.push({
      iconName: 'Person',
      text: getText('suggestions.addName'),
      onClick: () => {
        goToPage('settings-general');
      },
    });
  }

  return (
    <Fragment>
      {suggestions.length > 0 && (
        <Panel mb={3}>
          <ItemsGroup>
            {suggestions.map((suggestion) => {
              return (
                <ActionSuggestion
                  key={suggestion.text}
                  {...suggestion}
                  containerStyles={{ maxWidth: '30%' }}
                />
              );
            })}
          </ItemsGroup>
        </Panel>
      )}
      <Orders
        title='pages.home.titles.myInProgress'
        hasTitle
        scrollable
        orderItemActions={[]}
        orderListCategory={ORDER_LIST_CATEGORIES.IN_PROGRESS}
      />
      <Orders
        title='pages.home.titles.myOrders'
        hasTitle
        scrollable
        orderListCategory={ORDER_LIST_CATEGORIES.MY_ORDERS}
        orderItemActions={[ACTIONS_IDS.CREATE]}
      />
      <Orders
        key={timestamp}
        title='pages.home.titles.published'
        hasTitle
        scrollable
        orderItemActions={[]}
        orderListCategory={ORDER_LIST_CATEGORIES.PUBLISHED}
        orderListFilter={{
          userCoordinates: {
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
          },
        }}
        orderListPagination={{
          pagination: {
            page: 1,
            pageSize: 4,
          },
        }}
        showUserMode={SHOW_USER_MODES.BUYER}
      />
    </Fragment>
  );
});

PageHome.displayName = 'PageHome';

export { PageHome };
