import { FORM_INPUT_TYPES } from '@sdflc/ui';

export const contactUsForm = [
  {
    fields: [
      {
        field: 'subject',
        width: ['100%'],
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        field: 'message',
        type: FORM_INPUT_TYPES.TEXTAREA,
        width: ['100%'],
      },
    ],
  },
];
