import { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import { STATUSES } from '@sdflc/utils';
import { ToolBarItem, useToaster } from '@sdflc/ui';

import { useApiOrder } from '../../../graphql/hooks';

import {
  logger,
  OPERATIONS,
  OperationManager,
  onlyPropsOfObject,
  actionIdToOrderStepId,
  stepIdToActionId,
} from '../../../utils';

import {
  AppPagesContext,
  CommentsContext,
  LocalizationContext,
} from '../../../contexts';

import {
  appSettings,
  APP_PAGE_KEYS,
  ORDER_STEPS,
  ACTIONS_BUTTONS,
  ACTIONS_IDS,
} from '../../../config';
import { createDirectionsLink } from 'utils/googleMaps';

const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const tplNewOrder = {
  storeBrandId: null,
  paymentMethodId: null,
  locationId: null,
  deliveryCost: null,
  dueDate: null,
  buyerComment: null,
  shopperComment: null,
};

const useOrders = (props) => {
  const { orderListFilter, orderListCategory, orderListPagination } =
    props || {};
  const toast = useToaster();
  const { getText } = useContext(LocalizationContext);
  const { goToPage } = useContext(AppPagesContext);
  const { setChannelKey } = useContext(CommentsContext);

  const [filterToUse, setFilterToUse] = useState(null);
  const [distance, setDistance] = useState(appSettings.defaultDistanceKm);
  const [totalFormOpen, setTotalFormOpen] = useState(false);
  const [loadOrdersTrigger, setLoadOrdersTrigger] = useState(true);
  const {
    order,
    orders,
    orderCreate,
    orderUpdate,
    orderRemoveMany,
    orderGet,
    orderGetMany,
    orderSetStep,
    orderList,
    ordersOpsManager,
  } = useApiOrder();

  const orderId = order?.id || '';
  const createOp = ordersOpsManager.getCreateOp();
  const listOp = ordersOpsManager.get(
    OperationManager.defaultContext,
    OPERATIONS.LIST
  );
  const updateOp = ordersOpsManager.get(orderId, OPERATIONS.UPDATE);
  const setStepOp = ordersOpsManager.get(orderId, OPERATIONS.ORDER_SET_STEP);
  const saveOp = orderId ? updateOp : createOp;
  const saveOpResult = saveOp.result;
  const setStepOpResult = setStepOp.result;
  const removingOps = ordersOpsManager.getOpValues(OPERATIONS.REMOVE_MANY);
  const setStepOps = ordersOpsManager.getOpValues(OPERATIONS.ORDER_SET_STEP);

  useEffect(() => {
    setFilterToUse({
      ...(orderListFilter || {}),
      distance,
    });
    setLoadOrdersTrigger(true);
  }, [orderListFilter, distance]);

  useEffect(() => {
    if (loadOrdersTrigger && orderListCategory) {
      orderList({
        filter: {
          ...filterToUse,
          ...orderListFilter,
          orderListCategory,
        },
        params: orderListPagination || {},
      });
      setLoadOrdersTrigger(false);
    }
  }, [
    orderList,
    setLoadOrdersTrigger,
    loadOrdersTrigger,
    orderListFilter,
    orderListCategory,
    orderListPagination,
    filterToUse,
  ]);

  useEffect(() => {
    // This effect is for case when when an order has been created

    // Order is not being saved yet
    if (!createOp.called) {
      return;
    }

    // Order create operation result is not in progress and succeeded
    // So, set current order and go to the edit page
    if (!createOp.result.isInProgress()) {
      ordersOpsManager.reset(
        OperationManager.defaultContext,
        OPERATIONS.CREATE
      );

      if (createOp.result.didSucceed()) {
        const order = createOp.result.getDataFirst();

        goToPage(APP_PAGE_KEYS.ORDER_EDIT, {
          orderId: order.id,
        });
      } else {
        toast({
          message: getText('blocks.orders.errors.failedToCreate', {
            error: createOp.result.getErrorSummary(),
          }),
          variant: 'danger',
          timeout: 30,
        });
      }
    }
  }, [createOp, ordersOpsManager, goToPage, toast, getText]);

  useEffect(() => {
    // This effect is for case when when an order has been updated

    // Contact is not being saved yet
    if (!updateOp.called) {
      return;
    }

    // contact operation result is not in progress and succeeded
    if (!updateOp.result.isInProgress() && updateOp.result.didSucceed()) {
      ordersOpsManager.reset(orderId, OPERATIONS.UPDATE);
    }
  }, [updateOp, ordersOpsManager, orderId]);

  useEffect(() => {
    if (!setStepOp.called) {
      return;
    }

    if (!setStepOp.result.isInProgress()) {
      ordersOpsManager.reset(orderId, OPERATIONS.ORDER_SET_STEP);

      if (setStepOp.result.didSucceed()) {
        const newStepId = setStepOp.result.getDataFirst()?.currentStepId;

        switch (newStepId) {
          default:
            logger.debug('Unsupported order step:', newStepId);
            break;

          case ORDER_STEPS.PICKED:
            goToPage(APP_PAGE_KEYS.ORDER_SHOP_ITEMS, {
              orderId: setStepOp.result.getDataFirst()?.id,
            });
            break;

          case ORDER_STEPS.DELIVERED:
            goToPage(APP_PAGE_KEYS.ORDER_DELIVERED, {
              orderId: setStepOp.result.getDataFirst()?.id,
            });
            break;

          case ORDER_STEPS.CONFIRMED:
          case ORDER_STEPS.PAID:
            goToPage(APP_PAGE_KEYS.ORDER_CONFIRMED, {
              orderId: setStepOp.result.getDataFirst()?.id,
            });
            break;
        }
      } else {
        toast({
          message: getText('blocks.orders.errors.failedToSetOrderStep', {
            error: setStepOp.result.getErrorSummary(),
          }),
          variant: 'danger',
          timeout: 30,
        });
      }
    }
  }, [setStepOp, goToPage, getText, orderId, ordersOpsManager, toast]);

  useEffect(() => {
    Object.values(setStepOps).forEach((setStepOp) => {
      if (
        setStepOp.called &&
        !setStepOp.result.isInProgress() &&
        setStepOp.result.didFail()
      ) {
        toast({
          message: getText('blocks.orders.errors.failedToSetOrderStep', {
            error: setStepOp.result.getErrorSummary(),
          }),
          variant: 'danger',
          timeout: 30,
        });
      }
    });
  }, [setStepOps, toast, getText]);

  const handleClickAction = useCallback(
    (action, item, formData) => {
      logger.log('The "useOrders" got an action:', {
        action,
        item,
      });

      switch (action.id) {
        default:
          logger.warn('The "useOrders" got an unexpected action:', {
            action,
            item,
          });
          break;

        case ACTIONS_IDS.ADD:
        case ACTIONS_IDS.CREATE:
          ordersOpsManager.reset(
            OperationManager.defaultContext,
            OPERATIONS.CREATE
          );

          orderCreate({
            params: {
              dueDate: dayjs().add(appSettings.newOrderHoursOffset, 'hour'),
              locationId: null,
              deliveryCost: null,
              status: STATUSES.DRAFT,
            },
          });
          break;

        case ACTIONS_IDS.GO_TO_ORDER_VIEW:
        case ACTIONS_IDS.VIEW:
          goToPage(APP_PAGE_KEYS.ORDER_DETAILS, { orderId: item.id });
          break;

        case ACTIONS_IDS.SAVE:
          if (!item.id) {
            orderCreate({ params: item });
          } else {
            orderUpdate({
              where: { id: item.id },
              params: onlyPropsOfObject(item, tplNewOrder),
            });
          }
          break;

        case ACTIONS_IDS.EDIT:
          ordersOpsManager.reset(item?.id, OPERATIONS.UPDATE);
          goToPage(APP_PAGE_KEYS.ORDER_EDIT, { orderId: item.id });
          break;

        case ACTIONS_IDS.REMOVE:
          ordersOpsManager.reset(item?.id, OPERATIONS.REMOVE_MANY);
          orderRemoveMany({ where: [{ id: item.id }] });
          break;

        case ACTIONS_IDS.SHOP_ORDER_ITEMS:
          goToPage(APP_PAGE_KEYS.ORDER_SHOP_ITEMS, { orderId: item.id });
          break;

        case ACTIONS_IDS.PUBLISH:
        case ACTIONS_IDS.PICK:
        case ACTIONS_IDS.ON_THE_WAY:
        case ACTIONS_IDS.DELIVERED:
        case ACTIONS_IDS.CONFIRM:
        case ACTIONS_IDS.DISPUTE:
        case ACTIONS_IDS.CANCEL_ACTION:
          ordersOpsManager.reset(item?.id, OPERATIONS.ORDER_SET_STEP);
          orderSetStep({
            params: {
              id: item.id,
              stepId: actionIdToOrderStepId(action.id),
              reason: item.reason,
            },
          });
          break;

        case ACTIONS_IDS.ENTER_TOTAL:
          setTotalFormOpen(true);
          break;

        case ACTIONS_IDS.SAVE_ORDER_TOTAL:
          ordersOpsManager.reset(item?.id, OPERATIONS.UPDATE);
          orderUpdate({
            where: { id: item.id },
            params: {
              totalAmount: formData.totalAmount,
              shopperComment: formData.shopperComment,
            },
          });
          break;

        case ACTIONS_IDS.CANCEL_ORDER_TOTAL:
          setTotalFormOpen(false);
          break;

        case ACTIONS_IDS.VIEW_ORDER_COMMENTS:
          setChannelKey(`order-${item.id}`);
          break;

        case ACTIONS_IDS.GET_DIRECTIONS:
          window.open(
            createDirectionsLink({
              origin: '',
              destination: item.userLocation?.formattedAddress,
            }),
            'shopaDropaDirections'
          );
          break;
      }
    },
    [
      orderCreate,
      orderUpdate,
      orderSetStep,
      orderRemoveMany,
      setTotalFormOpen,
      setChannelKey,
      ordersOpsManager,
      goToPage,
    ]
  );

  const handleClickClose = useCallback(() => {
    handleClickAction({ id: ACTIONS_IDS.CANCEL }, null);
  }, [handleClickAction]);

  const handleChangeDistance = useCallback(
    (_, value) => {
      setDistance(value);
      setFilterToUse({
        ...(orderListFilter || {}),
        distance: value,
      });
      setLoadOrdersTrigger(true);
    },
    [setDistance, setFilterToUse, orderListFilter]
  );

  // const actionInProgress = useMemo(() => {
  //   logger.debug(
  //     'actionInProgress',
  //     createOp.result.isInProgress(),
  //     createOp.result
  //   );
  //   return createOp.result.isInProgress() ? ACTIONS_IDS.ADD : '';
  // }, [createOp]);

  const orderViewactionInProgress = useMemo(() => {
    const stepId = setStepOp.result.isInProgress()
      ? setStepOp.variables.params.stepId
      : '';
    const actionId = stepIdToActionId(stepId);
    // logger.log(
    //   'orderViewactionInProgress',
    //   setStepOp.result.isInProgress(),
    //   stepId,
    //   actionId,
    //   mapOrderStepIdToActionId[stepId],
    //   mapOrderStepIdToActionId
    // );
    return actionId;
  }, [setStepOp]);

  const toolBarItems = useMemo(() => {
    return [
      // {
      //   id: 'keyword',
      //   type: ToolBarItem.types.COMPONENT,
      //   name: 'keyword',
      //   value: filters.keyword,
      //   component: Input,
      //   placeholder: getText('generic.search'),
      //   leftIcon: 'Search',
      // },
      {
        ...BUTTONS.ADD,
        type: ToolBarItem.types.BUTTON,
        progress: createOp.result.isInProgress(),
      },
    ];
  }, [createOp]);

  return {
    orders,
    order,

    orderGet,
    orderList,
    orderGetMany,
    handleClickAction,
    handleClickClose,
    handleChangeDistance,
    setTotalFormOpen,
    setLoadOrdersTrigger,

    listOp,
    createOp,
    setStepOp,
    saveOpResult,
    setStepOpResult,
    removingOps,
    setStepOps,

    //actionInProgress,
    orderViewactionInProgress,

    filterToUse,
    distance,
    totalFormOpen,

    loadOrdersTrigger,

    toolBarItems,

    ordersOpsManager,
  };
};

export { useOrders };
