import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Text, ItemsGroup, Flex } from '@sdflc/ui';
import { appSettings } from 'config';

const StoreBrandBadge = memo((props) => {
  const { storeBrand } = props;
  const { storeBrandLogoUrl, l10n } = storeBrand || {};
  const { storeBrandName } = l10n || {};

  return (
    <ItemsGroup wrapMode='nowrap'>
      <Flex flex='0 0'>
        <Avatar
          src={storeBrandLogoUrl || appSettings.anyStoreUrl}
          dimension='small'
          variant='circle'
        />
      </Flex>
      <Flex flex='1 1'>
        <Text bold dimension='small'>
          {storeBrandName || 'N/A'}
        </Text>
      </Flex>
    </ItemsGroup>
  );
});

StoreBrandBadge.displayName = 'StoreBrandBadge';

StoreBrandBadge.propTypes = {
  appUser: PropTypes.shape({}),
};

export { StoreBrandBadge };
