export const processApiUserLocation = (userLocation) => {
  const {
    id,
    locationId,
    locationTypeId,
    notes /*, status */,
    location,
    isDefault,
  } = userLocation ?? {};
  const { l10n, l10ns, latitude, longitude, countryId, postalCode, address2 } =
    location ?? {};

  const l10nsTouse = Array.isArray(l10ns) ? l10ns : l10n ? [l10n] : [];
  let l10nToUse = l10nsTouse.find((l10n) => l10n?.lang === 'en');

  if (!l10nToUse) {
    l10nToUse = l10nsTouse[0] ?? {};
  }

  const { address1, address2Name, city, state } = l10nToUse;

  const formattedAddressParts = [
    address2 ? [address2Name || '', address2 || ''].join(' ') : null,
    address1,
    city,
    state,
    postalCode,
    countryId,
  ];

  return {
    id,
    locationId,
    locationTypeId,
    isDefault,
    formattedAddress: formattedAddressParts.filter((part) => !!part).join(', '),
    location: {
      countryId,
      postalCode,
      longitude,
      latitude,
      city,
      state,
      address1,
      address2,
      address2Name,
      notes,
    },
  };
};
