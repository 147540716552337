import { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@sdflc/ui';
import { ORDER_STATUSES } from '../../../config';

const MAP_CONTACT_TYPE_TO_ICON = {
  UNKNOWN: 'QuestionMark',
  [ORDER_STATUSES.DRAFT]: 'ModeEdit',
  [ORDER_STATUSES.PUBLISHED]: 'Flag',
  [ORDER_STATUSES.PROGRESS]: 'RunCircle',
  [ORDER_STATUSES.COMPLETED]: 'CheckCircle',
  [ORDER_STATUSES.CANCELLED]: 'Cancel',
};

const OrderStatusIcon = memo((props) => {
  const { status } = props;

  return (
    <Icon
      name={
        MAP_CONTACT_TYPE_TO_ICON[status] || MAP_CONTACT_TYPE_TO_ICON['UNKNOWN']
      }
    />
  );
});

OrderStatusIcon.displayName = 'OrderStatusIcon';

OrderStatusIcon.propTypes = {
  contactTypeId: PropTypes.string,
};

OrderStatusIcon.defaultProps = {
  contact: 'phone',
};

export { OrderStatusIcon };
