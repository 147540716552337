import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@sdflc/ui';

import { Container } from './styled';

const UserRating = memo((props) => {
  const { value } = props;

  if (value == null) {
    return null;
  }

  return (
    <Container>
      <Icon name='Star' />
      <span>{value}</span>
    </Container>
  );
});

UserRating.displayName = 'UserRating';

UserRating.propTypes = {
  value: PropTypes.number,
};

export { UserRating };
