import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelOver, Skeleton, Text } from '@sdflc/ui';
import { OrderItemImage } from 'components/OrderItemImage';
import { colors } from 'config';

const containerStyles = {
  display: 'block',
};

const OrderCardImages = memo((props) => {
  const {
    orderItems,
    mainImages,
    imageHolderRef,
    imageSize,
    imagesToShow,
    isLoading,
  } = props;
  return (
    <div
      ref={imageHolderRef}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: '10px',
        gap: `calc((100% - ${imageSize * imagesToShow}px) / ${
          imagesToShow - 1
        })`,
        height: imageSize,
      }}
    >
      {mainImages.map((orderItem, index) => {
        if (index === imagesToShow - 1 && orderItems.length > imagesToShow) {
          return (
            <Skeleton
              loading={isLoading}
              containerStyles={containerStyles}
              key={orderItem.id ? orderItem.id : index}
            >
              <Panel width={`${imageSize}px`}>
                <OrderItemImage
                  name={orderItem.name}
                  pictureUrl={orderItem.pictureUrl}
                  imageSize={imageSize}
                  blur
                />
                <PanelOver
                  backgroundColor={colors.black}
                  opacity={0.5}
                  borderRadius='8px'
                />
                <PanelOver
                  padding={'8px'}
                  placement={PanelOver.placements.CENTER}
                >
                  <Text color={colors.white}>+{orderItems.length - 4}</Text>
                </PanelOver>
              </Panel>
            </Skeleton>
          );
        }

        return (
          <Skeleton
            key={orderItem.id ? orderItem.id : index}
            loading={isLoading}
            containerStyles={containerStyles}
          >
            <OrderItemImage
              name={orderItem.name}
              pictureUrl={orderItem.pictureUrl}
              imageSize={imageSize}
            />
          </Skeleton>
        );
      })}
    </div>
  );
});

OrderCardImages.displayName = 'OrderCardImages';

OrderCardImages.propTypes = {
  orderItems: PropTypes.arrayOf(PropTypes.shape({})),
  mainImages: PropTypes.arrayOf(PropTypes.shape({})),
  imageHolderRef: PropTypes.any,
  imageSize: PropTypes.number,
  imagesToShow: PropTypes.number,
  isLoading: PropTypes.bool,
};

OrderCardImages.defaultProp = {};

export { OrderCardImages };
