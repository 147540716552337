import { memo, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Caption, FormInput, Tile, Panel, Row } from '@sdflc/ui';

import { logger } from '../../../utils';

import { Block } from '../../Block';
import { LocalizationContext, OrderInvitationContext } from '../../../contexts';

import { useApiAppUser } from 'graphql/hooks/useApiAppUser';

import { Loading, UserBadge } from 'components';

const OrderInvite = memo((props) => {
  const { orderId, hasTitle } = props;
  const { getText } = useContext(LocalizationContext);
  const { appUserList, appUserListLoading, users } = useApiAppUser();
  const {
    orderInvitationCreate /*, orderInvitationCreateReset, orderInvitation */,
  } = useContext(OrderInvitationContext);
  const [filter, setFilter] = useState({ keyword: '' });

  useEffect(() => {
    appUserList({
      filter,
      params: {
        pagination: {
          page: 1,
          pageSize: 100,
        },
      },
    });
  }, [appUserList, filter]);

  const handleClickInvite = useCallback(
    (user) => {
      logger.log('Invite user', user);
      orderInvitationCreate({
        params: {
          orderId: orderId,
          shopperId: user.id,
          buyerComment: '',
        },
      });
    },
    [orderInvitationCreate, orderId]
  );

  return (
    <Block title={hasTitle ? getText('pages.orderInvite.title') : ''}>
      <Caption level={3}>Intive shopper to execute the order</Caption>
      <FormInput
        placeholder={getText('generic.username')}
        fieldName='keyword'
        onChange={(_, value) => setFilter({ ...filter, keyword: value })}
        data={filter}
      />
      {appUserListLoading && <Loading />}
      {!appUserListLoading && users.didSucceed() && (
        <Panel>
          <Caption level={4}>Found users</Caption>
          {users.getData().map((user) => {
            return (
              <Tile key={user.id} mb={2} border='solid 1px #efefef'>
                <Row>
                  <UserBadge appUser={user} />
                  <Button
                    ml='auto'
                    icon='Send'
                    name={getText('buttons.invite')}
                    variant={Button.VARIANT_NAMES.SECONDARY}
                    dimension={Button.DIMENSION_NAMES.SMALL}
                    onClick={() => handleClickInvite(user)}
                  />
                </Row>
              </Tile>
            );
          })}
        </Panel>
      )}
    </Block>
  );
});

OrderInvite.displayName = 'OrderInvite';

OrderInvite.propTypes = {
  orderId: PropTypes.string,
};

export { OrderInvite };
