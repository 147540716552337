import { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@sdflc/ui';
import { ORDER_STEPS } from '../../../config';

const ICONS = {
  UNKNOWN: 'QuestionMark',
  [ORDER_STEPS.DRAFT]: 'Drafts',
  [ORDER_STEPS.AVAILABLE]: 'Flag',
  [ORDER_STEPS.PICKED]: 'RunCircle',
  [ORDER_STEPS.IN_STORE]: 'RunCircle',
  [ORDER_STEPS.ON_THE_WAY]: 'RunCircle',
  [ORDER_STEPS.DELIVERED]: 'RunCircle',
  [ORDER_STEPS.CONFIRMED]: 'RunCircle',
  [ORDER_STEPS.NOT_CONFIRMED]: 'RunCircle',
  [ORDER_STEPS.PAID]: 'Cancel',
  [ORDER_STEPS.CANCELLED_BY_SYSTEM]: 'Cancel',
  [ORDER_STEPS.CANCELLED_BY_USER]: 'Cancel',
};

const OrderStepIcon = memo((props) => {
  const { value } = props;

  return <Icon name={ICONS[value] || ICONS['UNKNOWN']} />;
});

OrderStepIcon.displayName = 'OrderStepIcon';

OrderStepIcon.propTypes = {
  value: PropTypes.any,
};

OrderStepIcon.defaultProps = {
  value: '',
};

export { OrderStepIcon };
