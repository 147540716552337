import { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { Orders } from '../../components';
import { ORDER_LIST_CATEGORIES } from 'config/orderCard';

/**
 * Page component with a list of orders fulfilled by the user in role of shopper
 */
const PageOrdersIDelivered = memo((props) => {
  return (
    <Fragment>
      <Orders
        title='pages.ordersIDelivered.title'
        orderListCategory={ORDER_LIST_CATEGORIES.SHOPPER_COMPLETED}
      />
    </Fragment>
  );
});

PageOrdersIDelivered.displayName = 'PageOrdersIDelivered';

PageOrdersIDelivered.propTypes = {
  children: PropTypes.node,
};

PageOrdersIDelivered.defaultProps = {};

export { PageOrdersIDelivered };
