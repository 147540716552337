import { Fragment, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormDrawer } from '@sdflc/ui';

import { LocalizationContext } from '../../../contexts';

import { Block } from '../../Block';
import { AppToolbar } from '../../AppToolbar';

import { UserContactsList } from './UserContactsList';
import { useUserContacts } from './useUserContacts';

const UserContacts = memo((props) => {
  const { hasTitle } = props;
  const { getText } = useContext(LocalizationContext);
  const {
    open,
    userContacts,
    userContactList,
    formData,
    formCfg,
    formActions,
    handleClickAction,
    handleClickClose,
    userContactsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOpResult,
    removingOps,
  } = useUserContacts();

  return (
    <Fragment>
      <Block title={hasTitle ? getText('pages.userContacts.title') : ''}>
        <AppToolbar items={toolBarItems} onClick={handleClickAction} />
        <UserContactsList
          onClickAction={handleClickAction}
          list={userContactList}
          items={userContacts}
          listOp={userContactsOpsManager.getListOp()}
          removingOps={removingOps}
        />
      </Block>
      <FormDrawer
        isOpen={open}
        title={getText('pages.userContacts.form.title.add')}
        description=''
        formData={formData}
        formCfg={formCfg}
        onClose={handleClickClose}
        actions={formActions}
        actionInProgress={actionInProgress}
        onClickAction={handleClickAction}
        progress={saveOpResult.isInProgress()}
        opResult={saveOpResult}
      />
    </Fragment>
  );
});

UserContacts.displayName = 'UserContacts';

UserContacts.propTypes = {
  children: PropTypes.node,
  hasTitle: PropTypes.bool,
};

UserContacts.defaultProps = {
  hasTitle: false,
};

export { UserContacts };
