import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { ToolBarItem } from '@sdflc/ui';

import {
  logger,
  OperationManager,
  OPERATIONS,
  onlyPropsOfObject,
} from '../../../utils';
import { ACTIONS_BUTTONS, ACTIONS_IDS } from 'config';
import { useApiOrderSubscriptionByDistance } from '../../../graphql/hooks';
import { LocalizationContext } from '../../../contexts';

import { orderSubscriptionByDistanceForm } from './orderSubscriptionByDistanceForm';

const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const tplOrderSubscriptionByDistance = {
  userLocationId: null,
  distanceKm: 5,
  status: 100,
};

const useOrderSubscriptionsByDistance = () => {
  const { getText } = useContext(LocalizationContext);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const {
    orderSubscriptionByDistance,
    orderSubscriptionByDistances,

    orderSubscriptionByDistanceCreate,
    orderSubscriptionByDistanceUpdate,
    orderSubscriptionByDistanceRemoveMany,
    orderSubscriptionByDistanceList,

    orderSubscriptionByDistancesOpsManager,
  } = useApiOrderSubscriptionByDistance();

  const contactId = formData?.id || '';
  const createOp = orderSubscriptionByDistancesOpsManager.getCreateOp();
  const updateOp = orderSubscriptionByDistancesOpsManager.get(
    contactId,
    OPERATIONS.UPDATE
  );
  const saveOp = contactId ? updateOp : createOp;
  const saveOpResult = saveOp.result;
  const updatingOps = orderSubscriptionByDistancesOpsManager.getOpValues(
    OPERATIONS.UPDATE
  );
  const removingOps = orderSubscriptionByDistancesOpsManager.getOpValues(
    OPERATIONS.REMOVE_MANY
  );

  useEffect(() => {
    setOpen(!!formData);
  }, [formData]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of new item

    // Item is not being saved yet
    if (!createOp.called) {
      return;
    }

    // Create operation result is not in progress and succeeded
    if (!createOp.result.isInProgress() && createOp.result.didSucceed()) {
      orderSubscriptionByDistancesOpsManager.reset(
        OperationManager.defaultContext,
        OPERATIONS.CREATE
      );
      setFormData(null);
    }
  }, [createOp, orderSubscriptionByDistancesOpsManager]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of exising item

    // Item is not being saved yet
    if (!updateOp.called) {
      return;
    }

    // Operation result is not in progress and succeeded
    if (!updateOp.result.isInProgress() && updateOp.result.didSucceed()) {
      orderSubscriptionByDistancesOpsManager.reset(
        contactId,
        OPERATIONS.UPDATE
      );
      setFormData(null);
    }
  }, [updateOp, orderSubscriptionByDistancesOpsManager, contactId]);

  const handleClickAction = useCallback(
    async (action, item) => {
      switch (action.id) {
        default:
          logger.warn(
            'The "useOrderSubscriptionsByDistance" got an unexpected action:',
            {
              action,
              item,
            }
          );
          break;

        case ACTIONS_IDS.ADD:
        case ACTIONS_IDS.CREATE:
          orderSubscriptionByDistancesOpsManager.reset(
            OperationManager.defaultContext,
            OPERATIONS.SET
          );
          setFormData(cloneDeep(tplOrderSubscriptionByDistance));
          break;

        case ACTIONS_IDS.SAVE:
          if (!item.id) {
            orderSubscriptionByDistanceCreate({ params: item });
          } else {
            orderSubscriptionByDistanceUpdate({
              where: { id: item.id },
              params: onlyPropsOfObject(item, tplOrderSubscriptionByDistance),
            });
          }
          break;

        case ACTIONS_IDS.CANCEL:
          setFormData(null);
          break;

        case ACTIONS_IDS.EDIT:
          orderSubscriptionByDistancesOpsManager.reset(
            OperationManager.defaultContext,
            OPERATIONS.UPDATE
          );
          setFormData(cloneDeep(item));
          break;

        case ACTIONS_IDS.REMOVE:
          orderSubscriptionByDistanceRemoveMany({
            where: [{ id: item.id }],
          });
          break;

        case ACTIONS_IDS.TOGGLE_STATUS:
          {
            const toUpd = onlyPropsOfObject(
              item,
              tplOrderSubscriptionByDistance
            );
            toUpd.status = item.status === 100 ? 0 : 100;
            orderSubscriptionByDistanceUpdate({
              where: { id: item.id },
              params: toUpd,
            });
          }
          break;
      }
    },
    [
      orderSubscriptionByDistanceCreate,
      orderSubscriptionByDistanceUpdate,
      orderSubscriptionByDistanceRemoveMany,
      orderSubscriptionByDistancesOpsManager,
    ]
  );

  const handleClickClose = useCallback(() => {
    handleClickAction({ id: ACTIONS_IDS.CANCEL }, null);
  }, [handleClickAction]);

  const formCfg = useMemo(() => {
    return {
      sections: orderSubscriptionByDistanceForm,
      labels: getText('blocks.orderSubscriptionByDistanceItem.form.labels'),
      hints: getText('pages.orderSubscriptionByDistanceItem.form.hints'),
      placeholders: getText(
        'pages.orderSubscriptionByDistanceItem.form.placeholders'
      ),
    };
  }, [getText]);

  const formActions = useMemo(() => [BUTTONS.SAVE, BUTTONS.CANCEL], []);
  const actionInProgress = useMemo(
    () => (saveOpResult.isInProgress() ? [ACTIONS_IDS.SAVE] : []),
    [saveOpResult]
  );

  const toolBarItems = useMemo(() => {
    return [
      // {
      //   id: 'keyword',
      //   type: ToolBarItem.types.COMPONENT,
      //   name: 'keyword',
      //   value: filters.keyword,
      //   component: Input,
      //   placeholder: getText('generic.search'),
      //   leftIcon: 'Search',
      // },
      { ...BUTTONS.ADD, type: ToolBarItem.types.BUTTON },
    ];
  }, []);

  return {
    open,
    orderSubscriptionByDistance,
    orderSubscriptionByDistances,
    orderSubscriptionByDistanceList,
    formData,
    formCfg,
    formActions,
    setFormData,
    handleClickAction,
    handleClickClose,
    orderSubscriptionByDistancesOpsManager,
    actionInProgress,
    toolBarItems,
    saveOp,
    saveOpResult,
    updatingOps,
    removingOps,
  };
};

export { useOrderSubscriptionsByDistance };
