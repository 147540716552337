import { Fragment, memo, useContext } from 'react';
import PropTypes from 'prop-types';

import { colors, ORDER_LIST_CATEGORIES } from '../../../config';
import { LocalizationContext, UserLocationContext } from '../../../contexts';

import { Block } from 'components/Block';
import { SelectDistance } from 'components/Controls/SelectDistance';
import { AppToolbar } from 'components/AppToolbar';
import { AlertMessage } from 'components/AlertMessage';

import { OrdersList } from './OrdersList';
import { FAKE_ORDERS } from './ordersHelpers';
import { useOrders } from './useOrders';

const Orders = memo((props) => {
  const { title, hasTitle, showDistance, scrollable, orderListCategory } =
    props;

  const { getText } = useContext(LocalizationContext);
  const { userLocations, userLocationsOpsManager } =
    useContext(UserLocationContext);

  const {
    orders,
    distance,
    listOp,
    removingOps,
    setStepOps,
    toolBarItems,
    handleClickAction,
    handleChangeDistance,
    orderList,
  } = useOrders(props);

  const isLoading = listOp.result.isInProgress();
  const orders2use = isLoading ? FAKE_ORDERS : orders;

  const userLocationsListOp = userLocationsOpsManager.getListOp();
  const hasLocations = userLocationsListOp.result.isLoading()
    ? undefined
    : userLocations.length > 0;

  if (
    orderListCategory === ORDER_LIST_CATEGORIES.IN_PROGRESS &&
    orders2use.length === 0
  ) {
    // Do not render anything if we need to show orders in progress and there are no such orders
    return null;
  }

  let toolbar = null;

  switch (orderListCategory) {
    default:
      break;

    case ORDER_LIST_CATEGORIES.MY_ORDERS:
      if (
        userLocationsListOp.called &&
        !userLocationsListOp.result.isInProgress()
      ) {
        if (hasLocations) {
          toolbar = (
            <AppToolbar
              items={toolBarItems}
              onClick={handleClickAction}
              backgroundColor={colors.grey01}
            />
          );
        } else {
          toolbar = (
            <AlertMessage
              variant={AlertMessage.VARIANT_NAMES.WARNING}
              message={getText('pages.orders.noUserLocations')}
            />
          );
        }
      }

      break;

    case ORDER_LIST_CATEGORIES.PUBLISHED:
      toolbar = (
        <SelectDistance onChange={handleChangeDistance} value={distance} />
      );
      break;
  }

  return (
    <Fragment>
      <Block title={hasTitle && title ? getText(title) : ''}>
        {toolbar}
        <OrdersList
          items={orders2use}
          isScrollable={scrollable}
          showDistance={showDistance}
          onClickAction={handleClickAction}
          orderListCategory={orderListCategory}
          list={orderList}
          listOp={listOp}
          removingOps={removingOps}
          setStepOps={setStepOps}
          hasLocations={hasLocations}
        />
      </Block>
    </Fragment>
  );
});

Orders.displayName = 'Orders';

Orders.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  orderItemActions: PropTypes.arrayOf(PropTypes.string),
  orderListPagination: PropTypes.shape({}),
  hasTitle: PropTypes.bool,
  showDistance: PropTypes.bool,
  scrollable: PropTypes.bool,
  orderListCategory: PropTypes.oneOf(Object.values(ORDER_LIST_CATEGORIES)),
};

Orders.defaultProps = {
  orderItemActions: [],
  hasTitle: false,
};

export { Orders };
