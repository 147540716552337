const parseDttmFields = (data, opt) => {
  const { dttmFieldsNames } = opt;

  if (data == null) {
    return;
  }

  if (Array.isArray(data)) {
    data.forEach((item) => {
      parseDttmFields(item, opt);
    });
  } else if (typeof data === 'object') {
    Object.keys(data).forEach((key) => {
      if (!dttmFieldsNames.includes(key)) {
        return;
      }

      let value = data[key];

      if (!value) {
        // if it is null, undefined, 0, empty string then set value as null
        data[key] = null;
        return;
      } else if (!isNaN(value)) {
        // the value is a number so this is most likely unix epoch time
        value = Number(value);
      }

      data[key] = new Date(value); // dayjs(value).format('YYYY-MM-DDTHH:mm:ss');
    });
  }
};

export const processDttmFields = (opResult, opt) => {
  let opt2use = opt || {};

  if (
    !Array.isArray(opt2use.dttmFieldsNames) ||
    opt2use.dttmFieldsNames.length === 0
  ) {
    opt2use['dttmFieldsNames'] = [
      'createdAt',
      'updatedAt',
      'removedAt',
      'placedAt',
      'dueDate',
      'currentStepStartAt',
    ];
  }

  parseDttmFields(opResult.data, opt2use);
};
