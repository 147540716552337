import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AuthContext, UserShoppingItemContext } from '../contexts';
import { useUserShoppingItem } from '../graphql/hooks';

const UserShoppingItemProvider = (props) => {
  const { children } = props;
  const hooks = useUserShoppingItem();
  const { userShoppingItemList, userShoppingItemsOpsManager } = hooks;
  const { appUser } = useContext(AuthContext);

  const listOp = userShoppingItemsOpsManager.getListOp();

  useEffect(() => {
    // Load user shopping items if they were not yet loaded

    if (appUser && !listOp.called) {
      userShoppingItemList({
        filter: {},
        params: {},
      });
    }
  }, [appUser, userShoppingItemList, listOp]);

  return (
    <UserShoppingItemContext.Provider value={hooks}>
      {children}
    </UserShoppingItemContext.Provider>
  );
};

UserShoppingItemProvider.displayName = 'UserShoppingItemProvider';

UserShoppingItemProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserShoppingItemProvider };
