import { FORM_INPUT_TYPES } from '@sdflc/ui';
import { EnableSwitch, SelectUser, SelectUserLocation } from '../..';

export const orderSubscriptionByDistanceForm = [
  {
    fields: [
      {
        field: 'userLocationId',
        width: ['100%'],
        component: SelectUserLocation,
        componentProps: {},
      },
      {
        field: 'distanceKm',
        type: FORM_INPUT_TYPES.NUMBER,
        required: true,
        width: ['100%'],
        componentProps: {
          min: 1,
          max: 10,
        },
      },
      {
        field: 'status',
        component: EnableSwitch,
        width: ['100%'],
      },
    ],
  },
];
