export const createDirectionsLink = (args) => {
  const { origin, travelMode, waypoints, destination } = args || {};

  if (!destination) {
    return '';
  }

  const paramsObj = {
    api: '1',
    travelMode: travelMode || 'auto',
    origin,
    waypoints,
    destination,
  };

  const urlParams = Object.keys(paramsObj).reduce((str, key) => {
    if (paramsObj[key] !== undefined) {
      return str + `&${key}=${encodeURIComponent(paramsObj[key])}`;
    }
    return str;
  }, '?');

  return `https://www.google.com/maps/dir/${urlParams}`;
};
