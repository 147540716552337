import { useState, useCallback, useMemo } from 'react';
import { OP_RESULT_CODES, OpResult } from '@sdflc/api-helpers';
import { logger, OPERATIONS, useAppMutation, useAppQuery } from '../../utils';
import { authQueries } from '../queries/auth';
import { userQueries } from '../queries/userQueries';
import { castArray } from 'lodash';

const useAuth = () => {
  const [signInResult, setSignInResult] = useState(new OpResult());
  const [refreshTokenResult, setRefreshTokenResult] = useState(new OpResult());
  const [signOutResult, setSignOutResult] = useState(new OpResult());
  const [signUpResult, setSignUpResult] = useState(new OpResult());
  const [appUserResult, setAppUserResult] = useState(new OpResult());
  const [appUser, setAppUser] = useState(null);
  const [updateUserResult, setUpdateUserResult] = useState(new OpResult());
  const [authOps, setAuthOps] = useState({});

  const handleDone = (operation) => (result) => {
    const contextId = '';

    switch (operation) {
      default:
        logger.warn(
          'The "useAuth" hook got unexpected value of operation: ',
          operation
        );
        break;

      case OPERATIONS.SIGN_IN:
        if (result.didSucceed()) {
          setAppUser(result.getDataFirst());
        }

        setSignInResult(result);
        break;

      case OPERATIONS.SIGN_OUT:
        if (result.didSucceed()) {
          setAppUser(null);
        }

        setSignOutResult(result);

        if (result.code === OP_RESULT_CODES.OK && window) {
          window.location.reload();
        }
        break;

      case OPERATIONS.REFRESH_TOKEN:
        setRefreshTokenResult(result);
        break;

      case OPERATIONS.SIGN_UP:
        if (result.didSucceed()) {
          setAppUser(result.getDataFirst());
        }

        setSignUpResult(result);
        break;

      case OPERATIONS.APP_ME:
        if (result.didSucceed()) {
          setAppUser(result.getDataFirst());
        }

        setAppUserResult(result);
        break;

      case OPERATIONS.UPDATE_USER:
        if (result.didSucceed()) {
          //const { avatarUrl } = result.getDataFirst({});
          //setAppUser(result.getDataFirst());
        }

        setUpdateUserResult(result);
        break;

      case OPERATIONS.RESET_PASSWORD_REQUEST:
        if (authOps[contextId]?.[OPERATIONS.RESET_PASSWORD_REQUEST]) {
          setAuthOps((state) => {
            state[contextId][OPERATIONS.RESET_PASSWORD_REQUEST] = {
              ...state[contextId][OPERATIONS.RESET_PASSWORD_REQUEST],
              result,
            };

            return {
              ...state,
            };
          });
        } else {
          logger.warn(
            'Received a result for the operation RESET_PASSWORD_REQUEST but no request for the operation found'
          );
        }

        break;

      case OPERATIONS.RESET_PASSWORD:
        if (authOps[contextId]?.[OPERATIONS.RESET_PASSWORD]) {
          setAuthOps((state) => {
            state[contextId][OPERATIONS.RESET_PASSWORD] = {
              ...state[contextId][OPERATIONS.RESET_PASSWORD],
              result,
            };

            return {
              ...state,
            };
          });
        } else {
          logger.warn(
            'Received a result for the operation RESET_PASSWORD but no request for the operation found'
          );
        }
        break;
    }
  };

  const [
    mutationSignIn,
    { called: signInCalled, loading: signInLoading, reset: signInReset },
  ] = useAppMutation({
    queries: authQueries,
    queryName: 'signIn',
    onDone: handleDone(OPERATIONS.SIGN_IN),
  });

  const [
    mutationSignOut,
    { called: signOutCalled, loading: signOutLoading, reset: signOutReset },
  ] = useAppMutation({
    queries: authQueries,
    queryName: 'signOut',
    onDone: handleDone(OPERATIONS.SIGN_OUT),
  });

  const [
    mutationRefreshToken,
    {
      called: refreshTokenCalled,
      loading: refreshTokenLoading,
      reset: refreshTokenReset,
    },
  ] = useAppMutation({
    queries: authQueries,
    queryName: 'refreshToken',
    onDone: handleDone(OPERATIONS.REFRESH_TOKEN),
  });

  const [
    mutationSignUp,
    { called: signUpCalled, loading: signUpLoading, reset: signUpReset },
  ] = useAppMutation({
    queries: authQueries,
    queryName: 'appSignUp',
    onDone: handleDone(OPERATIONS.SIGN_UP),
  });

  const [mutationResetPasswordRequest] = useAppMutation({
    queries: authQueries,
    queryName: 'resetPasswordRequest',
    onDone: handleDone(OPERATIONS.RESET_PASSWORD_REQUEST),
  });

  const [mutationResetPassword] = useAppMutation({
    queries: authQueries,
    queryName: 'resetPassword',
    onDone: handleDone(OPERATIONS.RESET_PASSWORD),
  });

  const [queryAppMe, { called: appMeCalled, loading: appMeLoading }] =
    useAppQuery({
      queries: authQueries,
      queryName: 'appMe',
      onDone: handleDone(OPERATIONS.APP_ME),
    });

  const [
    mutationUpdateUser,
    {
      called: updateUserCalled,
      loading: updateUserLoading,
      reset: updateUserReset,
    },
  ] = useAppMutation({
    queries: userQueries,
    queryName: 'userUpdate',
    onDone: handleDone(OPERATIONS.UPDATE_USER),
  });

  const signIn = useCallback(
    (variables) => {
      setSignInResult(new OpResult().clearErrors().startLoading().clone());
      mutationSignIn({ variables });
    },
    [mutationSignIn]
  );

  const signOut = useCallback(
    (variables) => {
      setSignOutResult(new OpResult().clearErrors().startLoading().clone());
      mutationSignOut({ variables });
    },
    [mutationSignOut]
  );

  const refreshToken = useCallback(
    (variables) => {
      setRefreshTokenResult(new OpResult().startLoading().clone());
      mutationRefreshToken({ variables });
    },
    [mutationRefreshToken]
  );

  const signUp = useCallback(
    (variables) => {
      setSignUpResult(new OpResult().clearErrors().startLoading().clone());
      mutationSignUp({ variables });
    },
    [mutationSignUp]
  );

  const resetPasswordRequest = useCallback(
    (variables, opt) => {
      setAuthOps((state) => {
        const contextId = opt?.contextId ?? '';

        if (!state[contextId]) {
          state[contextId] = {};
        }

        state[contextId][OPERATIONS.RESET_PASSWORD_REQUEST] = {
          called: true,
          variables,
          result: new OpResult().startSaving(),
        };

        return {
          ...state,
        };
      });

      mutationResetPasswordRequest({ variables });
    },
    [mutationResetPasswordRequest]
  );

  const resetPassword = useCallback(
    (variables, opt) => {
      setAuthOps((state) => {
        const contextId = opt?.contextId ?? '';

        if (!state[contextId]) {
          state[contextId] = {};
        }

        state[contextId][OPERATIONS.RESET_PASSWORD] = {
          called: true,
          variables,
          result: new OpResult().startSaving(),
        };

        return {
          ...state,
        };
      });

      mutationResetPassword({ variables });
    },
    [mutationResetPassword]
  );

  const appMe = useCallback(() => {
    setAppUserResult(new OpResult().startLoading());
    queryAppMe();
  }, [queryAppMe]);

  const updateUser = useCallback(
    (variables) => {
      setUpdateUserResult(new OpResult().startSaving());
      mutationUpdateUser({ variables });
    },
    [mutationUpdateUser]
  );

  const appUserLangGet = useCallback(() => {
    return appUser?.user?.uiLanguage ?? 'en';
  }, [appUser]);

  const authGetOp = useCallback(
    (args) => {
      const { contextId, operation } = args;

      const op = authOps[contextId]?.[operation];

      if (op) {
        return op;
      }

      return {
        called: false,
        result: new OpResult(),
        variables: {},
      };
    },
    [authOps]
  );

  const authResetOp = useCallback(
    (args) => {
      const { contextId, operation } = args;

      setAuthOps((state) => {
        const contextIds = contextId ? castArray(contextId) : [''];

        const ops = contextIds.reduce((acc, contextId) => {
          if (!acc[contextId]) {
            acc[contextId] = {};
          }

          acc[contextId][operation] = {
            called: false,
            result: new OpResult(),
            variables: {},
          };

          return acc;
        }, state);

        return {
          ...state,
          ...ops,
        };
      });
    },
    [setAuthOps]
  );

  const resetPasswordRequestOpId = useMemo(
    () => ({
      contextId: '',
      operation: OPERATIONS.RESET_PASSWORD_REQUEST,
    }),
    []
  );

  const resetPasswordOpId = useMemo(
    () => ({
      contextId: '',
      operation: OPERATIONS.RESET_PASSWORD,
    }),
    []
  );

  const resetPasswordRequestOp = authGetOp(resetPasswordRequestOpId);
  const resetPasswordOp = authGetOp(resetPasswordOpId);

  return {
    signIn,
    signInResult,
    signInCalled,
    signInLoading,
    signInReset,

    signOut,
    signOutResult,
    signOutCalled,
    signOutLoading,
    signOutReset,

    refreshToken,
    refreshTokenResult,
    refreshTokenCalled,
    refreshTokenLoading,
    refreshTokenReset,

    signUp,
    signUpResult,
    signUpCalled,
    signUpLoading,
    signUpReset,

    appMe,
    appMeCalled,
    appMeLoading,

    appUser,
    appUserResult,

    appUserLangGet,

    updateUser,
    updateUserResult,
    updateUserCalled,
    updateUserLoading,
    updateUserReset,

    resetPasswordRequest,
    resetPassword,

    authOps,
    authGetOp,
    authResetOp,

    resetPasswordRequestOpId,
    resetPasswordOpId,

    resetPasswordRequestOp,
    resetPasswordOp,
  };
};

export { useAuth };
