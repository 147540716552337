import { memo, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import check from 'check-types';
import {
  ItemsGroup,
  ValueDate,
  Grid,
  GridItem,
  Input,
  HR,
  Button,
  ConfirmationButton,
  InputContainer,
  Badge,
  Tag,
} from '@sdflc/ui';

import { ACTIONS_BUTTONS } from '../../../config';

import { SettingsRow } from '../../SettingsRow';

const BUTTONS = ACTIONS_BUTTONS({ withNames: true });

const PaymentMethodItem = memo((props) => {
  const { creditCard, onClickAction } = props;

  const handleClickAction = (action) => {
    if (check.function(onClickAction)) {
      onClickAction(action, creditCard);
    } else if (!onClickAction) {
      console.warn(
        'The "PaymentMethodItem" component expected the "onClickAction" to be a function but got:',
        onClickAction
      );
    }
  };

  const {
    cardHolderName,
    last4Digits,
    expirationDate,
    creditCardType /*, status */,
    isDefault,
  } = creditCard || {};

  const DefaultCreditCard = useMemo(
    () =>
      isDefault ? (
        <Tag
          variant={Badge.VARIANT_NAMES.WARNING}
          dimension={Badge.DIMENSION_NAMES.SMALL}
        >
          Default
        </Tag>
      ) : null,
    [isDefault]
  );

  if (!creditCard) {
    return null;
  }

  return (
    <Fragment>
      <SettingsRow name={creditCardType}>
        <Grid>
          <GridItem width={['100%', '100%', '100%', '60%']}>
            <Input
              leftIcon={''}
              value={last4Digits}
              readOnly
              RightSide={DefaultCreditCard}
            />
          </GridItem>
          <GridItem width={['100%', '100%', '100%', '40%']}>
            {/* <Input value={expirationDate} readOnly /> */}
            <InputContainer>
              <ValueDate
                value={new Date(Number(expirationDate))}
                dateFormat='MMMM YYYY'
                timeFormat=''
              />
            </InputContainer>
          </GridItem>
          <GridItem width={['100%', '100%', '100%', '100%']}>
            <Input value={cardHolderName} readOnly />
          </GridItem>
        </Grid>
        {!!onClickAction && (
          <ItemsGroup>
            {/* <Button
              {...BUTTONS.EDIT_ALT}
              dimension={Button.DIMENSION_NAMES.SMALL}
              onClick={() => handleClickAction(BUTTONS.EDIT_ALT)}
            /> */}
            {!isDefault && (
              <Button
                {...BUTTONS.MAKE_DEFAULT}
                dimension={Button.DIMENSION_NAMES.SMALL}
                onClick={() => handleClickAction(BUTTONS.MAKE_DEFAULT)}
              />
            )}
            <ConfirmationButton
              {...BUTTONS.REMOVE}
              dimension={Button.DIMENSION_NAMES.SMALL}
              onConfirm={() => handleClickAction(BUTTONS.REMOVE)}
            />
            {/* <Actions
              actions={[BUTTONS.EDIT_ALT, BUTTONS.REMOVE]}
              onClick={handleClickAction}
            /> */}
          </ItemsGroup>
        )}
      </SettingsRow>
      <HR />
    </Fragment>
  );
});

PaymentMethodItem.displayName = 'PaymentMethodItem';

PaymentMethodItem.propTypes = {
  creditCard: PropTypes.shape({
    cardHolderName: PropTypes.string,
    last4Digits: PropTypes.string,
    expirationDate: PropTypes.string,
    creditCardType: PropTypes.string,
    status: PropTypes.number,
  }),
  onClickAction: PropTypes.func,
};

PaymentMethodItem.defaultProps = {
  creditCard: null,
};

export { PaymentMethodItem };
