import { memo, useContext } from 'react';
//import PropTypes from 'prop-types';
import { Select } from '@sdflc/ui';
import { LocalizationContext } from 'contexts';

const SelectDistance = memo((props) => {
  const { getText } = useContext(LocalizationContext);

  const options = [
    {
      value: 1,
      text: `within 1 km`,
    },
    {
      value: 2,
      text: `within 2 km`,
    },
    {
      value: 5,
      text: `within 5 km`,
    },
    {
      value: 10,
      text: `within 10 km`,
    },
    {
      value: 25,
      text: `within 25 km`,
    },
    {
      value: 50,
      text: `within 50 km`,
    },
    {
      value: 100,
      text: `within 100 km`,
    },
  ];

  return (
    <Select
      {...props}
      options={options}
      placeholder={getText('selectors.selectDistance.placeholder')}
    />
  );
});

SelectDistance.displayName = 'SelectDistance';

SelectDistance.propTypes = {
  ...Select.propTypes,
};

SelectDistance.defaultProps = {};

export { SelectDistance };
