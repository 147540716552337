import { appPages } from '../config';
import { logger } from './logger';

const goToPage = (args) => {
  const { history, pageKey } = args || {};

  if (!pageKey) {
    logger.warn(
      'Cannot redirect to another page as either pageKey is missing:',
      { history, pageKey }
    );
    return;
  }

  const page = appPages[pageKey];

  if (!page) {
    logger.warn(`Page "${pageKey}" does not exist in siteMap`);
    return;
  }

  if (history) {
    history.push(page.url);
  } else {
    const redirectTo = `${window.location.origin}${page.url}`;
    if (redirectTo !== window.location.href) {
      window.location.href = redirectTo;
    }
  }
};

export { goToPage };
