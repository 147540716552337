import { colors } from '@sdflc/ui';
import { flattenHierarchy, mapArrayBy } from '@sdflc/utils';

import { APP_PAGE_KEYS } from './appPageKeys';
import { appSettings } from './appSettings';

const siteMap = [
  {
    pageKey: APP_PAGE_KEYS.SIGN_IN,
    name: 'Sign In',
    subtitle: '',
    url: '/account/signin',
    component: 'PageSignIn',
    layout: 'SplitScreenLayout',
    visible: false,
    options: {
      bgImage: appSettings.centeredPagesBgImage,
      public: true,
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.SIGN_UP,
    name: 'Sign Up',
    subtitle: '',
    url: '/account/signup',
    component: 'PageSignUp',
    layout: 'SplitScreenLayout',
    visible: false,
    options: {
      bgImage: appSettings.centeredPagesBgImage,
      public: true,
    },
    roles: [],
  },
  {
    pageKey: 'forgot-password',
    name: 'Forgot Password',
    subtitle: '',
    url: '/account/forgot-password',
    component: 'PageForgotPassword',
    layout: 'CenteredLayout',
    visible: false,
    options: {
      bgColor: colors.white,
      public: true,
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.RESET_PASSWORD,
    name: 'Reset Password',
    subtitle: '',
    url: '/account/reset-password',
    component: 'PageResetPassword',
    layout: 'CenteredLayout',
    visible: false,
    options: {
      bgColor: colors.white,
      public: true,
    },
    roles: [],
  },
  {
    pageKey: 'verify-email',
    name: 'Verify',
    subtitle: '',
    url: '/account/verify-email',
    component: 'PageVerifyEmail',
    layout: 'CenteredLayout',
    visible: false,
    options: {
      bgColor: colors.white,
      public: true,
    },
    roles: [],
  },
  {
    pageKey: 'home',
    name: 'Home Page',
    subtitle: '',
    url: '/',
    icon: 'Home',
    component: 'PageHome',
    //component: 'Redirect',
    layout: 'MainLayout',
    visible: true,
    options: {
      //to: '/orders',
      helpContent: 'home-help',
    },
    roles: [],
  },
  {
    pageKey: 'public-user-info',
    name: 'Public User Info',
    subtitle: '',
    url: '/users/:username',
    component: 'Home',
    layout: 'MainLayout',
    visible: false,
    options: {
      helpContent: 'public-user-info-help',
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.ORDER_DETAILS,
    name: 'Order Details',
    subtitle: '',
    url: '/orders/:orderId/view',
    component: 'PageViewOrder',
    layout: 'MainLayout',
    visible: false,
    options: {
      goBack: true,
      helpContent: 'order-details-help',
    },
    roles: [],
  },
  {
    pageKey: 'order-item-details',
    name: 'Order Item Details',
    subtitle: '',
    url: '/orders/view/:orderId/items/:itemId',
    component: 'Home',
    layout: 'MainLayout',
    visible: false,
    options: {
      goBack: true,
      helpContent: 'order-item-details-help',
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.ORDER_SHOP_ITEMS,
    name: 'Shop Order Items',
    subtitle: '',
    url: '/orders/:orderId/shop',
    component: 'PageShopOrderItems',
    layout: 'MainLayout',
    visible: false,
    options: {
      goBack: true,
      helpContent: 'order-shop-items-help',
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.ORDER_EDIT,
    name: 'Edit Order',
    subtitle: '',
    url: '/orders/:orderId/edit',
    component: 'PageEditOrder',
    layout: 'MainLayout',
    visible: false,
    options: {
      goBack: true,
      helpContent: 'order-edit-help',
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.ORDER_DELIVERED,
    name: 'Order Delivered',
    subtitle: '',
    url: '/orders/:orderId/delivered',
    component: 'PageOrderDelivered',
    layout: 'MainLayout',
    visible: false,
    options: {
      goBack: true,
      helpContent: 'order-delivered-help',
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.ORDER_CONFIRMED,
    name: 'Order Confirmed',
    subtitle: '',
    url: '/orders/:orderId/confirmed',
    component: 'PageOrderConfirmed',
    layout: 'MainLayout',
    visible: false,
    options: {
      goBack: true,
      helpContent: 'order-confirmed-help',
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.ORDER_PAID,
    name: 'Order Confirmed',
    subtitle: '',
    url: '/orders/:orderId/paid',
    component: 'PageOrderPaid',
    layout: 'MainLayout',
    visible: false,
    options: {
      goBack: true,
      helpContent: '',
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.ORDER_COMPLETE,
    name: 'Order Complete',
    subtitle: '',
    url: '/orders/:orderId/complete',
    component: 'PageOrderComplete',
    layout: 'MainLayout',
    visible: false,
    options: {
      goBack: true,
      helpContent: '',
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.ORDERS_TO_PICK,
    icon: 'TravelExplore',
    name: 'pages.publishedOrders.title',
    subtitle: '',
    url: '/orders/published',
    component: 'PageOrdersToPick',
    layout: 'MainLayout',
    visible: true,
    options: {
      helpContent: 'orders-published-help',
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.MY_PAST_ORDERS,
    icon: 'ListAlt',
    name: 'pages.myPastOrders.title',
    subtitle: '',
    url: '/orders/history',
    component: 'PageMyPastOrders',
    layout: 'MainLayout',
    visible: true,
    options: {
      helpContent: 'orders-history-help',
    },
    roles: [],
  },
  {
    pageKey: 'order-items',
    name: 'Order Items Pickup',
    subtitle: '',
    url: '/orders/items/:id',
    component: 'Home',
    layout: 'MainLayout',
    visible: false,
    options: {},
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.ORDERS_I_DELIVERED,
    icon: 'History',
    name: 'pages.ordersIDelivered.title',
    subtitle: '',
    url: '/orders/delivered',
    component: 'PageOrdersIDelivered',
    layout: 'MainLayout',
    visible: true,
    options: {
      helpContent: 'orders-i-delivered-help',
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.USER_SHOPPING_ITEMS,
    icon: 'Sell',
    name: 'pages.userShoppingItems.title',
    subtitle: '',
    url: '/account/shopping-items',
    component: 'PageShoppingItems',
    layout: 'MainLayout',
    visible: true,
    options: {
      helpContent: 'user-sopping-items-help',
    },
    roles: [],
  },
  {
    pageKey: APP_PAGE_KEYS.TRANSACTIONS,
    name: 'pages.transactions.title',
    icon: 'AccountBalanceWallet',
    subtitle: '',
    url: '/account/transactions',
    component: 'PageTransactions',
    layout: 'MainLayout',
    visible: true,
    options: {
      helpContent: 'transactions-help',
    },
    roles: [],
  },
  {
    pageKey: 'settings',
    icon: 'Settings',
    name: 'pages.settings.title',
    subtitle: '',
    url: '/account/settings',
    component: 'PageSettings',
    layout: 'MainLayout',
    visible: true,
    options: {},
    roles: [],
    items: [
      {
        pageKey: 'settings-general',
        name: 'pages.settings.tabs.general.title',
        subtitle: '',
        url: '/account/settings/general',
        component: 'PageSettings',
        layout: 'MainLayout',
        visible: true,
        options: {
          //to: '/account/locations',
        },
        roles: [],
      },
      {
        pageKey: 'settings-addresses',
        name: 'pages.settings.tabs.addresses.title',
        subtitle: '',
        url: '/account/settings/addresses',
        component: 'PageSettings',
        layout: 'MainLayout',
        visible: true,
        options: {
          helpContent: 'settings-help',
        },
        roles: [],
      },
      {
        pageKey: 'settings-contacts',
        name: 'pages.settings.tabs.contacts.title',
        subtitle: '',
        url: '/account/settings/contacts',
        component: 'PageSettings',
        layout: 'MainLayout',
        visible: true,
        options: {},
        roles: [],
      },
      {
        pageKey: 'settings-bank-accounts',
        name: 'pages.settings.tabs.bankAccounts.title',
        subtitle: '',
        url: '/account/settings/bank-accounts',
        component: 'PageSettings',
        layout: 'MainLayout',
        visible: true,
        options: {},
        roles: [],
      },
      {
        pageKey: 'settings-payment-methods',
        name: 'pages.settings.tabs.paymentMethods.title',
        subtitle: '',
        url: '/account/settings/payment-methods',
        component: 'PageSettings',
        layout: 'MainLayout',
        visible: true,
        options: {},
        roles: [],
      },
      {
        pageKey: 'user-credit-card-created',
        name: 'pages.paymentMethodCreated.title',
        subtitle: '',
        url: '/account/settings/payment-methods/created',
        component: 'PageCreditCardCreated',
        layout: 'MainLayout',
        visible: false,
        options: {},
        roles: [],
      },
      {
        pageKey: APP_PAGE_KEYS.SETTINGS_USER_SUBSCRIBES,
        name: 'pages.userSubscribes.title',
        subtitle: '',
        url: '/account/settings/user-subsribes',
        component: 'PageSettings',
        layout: 'MainLayout',
        visible: true,
        options: {},
        roles: [],
      },
      {
        pageKey: APP_PAGE_KEYS.SETTINGS_ORDER_SIBSCRIPTION_BY_DISTANCE,
        name: 'pages.orderSubscriptionByDistance.title',
        subtitle: '',
        url: '/account/settings/order-subsriptions-by-distance',
        component: 'PageSettings',
        layout: 'MainLayout',
        visible: true,
        options: {},
        roles: [],
      },
      {
        pageKey: APP_PAGE_KEYS.SETTINGS_ALLOWED_USERS,
        name: 'pages.userAllowListItem.title',
        subtitle: '',
        url: '/account/settings/allowed-users',
        component: 'PageSettings',
        layout: 'MainLayout',
        visible: true,
        options: {},
        roles: [],
      },
      {
        pageKey: APP_PAGE_KEYS.SETTINGS_BLOCKED_USERS,
        name: 'pages.userBlockListItem.title',
        subtitle: '',
        url: '/account/settings/blocked-users',
        component: 'PageSettings',
        layout: 'MainLayout',
        visible: true,
        options: {},
        roles: [],
      },
    ],
  },
  {
    pageKey: 'pageNotFound',
    name: 'Page Not Found',
    url: '*',
    component: 'PageNotFound', // note that we use string we would hook up with the componentsMap.
    layout: 'EmptyLayout', // note that we use string we would hook up with the componentsMap.
    visible: false,
    options: {
      mainImage: appSettings.pageNotFoundBgImage,
      public: true,
    },
  },
];

const flatMap = flattenHierarchy(siteMap, 'items');
const appPages = mapArrayBy(flatMap, 'pageKey');
const appUrls = mapArrayBy(flatMap, 'url');

export { siteMap, appPages, appUrls };
