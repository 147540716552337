import { useState, useCallback } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';
import {
  handleApiResult,
  OperationManager,
  OPERATIONS,
  processApiUserLocation,
  useAppMutation,
  useAppQuery,
} from '../../utils';
import { arrayOfObjectsToArray } from '../../utils/transformers';
import { userBlockListItemQueries } from '../queries/userBlockListItemQueries';

const useApiUserBlockListItem = () => {
  const [userBlockListItem, setUserBlockListItem] = useState(null);
  const [userBlockListItems, setUserBlockListItems] = useState([]);
  const [userBlockListItemsOps, setUserBlockListItemsOps] = useState({});
  const [userBlockListItemsOpsManager] = useState(new OperationManager());

  userBlockListItemsOpsManager.setStateControls(
    userBlockListItemsOps,
    setUserBlockListItemsOps
  );

  const handleApiResultOpts = {
    hookName: 'useUserBlockListItem',
    opsManager: userBlockListItemsOpsManager,
    setItem: setUserBlockListItem,
    setItems: setUserBlockListItems,
    transformItem: (item) => {
      return {
        ...item,
        userLocation: processApiUserLocation(item?.userLocation),
      };
    },
  };

  /**
   * GRAPHQL CALLERS
   */

  const [mutationUserBlockListItemCreate] = useAppMutation({
    queries: userBlockListItemQueries,
    queryName: 'userBlockListItemCreate',
    onDone: handleApiResult(OPERATIONS.CREATE, handleApiResultOpts),
  });

  const [mutationUserBlockListItemUpdate] = useAppMutation({
    queries: userBlockListItemQueries,
    queryName: 'userBlockListItemUpdate',
    onDone: handleApiResult(OPERATIONS.UPDATE, handleApiResultOpts),
  });

  const [mutationUserBlockListItemRemoveMany] = useAppMutation({
    queries: userBlockListItemQueries,
    queryName: 'userBlockListItemRemoveMany',
    onDone: handleApiResult(OPERATIONS.REMOVE_MANY, handleApiResultOpts),
  });

  const [queryUserBlockListItemList] = useAppQuery({
    queries: userBlockListItemQueries,
    queryName: 'userBlockListItemList',
    onDone: handleApiResult(OPERATIONS.LIST, handleApiResultOpts),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const userBlockListItemCreate = useCallback(
    (variables) => {
      userBlockListItemsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.CREATE,
        OP_RESULT_CODES.SAVING
      );

      mutationUserBlockListItemCreate({ variables });
    },
    [mutationUserBlockListItemCreate, userBlockListItemsOpsManager]
  );

  const userBlockListItemUpdate = useCallback(
    (variables) => {
      userBlockListItemsOpsManager.setStartOperation(
        variables?.where?.id,
        OPERATIONS.UPDATE,
        OP_RESULT_CODES.SAVING
      );

      mutationUserBlockListItemUpdate({ variables });
    },
    [mutationUserBlockListItemUpdate, userBlockListItemsOpsManager]
  );

  const userBlockListItemRemoveMany = useCallback(
    (variables) => {
      userBlockListItemsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.REMOVE_MANY,
        OP_RESULT_CODES.DELETING
      );

      mutationUserBlockListItemRemoveMany({ variables });
    },
    [mutationUserBlockListItemRemoveMany, userBlockListItemsOpsManager]
  );

  const userBlockListItemList = useCallback(
    (variables) => {
      userBlockListItemsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING
      );

      queryUserBlockListItemList({ variables });
    },
    [queryUserBlockListItemList, userBlockListItemsOpsManager]
  );

  return {
    userBlockListItem,
    userBlockListItems,

    userBlockListItemCreate,
    userBlockListItemUpdate,
    userBlockListItemRemoveMany,
    userBlockListItemList,

    userBlockListItemsOpsManager,
  };
};

export { useApiUserBlockListItem };
