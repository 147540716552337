import { camelCase } from 'lodash';
import { camelKeys } from '@sdflc/utils';

const tmpEnvVars = camelKeys(process.env);
const envVars = {};

Object.keys(tmpEnvVars).forEach((key) => {
  envVars[camelCase(key.replace(/^reactApp/gi, ''))] = tmpEnvVars[key];
});

const appSettings = {
  appName: 'ShopaDropa',
  contactUsFormId: '7463e00c-b03f-4562-8c96-4e7ac5440a04',
  centeredPagesBgImage: 'https://assets.shopadropa.com/home-bg-006.jpg',
  pageNotFoundBgImage: 'https://assets.shopadropa.com/page-not-found.png',
  newOrderHoursOffset: 6,
  defaultDistanceKm: 5,
  defaultFormWidth: '600px',
  //centeredPagesBgImage: 'https://cdn.pixabay.com/photo/2018/05/14/16/25/cyber-security-3400657_1280.jpg',
  //centeredPagesBgImage: 'https://cdn.pixabay.com/photo/2018/01/26/18/21/matrix-3109378_1280.jpg',
  ...envVars,
  logoOnLightBg:
    'https://assets.shopadropa.com/logo/logo_rectangle_light_bg_blue.svg',
  anyStoreUrl:
    'https://assets.shopadropa.com/images/shopping-items/shopping_item.svg',
  defaultUserShoppingItemPictureUrl:
    'https://assets.shopadropa.com/images/shopping-items/default-shopping-item.svg',
  defaultUserAvatarUrl:
    'https://assets.shopadropa.com/images/default-user-avatar.png',
  paymentMethodsImagesRoot:
    'https://assets.shopadropa.com/images/payment-methods',
  orderItemImageTranslationRulesGroupId: 'a69ade1f-6905-4aa5-bfff-f8809fe7bad5',
};

export { appSettings };
