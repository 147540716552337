import { memo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, Panel } from '@sdflc/ui';
import { usePaymentMethod } from 'graphql/hooks';
import { PaymentMethodItem } from './PaymentMethodItem';
import { LocalizationContext } from '../../../contexts';
import { Loading } from '../../Loading';

const PaymentMethodsList = memo((props) => {
  const { onClickAction } = props;
  const { getText } = useContext(LocalizationContext);
  const { paymentMethodList, paymentMethodListCalled, paymentMethods } =
    usePaymentMethod();

  useEffect(() => {
    if (!paymentMethodListCalled) {
      paymentMethodList({
        filter: {},
        params: {},
      });
    }
  }, [paymentMethodList, paymentMethodListCalled]);

  if (paymentMethods.isLoading()) {
    return <Loading />;
  }

  const items = paymentMethods.getData() || [];

  return (
    <Panel mt={2} p={1} overflow='initial'>
      {items.map((item) => {
        return (
          <PaymentMethodItem
            key={item.id}
            creditCard={item}
            onClickAction={onClickAction}
          />
        );
      })}
      {items.length === 0 && (
        <Alert variant='default'>
          {getText('pages.paymentMethods.noItems')}
        </Alert>
      )}
    </Panel>
  );
});

PaymentMethodsList.displayName = 'PaymentMethodsList';

PaymentMethodsList.propTypes = {
  onClickAction: PropTypes.func,
};

PaymentMethodsList.defaultProps = {
  onClickAction: null,
};

export { PaymentMethodsList };
