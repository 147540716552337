export const setUsername = (args) => {
  const { username } = args;

  localStorage.setItem(
    'user',
    JSON.stringify({ username, lastSignIn: new Date() })
  );
};

export const clearUsername = () => {
  localStorage.removeItem('user');
};

export const getUsername = () => {
  const userStr = localStorage.getItem('user');

  if (!userStr) {
    return null;
  }

  try {
    const user = JSON.parse(userStr);

    return user;
  } catch {
    return null;
  }
};
