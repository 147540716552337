import {
  memo,
  useEffect,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';

import { useHistory } from 'react-router-dom';
import { omit } from 'lodash';

import {
  ErrorText,
  FormState,
  Form,
  Grid,
  GridItem,
  Button,
  Flex,
  FormInput,
  FormDescription,
  Caption,
  Actions,
  CheckBox,
} from '@sdflc/ui';

import {
  appPages,
  APP_PAGE_KEYS,
  ACTIONS_IDS,
  FULL_WIDTH_BUTTONS_WITH_NAMES,
} from '../../config';
import { logger, setUsername, clearUsername, getUsername } from '../../utils';
import { AuthContext, LocalizationContext } from '../../contexts';

const PageSignIn = memo(() => {
  const history = useHistory();
  const { signIn, signInResult, signInCalled, appMe, appUserResult } =
    useContext(AuthContext);
  const { getText } = useContext(LocalizationContext);
  const [state, setState] = useState({
    username: getUsername()?.username ?? '',
    password: '',
    rememberMe: getUsername()?.username ? true : false,
  });

  const handleClickButton = useCallback(
    (action, submit) => {
      switch (action.id) {
        default:
          logger.warn(
            'The "handleClickButton" got an unexpected action:',
            action
          );
          break;

        case ACTIONS_IDS.SIGN_IN:
          submit();
          break;

        case ACTIONS_IDS.SIGN_UP:
          history.push(appPages[APP_PAGE_KEYS.SIGN_UP].url);
          break;
      }
    },
    [history]
  );

  const handleSignIn = useCallback(
    (formData) => {
      logger.debug('User signs in');
      setState(formData);
      signIn({ params: omit(formData, ['rememberMe']) });
    },
    [signIn]
  );

  const handleFormChange = useCallback((formData) => {
    logger.debug('Form changed', formData);
    if (formData.rememberMe === false) {
      clearUsername();
    }
  }, []);

  const handleClickForgotPassword = useCallback(() => {
    logger.debug('User forgot password, redirect to the forgot password page');
    history.push(appPages[APP_PAGE_KEYS.FORGOT_PASSWORD].url);
  }, [history]);

  useEffect(() => {
    if (signInResult.getDataFirst({}).id) {
      logger.debug(
        `User has been signed in sucessfully, request app user information`
      );

      appMe();
    }
  }, [history, signInResult, signInCalled, appMe]);

  useEffect(() => {
    if (appUserResult.getDataFirst({}).id) {
      logger.debug(
        `Got user details, redirect to the home page: ${appPages['home'].url}`
      );

      if (state.rememberMe) {
        setUsername({ username: state.username });
      }

      setTimeout(() => {
        history.push(appPages['home'].url);
      }, 100);
    }
  }, [history, appUserResult, state]);

  const { formCfg, actions } = useMemo(() => {
    const labels = getText('pages.signIn.form.labels');
    const hints = getText('pages.signIn.form.hints');
    const placeholders = getText('pages.signIn.form.placeholders');
    const actions = [
      FULL_WIDTH_BUTTONS_WITH_NAMES.SIGN_IN,
      FULL_WIDTH_BUTTONS_WITH_NAMES.SIGN_UP_ALT,
    ];

    return {
      formCfg: {
        labels,
        hints,
        placeholders,
      },
      actions,
    };
  }, [getText]);

  return (
    <FormState data={state} onSubmit={handleSignIn} onChange={handleFormChange}>
      {({ data, handleChange, submit }) => {
        return (
          <Form width={'600px'} maxWidth='90%'>
            <Grid distance={0}>
              <GridItem width={['100%']}>
                <Caption level={3}>
                  {getText('pages.signIn.form.title')}
                </Caption>
              </GridItem>
              <GridItem width={['100%']}>
                <FormDescription>
                  {getText('pages.signIn.form.description')}
                </FormDescription>
              </GridItem>
              <GridItem width={['100%']}>
                <FormInput
                  required
                  labelData={formCfg.labels}
                  placeholderData={formCfg.placeholders}
                  hintData={formCfg.hints}
                  data={data}
                  fieldName='username'
                  onChange={handleChange}
                  opResult={signInResult}
                  leftIcon='Person'
                />
              </GridItem>
              <GridItem width={['100%']}>
                <FormInput
                  required
                  labelData={formCfg.labels}
                  placeholderData={formCfg.placeholders}
                  hintData={formCfg.hints}
                  data={data}
                  fieldName='password'
                  onChange={handleChange}
                  opResult={signInResult}
                  type='password'
                  leftIcon='Security'
                />
              </GridItem>
              <GridItem width={['100%']}>
                <CheckBox
                  label={formCfg.labels.rememberMe}
                  id={'rememberMe'}
                  name={'rememberMe'}
                  value={data.rememberMe}
                  onChange={handleChange}
                  hasInputContainer={false}
                />
              </GridItem>
              <GridItem width={['100%']}>
                <Flex alignItems='flex-end' flexDirection='column' width='100%'>
                  <Button
                    variant='tertiary'
                    name={getText('pages.signIn.form.forgotPassword')}
                    dimension='small'
                    onClick={handleClickForgotPassword}
                  />
                </Flex>
              </GridItem>

              {!signInResult.isLoading() && signInResult.hasErrors() && (
                <ErrorText>{signInResult.getFieldErrors('')}</ErrorText>
              )}
              <GridItem width={['100%']}>
                <Actions
                  actions={actions}
                  actionInProgress={
                    signInResult.isLoading()
                      ? FULL_WIDTH_BUTTONS_WITH_NAMES.SIGN_IN.id
                      : ''
                  }
                  onClick={(button) => handleClickButton(button, submit)}
                />
              </GridItem>
            </Grid>
          </Form>
        );
      }}
    </FormState>
  );
});

PageSignIn.displayName = 'PageSignIn';

export { PageSignIn };
