import { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@sdflc/ui';
import { locationTypeToIcon } from 'utils/iconHelpers';

const UserLocationType = memo((props) => {
  const { locationTypeId } = props;

  return <Icon name={locationTypeToIcon(locationTypeId)} />;
});

UserLocationType.displayName = 'UserLocationType';

UserLocationType.propTypes = {
  locationTypeId: PropTypes.string,
};

UserLocationType.defaultProps = {
  locationTypeId: 'home',
};

export { UserLocationType };
