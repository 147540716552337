import { gql } from '@apollo/client';

const QUERY_LIST = gql`
  query UserAllowListItemList(
    $filter: UserAllowListItemFilter
    $params: PaginationAndSorting
  ) {
    userAllowListItemList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        allowedUserId
        status
        allowedUser {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
      }
    }
  }
`;

const MUTATION_CREATE = gql`
  mutation UserAllowListItemCreate($params: UserAllowListItemInput) {
    userAllowListItemCreate(params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        allowedUserId
        status
        allowedUser {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
      }
    }
  }
`;

const MUTATION_UPDATE = gql`
  mutation UserAllowListItemUpdate(
    $where: UserAllowListItemWhereInput
    $params: UserAllowListItemInput
  ) {
    userAllowListItemUpdate(where: $where, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        userId
        allowedUserId
        status
        allowedUser {
          userRating
          l10n {
            lang
            displayName
            preferredName
          }
          user {
            username
            avatarUrl
          }
        }
      }
    }
  }
`;

const MUTATION_REMOVE_MANY = gql`
  mutation UserAllowListItemRemoveMany($where: [UserAllowListItemWhereInput]) {
    userAllowListItemRemoveMany(where: $where) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
      }
    }
  }
`;

const userAllowListItemQueries = {
  userAllowListItemList: QUERY_LIST,
  userAllowListItemCreate: MUTATION_CREATE,
  userAllowListItemUpdate: MUTATION_UPDATE,
  userAllowListItemRemoveMany: MUTATION_REMOVE_MANY,
};

export { userAllowListItemQueries };
