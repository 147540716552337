import { colors } from '@sdflc/ui';
import styled from 'styled-components';

import {
  compose,
  position,
  layout,
  space,
  size,
  color,
  typography,
  shadow,
  border,
  background,
  flex,
} from 'styled-system';

const func = compose(
  layout,
  position,
  space,
  size,
  color,
  typography,
  border,
  background,
  shadow,
  flex
);

export const Container = styled.button`
  border: none;
  outline: none;
  background-color: ${colors.white};
  border-radius: ${({ theme }) => `${theme?.radii?.[6] || 16}px`};
  padding: ${({ theme }) => `${theme?.space?.[3] || 16}px`};
  flex: 1 1;
  cursor: pointer;

  &:focus {
    outline: solid 2px ${colors.lightBlue};
  }

  &:disabled {
    cursor: initial;
    opacity: 0.6;
  }

  ${func}
`;
