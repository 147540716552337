import { SdFlcReactRouterPages } from '@sdflc/react-router-pages';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AppTheme } from '@sdflc/ui';
import { siteMap, componentsMap, appTheme } from './config';
import { MainLayout } from './layouts';
import { AuthProvider, GlobalProvider } from './providers';

const App = () => {
  return (
    <AuthProvider>
      <GlobalProvider>
        <AppTheme theme={appTheme}>
          <SdFlcReactRouterPages
            siteMap={siteMap}
            componentsMap={componentsMap}
            layout={MainLayout}
            rolesDontMatchComponent={'PageNoAccess'}
            rolesDontMatchLayout={MainLayout}
            failoverComponent={() => {
              return (
                <div>
                  This page cannot be displayed as page component was not found.
                </div>
              );
            }}
            roles={[]} // array of strings with curreny user roles, should be requested from BE or come from auth0
            routerComponents={{ BrowserRouter, Route, Switch }}
          />
        </AppTheme>
      </GlobalProvider>
    </AuthProvider>
  );
};

export default App;
