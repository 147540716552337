import { useState, useCallback } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';

import {
  logger,
  OperationManager,
  OPERATIONS,
  useAppMutation,
  useAppQuery,
  arrayOfObjectsToArray,
  arrayOfObjectsToSet,
} from '../../utils';

import { bankAccountQueries } from '../queries/bankAccountQueries';

const useBankAccount = () => {
  const [bankAccount, setBankDetail] = useState(null);
  const [bankAccounts, setBankDetails] = useState([]);
  const [bankAccountsMany, setBankDetailsMany] = useState([]);
  const [bankAccountsOps, setBankDetailsOps] = useState({});
  const [bankAccountsOpsManager] = useState(new OperationManager());

  bankAccountsOpsManager.setStateControls(bankAccountsOps, setBankDetailsOps);

  const handleDone = (operation) => (result, clientOptions) => {
    switch (operation) {
      default:
        logger.warn(
          'The "useBankAccount" hook got unexpected value of operation: ',
          operation
        );
        break;

      case OPERATIONS.LIST:
        if (result.didSucceed()) {
          setBankDetails(result.getData());
        }

        bankAccountsOpsManager.setResult(
          OperationManager.defaultContext,
          OPERATIONS.LIST,
          result
        );

        break;

      case OPERATIONS.GET_MANY:
        {
          const contacts = result.getData();

          if (result.didSucceed()) {
            setBankDetailsMany(contacts);
          }

          bankAccountsOpsManager.setResult(
            arrayOfObjectsToArray({ data: contacts, fieldName: 'id' }),
            OPERATIONS.GET_MANY,
            result
          );
        }
        break;

      case OPERATIONS.CREATE:
        {
          const bankAccount = result.getDataFirst();

          if (bankAccount) {
            if (result.didSucceed()) {
              setBankDetail(bankAccount);
              setBankDetails((state) => {
                return [
                  bankAccount,
                  ...state.map((item) => {
                    if (
                      item.isDefault === true &&
                      bankAccount.isDefault === true
                    ) {
                      return {
                        ...item,
                        isDefault: false,
                      };
                    }

                    return item;
                  }),
                ];
              });
            }
          }

          bankAccountsOpsManager.setResult(
            OperationManager.defaultContext,
            OPERATIONS.CREATE,
            result
          );
        }
        break;

      case OPERATIONS.UPDATE:
        {
          const bankAccount = result.getDataFirst();

          if (bankAccount) {
            if (result.didSucceed()) {
              setBankDetail(bankAccount);
              setBankDetails((state) => {
                return state.map((item) => {
                  if (item?.id === bankAccount?.id) {
                    return bankAccount;
                  } else if (
                    item.isDefault === true &&
                    bankAccount.isDefault === true
                  ) {
                    // In case updated bank account is default then remove the default flag from other bank account
                    return {
                      ...item,
                      isDefault: false,
                    };
                  }

                  return item;
                });
              });
            }
          }

          const id = clientOptions.variables.where.id || '';
          bankAccountsOpsManager.setResult(id, OPERATIONS.UPDATE, result);
        }
        break;

      case OPERATIONS.REMOVE_MANY:
        {
          const bankAccounts = result.getData();

          if (result.didSucceed()) {
            const setBankAccountsIds = arrayOfObjectsToSet({
              data: bankAccounts,
              fieldName: 'id',
            });

            setBankDetails((state) => {
              return state.filter((item) => {
                return !setBankAccountsIds.has(item?.id);
              });
            });
          }

          const ids = arrayOfObjectsToArray({
            data: clientOptions.variables.where,
            fieldName: 'id',
          });
          bankAccountsOpsManager.setResult(ids, OPERATIONS.REMOVE_MANY, result);
        }
        break;
    }
  };

  /**
   * GRAPHQL CALLERS
   */

  const [mutationBankAccountCreate] = useAppMutation({
    queries: bankAccountQueries,
    queryName: 'bankAccountCreate',
    onDone: handleDone(OPERATIONS.CREATE),
  });

  const [mutationBankAccountUpdate] = useAppMutation({
    queries: bankAccountQueries,
    queryName: 'bankAccountUpdate',
    onDone: handleDone(OPERATIONS.UPDATE),
  });

  const [mutationBankAccountRemoveMany] = useAppMutation({
    queries: bankAccountQueries,
    queryName: 'bankAccountRemoveMany',
    onDone: handleDone(OPERATIONS.REMOVE_MANY),
  });

  const [queryBankAccountList] = useAppQuery({
    queries: bankAccountQueries,
    queryName: 'bankAccountList',
    onDone: handleDone(OPERATIONS.LIST),
  });

  const [queryBankAccountGetMany] = useAppQuery({
    queries: bankAccountQueries,
    queryName: 'bankAccountGetMany',
    onDone: handleDone(OPERATIONS.GET_MANY),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const bankAccountCreate = useCallback(
    (variables) => {
      bankAccountsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.CREATE,
        OP_RESULT_CODES.SAVING
      );
      mutationBankAccountCreate({ variables });
    },
    [mutationBankAccountCreate, bankAccountsOpsManager]
  );

  const bankAccountUpdate = useCallback(
    (variables) => {
      bankAccountsOpsManager.setStartOperation(
        variables?.where?.id,
        OPERATIONS.UPDATE,
        OP_RESULT_CODES.SAVING
      );
      mutationBankAccountUpdate({ variables });
    },
    [mutationBankAccountUpdate, bankAccountsOpsManager]
  );

  const bankAccountRemoveMany = useCallback(
    (variables) => {
      bankAccountsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.REMOVE_MANY,
        OP_RESULT_CODES.DELETING
      );
      mutationBankAccountRemoveMany({ variables });
    },
    [mutationBankAccountRemoveMany, bankAccountsOpsManager]
  );

  const bankAccountList = useCallback(
    (variables) => {
      bankAccountsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING
      );
      queryBankAccountList({ variables });
    },
    [queryBankAccountList, bankAccountsOpsManager]
  );

  const bankAccountGetMany = useCallback(
    (variables) => {
      bankAccountsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.GET_MANY,
        OP_RESULT_CODES.LOADING
      );
      queryBankAccountGetMany({ variables });
    },
    [queryBankAccountGetMany, bankAccountsOpsManager]
  );

  return {
    bankAccount,
    bankAccounts,
    bankAccountsMany,
    bankAccountCreate,
    bankAccountUpdate,
    bankAccountRemoveMany,
    bankAccountList,
    bankAccountGetMany,

    bankAccountsOpsManager,
  };
};

export { useBankAccount };
