import { times } from 'lodash';
import check from 'check-types';
import { ValueDate, Badge, ValueCurrency, Flex, Button } from '@sdflc/ui';

import {
  ACTIONS_BUTTONS,
  ACTIONS_IDS,
  ORDER_ITEM_STATUSES,
  ORDER_STATUSES,
  ORDER_STEPS,
} from '../../../config';

import { ValueDictionaryItem } from 'components/ValueDictionaryItem';
import { createDirectionsLink } from 'utils/googleMaps';
import { isOrderPaymentMethodCash } from 'utils';

export const FAKE_ORDERS = times(4, () => {
  return {
    orderNum: '',
    userLocation: {
      formattedAddress: '',
    },
    dueDate: null,
    deliveryCost: null,
    totalAmount: null,
    currentStepId: null,
    status: null,
    buyerComment: '',
    shopperComment: '',
    // buyerName: orderData?.userCreated?.username,
    // shopperName: orderData?.shopper?.username,
    buyerName: {
      username: '',
      avatarUrl: '',
      name: '',
      userRating: 0,
    },
    shopperName: {
      username: '',
      avatarUrl: '',
      name: '',
      userRating: 0,
    },
    storeBrand: {
      storeBrandLogoUrl: '',
      l10n: {
        storeBrandName: '',
      },
    },
  };
});

export const buildListItemActions = (args) => {
  const { value, appUser, getText } = args || {};
  const buttons = ACTIONS_BUTTONS({ withNames: false, getText });

  const orderItemActions = [];

  switch (value.currentStepId) {
    default:
      break;

    case ORDER_STEPS.DRAFT:
      orderItemActions.push(ACTIONS_IDS.EDIT);
      orderItemActions.push(ACTIONS_IDS.PUBLISH);
      orderItemActions.push(ACTIONS_IDS.REMOVE);
      break;

    case ORDER_STEPS.AVAILABLE:
      if (value.createdBy === appUser?.id) {
        // These are actions for buyer who created the order
        orderItemActions.push(ACTIONS_IDS.EDIT);
        orderItemActions.push(ACTIONS_IDS.PICK);
        orderItemActions.push(ACTIONS_IDS.CANCEL_ACTION);
      } else {
        // These are actions for shopper
        orderItemActions.push(ACTIONS_IDS.VIEW);
        orderItemActions.push(ACTIONS_IDS.PICK);
      }
      break;

    case ORDER_STEPS.PICKED:
    case ORDER_STEPS.IN_STORE:
      if (value.createdBy === appUser?.id) {
        // These are actions for buyer who created the order
        orderItemActions.push(ACTIONS_IDS.EDIT);
        if (value.createdBy === value.shopperId) {
          orderItemActions.push(ACTIONS_IDS.SHOP_ORDER_ITEMS);
          orderItemActions.push(ACTIONS_IDS.CANCEL_ACTION);
        }
      } else {
        // These are actions for shopper
        orderItemActions.push(ACTIONS_IDS.SHOP_ORDER_ITEMS);
        orderItemActions.push(ACTIONS_IDS.CANCEL_ACTION);
      }
      break;

    case ORDER_STEPS.ON_THE_WAY:
    case ORDER_STEPS.DELIVERED:
    case ORDER_STEPS.CONFIRMED:
    case ORDER_STEPS.NOT_CONFIRMED:
      orderItemActions.push(ACTIONS_IDS.VIEW);
      break;

    case ORDER_STEPS.PAID:
    case ORDER_STEPS.CANCELLED_BY_SYSTEM:
    case ORDER_STEPS.CANCELLED_BY_USER:
      if (value.status === ORDER_STATUSES.DRAFT) {
        orderItemActions.push(ACTIONS_IDS.EDIT);
        orderItemActions.push(ACTIONS_IDS.PUBLISH);
        orderItemActions.push(ACTIONS_IDS.REMOVE);
      } else {
        orderItemActions.push(ACTIONS_IDS.VIEW);
      }
      break;
  }

  const actions = orderItemActions.map((actionId) => buttons[actionId]);

  return actions;
};

export const orderDetailsConfig = (args) => {
  const { getText, order } = args;

  if (!check.function(getText)) {
    console.log(
      `The "orderDetailsConfig" expected the 'getText' to be a function but got:`,
      getText
    );
    return null;
  }

  if (!order) {
    console.log(
      `The "orderDetailsConfig" expected the 'order' to be an object with order details but got:`,
      order
    );
    return null;
  }

  return {
    orderNum: {
      label: getText('pages.order.details.orderNo'),
      rightIcon: 'Tag',
    },
    formattedAddress: {
      label: getText('pages.order.details.address'),
      rightIcon: 'LocationOn',
      onValueRender: ({ value }) => {
        return (
          <Flex flexDirection='column' alignItems='flex-start'>
            <span>{value || 'N/A'} </span>
            {!!value && (
              <Button
                variant='tertiary'
                dimension='small'
                onClick={() => {
                  window.open(
                    createDirectionsLink({
                      origin: '',
                      destination: value,
                    }),
                    'shopaDropaDirections'
                  );
                }}
                name={getText('buttons.getDirections')}
                rightIcon={'ChevronRight'}
              />
            )}
          </Flex>
        );
      },
    },
    dueDate: {
      label: getText('pages.order.details.due'),
      rightIcon: 'Schedule',
      onValueRender: ({ value }) => {
        return <ValueDate value={value} />;
      },
    },
    buyerName: {
      label: getText('pages.order.details.buyerName'),
      rightIcon: 'AccountCircle',
    },
    buyerComment: {
      label: getText('pages.order.details.buyerComment'),
      rightIcon: 'Comment',
    },
    shopperName: {
      label: getText('pages.order.details.shopperName'),
      rightIcon: 'Person',
    },
    shopperComment: {
      label: getText('pages.order.details.shopperComment'),
      rightIcon: 'Comment',
    },
    deliveryCost: {
      label: getText('pages.order.details.deliveryCost'),
      rightIcon: 'Paid',
      onValueRender: ({ value }) => {
        return (
          <ValueCurrency
            value={value}
            currencyCode='CAD'
            noValueMessage='N/A'
          />
        );
      },
    },
    totalAmount: {
      label: getText('pages.order.details.totalAmount'),
      rightIcon: 'Paid',
      onValueRender: ({ value }) => {
        return (
          <ValueCurrency
            value={value}
            currencyCode='CAD'
            noValueMessage='N/A'
          />
        );
      },
    },
    currentStepId: {
      label: getText('pages.order.details.step'),
      rightIcon: 'DirectionsRun',
      onValueRender: ({ value }) => {
        return (
          <Badge variant='success'>
            <ValueDictionaryItem dictionaryUid='order-steps' value={value} />
          </Badge>
        );
      },
    },
    status: {
      label: getText('pages.order.details.status'),
      rightIcon: 'DonutLarge',
      onValueRender: ({ value }) => {
        return (
          <Badge variant='success'>
            <ValueDictionaryItem dictionaryUid='order-status' value={value} />
          </Badge>
        );
      },
    },
  };
};

export const getOrderStepPagePreRequisites = (args) => {
  const { order, orderItems, getText, stepName } = args ?? {};
  return {
    orderDetails: {
      deliveredTo: order.userLocation?.formattedAddress,
      deliveredAt: order.updatedAt,
      orderItemsQty: orderItems.length,
      orderItemsTakenQty: orderItems.reduce((acc, item) => {
        return acc + (item.status === ORDER_ITEM_STATUSES.TAKEN ? 1 : 0);
      }, 0),
      orderAmount: order.orderAmount,
      deliveryCost: order.deliveryCost ?? 0,
      serviceFeeTotalAmount: order.serviceFeeTotalAmount,
      totalAmountForBuyer: order.totalAmountForBuyer,
      totalAmountForShopper: order.totalAmountForShopper,
      currency: order.currency,
      buyer: order.userCreated,
      shopper: order.shopper,
      paymentMethod: !isOrderPaymentMethodCash(order) ? 'Card' : 'Cash',
      whereToTransfer: 'TBD',
    },
    labelData: getText(`blocks.${stepName}.labels`),
    hintData: getText(`blocks.${stepName}.hints`),
  };
};
