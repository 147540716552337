import { Fragment, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormDrawer } from '@sdflc/ui';

import { LocalizationContext } from '../../../contexts';

import { Block } from '../../Block';
import { AppToolbar } from '../../AppToolbar';

import { UserSubscribeListItemsList } from './UserSubscribeListItemsList';
import { useUserSubscribeListItems } from './useUserSubscribeListItems';

const UserSubscribeListItems = memo((props) => {
  const { hasTitle } = props;
  const { getText } = useContext(LocalizationContext);
  const {
    open,
    userSubscribeListItems,
    userSubscribeListItemList,
    formData,
    formCfg,
    formActions,
    handleClickAction,
    handleClickClose,
    userSubscribeListItemsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOpResult,
    updatingOps,
    removingOps,
  } = useUserSubscribeListItems();

  return (
    <Fragment>
      <Block
        title={hasTitle ? getText('blocks.userSubscribeListItem.title') : ''}
      >
        <AppToolbar items={toolBarItems} onClick={handleClickAction} />
        <UserSubscribeListItemsList
          onClickAction={handleClickAction}
          list={userSubscribeListItemList}
          items={userSubscribeListItems}
          listOp={userSubscribeListItemsOpsManager.getListOp()}
          updatingOps={updatingOps}
          removingOps={removingOps}
        />
      </Block>
      <FormDrawer
        isOpen={open}
        title={getText('blocks.userSubscribeListItem.form.title')}
        description=''
        formData={formData}
        formCfg={formCfg}
        onClose={handleClickClose}
        actions={formActions}
        actionInProgress={actionInProgress}
        onClickAction={handleClickAction}
        progress={saveOpResult.isInProgress()}
        opResult={saveOpResult}
      />
    </Fragment>
  );
});

UserSubscribeListItems.displayName = 'UserSubscribeListItems';

UserSubscribeListItems.propTypes = {
  children: PropTypes.node,
  hasTitle: PropTypes.bool,
};

UserSubscribeListItems.defaultProps = {
  hasTitle: false,
};

export { UserSubscribeListItems };
