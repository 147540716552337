import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Sticky, Panel, ToolBar } from '@sdflc/ui';
import { colors } from 'config';

import check from 'check-types';
import { logger } from 'utils';

const AppToolbar = memo((props) => {
  const { items, onClick, backgroundColor } = props;
  const backgroundColorToUse = backgroundColor || colors.transparent;

  const handleClick = useCallback(
    (itemId, value) => {
      if (check.function(onClick)) {
        onClick({ id: itemId }, value);
      } else {
        logger.warn(
          `The "AppToolbar" expected the props "onClick" to be a function but got`,
          onClick
        );
      }
    },
    [onClick]
  );

  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  return (
    <Sticky>
      <Panel p={3} backgroundColor={backgroundColorToUse}>
        <ToolBar items={items} onClick={handleClick} />
      </Panel>
    </Sticky>
  );
});

AppToolbar.displayName = 'AppToolbar';
AppToolbar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
};

AppToolbar.defaultProp = {
  items: [],
};

export { AppToolbar };
