import React, { memo, Fragment, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PaymentMethodCreated } from '../../components/Blocks/PaymentMethods';
import { AppPagesContext } from '../../contexts';

/**
 * The page component is used after a credit card was created on a 3rd party website
 */
const PageCreditCardCreated = memo(() => {
  const location = useLocation();
  const history = useHistory();
  const { appPages } = useContext(AppPagesContext);

  const params = new URLSearchParams(location.search);
  const token = params.get('Token');
  const paymentMethod = params.get('PaymentMethod');

  if (!token) {
    history.push(appPages['settings-payment-methods']?.url);
  }

  return (
    <Fragment>
      <PaymentMethodCreated token={token} paymentMethod={paymentMethod} />
    </Fragment>
  );
});

PageCreditCardCreated.displayName = 'PageCreditCardCreated';

export { PageCreditCardCreated };
