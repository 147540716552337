import { ORDER_STEPS } from 'config';
import { ORDER_STATUSES } from 'config';
import { ACTIONS_IDS } from 'config';
import { PAYMENT_METHOD_ID_CASH } from 'config/constants';
import PropTypes from 'prop-types';
/**
 * Returns an object that has textId and user to display for an order
 * @param {object} args An object with the following props
 * @param {object} args.order A order object
 * @param {object} args.appUser A current user object
 * @param {boolean} args.oppositeSide Specifies what user and text to return
 */
export const getBuyerOrShopper = (args) => {
  const { order, appUser, oppositeSide } = args ?? {};
  const { shopperId, createdBy, userCreated } = order ?? {};
  const { id } = appUser ?? {};

  if (shopperId === createdBy) {
    return {
      textId: 'orderCard.shopperAndBuyer',
      user: userCreated,
    };
  }

  if (id === createdBy) {
    return {
      textId: oppositeSide ? 'orderCard.shopper' : 'orderCard.buyer',
      user: oppositeSide ? order.shopper : appUser,
    };
  }

  if (id === shopperId) {
    return {
      textId: oppositeSide ? 'orderCard.buyer' : 'orderCard.shopper',
      user: oppositeSide ? order.userCreated : order.shopper,
    };
  }

  return { textId: 'orderCard.buyer', user: userCreated };
};

getBuyerOrShopper.propTypes = {
  order: PropTypes.shape({
    shopperId: PropTypes.string,
    createdBy: PropTypes.string,
    userCreated: PropTypes.object,
    shopper: PropTypes.object,
  }),
  appUser: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export const isPaymentMethodCash = (paymentMethodId) => {
  return paymentMethodId === PAYMENT_METHOD_ID_CASH;
};

export const isOrderPaymentMethodCash = (order) => {
  return order?.paymentMethodId === PAYMENT_METHOD_ID_CASH;
};

const mapActionIdToOrderStepId = {
  [ACTIONS_IDS.PUBLISH]: ORDER_STEPS.AVAILABLE,
  [ACTIONS_IDS.PICK]: ORDER_STEPS.PICKED,
  [ACTIONS_IDS.ON_THE_WAY]: ORDER_STEPS.ON_THE_WAY,
  [ACTIONS_IDS.DELIVERED]: ORDER_STEPS.DELIVERED,
  [ACTIONS_IDS.CONFIRM]: ORDER_STEPS.CONFIRMED,
  [ACTIONS_IDS.DISPUTE]: ORDER_STEPS.NOT_CONFIRMED,
  [ACTIONS_IDS.CANCEL_ACTION]: ORDER_STEPS.CANCELLED_BY_USER,
  [ACTIONS_IDS.CONFIRM_PAYMENT]: ORDER_STEPS.PAID,
};

const mapOrderStepIdToActionId = {
  [ORDER_STEPS.AVAILABLE]: ACTIONS_IDS.PUBLISH,
  [ORDER_STEPS.ON_THE_WAY]: ACTIONS_IDS.ON_THE_WAY,
  [ORDER_STEPS.DELIVERED]: ACTIONS_IDS.DELIVERED,
  [ORDER_STEPS.CANCELLED_BY_USER]: ACTIONS_IDS.CANCEL_ACTION,
  [ORDER_STEPS.CONFIRMED]: ACTIONS_IDS.CONFIRM,
  [ORDER_STEPS.NOT_CONFIRMED]: ACTIONS_IDS.DISPUTE,
  [ORDER_STEPS.PICKED]: ACTIONS_IDS.PICK,
  [ORDER_STEPS.PAID]: ACTIONS_IDS.CONFIRM_PAYMENT,
};

export const actionIdToOrderStepId = (actionId) => {
  return mapActionIdToOrderStepId[actionId];
};

export const stepIdToActionId = (stepId) => {
  return mapOrderStepIdToActionId[stepId] || '';
};

export const getActionsCfgForMyOrder = (args) => {
  const { order, orderReceipts } = args ?? {};

  const actionsCfg = {
    pickActionId: ACTIONS_IDS.PICK_ALT,
    canPick: [
      ORDER_STATUSES.DRAFT,
      ORDER_STATUSES.PUBLISHED,
      ORDER_STATUSES.CANCELLED,
    ].includes(order.status),
    canPublish: [ORDER_STATUSES.DRAFT, ORDER_STATUSES.CANCELLED].includes(
      order.status
    ),
    canOnTheWay: false,
    canCancel: ![ORDER_STEPS.COMPLETE, ORDER_STEPS.CANCELLED_BY_USER].includes(
      order.currentStepId
    ),
    canDeliver:
      [
        ORDER_STEPS.PICKED,
        ORDER_STEPS.IN_STORE,
        ORDER_STEPS.ON_THE_WAY,
      ].includes(order.currentStepId) && orderReceipts.length > 0,
    canConfirmPayment: false,
  };

  return actionsCfg;
};

export const getActionsCfgForShopperOrder = (args) => {
  const { order, orderReceipts } = args ?? {};

  const actionsCfg = {
    pickActionId: ACTIONS_IDS.PICK,
    canPick: [ORDER_STATUSES.PUBLISHED].includes(order.status),
    canPublish: false,
    canOnTheWay:
      [ORDER_STEPS.PICKED, ORDER_STEPS.IN_STORE].includes(
        order.currentStepId
      ) && orderReceipts.length > 0,
    canCancel: [ORDER_STEPS.PICKED].includes(order.currentStepId),
    canDeliver: [ORDER_STEPS.ON_THE_WAY].includes(order.currentStepId),
    canConfirmPayment: [ORDER_STEPS.CONFIRMED].includes(order.currentStepId),
  };

  return actionsCfg;
};

export const getActionsCfgForOrderViewer = (args) => {
  const { order } = args ?? {};

  const actionsCfg = {
    pickActionId: ACTIONS_IDS.PICK,
    canPick: [ORDER_STATUSES.PUBLISHED].includes(order.status),
    canPublish: false,
    canOnTheWay: false,
    canCancel: false,
    canDeliver: false,
    canConfirmPayment: false,
  };

  return actionsCfg;
};

export const buildActionsFromCfg = (args) => {
  const { actionsCfg, order } = args ?? {};
  const actions = [];

  const { currentStepId, isMine, amIShopper } = order ?? {};
  const isDelivered = [ORDER_STEPS.DELIVERED].includes(currentStepId);

  if (actionsCfg.canConfirmPayment) {
    actions.push(ACTIONS_IDS.CONFIRM_PAYMENT);
  }

  if (actionsCfg.canPublish) {
    actions.push(ACTIONS_IDS.PUBLISH);
  }

  if (actionsCfg.canPick) {
    actions.push(actionsCfg.pickActionId);
  }

  if (actionsCfg.canOnTheWay) {
    actions.push(ACTIONS_IDS.ON_THE_WAY);
  }

  if (actionsCfg.canDeliver) {
    if (isMine && amIShopper) {
      actions.push(ACTIONS_IDS.CONFIRM);
    } else {
      actions.push(ACTIONS_IDS.GET_DIRECTIONS);
      actions.push(ACTIONS_IDS.DELIVERED);
    }
  }

  if (isDelivered) {
    if (isMine && !amIShopper) {
      actions.push(ACTIONS_IDS.CONFIRM);
      actions.push(ACTIONS_IDS.DISPUTE);
    }
  }

  if (actionsCfg.canCancel) {
    actions.push(ACTIONS_IDS.CANCEL_ACTION);
  }

  return actions;
};

export const getActionsForOrder = (args) => {
  const { order } = args ?? {};
  const { isMine, amIShopper } = order ?? {};

  if (!order) {
    return [];
  }

  let actionsCfg = null;

  if (isMine) {
    actionsCfg = getActionsCfgForMyOrder(args);
  } else if (amIShopper) {
    actionsCfg = getActionsCfgForShopperOrder(args);
  } else {
    actionsCfg = getActionsCfgForOrderViewer(args);
  }

  return buildActionsFromCfg({ actionsCfg, order });
};
