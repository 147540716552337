import { gql } from '@apollo/client';

const CONTENT_ITEM_LIST = gql`
  query ContentItemList(
    $filter: ContentItemFilter
    $params: PaginationAndSorting
    $lang: String
  ) {
    contentItemList(filter: $filter, params: $params) {
      code
      errors {
        name
        errors
        warnings
      }
      data {
        id
        itemId
        path
        isPublic
        contentType
        variant
        status
        l10n(lang: $lang) {
          lang
          localization
        }
      }
    }
  }
`;

const contentItemQueries = {
  contentItemList: CONTENT_ITEM_LIST,
};

export { contentItemQueries };
