import { useState, useCallback } from 'react';
import { OP_RESULT_CODES } from '@sdflc/api-helpers';
import {
  logger,
  OperationManager,
  OPERATIONS,
  processApiUserLocation,
  useAppMutation,
  useAppQuery,
} from '../../utils';
import { userLocationQueries } from '../queries/userLocationQueries';

import {
  arrayOfObjectsToArray,
  arrayOfObjectsToMap,
  arrayOfObjectsToSet,
} from 'utils/transformers';

const useUserLocation = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [userLocationsMany, setUserLocationsMany] = useState([]);
  const [userLocations, setUserLocations] = useState([]);
  const [userContactsOps, setUserContactsOps] = useState({});
  const [userLocationsOpsManager] = useState(new OperationManager());

  userLocationsOpsManager.setStateControls(userContactsOps, setUserContactsOps);

  const processResult = (result) => {
    if (!result.didSucceedAndHasData()) {
      return [];
    }

    const data = result.getData().map((value) => processApiUserLocation(value));

    return data;
  };

  const handleDone = (operation) => (result, clientOptions) => {
    switch (operation) {
      default:
        logger.warn(
          'The "useUserLocation" hook got unexpected value of operation: ',
          operation
        );
        break;

      case OPERATIONS.LIST:
        if (result.didSucceed()) {
          setUserLocations(processResult(result));
        }

        userLocationsOpsManager.setResult(
          OperationManager.defaultContext,
          OPERATIONS.LIST,
          result
        );

        break;

      case OPERATIONS.GET_MANY:
        {
          const locations = processResult(result);

          if (result.didSucceed()) {
            setUserLocationsMany(locations);
          }

          userLocationsOpsManager.setResult(
            arrayOfObjectsToArray({ data: locations, fieldName: 'id' }),
            OPERATIONS.GET_MANY,
            result
          );
        }
        break;

      case OPERATIONS.CREATE:
        {
          const location = processResult(result)[0];

          if (location) {
            if (result.didSucceed()) {
              setUserLocation(location);
              setUserLocations((state) => {
                return [
                  location,
                  ...state.map((item) => {
                    if (
                      item.isDefault === true &&
                      location.isDefault === true
                    ) {
                      return {
                        ...item,
                        isDefault: false,
                      };
                    }

                    return item;
                  }),
                ];
              });
            }
          }

          userLocationsOpsManager.setResult(
            OperationManager.defaultContext,
            OPERATIONS.CREATE,
            result
          );
        }
        break;

      case OPERATIONS.UPDATE:
        {
          const location = processResult(result)[0];

          if (location) {
            if (result.didSucceed()) {
              setUserLocation(location);
              setUserLocations((state) => {
                return state.map((item) => {
                  if (item?.id === location?.id) {
                    return location;
                  } else if (
                    item.isDefault === true &&
                    location.isDefault === true
                  ) {
                    // In case updated location is default then remove the default flag from other locations
                    return {
                      ...item,
                      isDefault: false,
                    };
                  }

                  return item;
                });
              });
            }
          }

          const id = clientOptions.variables.where.id || '';
          userLocationsOpsManager.setResult(id, OPERATIONS.UPDATE, result);
        }
        break;

      case OPERATIONS.SET:
        {
          const locations = processResult(result);

          if (locations.length) {
            const arrLocationIds = arrayOfObjectsToArray({
              data: locations,
              fieldName: 'id',
            });

            if (result.didSucceed()) {
              const mapLocations = arrayOfObjectsToMap({
                data: locations,
                fieldName: 'id',
              });

              const anyDefaults = locations.some(
                (item) => item.isDefault === true
              );

              setUserLocations((state) => {
                return state.map((item) => {
                  if (mapLocations[item?.id]) {
                    return mapLocations[item?.id];
                  } else if (item.isDefault === true && anyDefaults) {
                    // In case updated location is default then remove the default flag from other locations
                    return {
                      ...item,
                      isDefault: false,
                    };
                  }

                  return item;
                });
              });
            }

            userLocationsOpsManager.setResult(
              arrLocationIds,
              OPERATIONS.SET,
              result
            );
          }
        }
        break;

      case OPERATIONS.REMOVE_MANY:
        {
          const locations = processResult(result);

          if (result.didSucceed()) {
            const setContactIds = arrayOfObjectsToSet({
              data: locations,
              fieldName: 'id',
            });

            setUserLocations((state) => {
              return state.filter((item) => {
                return !setContactIds.has(item?.id);
              });
            });
          }
        }

        const ids = arrayOfObjectsToArray({
          data: clientOptions.variables.where,
          fieldName: 'id',
        });
        userLocationsOpsManager.setResult(ids, OPERATIONS.REMOVE_MANY, result);
        break;
    }
  };

  /**
   * GRAPHQL CALLERS
   */

  const [mutationUserLocationCreate] = useAppMutation({
    queries: userLocationQueries,
    queryName: 'userLocationCreate',
    onDone: handleDone(OPERATIONS.CREATE),
  });

  const [mutationUserLocationUpdate] = useAppMutation({
    queries: userLocationQueries,
    queryName: 'userLocationUpdate',
    onDone: handleDone(OPERATIONS.UPDATE),
  });

  const [mutationUserLocationSet] = useAppMutation({
    queries: userLocationQueries,
    queryName: 'userLocationSet',
    onDone: handleDone(OPERATIONS.SET),
  });

  const [mutationUserLocationRemoveMany] = useAppMutation({
    queries: userLocationQueries,
    queryName: 'userLocationRemoveMany',
    onDone: handleDone(OPERATIONS.REMOVE_MANY),
  });

  const [queryUserLocationList] = useAppQuery({
    queries: userLocationQueries,
    queryName: 'userLocationList',
    onDone: handleDone(OPERATIONS.LIST),
  });

  const [queryUserLocationGetMany] = useAppQuery({
    queries: userLocationQueries,
    queryName: 'userLocationGetMany',
    onDone: handleDone(OPERATIONS.GET_MANY),
  });

  /**
   * EXPORTED FUNCTIONS
   */

  const userLocationCreate = useCallback(
    (variables) => {
      userLocationsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.CREATE,
        OP_RESULT_CODES.SAVING
      );
      mutationUserLocationCreate({ variables });
    },
    [mutationUserLocationCreate, userLocationsOpsManager]
  );

  const userLocationUpdate = useCallback(
    (variables) => {
      userLocationsOpsManager.setStartOperation(
        variables?.where?.id,
        OPERATIONS.UPDATE,
        OP_RESULT_CODES.SAVING
      );
      mutationUserLocationUpdate({ variables });
    },
    [mutationUserLocationUpdate, userLocationsOpsManager]
  );

  const userLocationSet = useCallback(
    (variables) => {
      userLocationsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'locationId',
        }),
        OPERATIONS.SET,
        OP_RESULT_CODES.SAVING
      );
      mutationUserLocationSet({ variables });
    },
    [mutationUserLocationSet, userLocationsOpsManager]
  );

  const userLocationRemoveMany = useCallback(
    (variables) => {
      userLocationsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.REMOVE_MANY,
        OP_RESULT_CODES.DELETING
      );
      mutationUserLocationRemoveMany({ variables });
    },
    [mutationUserLocationRemoveMany, userLocationsOpsManager]
  );

  const userLocationList = useCallback(
    (variables) => {
      userLocationsOpsManager.setStartOperation(
        OperationManager.defaultContext,
        OPERATIONS.LIST,
        OP_RESULT_CODES.LOADING
      );
      queryUserLocationList({ variables });
    },
    [queryUserLocationList, userLocationsOpsManager]
  );

  const userLocationGetMany = useCallback(
    (variables) => {
      userLocationsOpsManager.setStartOperation(
        arrayOfObjectsToArray({
          data: variables?.where,
          fieldName: 'id',
        }),
        OPERATIONS.GET_MANY,
        OP_RESULT_CODES.LOADING
      );
      queryUserLocationGetMany({ variables });
    },
    [queryUserLocationGetMany, userLocationsOpsManager]
  );

  return {
    userLocation,
    userLocations,
    userLocationsMany,

    userLocationCreate,
    userLocationUpdate,
    userLocationSet,
    userLocationRemoveMany,
    userLocationList,
    userLocationGetMany,

    userLocationsOpsManager,
  };
};

export { useUserLocation };
