import {
  Fragment,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Flex,
  AppPageDrawer,
  Panel,
  Row,
  Tile,
  VisibleAbove,
  VisibleBelow,
  ItemsGroup,
  Sticky,
  FormDrawer,
} from '@sdflc/ui';

import { AlertMessage, Loading, OrdersSummaryBox } from '../../../components';

import { ACTIONS_IDS, BUTTONS_WITH_NAMES, colors } from '../../../config';
import {
  OrdersContext,
  LocalizationContext,
  CommentsContext,
} from '../../../contexts';
import { withProvider } from '../../../hoc';
import { OrdersProvider } from '../../../providers';

import { MobileBottomContainer } from './styled';
import { OrderReceiptAddEdit } from './OrderReceiptAddEdit';
import { logger } from 'utils';
import { orderReviewFormSections } from './orderReviewFormSections';

/**
 * The component loads order and its items and renders children and summary box on the side
 */
const OrderWithItemsAndSummaryComponent = memo((props) => {
  const { children, shopMode, readOnly, hideSummaryBox, titleId } = props;
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const { getText, setPageTitle } = useContext(LocalizationContext);
  const {
    isLoading,
    loadingError,
    orderHooks,
    orderGetOp,
    reviewToEdit,
    handleClickAction,
    actionInProgress,
  } = useContext(OrdersContext);
  const { order } = orderHooks;
  const { setChannelKey } = useContext(CommentsContext);

  useEffect(() => {
    if (orderGetOp.called && !orderGetOp.result.isInProgress()) {
      logger.debug('Set page title', titleId);

      if (titleId) {
        setPageTitle(getText(titleId, order ?? { orderNum: 'N/A' }));
      }
    }

    return () => {
      logger.debug('Clear page title');
      setPageTitle('');
    };
  }, [titleId, setPageTitle, getText, orderGetOp, order]);

  const handleClickSummary = useCallback(() => {
    setIsSummaryOpen((state) => !state);
  }, []);

  const handleClickClose = useCallback(() => {
    handleClickAction({ id: ACTIONS_IDS.CANCEL }, order);
  }, [handleClickAction, order]);

  const reviewFormCfg = useMemo(() => {
    return {
      sections: orderReviewFormSections,
      labels: getText('forms.orderReview.labels'),
      hints: getText('forms.orderReview.hints'),
      placeholders: getText('forms.orderReview.placeholders'),
    };
  }, [getText]);

  if (isLoading) {
    return <Loading />;
  }

  if (loadingError) {
    return (
      <AlertMessage
        message={loadingError}
        variant={AlertMessage.VARIANT_NAMES.DANGER}
      />
    );
  }

  if (!order) {
    return (
      <AlertMessage
        message={getText('pages.orders.orderNotFound')}
        variant={AlertMessage.VARIANT_NAMES.DANGER}
      />
    );
  }

  return (
    <Panel>
      <Row alignItems='flex-start'>
        <Panel
          width={[
            '100%',
            '100%',
            '100%',
            hideSummaryBox ? '100%' : 'calc(100% - 320px)',
          ]}
        >
          {children}
        </Panel>
        {!hideSummaryBox && (
          <VisibleAbove
            breakpoint='md'
            style={{ width: '320px', height: '100vh' }}
          >
            <Sticky top={'40px'}>
              <Panel ml={3} mt={'40px'}>
                <Flex flex='1 1'>
                  <Tile backgroundColor={colors.white} width='100%'>
                    <OrdersSummaryBox shopMode={shopMode} readOnly={readOnly} />
                  </Tile>
                </Flex>
                <Flex flex='1 1' mt={3}>
                  <Tile backgroundColor={colors.white} width='100%'>
                    <Button
                      name={getText('buttons.comments')}
                      icon='Comment'
                      onClick={() => {
                        setChannelKey(`order-${order.id}`);
                      }}
                      width={'100%'}
                      variant={Button.VARIANT_NAMES.SECONDARY}
                      dimension={Button.DIMENSION_NAMES.SMALL}
                    />
                  </Tile>
                </Flex>
              </Panel>
            </Sticky>
          </VisibleAbove>
        )}
      </Row>
      <VisibleBelow breakpoint='lg' style={{ width: '100%' }}>
        <MobileBottomContainer>
          <Panel
            backgroundColor={colors.white}
            padding={3}
            boxShadow='0 -3px 2px 0 rgba(11,11,12,0.10)'
          >
            <ItemsGroup wrapMode='nowrap'>
              <Button
                icon='Assignment'
                name={getText('buttons.summary')}
                width='100%'
                onClick={handleClickSummary}
                variant={Button.VARIANT_NAMES.PRIMARY}
                dimension={Button.DIMENSION_NAMES.SMALL}
              />
              <Button
                name={getText('buttons.comments')}
                icon='Comment'
                onClick={() => {
                  setChannelKey(`order-${order.id}`);
                }}
                width={'100%'}
                variant={Button.VARIANT_NAMES.SECONDARY}
                dimension={Button.DIMENSION_NAMES.SMALL}
              />
            </ItemsGroup>
          </Panel>
        </MobileBottomContainer>
      </VisibleBelow>
      {!hideSummaryBox && (
        <AppPageDrawer
          isOpen={isSummaryOpen}
          width='450px'
          maxWidth='100%'
          onClose={handleClickSummary}
          title={getText('buttons.summary')}
        >
          {({ data, setData }) => {
            return (
              <Fragment>
                <OrdersSummaryBox shopMode={shopMode} readOnly={readOnly} />
              </Fragment>
            );
          }}
        </AppPageDrawer>
      )}
      <FormDrawer
        isOpen={!!reviewToEdit}
        title={'Review'}
        description=''
        formData={reviewToEdit ?? {}}
        formCfg={reviewFormCfg}
        onClose={handleClickClose}
        actions={[BUTTONS_WITH_NAMES.SAVE, BUTTONS_WITH_NAMES.CANCEL]}
        actionInProgress={actionInProgress}
        onClickAction={handleClickAction}
        //progress={saveOpResult.isInProgress()}
        //opResult={saveOpResult}
      />
      <OrderReceiptAddEdit />
    </Panel>
  );
});

OrderWithItemsAndSummaryComponent.displayName =
  'OrderWithItemsAndSummaryComponent';

OrderWithItemsAndSummaryComponent.propTypes = {
  children: PropTypes.node,
  titleId: PropTypes.string,
  shopMode: PropTypes.bool,
  readOnly: PropTypes.bool,
  hideSummaryBox: PropTypes.bool,
};

OrderWithItemsAndSummaryComponent.defaultProps = {
  hideSummaryBox: false,
};

const OrderWithItemsAndSummary = withProvider(
  OrderWithItemsAndSummaryComponent,
  OrdersProvider
);

export { OrderWithItemsAndSummary };
