import React from 'react';

const withProvider = (WrappedComponent, Provider) => (props) => {
  return (
    <Provider>
      <WrappedComponent {...props} />
    </Provider>
  );
};

export { withProvider };
