import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertText, Icon } from '@sdflc/ui';

import { colors } from '../../config';

const VARIANTS = {
  DANGER: 'DANGER',
  WARNING: 'WARNING',
};

const mapVariantToIcon = {
  [VARIANTS.WARNING]: 'PriorityHigh',
  [VARIANTS.DANGER]: 'PriorityHigh',
};

const AlertMessage = memo((props) => {
  const { message, icon, variant } = props;
  const icon2use = mapVariantToIcon[variant] || icon;

  return (
    <Alert
      variant={Alert.VARIANT_NAMES.defaultProps}
      dimension={Alert.DIMENSION_NAMES.LARGE}
    >
      {!!icon2use && (
        <Icon name={icon2use} color={colors.orange} iconSize={32} />
      )}
      <AlertText>{message}</AlertText>
    </Alert>
  );
});

AlertMessage.VARIANTS = Object.values(VARIANTS);
AlertMessage.VARIANT_NAMES = VARIANTS;

AlertMessage.displayName = 'AlertMessage';

AlertMessage.propTypes = {
  message: PropTypes.string,
  icon: PropTypes.string,
  variant: PropTypes.oneOf(AlertMessage.VARIANTS),
};
AlertMessage.defaultProp = {};

export { AlertMessage };
