import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';

import { LocalizationContext, UserShoppingItemContext } from 'contexts';
import {
  logger,
  OPERATIONS,
  onlyPropsOfObject,
  hasStringMatch,
  OperationManager,
} from 'utils';

import { ACTIONS_IDS } from 'config';
import { userShoppingItemForm } from './userShoppingItemForm';
import { BUTTONS } from 'config';
import { Input, ToolBarItem } from '@sdflc/ui';
import { BUTTONS_WITH_NAMES } from 'config';

const tplNewUserShoppingItem = {
  shoppingItemName: '',
  manufacturer: null,
  shoppingItemDescription: '',
  categoryId: 'other',
  unitId: 'package',
  quantity: 1,
  minPricePerUnit: null,
  maxPricePerUnit: null,
  pictureUrl: '',
};

const useUserShoppingItems = (props) => {
  const [open, setOpen] = useState(false);
  const { getText } = useContext(LocalizationContext);
  const [formData, setFormData] = useState(null);
  const [filters, setFilters] = useState({
    keyword: '',
  });
  const {
    userShoppingItem,
    userShoppingItems,
    userShoppingItemList,
    userShoppingItemCreate,
    userShoppingItemUpdate,
    userShoppingItemRemoveMany,
    userShoppingItemsOpsManager,
  } = useContext(UserShoppingItemContext);

  const contactId = formData?.id || '';
  const createOp = userShoppingItemsOpsManager.getCreateOp();
  const updateOp = userShoppingItemsOpsManager.get(
    contactId,
    OPERATIONS.UPDATE
  );
  const saveOp = contactId ? updateOp : createOp;
  const saveOpResult = saveOp.result;
  const removingOps = userShoppingItemsOpsManager.getOpValues(
    OPERATIONS.REMOVE_MANY
  );

  useEffect(() => {
    setOpen(!!formData);
  }, [formData]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of new user shoping item

    // User shoping item is not being saved yet
    if (!createOp.called) {
      return;
    }

    // User shoping item operation result is not in prgoress and succeed
    if (!createOp.result.isInProgress() && createOp.result.didSucceed()) {
      userShoppingItemsOpsManager.reset(
        userShoppingItemsOpsManager.defaultContext,
        OPERATIONS.CREATE
      );
      setFormData(null);
    }
  }, [createOp, userShoppingItemsOpsManager]);

  useEffect(() => {
    // This effect should close the app drawer on successfull saving of existing user shoping item

    // User shoping item is not being saved yet
    if (!updateOp.called) {
      return;
    }

    // User shoping item operation result is not in prgoress and succeed
    if (!updateOp.result.isInProgress() && updateOp.result.didSucceed()) {
      userShoppingItemsOpsManager.reset(contactId, OPERATIONS.UPDATE);
      setFormData(null);
    }
  }, [contactId, updateOp, userShoppingItemsOpsManager]);

  const handleChangeFilters = useCallback((name, value) => {
    setFilters((state) => {
      return {
        ...state,
        [name]: value,
      };
    });
  }, []);

  const handleClickAction = useCallback(
    (action, item) => {
      switch (action.id) {
        default:
          logger.warn('The "useUserShoppingItems" got an unexpected action:', {
            action,
            item,
          });
          break;

        case ACTIONS_IDS.KEYWORD:
          handleChangeFilters(action.id, item);
          break;

        case ACTIONS_IDS.ADD:
        case ACTIONS_IDS.CREATE:
          userShoppingItemsOpsManager.reset(
            OperationManager.defaultContext,
            OPERATIONS.CREATE
          );
          setFormData(
            cloneDeep({
              ...tplNewUserShoppingItem,
              shoppingItemName: filters.keyword,
            })
          );
          break;

        case ACTIONS_IDS.SAVE:
          if (!item.id) {
            userShoppingItemCreate({ params: item });
          } else {
            userShoppingItemUpdate({
              where: { id: item.id },
              params: onlyPropsOfObject(item, tplNewUserShoppingItem),
            });
          }
          break;

        case ACTIONS_IDS.CANCEL:
          setFormData(null);
          break;

        case ACTIONS_IDS.EDIT:
          userShoppingItemsOpsManager.reset(item?.id, OPERATIONS.UPDATE);
          setFormData(cloneDeep(item));
          break;

        case ACTIONS_IDS.REMOVE:
          userShoppingItemRemoveMany({ where: [{ id: item.id }] });
          break;
      }
    },
    [
      handleChangeFilters,
      userShoppingItemCreate,
      userShoppingItemUpdate,
      userShoppingItemRemoveMany,
      filters.keyword,
      userShoppingItemsOpsManager,
    ]
  );

  const handleClickClose = useCallback(() => {
    handleClickAction({ id: ACTIONS_IDS.CANCEL }, null);
  }, [handleClickAction]);

  const formCfg = useMemo(() => {
    return {
      sections: userShoppingItemForm,
      labels: getText('pages.userShoppingItems.form.labels'),
      hints: getText('pages.userShoppingItems.form.hints'),
    };
  }, [getText]);

  const formActions = useMemo(
    () => [BUTTONS_WITH_NAMES.SAVE, BUTTONS_WITH_NAMES.CANCEL],
    []
  );
  const actionInProgress = useMemo(
    () => (saveOpResult.isInProgress() ? [ACTIONS_IDS.SAVE] : []),
    [saveOpResult]
  );

  const toolBarItems = useMemo(() => {
    return [
      {
        id: 'keyword',
        type: ToolBarItem.types.COMPONENT,
        name: 'keyword',
        value: filters.keyword,
        component: Input,
        placeholder: getText('generic.search'),
        leftIcon: 'Search',
      },
      { ...BUTTONS_WITH_NAMES.ADD, type: ToolBarItem.types.BUTTON },
    ];
  }, [getText, filters]);

  const userShoppingItems2use = useMemo(() => {
    return userShoppingItems.reduce((acc, item) => {
      if (
        filters.keyword &&
        !hasStringMatch(item.shoppingItemName, filters.keyword)
      ) {
        return acc;
      }

      acc.push(item);

      return acc;
    }, []);
  }, [userShoppingItems, filters.keyword]);

  return {
    open,
    userShoppingItem,
    userShoppingItems: userShoppingItems2use,
    userShoppingItemList,
    formData,
    formCfg,
    formActions,
    filters,
    handleClickAction,
    handleChangeFilters,
    setFormData,
    handleClickClose,
    userShoppingItemsOpsManager,
    actionInProgress,
    toolBarItems,
    saveOp,
    saveOpResult,
    removingOps,
  };
};

export { useUserShoppingItems };
