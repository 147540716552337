import { colors } from '@sdflc/ui';
import styled from 'styled-components';

export const Container = styled.div`
  color: ${colors.carrot};
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > span {
    padding-left: 4px;
  }
`;
