import React, { Fragment, memo, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Caption, Actions, Button, Panel, Text } from '@sdflc/ui';

import { LocalizationContext, OrdersContext } from '../../../contexts';

import {
  BUTTONS_WITH_NAMES,
  ORDER_COMPLETE,
  ORDER_EDITABLE_BY_ME,
  ORDER_SHOPABLE_BY_ME,
  ORDER_SHOULD_SHAW_PROGRESS,
  ORDER_STEPS,
} from '../../../config';
import { OrderItemsProgress } from '../OrderItems/OrderItemsProgress';
import { OrderReceipts } from './OrderReceipts';

import { OrderMiniDetails } from 'components/OrderMiniDetails';
import { TitledSection } from 'components/TitledSection';

const OrdersSummaryBox = memo((props) => {
  const { shopMode, readOnly } = props;
  const {
    orderActions,
    handleClickAction,
    actionInProgress,
    orderItemHooks,
    orderHooks,
    needsReceipts,
    qtyPickedItems,
    waitingForPaymentConfirmation,
  } = useContext(OrdersContext);
  const { getText } = useContext(LocalizationContext);
  const { orderItems } = orderItemHooks;
  const { order } = orderHooks;

  const orderActions2use = useMemo(() => {
    const actions = [...orderActions];

    if (readOnly) {
      if (order.isMine) {
        if (ORDER_EDITABLE_BY_ME.includes(order.currentStepId)) {
          actions.push(BUTTONS_WITH_NAMES.GO_TO_ORDER_EDIT);
        }

        if (ORDER_SHOPABLE_BY_ME.includes(order.currentStepId)) {
          actions.push(BUTTONS_WITH_NAMES.SHOP_ORDER_ITEMS);
        }
      }
    } else if (shopMode) {
      if (order.isMine) {
        if (ORDER_EDITABLE_BY_ME.includes(order.currentStepId)) {
          actions.push(BUTTONS_WITH_NAMES.GO_TO_ORDER_EDIT);
        }
      }
    } else {
      if (order.isMine) {
        if (ORDER_SHOPABLE_BY_ME.includes(order.currentStepId)) {
          actions.push(BUTTONS_WITH_NAMES.GO_TO_ORDER_SHOP);
        }
      }
    }

    return actions;
  }, [orderActions, readOnly, shopMode, order]);

  const shouldShawProgress = ORDER_SHOULD_SHAW_PROGRESS.includes(
    order.currentStepId
  );
  const waitingForConfirmation =
    !order.isMine &&
    order.amIShopper &&
    order.currentStepId === ORDER_STEPS.DELIVERED;

  return (
    <Fragment>
      <Panel>
        {(shopMode || readOnly) && (
          <Fragment>
            <OrderMiniDetails order={order} orderItems={orderItems} />
            {shouldShawProgress && (
              <OrderItemsProgress
                order={order}
                items={orderItems}
                shopMode={shopMode}
                readOnly={readOnly}
              />
            )}
          </Fragment>
        )}
        {!shopMode && !readOnly && (
          <TitledSection title={'pages.orders.summaryBox.title'}>
            <Text>
              {getText('pages.orders.summaryBox.readOnlyText', {
                qtyPickedItems,
                qtyOrderItems: orderItems.length,
              })}
            </Text>
          </TitledSection>
        )}
        {(order.isMine || order.amIShopper) && shouldShawProgress && (
          <OrderReceipts
            shopMode={shopMode}
            readOnly={
              readOnly ||
              [
                ORDER_STEPS.CONFIRMED,
                ORDER_STEPS.PAID,
                ORDER_STEPS.COMPLETE,
              ].includes(order.currentStepId)
            }
          />
        )}
      </Panel>

      <TitledSection
        title={'pages.orders.summaryBox.nextSteps'}
        distanceFromTitle={3}
        hidden={ORDER_COMPLETE.includes(order.currentStepId)}
      >
        {!readOnly && needsReceipts && (
          <Text
            variant={Text.VARIANT_NAMES.DANGER}
            dimension={Text.DIMENSION_NAMES.SMALL}
          >
            {getText('pages.orders.summaryBox.needReceipts')}
          </Text>
        )}
        {waitingForPaymentConfirmation && (
          <Text
            variant={Text.VARIANT_NAMES.DANGER}
            dimension={Text.DIMENSION_NAMES.SMALL}
          >
            {getText('pages.orders.summaryBox.waitingForPaymentConfirmation')}
          </Text>
        )}
        {waitingForConfirmation && (
          <Text
            variant={Text.VARIANT_NAMES.WARNING}
            dimension={Text.DIMENSION_NAMES.SMALL}
          >
            {getText('pages.orders.summaryBox.waitingForConfirmation')}
          </Text>
        )}
        <Actions
          actions={orderActions2use}
          onClick={handleClickAction}
          dimension={Button.DIMENSION_NAMES.SMALL}
          actionInProgress={actionInProgress}
          width='100%'
        />
      </TitledSection>
    </Fragment>
  );
});

OrdersSummaryBox.displayName = 'OrdersSummaryBox';
OrdersSummaryBox.propTypes = {
  shopMode: PropTypes.bool,
  readOnly: PropTypes.bool,
};
OrdersSummaryBox.defaultProp = {};

export { OrdersSummaryBox };
