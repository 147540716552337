export const sizesNames = {
  micro: 'micro',
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const mapSizes = {
  [sizesNames.micro]: '24px',
  [sizesNames.small]: '40px',
  [sizesNames.medium]: '80px',
  [sizesNames.large]: '160px',
};
