import { memo } from 'react';
import PropTypes from 'prop-types';
import { ErrorText, Icon } from '@sdflc/ui';

const InputError = memo((props) => {
  const { name, result } = props;
  const errorText = result.getErrorSummary(name);

  return errorText ? (
    <ErrorText>
      <Icon name='Error' />
      {errorText}
    </ErrorText>
  ) : null;
});

InputError.displayName = 'InputError';

InputError.propTypes = {
  name: PropTypes.string,
  result: PropTypes.any,
};

InputError.defaultProps = {
  name: '',
};

export { InputError };
